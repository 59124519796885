import { ISlideshow } from './../types/slideshow';
import { createAction } from 'redux-act';
import { IRemoveSlideshow } from '../../modules/types';

export const fetchSlideshowsList = createAction<{slideshowIds: string[], galleryID: string}>('FETCH_SLIDESHOWS_LIST');
export const fetchSlideshowsListSuccess = createAction<any>();
export const removeSlideshow = createAction<IRemoveSlideshow>('REMOVE_SLIDESHOW');
export const clearFetchedSlideshowsList = createAction('CLEAR_FETCHED_SLIDESHOWS_LIST');
export const changeSlideshowPublishStatus = createAction<{status: string; id: string}>('CHANGE_SLIDESHOW_PUBLISH_STATUS');
export const changeSlideshowPublishStatusSuccess = createAction<{slideshowsList: ISlideshow[]}>('CHANGE_SLIDESHOW_PUBLISH_STATUS_SUCCESS');
