import React from 'react';
import BookingFooter from './BookingFooter';

const getImageUrl = (image) => {
  if (image === 'default' || image === '/assets/images/booking-background-2.jpg') {
    return '/assets/images/booking-background-2.jpg';
  }

  return `${window.CDN_URL}/public/${image}`;
};

const BookingSiteContainer = ({ bookingSite: { image, title }, children }) => (
  <div
    className="booking-background"
    style={{
      backgroundImage: 'url(' + getImageUrl(image) + ')'
    }}
  >
    <div className="container-fluid">
      <div className="row justify-content-center p-t-20 p-b-20">
        <div className="col-12 col-sm-12 col-md-8 col-lg-6">
          <div className="booking-wrapper">
            <h1>{title}</h1>
            <div className="booking-content">{children}</div>
            <BookingFooter />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default BookingSiteContainer;
