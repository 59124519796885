import moment from 'moment';

const dateHelpers = {
  convertGermanDateStringToMoment: (dateString) => {
    try {
      if (typeof dateString !== 'string') {
        return null;
      }
      // 28.04.2020 - 21:48
      const dateTimeParts = dateString.split('-');
      const dateParts = dateTimeParts[0].split('.');
      const timeParts = dateTimeParts[1] && dateTimeParts[1].split(':');

      /* console.log(dateTimeParts);
      console.log(dateParts);
      console.log(timeParts); */

      const momentDate = moment({
        day: parseInt(dateParts[0]),
        month: parseInt(dateParts[1]) - 1, // momentsJs January is = 0
        year: parseInt(dateParts[2].trim())
      });

      if (timeParts) {
        momentDate.hour(parseInt(timeParts[0]));
        momentDate.minute(parseInt(timeParts[1]));
      }

      // console.log(momentDate);

      return momentDate;
    } catch (e) {
      // console.log(e);
      return null;
    }
  },

  sortMoments(a, b) {
    try {
      const dateA = dateHelpers.convertGermanDateStringToMoment(a);
      const dateB = dateHelpers.convertGermanDateStringToMoment(b);

      if (!dateA && !dateB) {
        return 0;
      }

      if ((!dateA && dateB) || dateA.isAfter(dateB)) {
        return 1;
      }

      if ((dateA && !dateB) || dateB.isAfter(dateA)) {
        return -1;
      }

      return 0;
    } catch (e) {
      return 0;
    }
  },

  convertMiliseconds(miliseconds, format) {
    const total_seconds = Math.floor(miliseconds / 1000);
    const total_minutes = Math.floor(total_seconds / 60);
    const total_hours = Math.floor(total_minutes / 60);
    const days = Math.floor(total_hours / 24);

    switch (format) {
      case 's':
        return total_seconds;
      case 'm':
        return total_minutes;
      case 'h':
        return total_hours;
      case 'd':
        return days;
      default:
        return {
          seconds: total_seconds,
          minutes: total_minutes,
          hours: total_hours,
          days
        };
    }
  }
};

export default dateHelpers;
