import React from 'react';
import { connect } from 'react-redux';
import Texts from '../../../json/texts.json';
import * as actions from '../../../actions';
import { Input, Label } from 'reactstrap';

const LanguageSelectSoftware = ({ auth, updateDefaultUserLanguages }) => (
  <div>
    <Label className="font-weight-bold">{Texts[window.LANGUAGE_SW].selectLanguageSoftware}:</Label>
    <Input
      type="select"
      value={auth.languageSoftware}
      onChange={({ target: { value } }) => {
        updateDefaultUserLanguages({
          languageSoftware: value
        });
      }}
    >
      {Object.keys(Texts[window.LANGUAGE_SW].languages).map((languageKey) => (
        <option key={languageKey} value={languageKey}>
          {Texts[window.LANGUAGE_SW].languages[languageKey]}
        </option>
      ))}
    </Input>
  </div>
);

const mapStateToProps = ({ auth }) => ({
  auth
});

export default connect(mapStateToProps, actions)(LanguageSelectSoftware);
