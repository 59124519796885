import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Button, Input } from 'reactstrap';
import * as actions from '../../../actions';
import * as actionsTs from '../../../modules/actions';
import Texts from '../../../json/texts.json';

class NewBrand extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      name: ''
    };

    this.toggle = this.toggle.bind(this);
    this.create = this.create.bind(this);
  }

  toggle() {
    const { setUpgradeAccountOpenState, availableRules = {} } = this.props;
    const isLimitExceeded = availableRules.brandsLeft === 0;

    if (isLimitExceeded) {
      return setUpgradeAccountOpenState({
        key: 'upgradeModal',
        state: true,
        desiredAction: 'brands',
        modalData: { addon: 'brands' }
      });
    }

    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  async create() {
    this.setState({ creating: true });

    const { createUserBrand, notify, onUserBrandCreated } = this.props;
    const userBrandId = await createUserBrand({ name: this.state.name }, notify);

    if (userBrandId && onUserBrandCreated) {
      this.setState({ isOpen: false, name: '' }, () => onUserBrandCreated(userBrandId));
    }

    this.setState({ creating: false });
  }

  render() {
    const { isOpen, name, creating } = this.state;
    const { disableBrandsCreation } = this.props;

    return (
      <div className="inline">
        <div onClick={disableBrandsCreation ? null : this.toggle}>{this.props.children}</div>
        {isOpen && (
          <Modal
            centered
            isOpen={isOpen}
            toggle={this.toggle}
            wrapClassName="modal-dark"
            size="sm"
            backdrop
          >
            <ModalHeader toggle={this.toggle}>
              {Texts[window.LANGUAGE_SW].userBrand.add}
            </ModalHeader>
            <ModalBody>
              <small>
                <b>{Texts[window.LANGUAGE_SW].userBrand.name}</b>
              </small>
              <Input
                className="mb-2 mt-2"
                value={name}
                type="text"
                onChange={({ target: { value } }) => this.setState({ name: value })}
                placeholder={Texts[window.LANGUAGE_SW].userBrand.namePlaceholder}
              />
              <Button
                color="primary"
                className="w-100-p"
                disabled={!name || creating}
                onClick={this.create}
              >
                {Texts[window.LANGUAGE_SW].userBrand.add}
              </Button>
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}

function mapStateToProps({ notify, accessRules: { availableRules } }) {
  return { notify, availableRules };
}
export default connect(mapStateToProps, { ...actions, ...actionsTs })(NewBrand);
