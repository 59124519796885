import React from 'react';
import { IPreset } from '../../modules/reducers/presets';
import { InputItem } from '../../components';
import { DEFAULT_TRACKING_INPUT_DATA, PRESET_TRACKING_DATA } from '../../modules/constants/presets';
import { getTranslationKey } from '../../modules/utils/helpers';
import { get, partial } from 'lodash';

interface IProps {
  preset: IPreset;
  onChangeCallback: (data: Partial<IPreset>) => void;
}

export const PresetTracking = ({ preset, onChangeCallback }: IProps) => {
  const { ga_id, gtm_id, fb_pixel_id } = preset;
  const ga = get(PRESET_TRACKING_DATA, 'ga_id', DEFAULT_TRACKING_INPUT_DATA);
  const gtm = get(PRESET_TRACKING_DATA, 'gtm_id', DEFAULT_TRACKING_INPUT_DATA);
  const fb_pixel = get(PRESET_TRACKING_DATA, 'fb_pixel_id', DEFAULT_TRACKING_INPUT_DATA);
  const changeFormHandler = (path: string, value: string | number | boolean) => {
    onChangeCallback({
      [path]: value
    });
  };

  return (
    <div>
      <InputItem
        value={ga_id}
        placeholder={getTranslationKey(ga.placeholder)}
        withTooltip
        label={getTranslationKey(ga.title)}
        id={ga.tooltip_id}
        tooltipText={getTranslationKey(ga.tooltip_text)}
        changeCallback={partial(changeFormHandler, 'ga_id')}
      />
      <InputItem
        value={gtm_id}
        placeholder={getTranslationKey(gtm.placeholder)}
        withTooltip
        label={getTranslationKey(gtm.title)}
        id={gtm.tooltip_id}
        tooltipText={getTranslationKey(gtm.tooltip_text)}
        changeCallback={partial(changeFormHandler, 'gtm_id')}
      />
      <InputItem
        value={fb_pixel_id}
        placeholder={getTranslationKey(fb_pixel.placeholder)}
        withTooltip
        label={getTranslationKey(fb_pixel.title)}
        id={fb_pixel.tooltip_id}
        tooltipText={getTranslationKey(fb_pixel.tooltip_text)}
        changeCallback={partial(changeFormHandler, 'fb_pixel_id')}
      />
    </div>
  );
};
