import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
  Progress,
  Alert
} from 'reactstrap';
import * as actions from '../../actions';
import Texts from '../../json/texts.json';
import history from '../../helpers/history';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { UserBrandSelection } from '../Brand/Brands';
import classnames from 'classnames';
import GalleryPresetSelect from './GalleryPresetSelect';
import { fetchGalleriesTags } from '../../modules/actions';
import { languageLocalizationMap } from '../../data/languageLocalizationMap';
import 'react-widgets/dist/css/react-widgets.css';
import moment from 'moment';

class CreateCollection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      title: '',
      type: props.userType === 'SB' ? 'ScrAppBook2' : 'ScrAppBook',
      step: 1,
      selectedTemplateId: 'ScrAppBook2_Breeze',
      showPreviewVideo: true,
      selectedTemplate: {},
      limitIsExceeded: true,
      showEmailConfirmation: false,
      emailConfirmationBody: Texts[window.LANGUAGE_SW].showEmailConfirmation.message,
      hideEmailConfirmationButton: false,
      isSmallScreen: window.innerWidth < 576
    };

    this.toggle = this.toggle.bind(this);
    this.emailConfirmationToggle = this.emailConfirmationToggle.bind(this);
    this.createCollection = this.createCollection.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
    this.handleCreateKeyPress = this.handleCreateKeyPress.bind(this);
    this.setStep = this.setStep.bind(this);
    this.sendConfirmationEmail = this.sendConfirmationEmail.bind(this);

    moment.locale(languageLocalizationMap[window.LANGUAGE_SW]);
  }

  componentDidMount() {
    if (this.props.onboardingProcess) window.scroll(0, 0);
  }

  componentDidUpdate(prevProps, prevState) {
    const { scrappbookTemplates, collectionsIds, registrationProcess, userType } = this.props;
    const { selectedTemplate } = this.state;

    if (
      (prevProps.scrappbookTemplates.length === 0 && scrappbookTemplates.length > 0) ||
      ((!selectedTemplate || !selectedTemplate._id) && scrappbookTemplates.length > 0)
    ) {
      const _selectedTemplate = scrappbookTemplates.find(
        (t) => t._id === this.state.selectedTemplateId
      );

      this.setState({ selectedTemplate: _selectedTemplate });
    }

    if (
      selectedTemplate &&
      prevState.selectedTemplate &&
      selectedTemplate._id !== prevState.selectedTemplate._id
    ) {
      this.setState(
        {
          showPreviewVideo: false
        },
        () => window.setTimeout(() => this.setState({ showPreviewVideo: true }), 500)
      );
    }

    if (
      (prevProps.collectionsIds.length === 0 && collectionsIds.length > 0) ||
      (prevProps.collectionsIds.length > 0 && collectionsIds.length === 0)
    ) {
      this.setState({
        showEmailConfirmation:
          collectionsIds.length > 0 && !registrationProcess.confirmed && userType === 'SB'
      });
    }
  }

  handleCreateKeyPress(target) {
    if (target.charCode === 13) {
      this.state.step === 1 && this.props.userType === 'SB'
        ? this.setStep()
        : this.createCollection();
    }
  }

  onChangeText(value, key) {
    this.setState({ [key]: value });
  }

  setStep() {
    if (!this.state.title) {
      return this.props.notify({
        title: Texts[window.LANGUAGE_SW].missingInput,
        message: Texts[window.LANGUAGE_SW].collection.titleRequired,
        status: 'warning',
        position: 'tc',
        dismissible: true,
        dismissAfter: 5000
      });
    }

    this.setState((prevState) => ({ step: prevState.step === 1 ? 2 : 1 }));
  }

  async createCollection() {
    const { setCollectionsModalOpenState, getCollectionsIds, fetchGalleriesTags } = this.props;
    const { name, title, eventDate, userBrand, selectedTemplate, galleryPreset } = this.state;
    this.setState({ loading: true });
    const { collection_id, err } = await this.props.createCollection({
      name,
      title: title || Texts[window.LANGUAGE_SW].standardTitle,
      eventDate,
      templateId: selectedTemplate._id || 'ScrAppBook2_SimpleFiligree',
      userBrand: userBrand || this.props.defaultUserBrand._id || this.props.defaultUserBrand,
      galleryPresetId: galleryPreset && galleryPreset._id
    });

    if (err === 'generalError') {
      this.props.notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].generalError,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 5000
      });
    } else if (err === 'templatePurchase') {
      this.props.notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].sb_templates.templateRequired,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 10000
      });
    } else if (collection_id) {
      this.setState({ name: '', title: '', step: 1, loading: false });
      history.push('/collection/edit/' + collection_id);
      setCollectionsModalOpenState({ key: 'collectionModal', state: false });
      getCollectionsIds({ withInitialCollectionsCount: false });
      fetchGalleriesTags();
    }

    setCollectionsModalOpenState({ key: 'collectionModal', state: false });
    this.setState({ loading: false });
  }

  toggle() {
    const { setCollectionsModalOpenState, collectionModal } = this.props;

    const newCollectionModalOpenState = !collectionModal;

    setCollectionsModalOpenState({ key: 'collectionModal', state: newCollectionModalOpenState });
    this.refs.title && this.refs.title.focus(); // eslint-disable-line

    if (newCollectionModalOpenState && this.props.availableRules.collectionsLeft > 0) {
      this.props.trackEventAction({
        name: 'modal-window-opened',
        payload: {
          modal_id: 'create-collection',
          url: window.location.href
        }
      });
    }
  }

  emailConfirmationToggle() {
    if (this.state.hideEmailConfirmationButton) {
      return;
    }

    const { setCollectionsModalOpenState, collectionModal } = this.props;
    setCollectionsModalOpenState({ key: 'collectionModal', state: !collectionModal });
  }

  async sendConfirmationEmail() {
    this.setState({ loading: true });
    const { sendConfirmationEmail } = this.props;
    const response = await sendConfirmationEmail();
    if (!response) {
      this.setState({
        emailConfirmationBody: Texts[window.LANGUAGE_SW].generalError
      });

      return;
    }

    if (response.error) {
      this.setState({
        emailConfirmationBody: Texts[window.LANGUAGE_SW].generalError
      });

      return;
    }

    this.setState({
      emailConfirmationBody: Texts[window.LANGUAGE_SW].showEmailConfirmation.success,
      loading: false,
      hideEmailConfirmationButton: true
    });
  }

  renderGalleryPresetSelection() {
    return (
      <div className="m-b-10">
        <div className="mb-1">
          <small className="pt-tt-preset-selection-gallery-creation">
            <b>{Texts[window.LANGUAGE_SW].collection.selectGalleryPreset}</b>
          </small>
        </div>
        <GalleryPresetSelect
          selectedPresetId={this.state.galleryPreset && this.state.galleryPreset._id}
          onChange={(galleryPreset) => {
            try {
              const update = {
                galleryPreset
              };

              if (galleryPreset._userbrand) {
                update.userBrand = galleryPreset._userbrand;
              }
              if (galleryPreset._scrappbookGalleryTemplate) {
                const selectedTemplate = this.props.scrappbookTemplates.find(
                  (t) => t._id === galleryPreset._scrappbookGalleryTemplate
                );
                if (selectedTemplate) {
                  update.selectedTemplateId = selectedTemplate._id;
                  update.selectedTemplate = selectedTemplate;
                }
              }

              this.setState(update);
            } catch (e) {
              console.log(e);
            }
          }}
        />
      </div>
    );
  }

  renderBrandSelection() {
    return (
      <div>
        <div className="mb-1">
          <small className="pt-tt-brand-selection-gallery-creation">
            <b>
              {this.props.userType === 'SB'
                ? Texts[window.LANGUAGE_SW].collection.selectUserBrandSB
                : Texts[window.LANGUAGE_SW].collection.selectUserBrand}
            </b>
          </small>
        </div>
        <UserBrandSelection
          selectedUserBrand={this.state.userBrand}
          onChange={(userBrand) => this.setState({ userBrand })}
        />
      </div>
    );
  }

  renderModalBody() {
    const { subscription, scrappbookTemplates } = this.props;

    if (this.state.showEmailConfirmation) {
      return (
        <div>
          <div
            dangerouslySetInnerHTML={{
              __html: this.state.emailConfirmationBody
            }}
          />
          <div className="row">
            <div className="col-lg-12">
              {this.state.loading && (
                <div>
                  <Progress className="m-t-10 w-100-p" animated color="success" value="100">
                    {Texts[window.LANGUAGE_SW].showEmailConfirmation.loading}
                  </Progress>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.state.step === 1 ? (
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-1">
                <small>
                  <b>{Texts[window.LANGUAGE_SW].collection.title}</b>
                </small>
              </div>
              <InputGroup className="m-b-10">
                <input
                  data-cy="modal-title"
                  className="form-control help_create_collection_title"
                  autoFocus
                  ref="title" // eslint-disable-line
                  onChange={({ target: { value } }) => {
                    this.onChangeText(value, 'title');
                  }}
                  value={this.state.title}
                  placeholder={Texts[window.LANGUAGE_SW].collection.title}
                />
                <InputGroupAddon addonType="append" id="createGalleryTitleTooltip">
                  <InputGroupText>
                    <i className="fa fa-icon fa-question-circle" />
                  </InputGroupText>
                </InputGroupAddon>

                <UncontrolledTooltip placement="right" target="createGalleryTitleTooltip">
                  {Texts[window.LANGUAGE_SW].collectionTitleTooltip}
                </UncontrolledTooltip>
              </InputGroup>
              <div className="mb-1">
                <small>
                  <b>{Texts[window.LANGUAGE_SW].collection.name}</b>
                </small>
              </div>
              <InputGroup className="m-b-10">
                <Input
                  data-cy="modal-customer-name"
                  className="help_create_collection_intern"
                  onChange={({ target: { value } }) => this.onChangeText(value, 'name')}
                  value={this.state.name}
                  placeholder={Texts[window.LANGUAGE_SW].collection.name}
                />
                <InputGroupAddon addonType="append" id="createGalleryNameTooltip">
                  <InputGroupText>
                    <i className="fa fa-icon fa-question-circle" />
                  </InputGroupText>
                </InputGroupAddon>

                <UncontrolledTooltip placement="right" target="createGalleryNameTooltip">
                  {Texts[window.LANGUAGE_SW].interNameToolTip}
                </UncontrolledTooltip>
              </InputGroup>

              <InputGroup className="m-b-10">
                <div className="mb-1">
                  <small>
                    <b>{Texts[window.LANGUAGE_SW].collection.eventDate}</b>
                  </small>
                </div>
                <DateTimePicker
                  className="w-100-p"
                  time={false}
                  placeholder={Texts[window.LANGUAGE_SW].eventDate}
                  onChange={(eventDate) => this.setState({ eventDate })}
                />
              </InputGroup>

              {this.props.userType !== 'SB' && this.renderBrandSelection()}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-12">
              {!this.props.onboardingProcess && this.renderGalleryPresetSelection()}
              {!this.props.onboardingProcess && this.renderBrandSelection()}

              <div className="mb-1 m-t-10">
                <small>
                  <b>{Texts[window.LANGUAGE_SW].collection.selectDesign}</b>
                </small>
              </div>

              {scrappbookTemplates.length > 0 ? (
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <Input
                      type="select"
                      data-cy="help_collection_template_create"
                      className="help_collection_template_create"
                      onChange={({ target: { value } }) => {
                        const selectedTemplate = scrappbookTemplates.find((t) => t._id === value);
                        this.setState({
                          selectedTemplateId: value,
                          selectedTemplate
                        });
                      }}
                      value={this.state.selectedTemplateId}
                    >
                      {scrappbookTemplates.map(
                        (template) =>
                          (!this.props.onboardingProcess ||
                            (this.props.onboardingProcess &&
                              template.userIsAllowedToUseTemplate)) && (
                            <option key={template._id} value={template._id}>
                              {template.displayName}
                            </option>
                          )
                      )}
                    </Input>
                    <p className="m-t-15 h-150">
                      {
                        Texts[window.LANGUAGE_SW].sb_templates.descriptions[
                          this.state.selectedTemplateId
                        ]
                      }
                    </p>
                    {!this.state.selectedTemplate.inDevelopment && !this.props.onboardingProcess && (
                      <a
                        className="btn btn-primary w-100-p"
                        target="_blank"
                        href={this.state.selectedTemplate.previewUrl}
                        rel="noopener noreferrer"
                      >
                        {Texts[window.LANGUAGE_SW].template_preview}
                      </a>
                    )}
                    {!this.state.selectedTemplate.userIsAllowedToUseTemplate &&
                      this.state.selectedTemplate.isPurchable && (
                        <div className="mt-3">
                          {this.state.selectedTemplate.presale ? (
                            <div>
                              {!subscription.purchased_templates.includes(
                                this.state.selectedTemplate.name
                              ) ? (
                                <Alert color="warning">
                                  {Texts[window.LANGUAGE_SW].sb_templates.templatePresale}
                                </Alert>
                              ) : (
                                <Alert color="warning">
                                  {
                                    Texts[window.LANGUAGE_SW].sb_templates
                                      .templatePresaleAlreadyPurchased
                                  }
                                </Alert>
                              )}
                            </div>
                          ) : (
                            <Alert color="danger">
                              {Texts[window.LANGUAGE_SW].templateMoreInformation}
                            </Alert>
                          )}
                          <button
                            className="btn btn-primary w-100-p m-b-10"
                            onClick={() => {
                              this.props.setModalOpenState({
                                key: 'upgradeModal',
                                state: true,
                                modalData: {
                                  addon: 'template',
                                  templateId: this.state.selectedTemplate._id
                                }
                              });
                              this.toggle();
                              this.props.logUserActivity({
                                action_data: this.state.selectedTemplate.name,
                                action: 'GO_TO_TEMPLATE_LP',
                                logAsHubpostNote: true,
                                leadScore: 2
                              });
                            }}
                          >
                            {Texts[window.LANGUAGE_SW].purchase_template}
                          </button>
                        </div>
                      )}
                  </div>
                  <div className="col-12 col-sm-6">{this.renderTemplatePreview()}</div>
                </div>
              ) : (
                <p>loading</p>
              )}
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-12">
            {this.state.loading && (
              <div>
                <Progress className="m-t-10 w-100-p" animated color="success" value="100">
                  {Texts[window.LANGUAGE_SW].creatingSite}
                </Progress>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  renderModalFooter() {
    if (this.state.showEmailConfirmation) {
      return (
        <div>
          {!this.state.hideEmailConfirmationButton && (
            <button
              className={classnames('btn btn-primary help_collection_template_create pull-right', {
                'w-100-p': this.props.onboardingProcess,
                'm-t-10': this.props.onboardingProcess
              })}
              onClick={this.sendConfirmationEmail}
              disabled={this.state.loading}
            >
              {Texts[window.LANGUAGE_SW].showEmailConfirmation.button}
            </button>
          )}
        </div>
      );
    }

    return this.state.isSmallScreen ? (
      <div className="d-block w-100-p">
        <button
          className={classnames(
            'btn btn-primary pt-create-gallery gtm-create-gallery pull-right w-100-p',
            {
              'm-t-10': this.props.onboardingProcess
            }
          )}
          onClick={this.createCollection}
          disabled={this.state.loading}
        >
          {Texts[window.LANGUAGE_SW].create}
        </button>
      </div>
    ) : (
      <div>
        {!this.props.onboardingProcess && (
          <button
            className="btn btn-danger pull-left m-r-5"
            onClick={this.state.step === 1 ? this.toggle : this.setStep}
          >
            {this.state.step === 1
              ? Texts[window.LANGUAGE_SW].abort
              : Texts[window.LANGUAGE_SW].back}
          </button>
        )}
        <button
          data-cy="modal-title-field"
          className={classnames('btn btn-primary pt-create-gallery pull-right', {
            'w-100-p': this.props.onboardingProcess,
            'm-t-10': this.props.onboardingProcess,
            'gtm-create-gallery': this.state.step === 2 && this.props.userType === 'SB'
          })}
          onClick={
            this.state.step === 1 && this.props.userType === 'SB'
              ? this.setStep
              : this.createCollection
          }
          disabled={this.state.loading}
        >
          {this.state.step === 1 && this.props.userType === 'SB'
            ? Texts[window.LANGUAGE_SW].nextStep
            : Texts[window.LANGUAGE_SW].create}
        </button>
      </div>
    );
  }

  renderTemplatePreview() {
    return this.state.showPreviewVideo ? (
      <div className="template-preview">
        <div className="videowrap">
          <video playsInline autoPlay loop muted>
            <source type="video/mp4" src={this.state.selectedTemplate.assetUrl} />
          </video>

          <div className="videoframe">
            <img
              className="img-fluid"
              src="https://cf.scrappbook.de/wp-content/uploads/2019/09/Header-Smartphone-Mockup-1.png"
              alt=""
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="template-preview">
        <div className="videowrap">
          <div className="videoframe">
            <img
              className="img-fluid"
              src="https://cf.scrappbook.de/wp-content/uploads/2019/09/Header-Smartphone-Mockup-1.png"
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }

  renderModals() {
    const { setUpgradeAccountOpenState, availableRules = {}, collectionModal } = this.props;
    const limitIsExceeded = collectionModal && availableRules.collectionsLeft === 0;

    if (limitIsExceeded) {
      setUpgradeAccountOpenState({
        key: 'upgradeModal',
        state: true,
        callback: this.toggle,
        desiredAction: 'collections'
      });

      return;
    }

    if (this.state.showEmailConfirmation) {
      if (!this.state.hideEmailConfirmationButton) {
        return (
          <Modal
            centered
            isOpen={collectionModal}
            wrapClassName="modal-dark"
            size="md"
            onKeyPress={this.handleCreateKeyPress}
            toggle={this.emailConfirmationToggle}
          >
            <ModalHeader toggle={this.emailConfirmationToggle}>
              {Texts[window.LANGUAGE_SW].showEmailConfirmation.title}
            </ModalHeader>
            <ModalBody>{this.renderModalBody()}</ModalBody>
            <ModalFooter>{this.renderModalFooter()}</ModalFooter>
          </Modal>
        );
      }

      return (
        <Modal
          centered
          isOpen={collectionModal}
          wrapClassName="modal-dark"
          size="md"
          onKeyPress={this.handleCreateKeyPress}
        >
          <ModalHeader>{Texts[window.LANGUAGE_SW].showEmailConfirmation.title}</ModalHeader>
          <ModalBody>{this.renderModalBody()}</ModalBody>
          <ModalFooter>{this.renderModalFooter()}</ModalFooter>
        </Modal>
      );
    }

    return (
      <Modal
        centered
        isOpen={collectionModal}
        toggle={this.toggle}
        wrapClassName="modal-dark"
        size="lg"
        onKeyPress={this.handleCreateKeyPress}
      >
        <ModalHeader toggle={this.toggle}>
          {this.props.userType === 'SB'
            ? Texts[window.LANGUAGE_SW].collection.createScrAppBook
            : Texts[window.LANGUAGE_SW].collection.create}
        </ModalHeader>
        <ModalBody>{this.renderModalBody()}</ModalBody>
        <ModalFooter>{this.renderModalFooter()}</ModalFooter>
      </Modal>
    );
  }

  render() {
    const { renderWithOutModal } = this.props;

    return (
      <div className="inline">
        {!renderWithOutModal && (
          <button
            id="create-collection-button"
            className="btn btn-success help_create_collection"
            onClick={this.toggle}
          >
            <i className="fa fa-icon fa-plus" />{' '}
            <span className="d-sm-down-none">
              {this.props.userType === 'SB'
                ? Texts[window.LANGUAGE_SW].collection.createScrAppBook
                : Texts[window.LANGUAGE_SW].collection.create}
            </span>
            <span className="d-md-none">
              {this.props.userType === 'SB'
                ? Texts[window.LANGUAGE_SW].collection.scrappbook
                : Texts[window.LANGUAGE_SW].collection.collection}
            </span>
          </button>
        )}

        {renderWithOutModal ? (
          <>
            {this.renderModalBody()}
            {this.renderModalFooter()}
          </>
        ) : (
          <>{this.renderModals()}</>
        )}
      </div>
    );
  }
}

function mapStateToProps({
  auth: {
    defaultUserBrand,
    type,
    additionalFeatures,
    subscription,
    digistore,
    email,
    registrationProcess
  },
  collections: { scrappbookTemplates, ids },
  accessRules: { availableRules },
  modals: { collectionModal }
}) {
  return {
    defaultUserBrand,
    additionalFeatures,
    userType: type,
    subscription,
    digistore,
    availableRules,
    affiliate: digistore.discountKey ? digistore.discountKey.affiliate : null,
    userEmail: email,
    scrappbookTemplates,
    collectionModal,
    collectionsIds: ids,
    registrationProcess
  };
}

export default connect(mapStateToProps, { ...actions, fetchGalleriesTags })(CreateCollection);
