import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'reactstrap';

const UserBrandSelection = ({
  userBrands,
  onChange,
  defaultUserBrand,
  collection,
  selectedUserBrand
}) => (
  <Input
    type="select"
    onChange={({ target: { value } }) => onChange(value)}
    value={
      (collection && collection.userBrand) ||
      selectedUserBrand ||
      defaultUserBrand._id ||
      defaultUserBrand
    }
  >
    {Object.keys(userBrands).map(
      (key) =>
        userBrands[key] &&
        userBrands[key]._id && (
          <option key={key} value={userBrands[key]._id}>
            {userBrands[key].name}
          </option>
        )
    )}
  </Input>
);

function mapStateToProps({ userBrands, auth: { defaultUserBrand } }) {
  return { userBrands, defaultUserBrand };
}

export default connect(mapStateToProps, null)(UserBrandSelection);
