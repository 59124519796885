import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Row } from 'reactstrap';
import Texts from '../../../json/texts.json';
import { connect } from 'react-redux';
import InputGroup from '../../../elements/Forms/InputGroup';
import * as actions from '../../../actions';
import { Field, reduxForm } from 'redux-form';
import approve from 'approvejs';
import queryString from 'query-string';
import history from '../../../helpers/history';
import { sample } from 'lodash';
import { isLocalhost } from '../../../registerServiceWorker';

const fields = {
  email: {
    title: Texts[window.LANGUAGE_SW].email,
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].emailPlaceholder,

    rules: {
      email: {
        message: Texts[window.LANGUAGE_SW].emailErrorMessage
      }
    }
  },
  password: {
    type: 'password',
    placeholder: Texts[window.LANGUAGE_SW].password,
    title: Texts[window.LANGUAGE_SW].password,

    rules: {
      required: { message: Texts[window.LANGUAGE_SW].requiredInput }
    }
  }
};

class Login extends Component {
  constructor(props) {
    super(props);
    const { error, destinationAfterSignIn, confirmed, companyID, redirect } = queryString.parse(
      history.location.search
    );

    this.state = {
      error,
      destinationAfterSignIn,
      confirmed,
      backgroundImage: this.getRandomBackgroundUrl(),
      cannyCompanyId: companyID,
      cannyRedirectUrl: redirect
    };
  }

  componentDidMount() {
    if (this.props.notify) {
      if (this.state.confirmed) {
        this.props.notify({
          title: Texts[window.LANGUAGE_SW].userActivityLog.CONFIRMED,
          status: 'success',
          position: 'tc',
          dismissible: true,
          dismissAfter: 5000
        });
      }

      if (this.state.error === '1') {
        setTimeout(
          () =>
            this.props.notify({
              title: Texts[window.LANGUAGE_SW].error,
              message: Texts[window.LANGUAGE_SW].loginError,
              status: 'error',
              position: 'tc',
              dismissible: true,
              dismissAfter: 5000
            }),
          1000
        );
      }
    }
  }

  getRandomBackgroundUrl() {
    const backgroundUrls = [
      `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/builder/login/flying-camera.jpg`,
      `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/builder/login/camera-smartphone-laptop.jpg`,
      `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/builder/login/workspace.jpg`
    ];

    return sample(backgroundUrls);
  }

  submit = (values) => {
    const user = { ...values };
    user.email = user.email.toLowerCase();
    if (window.USER_TYPE) user.type = window.USER_TYPE;
    this.props.loginUser(user, this.props.notify);
  };

  render() {
    return (
      <div
        className="app flex-row align-items-center"
        style={{
          backgroundImage:
            window.USER_TYPE !== 'VST'
              ? `url(${this.state.backgroundImage})`
              : `url(${window.CDN_URL}/public/gallerypix-loginscreen.jpg)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: window.USER_TYPE !== 'VST' ? 'gray' : 'unset'
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={7}>
              <CardGroup>
                <Card>
                  <CardBody>
                    <Form
                      action={isLocalhost ? null : `${window.API_URL}/api/signin`}
                      method={isLocalhost ? null : 'POST'}
                      onSubmit={isLocalhost ? this.props.handleSubmit(this.submit) : null}
                    >
                      {window.USER_TYPE === 'SB' && (
                        <div className="text-center m-b-25">
                          <img
                            height="75"
                            src={process.env.REACT_APP_SB_LOGO_URL}
                            alt="scrappbook logo"
                            className="img-fluid"
                          />
                        </div>
                      )}

                      {Object.keys(fields).map((fieldKey, index) => (
                        <Field
                          component={InputGroup}
                          key={index}
                          name={fieldKey}
                          field={fields[fieldKey]}
                        />
                      ))}

                      <input
                        type="hidden"
                        name="destinationAfterSignIn"
                        value={this.state.destinationAfterSignIn}
                      />
                      <input type="hidden" name="type" value={window.USER_TYPE} />
                      <input
                        type="hidden"
                        name="loginOrigin"
                        value={encodeURIComponent(
                          `${window.location.protocol}//${window.location.host}`
                        )}
                      />
                      <input
                        type="hidden"
                        name="cannyCompanyId"
                        value={this.state.cannyCompanyId}
                      />
                      <input
                        type="hidden"
                        name="cannyRedirectUrl"
                        value={this.state.cannyRedirectUrl}
                      />
                      <Row>
                        <Col xs="12">
                          <Button
                            data-cy="component-login"
                            color="primary"
                            className="w-100-p"
                            disabled={this.props.invalid || this.props.submitting}
                            type="submit"
                          >
                            {Texts[window.LANGUAGE_SW].login}
                          </Button>
                        </Col>
                      </Row>
                      {window.USER_TYPE === 'SB' && (
                        <Row>
                          <Col className="m-t-10 text-center">
                            <a href={`${window.API_URL}/api/auth/facebook`}>Facebook Login</a>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col className="m-t-10 text-center">
                          <a href="/#/account/forgot-password/">
                            {Texts[window.LANGUAGE_SW].forgotPasswordTitle}?
                          </a>
                        </Col>
                      </Row>
                      {window.USER_TYPE === 'SB' && window.LANGUAGE_SW !== 2 && (
                        <Row>
                          <Col className="m-t-10 text-center">
                            <a
                              href={
                                window.IS_TEST
                                  ? '/#/register'
                                  : 'https://scrappbook.de/registrieren'
                              }
                            >
                              {Texts[window.LANGUAGE_SW].noAccount}
                            </a>
                          </Col>
                        </Row>
                      )}

                      {window.USER_TYPE === 'VST' && (
                        <Row className="m-t-20">
                          <Col xs="12">
                            <hr />
                            <a
                              target="_blank"
                              href="https://www.vst-pro.de/impressum/"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].imprint}
                            </a>
                            {' - '}
                            <a
                              target="_blank"
                              href="https://www.vst-pro.de/datenschutz/"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].privacy}
                            </a>
                            {' - '}
                            <a
                              target="_blank"
                              href="https://www.vst-pro.de/kontakt/"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].contact}
                            </a>
                            {' - '}
                            <a
                              target="_blank"
                              href="https://www.vst-pro.de/produktwelten/produkte/produkt/vst-gallerypix/"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].productInfos}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {window.USER_TYPE === 'SB' && (
                        <Row className="m-t-20">
                          <Col xs="12">
                            <hr />
                            <a
                              target="_blank"
                              href={process.env.REACT_APP_IMPRINT_LINK}
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].imprint}
                            </a>
                            {' - '}
                            <a
                              target="_blank"
                              href={process.env.REACT_APP_PRIVACY_LINK}
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].privacy}
                            </a>
                            {' - '}
                            <a
                              target="_blank"
                              href={process.env.REACT_APP_PRIVACY_LINK}
                              rel="noopener noreferrer"
                              onClick={(e) => {
                                try {
                                  window.Intercom('show');
                                  e.preventDefault();
                                } catch (error) {
                                  console.log(error);
                                }
                              }}
                            >
                              {Texts[window.LANGUAGE_SW].contact}
                            </a>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  Object.keys(fields).forEach((fieldKey) => {
    const result = approve.value(values[fieldKey], fields[fieldKey].rules);
    if (result.errors.length > 0) {
      errors[fieldKey] = result.errors;
    }
  });

  return errors;
}

Login = reduxForm({
  form: 'loginForm',
  validate
})(Login);

Login = connect(
  (state) => ({
    auth: state.auth,
    notify: state.notify
  }),
  actions
)(Login);

export default Login;
