import React, { Component } from 'react';
import Texts from '../../../json/texts.json';
import { Alert } from 'reactstrap';
import ConfirmData from '../../../elements/Booking/Customer/ConfirmData';
import axios from 'axios';
import queryString from 'query-string';
import BookingSiteContainer from './BookingSiteContainer';

class BookingConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      lead: {},
      bookingSite: {}
    };

    this.completePayment = this.completePayment.bind(this);
  }

  UNSAFE_componentWillMount() {
    const parsed = queryString.parse(this.props.location.search);
    const { paymentId, PayerID } = parsed;
    this.loadTransaction(paymentId);
    this.setState({ paymentId, PayerID });
  }

  async loadTransaction(paymentId) {
    if (paymentId) {
      try {
        const result = await axios.get(
          `/api/bookingPaymentTransaction?paypal_paymentId=${paymentId}`
        );
        const { product, lead, bookingSite, appointment, status } = result.data;
        this.setState({
          product,
          lead,
          bookingSite,
          paymentStatus: status,
          appointment
        });
      } catch (e) {}
    }
  }

  async completePayment() {
    try {
      this.setState({ loading: true });
      const { paymentId, PayerID, bookingSite } = this.state;
      await axios.post('/api/booking/approvepayment', {
        paymentId,
        PayerID
      });
      this.setState({ status: 'success' });
      if (bookingSite.thankYouUrl) window.location = bookingSite.thankYouUrl;
      else this.setState({ loading: false });
    } catch (e) {
      console.log(e);
      this.setState({ status: 'danger', loading: false });
    }
  }

  render() {
    const { bookingSite, status, paymentStatus } = this.state;
    if (!bookingSite._id || this.state.loading) {
      return (
        <div className="loading">
          <div className="loader" />
        </div>
      );
    }

    return (
      <BookingSiteContainer bookingSite={bookingSite}>
        <ConfirmData {...this.state} />
        <button
          className="btn btn-primary w-100-p btn-booking"
          onClick={this.completePayment}
          disabled={status === 'success' || paymentStatus === 'confirmed'}
        >
          {Texts[window.LANGUAGE_SW].bookMandatory}
        </button>
        {paymentStatus === 'confirmed' && (
          <Alert className="m-t-10" color="success">
            {Texts[0]['booking-already-done']}
          </Alert>
        )}
        {status && (
          <Alert className="m-t-10" color={status}>
            {Texts[0][`booking-${status}`]}
          </Alert>
        )}
      </BookingSiteContainer>
    );
  }
}

export default BookingConfirm;
