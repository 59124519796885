import React, { useState } from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Button,
  Badge,
  NavLink
} from 'reactstrap';
import { partial } from 'lodash';
import { getTranslationKey } from '../../modules/utils';
import { useSelector } from 'react-redux';
import { getUser } from '../../modules/selectors';

interface IProps {
  onlyLogout: boolean;
}

export const DefaultAppHeaderDropdown: React.FC<IProps> = ({ onlyLogout }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { digistore } = useSelector(getUser);
  const currentStrategy = true;

  return onlyLogout ? (
    <Nav className="ml-auto" navbar>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={partial(setDropdownOpen, !dropdownOpen)}
        className="custom-dropdown-position"
      >
        <DropdownToggle nav>
          <img src="assets/img/misc/user.png" className="img-avatar" alt="avatar" />
        </DropdownToggle>
        <DropdownMenu right style={{ right: 'auto' }}>
          <DropdownItem>
            <i className="fa fa-lock" />
            {getTranslationKey('logout')}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  ) : (
    <Nav className="ml-auto" navbar>
      {currentStrategy && (
        <NavItem className="d-md-down-none pr-3">
          <Button color="primary">
            <i className="fa fa-icon fa-rocket" /> {getTranslationKey('strategies.showCurrent')}
          </Button>
        </NavItem>
      )}

      <Dropdown
        isOpen={dropdownOpen}
        toggle={partial(setDropdownOpen, !dropdownOpen)}
        className="custom-dropdown-position"
      >
        <DropdownToggle nav>
          <img src="assets/img/misc/user.png" className="img-avatar" alt="User ico" />
        </DropdownToggle>
        <DropdownMenu right style={{ right: 'auto' }}>
          <DropdownItem header tag="div" className="text-center">
            <strong>{getTranslationKey('myAccount')}</strong>
          </DropdownItem>
          <DropdownItem href="#/account">
            <i className="fa fa-user" />
            {getTranslationKey('accountData')}
          </DropdownItem>
          {/* @ts-ignore */}
          <DropdownItem href={`${window.API_URL}/api/coaching`} target="_blank">
            <i className="fa fa-graduation-cap" />
            {getTranslationKey('leadSuiteAcademy')}
          </DropdownItem>

          <DropdownItem href="#/subscription">
            <i className="fa fa-file-text-o" />
            {getTranslationKey('aboManagement')}
          </DropdownItem>

          {digistore && digistore.discountKey && (
            <DropdownItem href="#/subscription/pricing">
              <i className="fa fa-gift" />
              {getTranslationKey('subscription.activeDiscountKey')}
              <Badge pill color="danger">
                1
              </Badge>
            </DropdownItem>
          )}

          <DropdownItem className="hidden" href="/#/gateways">
            <i className="fa fa-envelope-open" />
            {getTranslationKey('mailsuite.mailsuite')}
            <Badge pill color="danger">
              {getTranslationKey('newBadge')}
            </Badge>
          </DropdownItem>

          <DropdownItem>
            <NavLink to="/logout">
              <i className="fa fa-lock" />
              {getTranslationKey('logout')}
            </NavLink>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Nav>
  );
};
