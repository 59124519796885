import { createAction } from 'redux-act';
import { ISubscriptionReducerState } from '../reducers/subscription';
import {
  ICancelPayload,
  IRestartSubscription,
  IUpdateBillingAddressPayload
} from '../types/subscriptions';
import { ISetupIntentFailed } from '../types/subscription';

export const updateSubscriptionStore = createAction<Partial<ISubscriptionReducerState>>();
export const buySubscription = createAction();

export const cancelSubscription = createAction<ICancelPayload>();
export const restartSubscription = createAction<IRestartSubscription>();

export const updateBillingAddress = createAction<IUpdateBillingAddressPayload>();

export const setupIntentFailed = createAction<ISetupIntentFailed>();
