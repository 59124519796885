export default function (state = { all: [] }, action) {
  switch (action.type) {
    case 'SET_ALL_DOMAINS':
      return { ...state, all: action.payload };
    case 'ADD_DOMAIN':
      return { ...state, all: [action.payload, ...state.all] };
    case 'REMOVE_DOMAIN':
      return {
        ...state,
        all: state.all.filter((domain) => domain._id !== action.payload)
      };
    default:
      return state;
  }
}
