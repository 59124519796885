import 'core-js';
import 'get-root-node-polyfill/implement';
import './setupIndexHtml';
import './registerUnknownFunctions';
import './storeQueryUtmToCookies';
import './addCookieFunctionToWindow';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import axios from './helpers/axios';
import store from './modules/store';
import App from './App';
import { sleep } from './helpers/globalFunctions';

import BigCalendar from 'react-big-calendar';
import momentLocalizer from 'react-widgets-moment';
import moment from 'moment';
import 'moment/locale/de.js';

moment.locale('de');
momentLocalizer();
BigCalendar.momentLocalizer(moment);
require('es6-promise').polyfill();

const logToServer = async function (message, attempt = 0) {
  try {
    console.log('[LOG TO SERVER] =>', message);
    await axios.post('/api/log', { message });
  } catch (e) {
    console.log('[LOG TO SERVER] => FAILED - WAITING');
    await sleep(5000 * (attempt + 1));

    if (attempt < 20) return await logToServer(message, attempt + 1);
    console.log('logToServer failed 20 times... give up');
  }
};

window.logToServer = (message) => logToServer(message);

console.log('check auth flag =>', localStorage.getItem('auth'));

if (localStorage.getItem('auth')) store.dispatch({ type: 'AUTH_USER' });

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
