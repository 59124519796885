import { Buffer, buffers, SagaIterator } from 'redux-saga';
import {
  actionChannel,
  all,
  call,
  cancelled,
  delay,
  flush,
  fork,
  put,
  race,
  select,
  take
} from 'redux-saga/effects';
import Api from '../../../modules/utils/API';
import {
  addImagesToUploadSession,
  changeModalState,
  cleanUpImagesSuccess,
  clearImagesUploadSession,
  clearSelected,
  closeFullscreen,
  duplicatesAction,
  errorsGlobalError,
  fetchCollectionSuccess,
  fetchImages,
  fetchImagesSuccess,
  fetchPresetsAction,
  fetchSinglePortfolioAction,
  galleriesUploadingClearAction,
  galleryUploadingClearAction,
  galleryUploadingClearSuccess,
  imageUploadingNext,
  moveSelectedImagesToGallery,
  pauseUploading,
  resetImagesSelectionsFilter,
  restoreUploading,
  selectImage,
  selectImageSuccess,
  showNotifySuccess,
  startUploadingProcess,
  stopAllUpload,
  stopUploading,
  trackEventAction,
  updateGallery,
  updateImageUploadSessionData
} from '../../actions';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import {
  getCollectionId,
  getFullscreenState,
  getGalleriesList,
  getGalleryById,
  getGalleryImagesByGalleryID,
  getGalleryImagesDuplicateIDS,
  getImageByID,
  getImagesList,
  getSelectedImagesIDs,
  getSelectionByCollectionId,
  getUploadedImagesList,
  getUploadPauseState,
  getUploadSessionList,
  sortImages
} from '../../selectors';
import {
  IDeleteGalleryAction,
  IDeleteHeaderImageAction,
  IDragImageAction,
  IDragMultiImageAction,
  IGetSighUrlParams,
  IImageDimensions,
  IMoveImagesToGallery,
  ISagaAction,
  ISelectSelectionImageAction,
  ISetFocalPointParams,
  ISetGifMetaDataAction,
  IToggleImageWatermarkAction,
  IUploadImagesAction
} from '../../types';
import { IGallery, IImage } from '../../reducers';
import {
  chain,
  cloneDeep,
  difference,
  filter,
  find,
  findIndex,
  first,
  flatten,
  get,
  keyBy,
  maxBy,
  uniq,
  uniqBy,
  uniqueId
} from 'lodash';
import { multipleAttempts } from '../global';
import { uploadImageToS3 } from '../collections';
import moment from 'moment';
import {
  arrayMoveHelper,
  getDuplicateId,
  getOrderedDnDImages,
  getTranslationKey,
  isImageSizeAccepted,
  processImageDimensions
} from '../../utils';
import { DEFAULT_IMAGE } from '../../constants';
/* tslint:disable */
import Texts from '../../../json/texts.json';
import { setModalOpenState } from '../../../actions';

export const fetchImagesSaga = function* (): SagaIterator {
  try {
    let collectionID = yield select(getCollectionId);

    if (!Boolean(collectionID.length)) {
      yield take(fetchCollectionSuccess);
    }
    collectionID = yield select(getCollectionId);
    const response = yield call(Api.Images.get, {
      collection_id: collectionID,
      ownerRequest: true
    });
    ApiErrors.checkOnApiError(response);

    const appIcon = find(response, { isIcon: true }) || {};
    const items = filter(response, (image) => !image.isIcon && image.status !== 'deleting');
    yield put(fetchImagesSuccess({ items, appIcon }));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const setImageTitleSaga = function* ({ payload }: ISagaAction<IImage>): SagaIterator {
  try {
    const { _id, gallery_id } = payload;
    const images = yield select(getImagesList);

    const response = yield call(Api.Images.setImageTitle, {
      gallery_id,
      image_id: _id,
      isForSmallScreen: false
    });
    ApiErrors.checkOnApiError(response);

    const updated = images.map((image: IImage) =>
      image.gallery_id === gallery_id
        ? {
            ...image,
            isTitleImage: image._id === _id,
            focalPoints: {
              ...image.focalPoints,
              desktop: null
            }
          }
        : image
    );

    yield put(fetchImagesSuccess({ items: updated }));
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const setFocalPointSaga = function* ({
  payload
}: ISagaAction<ISetFocalPointParams>): SagaIterator {
  try {
    const { portfolioId, image_id, focalPoint, screenType } = payload;
    const response = yield call(Api.Images.setFocalPoint, payload);
    ApiErrors.checkOnApiError(response);
    if (portfolioId) {
      yield put(fetchSinglePortfolioAction({ portfolioId }));
    }

    const images = yield select(getImagesList);
    const updated = images.map((image: IImage) =>
      image._id === image_id
        ? {
            ...image,
            focalPoints: {
              ...image.focalPoints,
              [screenType]: focalPoint
            }
          }
        : image
    );

    yield put(fetchImagesSuccess({ items: updated }));
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const setSmallScreenImageTitleSaga = function* ({
  payload
}: ISagaAction<IImage>): SagaIterator {
  try {
    const { _id, gallery_id } = payload;
    const images = yield select(getImagesList);

    const response = yield call(Api.Images.setImageTitle, {
      gallery_id,
      image_id: _id,
      isForSmallScreen: true
    });
    ApiErrors.checkOnApiError(response);

    const updated = images.map((image: IImage) =>
      image.gallery_id === gallery_id
        ? {
            ...image,
            isSmallScreenTitleImage: image._id === _id,
            focalPoints: {
              ...image.focalPoints,
              mobile: null
            }
          }
        : image
    );

    yield put(fetchImagesSuccess({ items: updated }));
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const deleteImageSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.Images.delete, {
      image_id: payload
    });
    ApiErrors.checkOnApiError(response);

    const fullscreenState = yield select(getFullscreenState);
    let images: IImage[] = yield select(getImagesList);
    const deletedImageIndex = images.findIndex((image: IImage) => image._id === payload);
    const deletedImage = images[deletedImageIndex];
    images = images.filter((image: IImage) => image._id !== payload);
    const gallery = yield select((state) => getGalleryById(state)(deletedImage.gallery_id));

    if (gallery.sortBy === 'manualOrder') {
      images = fixGalleryManualOrder(images, gallery);
    }

    if (!images.length && fullscreenState.isOpen) {
      yield put(closeFullscreen());
    }

    yield put(fetchImagesSuccess({ items: images }));
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const deleteImagesSaga = function* (): SagaIterator {
  try {
    const images: IImage[] = yield select(getImagesList);
    const selected = yield select(getSelectedImagesIDs);
    const collectionID = yield select(getCollectionId);

    const response = yield call(Api.Images.deleteByIDs, {
      collection_id: collectionID,
      image_ids: selected
    });

    ApiErrors.checkOnApiError(response);

    const galleries: IGallery[] = yield select(getGalleriesList);
    const affectedGalleriesIds = images
      .filter((image) => selected.includes(image._id))
      .map((image) => image.gallery_id);
    const galleriesToFix = galleries.filter(
      (gallery) => affectedGalleriesIds.includes(gallery._id) && gallery.sortBy === 'manualOrder'
    );
    const updated = images.filter((image: IImage) => Boolean(selected.indexOf(image._id) === -1));
    const imagesWithCorrectManualOrder = fixGalleriesManualOrder(updated, galleriesToFix);

    yield put(fetchImagesSuccess({ items: imagesWithCorrectManualOrder }));
    yield put(clearSelected());
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

function fixGalleryManualOrder(allImages: IImage[], gallery: IGallery): IImage[] {
  const images = cloneDeep(allImages);
  const galleryImages = images.filter((image) => image.gallery_id === gallery._id);
  const imagesWithCorrectManualOrder = sortImages(
    galleryImages,
    gallery.sortBy,
    gallery.sortOrder
  ).map((image, idx) => ({ ...image, manualOrder: idx }));

  images.forEach((image) => {
    const imageToUpdate = imagesWithCorrectManualOrder.find((img) => img._id === image._id);
    if (imageToUpdate) {
      image.manualOrder = imageToUpdate.manualOrder;
    }
  });

  return images;
}

function fixGalleriesManualOrder(allImages: IImage[], galleries: IGallery[]): IImage[] {
  let images = cloneDeep(allImages);
  galleries.forEach((gallery) => {
    images = fixGalleryManualOrder(images, gallery);
  });

  return images;
}

export const deleteGalleryImagesSaga = function* ({
  payload
}: ISagaAction<IDeleteGalleryAction>): SagaIterator {
  try {
    const { gallery_id } = payload;
    const images = yield select(getImagesList);
    const collectionID = yield select(getCollectionId);

    const response = yield call(Api.Images.deleteByIDs, {
      collection_id: collectionID,
      gallery_id
    });

    ApiErrors.checkOnApiError(response);
    const updated = images.filter((image: IImage) => image.gallery_id !== gallery_id);
    yield put(fetchImagesSuccess({ items: updated }));
    yield put(showNotifySuccess({}));
    yield put(stopUploading(gallery_id));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const selectImageSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  const selected = yield select(getSelectedImagesIDs);

  let IDs = selected;
  if (selected.includes(payload)) {
    IDs = selected.filter((id: string) => id !== payload);
  } else {
    IDs.push(payload);
  }
  yield put(resetImagesSelectionsFilter(true));
  yield put(selectImageSuccess([...IDs]));
};

export const selectImagesSaga = function* ({ payload }: ISagaAction<string[]>): SagaIterator {
  yield put(resetImagesSelectionsFilter(true));
  yield put(selectImageSuccess([...payload]));
};

export const invertSelectedImagesSaga = function* (): SagaIterator {
  const selected = yield select(getSelectedImagesIDs);
  const allImages = yield select(getUploadedImagesList);
  const allImagesIDs = allImages.map(({ _id }: IImage) => _id);
  yield put(selectImageSuccess(difference(allImagesIDs, selected)));
};

export const moveSelectedImagesToGallerySaga = function* ({
  payload
}: ISagaAction<string>): SagaIterator {
  const selectedImages = yield select(getSelectedImagesIDs);
  yield call(moveImagesToGallerySaga, {
    galleryID: payload,
    imagesIDs: selectedImages
  });
  yield put(clearSelected());
};

export const moveImagesToGallerySaga = function* ({
  galleryID,
  imagesIDs,
  isSilent
}: IMoveImagesToGallery): SagaIterator {
  try {
    const images = yield select(getImagesList);
    const collectionID = yield select(getCollectionId);

    const response = yield call(Api.Images.moveTo, {
      collection_id: collectionID,
      target_gallery_id: galleryID,
      image_ids: imagesIDs,
      addToTheEnd: true
    });

    ApiErrors.checkOnApiError(response);
    const updated = uniqBy(response.concat(images), '_id');

    const galleries: IGallery[] = yield select(getGalleriesList);
    const galleriesToFix = galleries.filter((gallery) => gallery.sortBy === 'manualOrder');
    const imagesWithCorrectManualOrder = fixGalleriesManualOrder(updated as any, galleriesToFix);

    yield put(fetchImagesSuccess({ items: imagesWithCorrectManualOrder as any }));
    if (!isSilent) {
      yield put(showNotifySuccess({}));
    }
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const actionDropImageSaga = function* ({
  payload
}: ISagaAction<IDragImageAction>): SagaIterator {
  try {
    const { drop_id, drag_id, gallery_id, images } = payload;

    let galleryImages = images;

    const allImage = yield select(getImagesList);
    const dragImage = find(allImage, { _id: drag_id });
    const dropImage = find(allImage, { _id: drop_id });
    const collectionID = yield select(getCollectionId);
    const gallery = yield select((state) => getGalleryById(state)(gallery_id));
    const isAnotherGallery = dragImage.gallery_id !== gallery_id;
    if (isAnotherGallery) {
      yield put(selectImage(drag_id));
      yield put(moveSelectedImagesToGallery(gallery_id));
      yield take(clearSelected);
      dragImage.gallery_id = gallery_id;
      dragImage._watermark = gallery._watermark;
    }

    const defaultLength = galleryImages.length > 0 ? galleryImages.length : 0;
    const dragIndex = findIndex(galleryImages, (image: IImage) => image._id === drag_id);
    let targetIndex = dropImage
      ? findIndex(galleryImages, (image: IImage) => image._id === dropImage._id)
      : defaultLength;

    if (dragIndex < targetIndex && !isAnotherGallery) {
      targetIndex = targetIndex - 1;
    }

    if (isAnotherGallery) {
      galleryImages.splice(targetIndex, 0, dragImage);
    }

    const movedImages = yield select(getImagesList);
    let updated = !isAnotherGallery
      ? arrayMoveHelper(galleryImages, dragIndex, targetIndex)
      : galleryImages;
    updated = updated.map((image: IImage, index: number) => ({
      ...image,
      manualOrder: index
    }));

    yield put(fetchImagesSuccess({ items: uniqBy(updated.concat(movedImages), '_id') }));

    const response = yield call(Api.Images.order, {
      collection_id: collectionID,
      gallery_id,
      image_id: drag_id,
      order: targetIndex
    });
    ApiErrors.checkOnApiError(response);

    if (gallery.sortBy !== 'manualOrder') {
      yield put(updateGallery({ gallery_id, data: { sortBy: 'manualOrder' }, isSilent: true }));
    }

    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const actionDropMultiImageSaga = function* ({
  payload
}: ISagaAction<IDragMultiImageAction>): SagaIterator {
  try {
    const { drop_id, gallery_id, images, isMoveOnly } = payload;

    const collectionID = yield select(getCollectionId);
    const selectedIDs = yield select(getSelectedImagesIDs);
    const allImage = yield select(getImagesList);
    const galleries = yield select(getGalleriesList);
    const dropImage = find(allImage, { _id: drop_id });
    const gallery = yield select((state) => getGalleryById(state)(gallery_id));
    const {
      imagesToMove,
      images: orderedDropImages,
      targetImagesIDS
    } = getOrderedDnDImages(selectedIDs, allImage, galleries, gallery_id);
    const galleryImages = images.filter((image) => !targetImagesIDS.includes(image._id));
    const allGalleriesImages = images.filter((image) => image.gallery_id === gallery_id);

    if (imagesToMove.length) {
      yield call(moveImagesToGallerySaga, {
        galleryID: gallery_id,
        imagesIDs: imagesToMove,
        isSilent: true
      });
    }

    if (isMoveOnly) {
      yield put(showNotifySuccess({}));
      return;
    }

    const defaultLength = galleryImages.length > 0 ? galleryImages.length : 0;
    const firstDragIndex = allGalleriesImages.findIndex(
      (image) => image._id === targetImagesIDS[0]
    );

    let targetIndex = dropImage
      ? findIndex(galleryImages, (image: IImage) => image._id === dropImage._id)
      : defaultLength;

    if (firstDragIndex <= targetIndex) {
      targetIndex += 1;
    }

    // @ts-ignore
    galleryImages.splice(targetIndex, 0, orderedDropImages);

    const movedImages = yield select(getImagesList);

    const updated = flatten(galleryImages).map((image: IImage, index: number) => ({
      ...image,
      manualOrder: index,
      gallery_id,
      _watermark: gallery._watermark
    }));

    // @ts-ignore
    yield put(fetchImagesSuccess({ items: uniqBy(updated.concat(movedImages), '_id') }));

    const response = yield call(Api.Images.multipleOrder, {
      collection_id: collectionID,
      gallery_id,
      images: updated.map((image) => ({
        image_id: image._id,
        order: image.manualOrder
      }))
    });
    ApiErrors.checkOnApiError(response);

    if (gallery.sortBy !== 'manualOrder') {
      yield put(updateGallery({ gallery_id, data: { sortBy: 'manualOrder' }, isSilent: true }));
    }

    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const toggleImageWatermarkSaga = function* ({
  payload
}: ISagaAction<IToggleImageWatermarkAction>): SagaIterator {
  try {
    const collectionID = yield select(getCollectionId);
    const response = yield call(Api.Images.toggleWatermark, {
      collection_id: collectionID,
      ...payload
    });
    ApiErrors.checkOnApiError(response);

    const images = yield select(getImagesList);
    const updatedImages = images.map((image: IImage) =>
      response._id === image._id ? response : image
    );
    yield put(fetchImagesSuccess({ items: updatedImages }));
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

let offlineImagesQueue: IImage[] = [];

export function* checkAndUploadOfflineImagesQueueSaga() {
  while (true) {
    yield delay(10_000);

    if (navigator.onLine && offlineImagesQueue.length) {
      for (const offlineImage of offlineImagesQueue) {
        yield fork(uploadImageSaga, offlineImage);
      }

      offlineImagesQueue = [];
    }
  }
}

function* refetchImagesSaga(times: number = 1, delayTime: number = 1000) {
  for (let i = 0; i <= times; i++) {
    yield delay(delayTime);
    yield put(fetchImages());
  }
}

let newDuplicateImagesQueu: IImage[] = [];

function* checkAndUploadDuplicateImagesSaga(buffer: Buffer<any>) {
  if (buffer.isEmpty() && newDuplicateImagesQueu.length) {
    for (const image of newDuplicateImagesQueu) {
      const galleryImages: IImage[] = yield select((state) =>
        getGalleryImagesByGalleryID(state)(image.gallery_id)
      );

      const duplicateImage = galleryImages.find(
        (uplodedImage: IImage) =>
          uplodedImage.gallery_id === image.gallery_id &&
          uplodedImage.originalImageName === image.originalImageName
      );

      yield put(clearImagesUploadSession());
      yield put(addImagesToUploadSession({ albumId: image.gallery_id, images: [image] }));
      yield fork(uploadImageSaga, { ...image, duplicateId: duplicateImage._id });
    }

    newDuplicateImagesQueu = [];
  }
}

export function* watchRequest(): SagaIterator {
  const buffer: Buffer<any> = buffers.expanding();
  const requestChannel = yield actionChannel(startUploadingProcess, buffer);

  try {
    while (true) {
      const action = yield take(requestChannel);
      const { stopAll, singleCancel } = yield race({
        task: call(startUploadingProcessSaga, action),
        stopAll: take(stopAllUpload),
        singleCancel: take(stopUploading)
      });

      const collectionId = yield select(getCollectionId);

      if (singleCancel && singleCancel.payload) {
        yield put(galleryUploadingClearAction({ gallery_id: singleCancel.payload }));
        yield call(clearUploadingAboardImageSaga, singleCancel.payload);
        yield put(
          trackEventAction({
            name: 'images-upload-canceled',
            payload: {
              collection_id: collectionId,
              album_id: get(first(action.payload), 'gallery_id'),
              images_count: action.payload.length
            }
          })
        );

        yield call(refetchImagesSaga);
        continue;
      }

      if (stopAll !== undefined && stopAll.payload) {
        yield flush(requestChannel);
        yield put(galleriesUploadingClearAction());
        yield put(
          trackEventAction({
            name: 'images-upload-canceled',
            payload: {
              collection_id: collectionId,
              album_id: get(first(action.payload), 'gallery_id'),
              images_count: action.payload.length
            }
          })
        );

        yield call(refetchImagesSaga, 5);
      }

      yield call(checkAndUploadDuplicateImagesSaga, buffer);
    }
  } finally {
    if (yield cancelled()) {
      console.log('cancelled');
    }
  }
}

export const uploadImagesSaga = function* ({
  payload
}: ISagaAction<IUploadImagesAction>): SagaIterator {
  // Start uploading
  const { gallery_id, files } = payload;
  const gallery = yield select((state) => getGalleryById(state)(gallery_id));
  const isInUploading = get(gallery, 'uploadingProcess.start', false);
  const uploadSessionImages = yield select(getUploadSessionList);
  const galleryImages = yield select((state) => getGalleryImagesByGalleryID(state)(gallery_id));
  const maxImageOrder = maxBy(galleryImages, (image: IImage) => image.manualOrder) || {
    manualOrder: -1
  };
  let maxOrder = maxImageOrder.manualOrder;
  // Get Duplicates
  // @ts-ignore
  const duplicatesSelector = yield select(getGalleryImagesDuplicateIDS);

  const uploadSesstionImagesArr = Object.values(uploadSessionImages).flat();
  let duplicates = duplicatesSelector(gallery_id, files, uploadSesstionImagesArr);

  let imagesList: File[] = files;
  if (duplicates.length) {
    yield put(pauseUploading(true));
    // Ask to replace duplicates
    yield put(changeModalState({ key: 'duplicatesImagesModal', state: true }));

    const { payload: duplicatesPayload } = yield take(duplicatesAction);
    // If Skip remove duplicates and remove that images from upload
    if (duplicatesPayload === 'skip') {
      const duplicatesNames = duplicates.map((item: any) => item.name);
      imagesList = files.filter((file) => duplicatesNames.indexOf(file.name) === -1);
      duplicates = [];
    }
    //If User will upload next chunk of images to the same gallery
    if (duplicatesPayload === 'replace' && isInUploading) {
      const galleryImagesOnReplace = yield select((state) =>
        getGalleryImagesByGalleryID(state)(gallery_id)
      );
      const duplicatedIds = duplicates.map((dup: any) => dup.id);
      const imagesWithFilesForReplace = filter(
        galleryImagesOnReplace,
        (image) => galleryImages.file && duplicatedIds.includes(image._id)
      ).map((image) => image.name);
      const imagesWithFilesNotReplace = filter(
        galleryImagesOnReplace,
        (image) => image.status !== 'uploaded' && !duplicatedIds.includes(image._id)
      ).map((image) => image.file);
      const newFiles = filter(files, (file) => !imagesWithFilesForReplace.includes(file.name));
      imagesList = [...imagesWithFilesNotReplace, ...newFiles];
      yield put(stopUploading(gallery_id));
      yield all([take(galleryUploadingClearSuccess), take(cleanUpImagesSuccess)]);
    }
  }

  if (!imagesList.length) return;

  // Create preload images to show up in the progress modal
  const tmpImages: IImage[] = imagesList.map((image) => {
    maxOrder++;
    const duplicateId = getDuplicateId(duplicates, image);
    const uploadSizeError =
      image.type === 'image/gif'
        ? getTranslationKey('uploadSizeError.gif', { fileName: image.name })
        : getTranslationKey('uploadSizeError.general', { fileName: image.name });
    return {
      ...DEFAULT_IMAGE,
      uploadedAt: moment().format(),
      _id: uniqueId(`uploading_image_`),
      manualOrder: maxOrder,
      compressionStatus: 'none',
      file: image,
      gallery_id,
      mimeType: image.type,
      originalImageName: image.name,
      size: image.size,
      status: 'uploading',
      duplicateId,
      uploadingStatus: 'pre-upload',
      error: !isImageSizeAccepted(image.size, image.type) ? uploadSizeError : '',
      errorType: !isImageSizeAccepted(image.size, image.type) ? 'warning' : ''
    };
  });

  const imagesWithoutErrors = tmpImages.filter(
    (image: IImage) => !(image.error && image.error.length)
  );

  if (!imagesWithoutErrors.length) return;

  const isPaused = yield select(getUploadPauseState);

  if (isPaused) {
    yield put(restoreUploading(true));
    // yield take(imagesChunkUploadingSuccess);
  }

  // Start uploading process to server
  yield put(
    addImagesToUploadSession({
      albumId: get(first(imagesWithoutErrors), 'gallery_id'),
      images: imagesWithoutErrors
    })
  );
  yield put(setModalOpenState({ key: 'progressUploadModal', state: true }));
  yield put(startUploadingProcess(imagesWithoutErrors));
};

export const startUploadingProcessSaga = function* ({ payload }: ISagaAction<any>): SagaIterator {
  const collectionId = yield select(getCollectionId);

  yield put(
    trackEventAction({
      name: 'images-upload-started',
      payload: {
        collection_id: collectionId,
        album_id: get(first(payload), 'gallery_id'),
        images_count: payload.length
      }
    })
  );

  for (const image of payload) {
    const storeInImage = yield select((state) => getImageByID(state)(image._id));

    if (storeInImage.stopUploading || image.error?.length) continue;

    // image with the same name is uploading atm.
    if (image.duplicateId.includes('uploading_image_')) {
      newDuplicateImagesQueu.push(image);
      continue;
    }

    // Fire upload action per image without blocking
    yield fork(uploadImageSaga, image);

    // Wait for upload success or cancel
    const [uploaded, is_paused] = yield race([take(imageUploadingNext), take(pauseUploading)]);

    const isCurrentPaused = get(is_paused, 'payload', '');

    // If pause action was fired we will wait for resume action
    if (isCurrentPaused) {
      yield take(restoreUploading);

      yield put(
        trackEventAction({
          name: 'images-upload-paused',
          payload: {
            collection_id: collectionId,
            album_id: get(first(payload), 'gallery_id'),
            images_count: payload.length
          }
        })
      );
    }

    // Start new upload after success
    if (uploaded) continue;
  }

  yield put(
    trackEventAction({
      name: 'images-upload-finished',
      payload: {
        collection_id: collectionId,
        album_id: get(first(payload), 'gallery_id'),
        images_count: payload.length
      }
    })
  );

  // yield put(imagesChunkUploadingSuccess());
};

const singUrlRequest = function* (data: IGetSighUrlParams, image: IImage): SagaIterator {
  const response = yield call(multipleAttempts(Api.Images.getSignUrl), data);
  const { storageLimitReached = false, maintenance_mode = false } = response;

  if (response.error) {
    yield call(updateImageDataSaga, image, {
      error: getTranslationKey('errors.signedURLRequest'),
      errorType: 'error'
    });
    return { image };
  } else if (storageLimitReached) {
    yield put(setModalOpenState({ key: 'spaceLimitReachedModal', state: true }));
    yield put(setModalOpenState({ key: 'progressUploadModal', state: false }));

    yield put(clearImagesUploadSession());
    yield put(stopAllUpload(true));

    return { image };
  } else if (maintenance_mode) {
    yield call(updateImageDataSaga, image, {
      error: getTranslationKey('maintenance.common'),
      errorType: 'warning'
    });
    return { image };
  } else {
    return { ...response, image, newImage: response.newImage };
  }
};

const S3Request = function* (singUrlRequestResponse: any): SagaIterator {
  const { signedUrl, image, newImage, attempt } = singUrlRequestResponse;
  if (!singUrlRequestResponse.signedUrl) {
    yield put(imageUploadingNext());
    return;
  }
  const response = yield call(multipleAttempts(uploadImageToS3), signedUrl, image.file);
  if (!response) {
    yield call(updateImageDataSaga, newImage, {
      // @ts-ignore
      error: Texts[window.LANGUAGE_SW].errors.s3Request.replace(
        '${ image.originalImageName }', // eslint-disable-line
        newImage.originalImageName
      ),
      errorType: 'error',
      uploadingStatus: 'fail'
    });
  } else {
    yield put(imageUploadingNext());
    return { image, newImage, signedUrl, attempt };
  }
};

const checkIsImageOnS3Saga = function* (s3Response: any): SagaIterator {
  if (!s3Response) {
    yield put(imageUploadingNext());
    return;
  }

  const { newImage, image, attempt } = s3Response;

  const response = yield call(multipleAttempts(Api.Images.checkS3Exist), {
    image_id: newImage._id,
    original: true
  });

  if (response.error) {
    if (attempt < 4) {
      console.warn('==========================');
      console.warn(`Attempt ${attempt}`);
      console.warn('==========================');
      const s3SecondResponse = yield call(S3Request, {
        ...s3Response,
        attempt: attempt + 1
      });
      return yield call(checkIsImageOnS3Saga, s3SecondResponse);
    } else {
      yield call(updateImageDataSaga, newImage, {
        // @ts-ignore
        error: Texts[window.LANGUAGE_SW].errors.s3Request.replace(
          '${ image.originalImageName }', // eslint-disable-line
          newImage.originalImageName
        ),
        errorType: 'error',
        uploadingStatus: 'fail'
      });
    }
  } else {
    return { newImage, image };
  }
};

const processGifUpload = function* (s3Response: any, file: File): SagaIterator {
  const { newImage } = s3Response;
  const dimensionsResponse: IImageDimensions | null = yield call(processImageDimensions, file);
  let dimensions = dimensionsResponse;

  if (!dimensions) {
    dimensions = { width: 0, height: 0 };
  }

  const setGifresponse = yield call(Api.Images.setGifMetaData, newImage._id, dimensions);

  if (setGifresponse.error || !setGifresponse.result.value) {
    yield call(updateImageDataSaga, newImage, {
      // @ts-ignore
      error: Texts[window.LANGUAGE_SW].errors.processGifUpload.replace(
        '${ image.originalImageName }', // eslint-disable-line
        newImage.originalImageName
      ),
      errorType: 'error'
    });
    yield put(
      updateImageUploadSessionData({
        albumId: newImage.gallery_id,
        image: { ...newImage, status: 'compressionError' }
      })
    );
    yield put(imageUploadingNext());
  } else {
    yield delay(500);
    yield call(updateImageDataSaga, setGifresponse.result.value, {
      uploadingStatus: 'uploaded',
      uploadedAt: newImage.uploadedAt
    });
    yield put(
      updateImageUploadSessionData({
        albumId: newImage.gallery_id,
        image: { ...newImage, status: 'uploaded' }
      })
    );
  }
};

const compressRequest = function* (s3Response: any): SagaIterator {
  const { newImage } = s3Response;

  // yield call(updateImageDataSaga, image, { uploadingStatus: 'compressing' });
  const response = yield call(multipleAttempts(Api.Images.compress), { image_id: newImage._id });
  if (response.error || response.compressionStatus === 'error') {
    yield call(updateImageDataSaga, newImage, {
      // @ts-ignore
      error: Texts[window.LANGUAGE_SW].errors.s3Request.replace(
        '${ image.originalImageName }',
        newImage.originalImageName
      ),
      errorType: 'error'
    });
    yield put(
      updateImageUploadSessionData({
        albumId: newImage.gallery_id,
        image: { ...newImage, status: 'compressionError' }
      })
    );
    yield put(imageUploadingNext());
  } else {
    yield delay(500);
    yield call(updateImageDataSaga, response, {
      uploadingStatus: 'uploaded',
      uploadedAt: newImage.uploadedAt
    });
    yield put(
      updateImageUploadSessionData({
        albumId: newImage.gallery_id,
        image: { ...newImage, status: 'uploaded' }
      })
    );
  }
};

export const uploadImageSaga = function* (payload: IImage): SagaIterator {
  const collection_id = yield select(getCollectionId);
  const { gallery_id, originalImageName, size, duplicateId } = payload;

  const uploadingData: IGetSighUrlParams = {
    collection_id,
    gallery_id,
    originalImageName,
    imageSize: size,
    builderRequest: true
  };

  if (duplicateId) {
    uploadingData.replace = true;
    uploadingData.existing_image_id = duplicateId || '';
  }

  // yield call(updateImageDataSaga, payload, { uploadingStatus: 'uploading' });

  const singUrlRequestResponse = yield call(singUrlRequest, uploadingData, payload);
  const s3Response = yield call(S3Request, { ...singUrlRequestResponse, attempt: 0 });
  const checkS3response = yield call(checkIsImageOnS3Saga, s3Response);

  if (!s3Response) {
    offlineImagesQueue.push(payload);
    yield put(imageUploadingNext());
  } else {
    if (s3Response.newImage.extension.toLowerCase() === 'gif') {
      yield call(processGifUpload, checkS3response, payload.file);
    } else {
      yield call(compressRequest, checkS3response);
    }
  }
};

const updateImageDataSaga = function* (newImage: IImage, data: Partial<IImage>): SagaIterator {
  const images = cloneDeep(yield select(getImagesList));

  const isFromDifferentGalleries = (image: IImage) => image.gallery_id !== newImage.gallery_id;
  const isFromSameGalleryWithDifferentNames = (image: IImage) =>
    image.gallery_id === newImage.gallery_id &&
    image.originalImageName !== newImage.originalImageName;

  const imagesUniq = images.filter(
    (image: IImage) => isFromDifferentGalleries(image) || isFromSameGalleryWithDifferentNames(image)
  );

  const duplicateImageIdx = findIndex(imagesUniq, {
    originalImageName: newImage.originalImageName,
    gallery_id: newImage.gallery_id
  });

  if (duplicateImageIdx >= 0 && newImage) {
    imagesUniq.splice(duplicateImageIdx, 1, newImage);
  }
  if (duplicateImageIdx < 0 && newImage) {
    imagesUniq.push({ ...newImage, ...data });
  }

  yield put(fetchImagesSuccess({ items: imagesUniq }));
};

export const updateSingleImageDataSaga = function* ({ payload }: ISagaAction<any>): SagaIterator {
  const { image, data } = payload;
  yield call(updateImageDataSaga, image, data);
};

const clearUploadingAboardImageSaga = function* (gallery_id: string): SagaIterator {
  const images = yield select(getImagesList);
  const updatedImages = filter(
    images,
    (image: IImage) => !(image.status === 'uploading' && image.gallery_id === gallery_id)
  );
  yield put(cleanUpImagesSuccess({ items: updatedImages }));
};

export const selectSelectionImagesByFilterSaga = function* ({
  payload
}: ISagaAction<ISelectSelectionImageAction>): SagaIterator {
  const contestID = yield select(getCollectionId);
  const images = yield select(getUploadedImagesList);
  const imagesIDs = images.map(({ _id }: IImage) => _id);
  const selections = yield select((state) => getSelectionByCollectionId(state)(contestID));
  const favorites = uniq(
    chain(selections)
      .map((selection) => filter(selection.favorites, { like: true }))
      .flatten()
      .map((favorite) => favorite._image._id)
      .value()
  );

  const isAll = find(payload, { value: 'all' });
  const isLiked = find(payload, { value: 'liked' });
  const notLiked = find(payload, { value: 'notLiked' });
  const isSelectionSelected = Boolean(
    find(payload, (imageFilter) => imageFilter.value.startsWith('selection'))
  );
  const isGallerySelected = Boolean(
    find(payload, (imageFilter) => imageFilter.value.startsWith('gallery'))
  );

  let selected: string[] = [];

  if (isAll) {
    selected = imagesIDs;
  } else if (isLiked) {
    selected = favorites;
  } else if (notLiked) {
    selected = difference(imagesIDs, favorites);
  } else if (isSelectionSelected || isGallerySelected) {
    const selectionsKeyBy = keyBy(selections, '_id');
    const selectionImages = chain(payload)
      .map((imageFilter) => {
        const selectionID = imageFilter.value.replace('selection_', '');
        const selectionFavorites = selectionsKeyBy[selectionID];
        return selectionFavorites ? filter(selectionFavorites.favorites, { like: true }) : [];
      })
      .flatten()
      .map((favorite) => favorite._image._id)
      .value();

    const galleriesImages = chain(payload)
      .map((imageFilter) => {
        const galleryID = imageFilter.value.replace('gallery_', '');
        return images.filter((image: IImage) => image.gallery_id === galleryID);
      })
      .flatten()
      .map((image) => image._id)
      .value();
    selected = uniq([...selectionImages, ...galleriesImages]);
  }

  yield put(selectImageSuccess(selected));
};

export const setGifMetaDataSaga = function* ({
  payload
}: ISagaAction<ISetGifMetaDataAction>): SagaIterator {
  try {
    yield call(Api.Images.setGifMetaData, payload.image_id, payload.dimensions);
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const deleteHeaderImageSaga = function* ({
  payload
}: ISagaAction<IDeleteHeaderImageAction>): SagaIterator {
  try {
    const response = yield call(Api.Images.deleteHeaderImage, payload.imageId, {
      collectionId: payload.collectionId,
      galleryPresetId: payload.galleryPresetId
    });

    ApiErrors.checkOnApiError(response);

    if (payload.galleryPresetId) {
      yield put(fetchPresetsAction());
    }

    if (payload.collectionId) {
      const images = yield select(getImagesList);
      const updated = images.filter((image: IImage) => image._id !== payload.imageId);
      yield put(fetchImagesSuccess({ items: updated }));
    }

    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};
