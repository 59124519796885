export function config(
  state = {
    layout: 'top-navigation-1',
    background: 'dark',
    navbar: 'dark',
    topNavigation: 'info',
    collapsed: true,
    logo: 'info',
    leftSidebar: 'light',
    rightSidebar: false,
    hideNavbar: false
  },
  action
) {
  switch (action.type) {
    case 'SET_CONFIG':
      return { ...state, ...action.config };
    default:
      return state;
  }
}
