import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import * as actions from '../../actions';
import Texts from '../../json/texts.json';
import { Field, reduxForm } from 'redux-form';
import InputGroup from '../Forms/InputGroup';
import approve from 'approvejs';

const fields = {
  description: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].description,
    tooltip_id: 'imprintDescription',
    tooltip_text: Texts[window.LANGUAGE_SW].customDomains.descriptionTooltip,
    title: Texts[window.LANGUAGE_SW].description,
    rules: {
      required: { message: Texts[window.LANGUAGE_SW].requiredInput }
    }
  },
  url: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].customDomains.url,
    tooltip_id: 'urlToolTip',
    tooltip_text: Texts[window.LANGUAGE_SW].customDomains.urlToolTip,
    title: Texts[window.LANGUAGE_SW].customDomains.url,
    rules: {}
  },
  imprint: {
    type: 'textarea',
    placeholder: Texts[window.LANGUAGE_SW].customDomains.imprint,
    tooltip_id: 'imprintTooltip',
    tooltip_text: Texts[window.LANGUAGE_SW].customDomains.imprintTooltip,
    title: Texts[window.LANGUAGE_SW].customDomains.imprint,
    rules: {}
  }
};

class Imprint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedImprint: props.selectedImprint || 'select'
    };

    this.toggle = this.toggle.bind(this);
    this.submit = this.submit.bind(this);
    this.onSelectImprint = this.onSelectImprint.bind(this);
    this.delete = this.delete.bind(this);
  }

  onSelectImprint({ target: { value } }) {
    if (this.props.mode === 'edit') {
      const currentImprint = this.props.imprints.all.filter((imprint) => imprint._id === value)[0];
      if (currentImprint) {
        this.setState({ currentImprintId: value });
        this.props.change('description', currentImprint.description);
        this.props.change('url', currentImprint.url);
        this.props.change('imprint', currentImprint.imprint);
        this.toggle();
      }
    } else {
      if (value === 'new') this.toggle();
      else {
        this.setState({ selectedImprint: value }, () => {
          const { onSelectedImprintChanged, selectedImprint, domain } = this.props;
          if (onSelectedImprintChanged) {
            onSelectedImprintChanged(this.state.selectedImprint);
          }
          if (selectedImprint && domain) {
            this.props.updateDomain({
              _id: domain,
              imprint: this.state.selectedImprint
            });
          }
        });
      }
    }
  }

  toggle() {
    this.setState(
      (prevState) => ({ isOpen: !prevState.isOpen }),
      () => {
        if (!this.state.isOpen) this.props.reset();
      }
    );
  }

  submit(values) {
    if (this.props.mode === 'edit') {
      this.props.editImprint({ ...values, _id: this.state.currentImprintId }, this.props.notify);
    } else this.props.createImprint(values, this.props.notify);
    this.toggle();
  }

  delete() {
    this.props.deleteImprint(this.state.currentImprintId, this.props.notify);
    this.toggle();
  }

  render() {
    const imprints = this.props.imprints.all;
    const { mode } = this.props;
    const label =
      mode === 'edit'
        ? Texts[window.LANGUAGE_SW].customDomains.editImprint
        : Texts[window.LANGUAGE_SW].customDomains.createImprint;

    return (
      <div className="inline">
        <FormGroup>
          <div>
            <Input
              type="select"
              name="product"
              value={this.state.selectedImprint}
              onChange={this.onSelectImprint}
            >
              {mode === 'edit' && (
                <option value="select" disabled>
                  {Texts[window.LANGUAGE_SW].customDomains.editImprint}
                </option>
              )}
              {!mode && (
                <option value="select" disabled>
                  {Texts[window.LANGUAGE_SW].pleaseSelect}
                </option>
              )}
              {imprints.map((imprint, key) => (
                <option value={imprint._id} key={key}>
                  {imprint.description}
                </option>
              ))}
            </Input>
          </div>
        </FormGroup>
        <Modal
          centered
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          wrapClassName="modal-primary"
          size="default"
        >
          <ModalHeader toggle={this.toggle}>{label}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <form onSubmit={this.props.handleSubmit(this.submit)}>
                  {Object.keys(fields).map((fieldKey, index) => (
                    <Field
                      component={InputGroup}
                      key={index}
                      name={fieldKey}
                      field={fields[fieldKey]}
                    />
                  ))}

                  <button
                    className="btn btn-primary w-100-p"
                    type="submit"
                    disabled={this.props.pristine || this.props.invalid || this.props.submitting}
                  >
                    {label}
                  </button>
                </form>
                <a
                  href="http://www.impressum-generator.de/impressum-generator/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {Texts[window.LANGUAGE_SW].customDomains.imprintGenerator}
                </a>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {mode === 'edit' && (
              <small className="cursor-pointer " onClick={this.delete}>
                <i className="sli-trash m-r-5" />
                {`${Texts[window.LANGUAGE_SW].imprint} ${Texts[window.LANGUAGE_SW].delete}`}
              </small>
            )}
            <button className="btn btn-danger pull-left" onClick={this.toggle}>
              {Texts[window.LANGUAGE_SW].abort}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.url && !values.imprint) {
    const error = [Texts[window.LANGUAGE_SW].minOneRequired];
    errors.imprint = error;
    errors.url = error;
  }

  Object.keys(fields).forEach((fieldKey) => {
    const result = approve.value(values[fieldKey], fields[fieldKey].rules);
    if (result.errors.length > 0) {
      errors[fieldKey] = result.errors;
    }
  });

  return errors;
}

function mapStateToProps({ imprints, notify }) {
  return { imprints, notify };
}

Imprint = reduxForm({
  form: 'imprintCreationForm',
  validate
})(Imprint);

Imprint = connect(mapStateToProps, actions)(Imprint);

export default Imprint;
