import { createReducer } from 'redux-act';
import {
  IEmailPackage,
  IEmailSettingsState,
  ISelectionOption
} from '../../../modules/types/emails';
import { EditorState } from 'draft-js';
import {
  setEmailStrategy,
  setEmailPackage,
  fetchEmailPackagesSuccess,
  setEmailSelectionOptions,
  setEmailEditorContent,
  setEditorWasChanged
} from '../../actions/emailSettings';

const initialState: IEmailSettingsState = {
  packageSelected: '',
  strategySelected: '',
  editorContent: null,
  editorWasChanged: false,
  selectionOptions: [],
  emailPackages: [
    {
      _id: '',
      templates: {
        abandonedShoppingCartReminder: [],
        couponCodeAdvertising: [],
        emptyCartProductSuggestion: [],
        galleryExpires: [],
        templateTitle: ''
      }
    }
  ]
};

export const emailSettings = createReducer<IEmailSettingsState>({}, initialState);

emailSettings.on(
  setEmailSelectionOptions,
  (state: IEmailSettingsState, payload: ISelectionOption[]) => ({
    ...state,
    selectionOptions: payload
  })
);

emailSettings.on(setEmailPackage, (state: IEmailSettingsState, payload: string) => ({
  ...state,
  strategySelected: '',
  packageSelected: payload
}));

emailSettings.on(setEmailStrategy, (state: IEmailSettingsState, payload: string) => ({
  ...state,
  strategySelected: payload
}));

emailSettings.on(setEmailEditorContent, (state: IEmailSettingsState, payload: EditorState) => ({
  ...state,
  editorContent: payload
}));

emailSettings.on(setEditorWasChanged, (state: IEmailSettingsState, payload: boolean) => ({
  ...state,
  editorWasChanged: payload
}));

emailSettings.on(
  fetchEmailPackagesSuccess,
  (state: IEmailSettingsState, payload: IEmailPackage[]) => ({
    ...state,
    emailPackages: payload
  })
);
