if (!String.prototype.capitalize) {
  String.prototype.capitalize = function () { // eslint-disable-line
    return this.charAt(0).toUpperCase() + this.slice(1);
  };
}

if (!String.prototype.includes) {
  String.prototype.includes = function (str) { // eslint-disable-line
    let returnValue = false;

    if (this.indexOf(str) !== -1) {
      returnValue = true;
    }

    return returnValue;
  };
}

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) { // eslint-disable-line
    const newPosition = position || 0;

    return this.indexOf(searchString, newPosition) === newPosition;
  };
}

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (searchString, position) { // eslint-disable-line
    const subjectString = this.toString();
    if (
      typeof position !== 'number' ||
      !Number.isFinite(position) ||
      Math.floor(position) !== position ||
      position > subjectString.length
    ) {
      position = subjectString.length; // eslint-disable-line
    }
    position -= searchString.length; // eslint-disable-line
    const lastIndex = subjectString.indexOf(searchString, position);

    return lastIndex !== -1 && lastIndex === position;
  };
}

if (!Array.prototype.swap) {
  Array.prototype.swap = function (x, y) { // eslint-disable-line
    const b = this[x];
    this[x] = this[y];
    this[y] = b;

    return this;
  };
}

if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', { // eslint-disable-line
    value(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      const len = o.length >>> 0; // eslint-disable-line

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      const thisArg = arguments[1]; // eslint-disable-line

      // 5. Let k be 0.
      let k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        const kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true
  });
}

if (!String.prototype.endsWith) {
  String.prototype.endsWith = function (searchString, position) { // eslint-disable-line
    const subjectString = this.toString();
    if (
      typeof position !== 'number' ||
      !Number.isFinite(position) ||
      Math.floor(position) !== position ||
      position > subjectString.length
    ) {
      position = subjectString.length;
    }
    position -= searchString.length;
    const lastIndex = subjectString.indexOf(searchString, position);

    return lastIndex !== -1 && lastIndex === position;
  };
}
