import { IImage, IImageDuplicate } from '../../../modules/reducers/images';
import {
  COLLECTION_LINK_BY_USER_TYPE,
  COLLECTION_SHARE_URL,
  EXPORT_TYPES
} from '../../../modules/constants';
import {
  get,
  find,
  findIndex,
  groupBy,
  mapKeys,
  keyBy,
  chain,
  orderBy,
  partial,
  isNull,
  mapValues
} from 'lodash';
import querystring from 'query-string';
import { IStatisticsCollection } from '../../../modules/reducers/statistics';
import { IDictionary, ISelectOption, ISlide, ISong } from '../../../modules/types';
import {
  ACTIVITY_SHARE_POLICY_TYPES,
  STATISTICS_ACTIVITY_ACTIONS
} from '../../../modules/constants/statistics';
import { ICollectionsReducerState } from '../../../modules/reducers/collection';
import { IGallery } from '../../../modules/reducers/galleries';
import naturalCompare from 'natural-compare-lite';
import Texts from '../../../json/texts.json';
import moment from 'moment';
/* tslint:disable */

export { displayPrice } from './display-price';

export const getCollectionImageURL = (
  image: IImage,
  size: 'XS' | 'L' | 'M' = 'XS',
  isWatermark: boolean = false,
  cdnUrl: string
) => {
  const { identifier = '', extension = '', watermarkIdentifier } = image || {};
  if (
    extension.toLowerCase() === 'gif' &&
    moment(image.uploadedAt).isAfter(moment('2022-11-16 13:40'))
  ) {
    return cdnUrl.replace('*', `${identifier}.${extension}`);
  }
  const imageIdentifier =
    isWatermark && watermarkIdentifier ? `${watermarkIdentifier}/wm/` : `${identifier}/`;

  return cdnUrl.replace('*', `${imageIdentifier}${size}.${extension}`);
};

export const getExportImagesText = (
  images: IImage[],
  withoutExtention: boolean,
  exportType: string
) => {
  const type = find(EXPORT_TYPES, { value: exportType }) || {};
  const delimiter = get(type, 'delimiter', ' ');
  let text = '';

  images.forEach((image) => {
    let imageName: any = image.originalImageName || '';

    if (withoutExtention) {
      imageName = imageName.replace('.' + imageName.split(/#|\?/)[0].split('.').pop().trim(), '');
    }

    text += text.length === 0 ? imageName : `${delimiter}${imageName}`;
  });

  return text;
};

interface ICollectionDnDModule {
  type: string;
  data: {
    drop_id?: string;
    drag_id?: string;
    drag_index?: number;
    imageIndex?: number;
  };
}

export const CollectionDnDModule = (() => {
  let dnd: ICollectionDnDModule = {
    type: '',
    data: {
      drop_id: '',
      drag_id: '',
      drag_index: 0,
      imageIndex: 0
    }
  };
  return {
    set: (data: Partial<ICollectionDnDModule>) => {
      dnd = {
        ...dnd,
        ...data
      };
    },
    getType: () => dnd.type,
    getData: () => dnd.data
  };
})();

export const isImageSizeAccepted = (imageSize: number, imageType: string) => {
  const conversion = 1024 * 1024;

  const maxAllowedSize = (imageType === 'image/gif' ? 20 : 150) * conversion;

  return imageSize < maxAllowedSize;
};

export const getDuplicateId = (duplicates: IImageDuplicate[], image: File): string => {
  const duplicate = duplicates.find((dup) => dup.name === image.name);
  return get(duplicate, 'id', '');
};

export const isMobile = () =>
  typeof window.matchMedia !== undefined ? window.matchMedia('(max-width: 480px)').matches : false;

export const arrayMoveHelper = (arr: any[], old_index: number, new_index: number) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

export const getPricingPageUrl = (digistore: any, utm = {}) => {
  try {
    if (digistore && digistore.discountKey && digistore.discountKey._id)
      return (
        '/#/subscription/pricing?' +
        querystring.stringify({
          a: 1, // to avaoid parse error when getting parameters from hash url
          ...digistore.discountKey,
          discountKey: digistore.discountKey._id,
          _id: undefined,
          replaceDiscountKey: 1,
          ...utm
        })
      );
    else
      return (
        '/#/subscription/pricing?' +
        querystring.stringify({
          a: 1, // to avaoid parse error when getting parameters from hash url
          ...utm
        })
      );
  } catch (e) {
    console.log(e);
    return '/#/subscription/pricing';
  }
};

export const moveAroundByCollection = (
  direction: 'prev' | 'next',
  collection: any[],
  selectedID: string | number
) => {
  const len = collection.length;
  const index = findIndex(collection, (col: any) => col._id === selectedID);
  const directions = {
    prev: collection[(index + len - 1) % len],
    next: collection[(index + 1) % len]
  };
  return directions[direction];
};

export const getTranslationKey = (key: string, replacedValues?: IDictionary<string | number>) => {
  // @ts-ignore
  const text = get(Texts[window.LANGUAGE_SW], key, null);
  let defaultString = isNull(text) ? `key - ${key} not found` : text;
  mapValues(replacedValues, (value, replaceKey) => {
    defaultString = defaultString.replace(`{${replaceKey}}`, value);
  });
  return !defaultString ? `key - ${key} not translated` : defaultString;
};

export const getStatisticsActivitiesSpecialsText = (activity: IStatisticsCollection) => {
  let specialKey = '';
  if (activity.action === 'view' && activity.scope === 'share' && activity.actionData === 'page') {
    specialKey = '_page';
  }
  if (
    activity.action === 'view' &&
    activity.scope === 'selection' &&
    activity.actionData === 'page'
  ) {
    specialKey = '_page';
  }
  return specialKey;
};
export const getStatisticActivityText = (
  activity: IStatisticsCollection,
  textReplaceValues?: IDictionary<string | number>
) => {
  const translation = getTranslationKey(
    `collectionActivities.activities.text.${activity.action}.${
      activity.scope
    }${getStatisticsActivitiesSpecialsText(activity)}`,
    textReplaceValues
  );
  const replacePaths = get(STATISTICS_ACTIVITY_ACTIONS, `${activity.action}.${activity.scope}`, []);
  const unknownTranslate = getTranslationKey(`collectionActivities.activities.unknown`);
  let replaced = translation;
  replacePaths.forEach((path: IDictionary<string>) => {
    const sharePolicyType = get(activity, '_share.policy.type', '') as string;
    let prop = path.prop === 'none' ? ACTIVITY_SHARE_POLICY_TYPES[sharePolicyType] : path.prop;
    let replaceValue = get(activity, `${prop}`, unknownTranslate);
    if (prop === 'actionData') {
      replaceValue = getTranslationKey(
        `collectionActivities.activities.actionData.${replaceValue}`
      );
    }
    if (prop === '_share.policy.type') {
      replaceValue = getTranslationKey(
        `collectionActivities.activities.policyTypes.${replaceValue}`
      );
    }
    if (sharePolicyType === 'all-images')
      replaced = getTranslationKey(`collectionActivities.activities.policyTypes.all-images`);
    else replaced = replaced.replace(`${path.replaceKey}`, replaceValue);
  });

  return replaced;
};

export const getSiteOrCustomUrl = (user: any, site: ICollectionsReducerState) => {
  let url = '';
  if (site.customDomain && site.customDomain.url) {
    url = site.customDomain.url;
  } else if (user.custom_domain && user.custom_domain !== '')
    url = `${user.custom_domain}${site._id ? `/${site._id}` : ''}`;
  else {
    url = `${COLLECTION_LINK_BY_USER_TYPE[user.type]}${site._id}`;
  }

  return url;
};

export const getShareCollectionURL = (type: string, siteUrl: string) => {
  const url = get(COLLECTION_SHARE_URL, type, COLLECTION_SHARE_URL.default);
  return `${url}${siteUrl}`;
};

export const getQRCodeUrl = (user: any, collection: ICollectionsReducerState) => {
  let url = '';
  if (collection.customDomain && collection.customDomain.url) {
    const pathToReplace = collection.customDomain.path
      ? collection.customDomain.path.toLowerCase()
      : '';
    const domain = collection.customDomain.url ? collection.customDomain.url.toLowerCase() : '';
    url = `${domain.replace(pathToReplace, '')}api/collection/open/${collection._id}`;
  } else if (user.custom_domain && user.custom_domain !== '') {
    url = `${user.custom_domain}/api/collection/open/${collection._id}`;
  } else {
    // @ts-ignore
    url = `${window.API_URL}/api/collection/open/${collection._id}`;
  }
  return url;
};

export const dateOrderResolver = (sortBy: string = '', image: IImage) => {
  const datesSorts = ['createdAt', 'deleteAt', 'recordedAt', 'uploadedAt'];
  const isInDateSort = datesSorts.includes(sortBy);
  const sortValue = get(image, sortBy, null);
  return isInDateSort ? new Date(sortValue).getTime() : sortValue;
};

interface IOrderImages {
  order: number;
  images: IImage[];
}

export const getOrderedDnDImages = (
  imagesIDs: string[],
  images: IImage[],
  galleries: IGallery[],
  targetGallery: string
) => {
  const selectedImages = images.filter((image) => imagesIDs.indexOf(image._id) > -1);
  const imagesToMove = selectedImages
    .filter((image) => image.gallery_id !== targetGallery)
    .map((image) => image._id);
  const imagesByGallery = groupBy(selectedImages, 'gallery_id');
  const galleriesByID = keyBy(galleries, '_id');
  const targetImagesIDS = imagesByGallery[targetGallery]
    ? imagesByGallery[targetGallery].map((image) => image._id)
    : [];
  const orderedImages: IOrderImages[] = [];
  mapKeys(imagesByGallery, (value, key) => {
    const { sortBy, sortOrder, order = 0 } = galleriesByID[key];
    const ordered = chain(value)
      .thru((list) => {
        if (sortBy === 'originalImageName') {
          list.sort((a: any, b: any) => naturalCompare(a.originalImageName, b.originalImageName));
          if (sortOrder === 'desc') {
            list.reverse();
          }
          return list;
        }
        return orderBy(
          list,
          partial(dateOrderResolver, sortBy),
          sortBy !== 'manualOrder' ? sortOrder : ['asc']
        );
      })
      .value();
    orderedImages.push({
      order,
      images: ordered
    });
  });
  const sortedByGallery = chain(orderedImages)
    .sortBy('order')
    .map((item) => item.images)
    .flatten()
    .value();
  return {
    imagesToMove,
    images: sortedByGallery,
    targetImagesIDS
  };
};

export const getSelectTranslatedOptions = (options: ISelectOption[]) => {
  return options.map((option) => ({
    ...option,
    text: getTranslationKey(option.text)
  }));
};

export const getOptionsForSelect = (
  options: IDictionary<any>[],
  valueKey: string,
  labelKey: string,
  isTranslated?: boolean
) => {
  return options.map((option) => ({
    value: option[valueKey],
    text: isTranslated ? getTranslationKey(option[labelKey]) : option[labelKey]
  }));
};

export const prepareSlidesForRequest = (slides: ISlide[]) => {
  return slides.map((slide: ISlide) => ({
    asset: slide.asset,
    id: slide.slideId,
    transition: slide.transition
  }));
};

export const updateSoundtracksForRequest = (soundtracks: ISong[]) => {
  return soundtracks.map((song: ISong) => ({
    id: song.id,
    durationSeconds: song.durationSeconds,
    soundtrackVendorId: song.soundtrackVendorId,
    src: song.src,
    bpm: song.bpm
  }));
};

export const updateSlideId = (slides: ISlide[]) => {
  return slides.map((slide: ISlide) => ({
    slideId: slide.id,
    asset: slide.asset,
    durationSeconds: slide.durationSeconds,
    startTime: slide.startTime,
    transition: slide.transition
  }));
};

export const reorderItems = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const handleCreateSoundtrackOptions = (soundtracks: ISong[]) => {
  let startTime = 0;

  return soundtracks.map((track: ISong) => {
    const trackStartTime = startTime;
    startTime += track.durationSeconds;

    return {
      ...track,
      startTime: trackStartTime
    };
  });
};

export const handleSoundtracksDuration = (soundtracks: ISong[]) => {
  return soundtracks.reduce((acc: number, track: ISong) => acc + track.durationSeconds, 0);
};

export const findCurrentSong = (soundtracks: ISong[], currentTime: number) => {
  return soundtracks.find((song) => {
    const endTime = song.startTime + song.durationSeconds;
    return currentTime >= song.startTime && currentTime < endTime;
  });
};

export const createFontLink = (templatesFonts: any) => {
  for (const font of templatesFonts) {
    if (!font.url) continue;

    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = font.url;

    document.head.appendChild(link);
  }
};

export const formatTime = (time: number | null) => {
  if (!time) {
    return '0:00';
  }

  const minutes = Math.floor(time / 60);
  const seconds = parseFloat((time % 60).toFixed(0));

  return seconds === 60 ? minutes + 1 + ':00' : minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
};

export * from './images';
