import { createReducer } from 'redux-act';
import {
  addImagesToUploadSession,
  cleanUpImagesSuccess,
  clearImages,
  clearImagesUploadSession,
  clearSelected,
  closeFullscreen,
  deleteImage,
  fetchImagesFailed,
  fetchImagesSuccess,
  handlePhotoswipeAction,
  openImageInFullscreenAction,
  pauseUploading,
  resetImagesSelectionsFilter,
  restoreUploading,
  searchImage,
  selectImageSuccess,
  setIsCropped,
  setIsImageDarggingAction,
  updateFullscreenImageListAction,
  updateImageUploadSessionData
} from '../../../modules/actions';
import { DEFAULT_IMAGE } from '../../../modules/constants';
import { cloneDeep, uniqBy } from 'lodash';

export interface IImageDuplicate {
  id: string;
  name: string;
}

export type ImageErrors = 'warning' | 'error' | 'storage_limit' | '';

export interface IFocalPoint {
  xPercent: number;
  yPercent: number;
}

export interface IFocalPoints {
  mobile?: IFocalPoint | null;
  desktop?: IFocalPoint | null;
}

export interface IImage {
  compressionStatus: string;
  deleteAt: string;
  extension: string;
  gallery_id: string;
  height: number;
  identifier: string;
  imgsFolder: string;
  mimeType: string;
  originalImageName: string;
  sizeMb: number;
  size: number;
  status: string;
  uploadedAt: string;
  width: number;
  manualOrder: number;
  watermarkIdentifier: string;
  isTitleImage: boolean;
  isSmallScreenTitleImage: boolean;
  _collection: string;
  _id: string;
  _user: string;
  file?: File;
  duplicateId?: string;
  error?: string;
  errorType?: ImageErrors;
  focalPoints?: IFocalPoints;
  _watermark: string;
  isIcon?: boolean;
  uploadingStatus?: string;
  stopUploading?: boolean;
  isCollectionHeader?: boolean;
}

export interface IImageReducerState {
  items: IImage[];
  searchImage: string;
  isFetched: boolean;
  selected: string[];
  isCropped: boolean;
  isDragging: any;
  appIcon: IImage;
  resetSelectionsFilter: boolean;
  uploadSessionImages: Record<string, IImage[]>;
  uploadIsPaused: boolean;
  imageDeletionInProcess: boolean;
  fullscreen: {
    isOpen: boolean;
    images: IImage[];
    startIndex: number;
  };
  fullscreenData: {
    image: IImage;
    currentImageIndex: number;
  };
}

const initialState: IImageReducerState = {
  items: [],
  isFetched: false,
  searchImage: '',
  selected: [],
  isCropped: false,
  appIcon: DEFAULT_IMAGE,
  resetSelectionsFilter: false,
  isDragging: false,
  uploadSessionImages: {},
  uploadIsPaused: false,
  imageDeletionInProcess: false,
  fullscreen: {
    isOpen: false,
    images: [],
    startIndex: 0
  },
  fullscreenData: {
    image: null,
    currentImageIndex: 0
  }
};

// TODO: Rename to the images after refactoring
export const collectionImages = createReducer<IImageReducerState>({}, initialState);
collectionImages.on(
  fetchImagesSuccess,
  (state: IImageReducerState, payload: { items?: IImage[]; appIcon?: IImage }) => ({
    ...state,
    items: payload.items || state.items,
    appIcon: payload.appIcon || state.appIcon,
    imageDeletionInProcess: false,
    isFetched: true
  })
);
collectionImages.on(
  cleanUpImagesSuccess,
  (state: IImageReducerState, payload: { items?: IImage[]; appIcon?: IImage }) => ({
    ...state,
    items: payload.items || state.items,
    appIcon: payload.appIcon || state.appIcon,
    isFetched: true
  })
);
collectionImages.on(searchImage, (state: IImageReducerState, payload: string) => ({
  ...state,
  searchImage: payload
}));
collectionImages.on(selectImageSuccess, (state: IImageReducerState, payload: string[]) => ({
  ...state,
  selected: payload
}));
collectionImages.on(clearSelected, (state: IImageReducerState) => ({
  ...state,
  selected: [],
  resetSelectionsFilter: true
}));
collectionImages.on(setIsCropped, (state: IImageReducerState, payload) => ({
  ...state,
  isCropped: payload
}));
collectionImages.on(resetImagesSelectionsFilter, (state: IImageReducerState, payload) => ({
  ...state,
  resetSelectionsFilter: payload
}));
collectionImages.on(fetchImagesFailed, () => initialState);
collectionImages.on(clearImages, () => initialState);

collectionImages.on(openImageInFullscreenAction, (state, { images, index }) => ({
  ...state,
  fullscreen: { ...state.fullscreen, images, isOpen: true, startIndex: index }
}));
collectionImages.on(handlePhotoswipeAction, (state, { image, index }) => ({
  ...state,
  fullscreenData: { image, currentImageIndex: index }
}));
collectionImages.on(updateFullscreenImageListAction, (state, { images }) => ({
  ...state,
  fullscreen: { ...state.fullscreen, images }
}));

collectionImages.on(closeFullscreen, (state) => ({
  ...state,
  fullscreen: { ...state.fullscreen, isOpen: false, currentImageIndex: 0, images: [] }
}));

collectionImages.on(setIsImageDarggingAction, (state, payload) => ({
  ...state,
  isDragging: payload
}));

collectionImages.on(addImagesToUploadSession, (state, payload) => {
  const newArray = cloneDeep(state.uploadSessionImages[payload.albumId] || []);
  const resultArray = [...newArray, ...payload.images];
  const uniqueArray = uniqBy(resultArray, 'originalImageName');

  return {
    ...state,
    uploadSessionImages: {
      ...state.uploadSessionImages,
      [payload.albumId]: uniqueArray
    }
  };
});

collectionImages.on(updateImageUploadSessionData, (state, payload) => {
  const newUploadSessionArray = cloneDeep(state.uploadSessionImages[payload.albumId] || []);
  const currentSessionIdx = newUploadSessionArray.findIndex(
    (image) => image?.originalImageName === payload.image?.originalImageName
  );

  if (payload.stopUploading) {
    currentSessionIdx >= 0 && newUploadSessionArray.splice(currentSessionIdx, 1);
  } else {
    currentSessionIdx >= 0 && newUploadSessionArray.splice(currentSessionIdx, 1, payload.image);
  }

  return {
    ...state,
    uploadSessionImages: {
      ...state.uploadSessionImages,
      [payload.albumId]: newUploadSessionArray
    }
  };
});

collectionImages.on(clearImagesUploadSession, (state) => ({
  ...state,
  uploadSessionImages: {}
}));

collectionImages.on(deleteImage, (state) => ({ ...state, imageDeletionInProcess: true }));

collectionImages.on(pauseUploading, (state) => ({ ...state, uploadIsPaused: true }));
collectionImages.on(restoreUploading, (state) => ({ ...state, uploadIsPaused: false }));
