import { radioMap } from '../FirstStep/data';
import { getTranslationKey } from '../../../modules/utils/helpers';

export const getAnswersData = () => ({
  [radioMap.secondRadio]: {
    answer1: getTranslationKey('cancelSubscriptionModal.answers.secondRadio_1'),
    answer2: getTranslationKey('cancelSubscriptionModal.answers.secondRadio_2')
  },
  [radioMap.thirdRadio]: {
    answer1: getTranslationKey('cancelSubscriptionModal.answers.thirdRadio_1'),
    answer2: getTranslationKey('cancelSubscriptionModal.answers.thirdRadio_2')
  },
  [radioMap.fourthRadio]: {
    answer1: getTranslationKey('cancelSubscriptionModal.answers.fourthRadio_1'),
    answer2: getTranslationKey('cancelSubscriptionModal.answers.fourthRadio_2')
  },
  [radioMap.fifthRadio]: {
    answer1: getTranslationKey('cancelSubscriptionModal.answers.fifthRadio_1'),
    answer2: getTranslationKey('cancelSubscriptionModal.answers.fifthRadio_2')
  },
  [radioMap.sixthRadio]: {
    answer1: getTranslationKey('cancelSubscriptionModal.answers.sixthRadio_1')
  },
  [radioMap.seventhRadio]: {
    answer1: getTranslationKey('cancelSubscriptionModal.answers.seventhRadio_1'),
    answer2: getTranslationKey('cancelSubscriptionModal.answers.seventhRadio_2')
  }
});
