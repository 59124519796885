import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import * as actions from '../../actions';
import Texts from '../../json/texts.json';
import { Field, reduxForm } from 'redux-form';
import InputGroup from '../Forms/InputGroup';
import approve from 'approvejs';

const fields = {
  description: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].description,
    tooltip_id: 'imprintDescription',
    tooltip_text: Texts[window.LANGUAGE_SW].customDomains.descriptionTooltip,
    title: Texts[window.LANGUAGE_SW].description,
    rules: {
      required: { message: Texts[window.LANGUAGE_SW].requiredInput }
    }
  },
  url: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].customDomains.url,
    tooltip_id: 'urlToolTipPrivacy',
    tooltip_text: Texts[window.LANGUAGE_SW].customDomains.urlToolTipPrivacy,
    title: Texts[window.LANGUAGE_SW].customDomains.url,
    rules: {}
  },
  privacy: {
    type: 'textarea',
    placeholder: Texts[window.LANGUAGE_SW].customDomains.privacy,
    tooltip_id: 'privacyTooltip',
    tooltip_text: Texts[window.LANGUAGE_SW].customDomains.privacyTooltip,
    title: Texts[window.LANGUAGE_SW].customDomains.privacy,
    rules: {}
  }
};

class Privacy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      selectedPrivacy: props.selectedPrivacy || 'select'
    };

    this.toggle = this.toggle.bind(this);
    this.submit = this.submit.bind(this);
    this.onSelectPrivacy = this.onSelectPrivacy.bind(this);
    this.delete = this.delete.bind(this);
  }

  onSelectPrivacy({ target: { value } }) {
    if (this.props.mode === 'edit') {
      const currentPrivacy = this.props.privacys.all.filter((privacy) => privacy._id === value)[0];
      if (currentPrivacy) {
        this.setState({ currentPrivacyId: value });
        this.props.change('description', currentPrivacy.description);
        this.props.change('url', currentPrivacy.url);
        this.props.change('privacy', currentPrivacy.privacy);
        this.toggle();
      }
    } else {
      if (value === 'new') this.toggle();
      else {
        this.setState({ selectedPrivacy: value }, () => {
          const { onSelectedPrivacyChanged, domain } = this.props;
          if (onSelectedPrivacyChanged) {
            onSelectedPrivacyChanged(this.state.selectedPrivacy);
          }
          if (domain) {
            this.props.updateDomain({
              _id: domain,
              privacy: this.state.selectedPrivacy
            });
          }
        });
      }
    }
  }

  toggle() {
    this.setState(
      (prevState) => ({ isOpen: !prevState.isOpen }),
      () => {
        if (!this.state.isOpen) this.props.reset();
      }
    );
  }

  submit(values) {
    if (this.props.mode === 'edit') {
      this.props.editPrivacy({ ...values, _id: this.state.currentPrivacyId }, this.props.notify);
    } else this.props.createPrivacy(values, this.props.notify);
    this.toggle();
  }

  delete() {
    this.props.deletePrivacy(this.state.currentPrivacyId, this.props.notify);
    this.toggle();
  }

  render() {
    const privacys = this.props.privacys.all;
    const { mode } = this.props;
    const label =
      mode === 'edit'
        ? Texts[window.LANGUAGE_SW].customDomains.editPrivacy
        : Texts[window.LANGUAGE_SW].customDomains.createPrivacy;

    return (
      <div className="inline">
        <FormGroup>
          <div>
            <Input
              type="select"
              name="product"
              value={this.state.selectedPrivacy}
              onChange={this.onSelectPrivacy}
            >
              {mode === 'edit' && (
                <option value="select" disabled>
                  {Texts[window.LANGUAGE_SW].customDomains.editPrivacy}
                </option>
              )}
              {!mode && (
                <option value="select" disabled>
                  {Texts[window.LANGUAGE_SW].pleaseSelect}
                </option>
              )}
              {privacys.map((privacy, key) => (
                <option value={privacy._id} key={key}>
                  {privacy.description}
                </option>
              ))}
            </Input>
          </div>
        </FormGroup>
        <Modal
          centered
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          wrapClassName="modal-primary"
          size="default"
        >
          <ModalHeader toggle={this.toggle}>{label}</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12">
                <form onSubmit={this.props.handleSubmit(this.submit)}>
                  {Object.keys(fields).map((fieldKey, index) => (
                    <Field
                      component={InputGroup}
                      key={index}
                      name={fieldKey}
                      field={fields[fieldKey]}
                    />
                  ))}
                  <button
                    className="btn btn-primary w-100-p"
                    type="submit"
                    disabled={this.props.pristine || this.props.invalid || this.props.submitting}
                  >
                    {label}
                  </button>
                </form>
                <a
                  href="https://dsgvo-muster-datenschutzerklaerung.dg-datenschutz.de/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {Texts[window.LANGUAGE_SW].customDomains.privacyGenerator}
                </a>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {mode === 'edit' && (
              <small className="cursor-pointer " onClick={this.delete}>
                <i className="sli-trash m-r-5" />
                {`${Texts[window.LANGUAGE_SW].privacy} ${Texts[window.LANGUAGE_SW].delete}`}
              </small>
            )}
            <button className="btn btn-danger pull-left" onClick={this.toggle}>
              {Texts[window.LANGUAGE_SW].abort}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  if (!values.url && !values.privacy) {
    const error = [Texts[window.LANGUAGE_SW].minOneRequired];
    errors.privacy = error;
    errors.url = error;
  }

  Object.keys(fields).forEach((fieldKey) => {
    const result = approve.value(values[fieldKey], fields[fieldKey].rules);
    if (result.errors.length > 0) {
      errors[fieldKey] = result.errors;
    }
  });

  return errors;
}

function mapStateToProps({ privacys, notify }) {
  return { privacys, notify };
}

Privacy = reduxForm({
  form: 'privacyCreationForm',
  validate
})(Privacy);

Privacy = connect(mapStateToProps, actions)(Privacy);

export default Privacy;
