import qs from 'qs';
import { setCookie } from './helpers/cookies';

const queryParams = qs.parse(window.location.href.split('?')[1]);
const utmParamsNames = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

const isAtleastOneUTMParamPresented = utmParamsNames.some((param) => !!queryParams[param]);
utmParamsNames.forEach((paramName) => {
  if (!!queryParams[paramName]) {
    setCookie(paramName, queryParams[paramName], 100);
  } else if (isAtleastOneUTMParamPresented) {
    setCookie(paramName, '', 100);
  }
});
