import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
	commonColors: {
		main: '#5d5d5d',
		second: '#5C8762',
		mainBackground: '#ffffff',
		secondBackground: '#f6f6f6'
	},
	textColors: {
		baseText: '#5d5d5d',
		muted: '#73818f',
		link: '#5C8762'
	},
	formsColors: {
		mainBackground: '#ffffff',
		mainColor: '#7b7b7b',
		mainBorderColor: '#dadada',
		secondBackground: 'white',
		secondColor: '#5C8762',
		secondBorderColor: '#5C8762',
	},
	buttonsColors: {
		main: '#5C8762',
		second: 'white',
		danger: '#f64846',
		mainTextColor: '#ffffff',
		secondTextColor: '#5C8762'
	},
	fonts: {
		primary: '',
		bold: '',
		semiBold: '',
	},
};

interface ICommonColors {
	main: string;
	second: string;
	mainBackground: string;
	secondBackground: string;
}

interface ITextColors {
	baseText: string;
	muted: string;
	link: string;
}

interface IFormsColors {
	mainBackground: string;
	mainColor: string;
	mainBorderColor: string;
	secondBackground: string;
	secondColor: string;
	secondBorderColor: string;
}

interface IButtonsColors {
	main: string;
	second: string;
	danger: string;
	mainTextColor: string;
	secondTextColor: string;
}

interface IFonts {
	primary: string;
	bold: string;
	semiBold: string;
}

declare module 'styled-components' {
	/* tslint:disable:interface-name */
	export interface DefaultTheme {
		/* tslint:enable:interface-name */
		/**
		 * Common colors
		 */
		commonColors: ICommonColors;
		/**
		 * Colors of text
		 */
		textColors: ITextColors;
		/**
		 * Forms colors
		 */
		formsColors: IFormsColors;
		/**
		 * Buttons colors
		 */
		buttonsColors: IButtonsColors;
		/**
		 * Fonts
		 */
		fonts: IFonts;
	}
}

export default theme;
