import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import DefaultAppHeaderDropdown from './DefaultAppHeaderDropdown';
import CreateCollection from '../../elements/Collections/CreateCollection';
import { connect } from 'react-redux';

const DefaultHeader = ({ user, onlyLogout }) => (
  <>
    {!onlyLogout && <AppSidebarToggler className="d-lg-none" display="md" mobile />}

    <AppNavbarBrand
      href="/#"
      className="p-2"
      full={{
        src: user.type !== 'SB' ? user.whiteLabel.logoUrl : null,
        width: user.type === 'VST' ? '50px' : '100%'
      }}
      minimized={{
        src: user.whiteLabel.iconUrl,
        width: 30,
        height: 30,
        alt: 'Icon'
      }}
    />

    {!onlyLogout && <AppSidebarToggler className="d-md-down-none" display="lg" />}

    {!onlyLogout && (
      <Nav navbar>
        <NavItem className="pr-3">
          <CreateCollection />
        </NavItem>
      </Nav>
    )}
    <DefaultAppHeaderDropdown onlyLogout={onlyLogout} />
  </>
);

const mapStateToProps = ({ auth }) => ({ user: auth });

export default connect(mapStateToProps, null)(DefaultHeader);
