import { createAction } from 'redux-act';
import {
  IEmailTemplate,
  IEmailTemplatesReducer,
  IFetchEmailTemplatesParams,
  ISendEmailsAction,
  ISendLoginLinkEmail
} from '../../modules/types/emails';

export const saveEmailTemplatesAction = createAction<IEmailTemplate[]>();
export const createEmailTemplateAction = createAction<IEmailTemplate>();

export const deleteEmailTemplateAction = createAction<string>();

export const fetchEmailTemplatesAction = createAction<IFetchEmailTemplatesParams>();
export const fetchEmailTemplatesSuccessAction = createAction<IEmailTemplate[]>();

export const setSelectedEmailTemplateAction = createAction<IEmailTemplate>();

export const fetchRecipientsAction = createAction<string>();
export const fetchRecipientsSuccessAction = createAction<string[]>();

export const getEmailTemplatePreviewAction = createAction();
export const getEmailTemplatePreviewSuccessAction = createAction<string>();

export const updateEmailTemplateAction = createAction<IEmailTemplate>();
export const setDeliveryFormAction = createAction<Partial<IEmailTemplatesReducer>>();

export const sendEmailAction = createAction<ISendEmailsAction>();

export const sendLoginLinkEmailAction = createAction<ISendLoginLinkEmail>();
