import { createReducer } from 'redux-act';
import { fetchTemplatesFailed, fetchTemplatesFontsSuccess, fetchTemplatesSuccess } from '../../../modules/actions';
import { TemplateFont } from '../../../modules/types';

export interface ITemplate {
	assetUrl: string;
	desktopMockupUrl: string;
	displayName: string;
	inDevelopment: boolean;
	mobileMockupUrl: string;
	name: string;
	order: number;
	previewUrl: string;
	cardUrl: string;
	type: string;
	isPurchable: boolean;
	userIsAllowedToUseTemplate: boolean;
	landingPage?: string;
	price: number
	defaultDesign: {
		font: string
		primaryColor: string
		secondaryColor: string
	}
	imageSpacing: string
	__v: number
	_id: string;
}

export interface ITemplatesReducerState {
	items: ITemplate[];
	fonts: TemplateFont[];
	isFetched: boolean;
}

const initialState: ITemplatesReducerState = {
	items: [],
	fonts: [],
	isFetched: false
};

export const templates = createReducer<ITemplatesReducerState>( {}, initialState );
templates.on( fetchTemplatesSuccess, ( state: ITemplatesReducerState, payload: any[] ) => ({
	...state,
	items: payload,
	isFetched: true
}) );

templates.on( fetchTemplatesFontsSuccess, ( state: ITemplatesReducerState, payload: any[] ) => ({
	...state,
	fonts: payload
}) );

templates.on( fetchTemplatesFailed, () => (initialState) );

