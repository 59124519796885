import React from 'react';
import styled from 'styled-components';
import { SalesAutomationForm } from '../../components/SalesAutomation';
import { IShopSalesAutomation } from '../../modules/types';
import { IPreset } from '../../modules/reducers';
import { ShopSettingsToggle } from '../../components';
import { DigitalShopSettings } from '../collectionEdit/shopTabContent/DigitalShopSettings';
import { getTranslationKey } from '../../modules/utils';
import { DEFAULT_PRESET_ITEM } from '../../modules/constants/presets';
import { GalleryProductsSettings } from '../collectionEdit/shopTabContent/GalleryProductsSettings';

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
`;

const Wrapper = styled.div``;

interface IProps {
  preset: IPreset;
  onChangeCallback: (data: any) => void;
}

export const PresetAutoMarketing: React.FC<IProps> = ({ preset, onChangeCallback }) => {
  const {
    shopActive,
    salesAutomationConfig,
    digitalShop,
    showOnlyPurchasedPrints,
    _productPricingList,
    shopCtaPlacement
  } = preset;

  const salesAutomationChanges = (value: IShopSalesAutomation) => {
    onChangeCallback({
      shopActive,
      salesAutomationConfig: value
    });
  };

  const shopSettingsCallback = (field: string, value: unknown) => {
    onChangeCallback({
      [field]: value
    });
  };

  const changeDigitalShopCallBack = (path: string, value: any) => {
    onChangeCallback({
      digitalShop: {
        ...preset.digitalShop,
        [path]: value
      }
    });
  };

  return (
    <Wrapper className="pt-gallery-preset-shop-tab-content">
      <Title>{getTranslationKey('shop.printShopTitle')}</Title>
      <ShopSettingsToggle
        isShopActive={shopActive}
        _productPricingList={_productPricingList}
        changeCallBack={shopSettingsCallback}
        showOnlyPurchasedPrints={showOnlyPurchasedPrints}
      />
      <DigitalShopSettings
        digitalShop={digitalShop || DEFAULT_PRESET_ITEM.digitalShop}
        changeCallBack={changeDigitalShopCallBack}
      />
      <GalleryProductsSettings
        shopCtaPlacement={shopCtaPlacement || DEFAULT_PRESET_ITEM.shopCtaPlacement}
        changeCallBack={shopSettingsCallback}
      />
      <Title className="mt-4">{getTranslationKey('collectionShop.salesAutomationTitle')}</Title>
      <SalesAutomationForm
        isShopActive={shopActive || (digitalShop && digitalShop.enabled)}
        values={salesAutomationConfig}
        debounceUpdates={false}
        updateCallback={salesAutomationChanges}
      />
    </Wrapper>
  );
};
