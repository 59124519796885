import React from 'react';
/* tslint:disable */
import Texts from '../../json/texts.json';
import { get } from 'lodash';

interface IHelpLink {
  href: string;
  className?: string;
  target?: string;
  hideSmall?: boolean;
  label?: string;
  hideIcon?: boolean;
  inline?: boolean;
}

export const HelpLink = ({
  href,
  className,
  target,
  hideSmall,
  label,
  hideIcon,
  inline
}: IHelpLink) => {
  const hrefLink = href || 'https://support.scrappbook.de';
  const targetValue = target || '_blank';
  const display = inline ? 'inline' : 'block';
  const bigClassNames = className
    ? `${className} d-none d-md-${display}`
    : `float-right d-none d-md-${display}`;
  const smallClassNames = className ? `${className} d-md-none` : 'float-right d-md-none';
  const text = label || Texts[window.LANGUAGE_SW].showHelp;

  const onClickHelpLink = function (e: any) {
    try {
      const href = get(e, 'target.href', '');

      if (href.includes('articles') && get(window, 'Intercom.booted')) {
        const articleId = href.substring(href.lastIndexOf('/') + 1);

        if (articleId) {
          console.log('open article', articleId);
          // @ts-ignore
          window.Intercom('showArticle', articleId);
          e.preventDefault();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <span>
      <a href={hrefLink} target={targetValue} className={bigClassNames} onClick={onClickHelpLink}>
        {!hideIcon && <i className="nav-icon icon-book-open" style={{ marginRight: '0.2rem' }} />}
        {/* @ts-ignore */}
        {text}
      </a>
      {!hideSmall && (
        <a
          href={hrefLink}
          target={targetValue}
          className={smallClassNames}
          onClick={onClickHelpLink}
        >
          {!hideIcon && <i className="nav-icon icon-book-open" style={{ marginRight: '0.2rem' }} />}
        </a>
      )}
    </span>
  );
};
