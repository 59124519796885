import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { InputItem } from '../../components';
import { getTranslationKey } from '../../modules/utils/helpers';

interface IProps {
  modalData: {
    isOpen: boolean;
    galleryIndex: number;
    text: string;
  };
  closeCallback: () => void;
  saveCallback: (index: number, path: string, value: any) => void;
}

export const ChangeGalleryTextPresetModal = ({
  modalData,
  closeCallback,
  saveCallback
}: IProps) => {
  const { isOpen, galleryIndex, text } = modalData;
  const [galleryText, changeState] = useState('');

  useEffect(() => {
    if (!isOpen) {
      changeState('');
    } else {
      changeState(text);
    }
  }, [text, isOpen]); // eslint-disable-line

  const changeStateHandler = (value: string) => changeState(value);
  const updateGalleryHandler = useCallback(() => {
    saveCallback(galleryIndex, 'text', galleryText);
    closeCallback();
  }, [galleryIndex, galleryText]); // eslint-disable-line

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={closeCallback}
      wrapClassName="modal-dark"
      size="default"
    >
      <ModalHeader toggle={closeCallback}>
        {getTranslationKey('gallerySettings.galleryTextModalTitle')}
      </ModalHeader>
      <ModalBody>
        <InputItem
          type="textarea"
          value={galleryText}
          changeCallback={changeStateHandler}
          aria-multiline
          placeholder={getTranslationKey('gallerySettings.galleryTextInputPlaceholder')}
        />
      </ModalBody>
      <ModalFooter>
        <Button block color="success" onClick={updateGalleryHandler} className="btn btn-primary">
          {getTranslationKey('gallerySettings.galleryTextModalButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
