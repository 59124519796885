import { call, put, select } from '@redux-saga/core/effects';
import { set } from 'lodash';
import { errorsGlobalError, showNotifySuccess, fetchPresetsAction } from '../../../modules/actions';
import {
  fetchDigitalPricingListPackagesSuccessAction,
  fetchDigitalPricingListsSuccessAction,
  setCurrentDigitalPricingListSuccessAction,
  setCurrentDigitalPricingListAction,
  setIsCreatePackageModalOpen
} from '../../../modules/actions/digital_pricing';
import { changeCollectionStateSuccess } from '../../../modules/actions/collections';
import {
  getCurrentlyOpenedDigitalList,
  getDigitalPricingLists
} from '../../../modules/selectors/digitalPricing';
import { getCollectionsNew } from '../../../modules/selectors';
import { ISagaAction, IShopReducerState } from '../../../modules/types';
import {
  ICreateDigitalPricingList,
  ICreatePackage,
  IDeleteDigitalPricingList,
  IDeletePackage,
  IDigitalPricingList,
  IGetDigitalPricingListPackages,
  IPackage,
  ISetCurrentDigitalPricingList,
  IUpdateDigitalPricingList,
  IUpdatePackage,
  IUpdateDependentStores
} from '../../../modules/types/digital-pricing';
import { Api } from '../../../modules/utils';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import { SagaIterator } from 'redux-saga';

export const fetchDigitalPricingListsSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Shop.getDigitalPricingLists);
    ApiErrors.checkOnApiError(response);
    yield put(fetchDigitalPricingListsSuccessAction({ digitalPricingLists: response.result }));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

const mapPackateToClient = (pckg: IPackage) => {
  return {
    ...pckg,
    totalPrice: Math.round(pckg.totalPrice) / 100,
    additionalImagePrice: Math.round(pckg.additionalImagePrice) / 100,
    grossTotalPrice: Math.round(pckg.grossTotalPrice) / 100,
    grossAdditionalImagePrice: Math.round(pckg.grossAdditionalImagePrice) / 100
  };
};

export const getDigitalPricingListPackagesSaga = function* ({
  payload
}: ISagaAction<IGetDigitalPricingListPackages>): SagaIterator {
  try {
    const response = yield call(Api.Shop.getDigitalPricingListPackages, payload);
    ApiErrors.checkOnApiError(response);
    yield put(
      fetchDigitalPricingListPackagesSuccessAction(response.result.map(mapPackateToClient))
    );
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const createPackageSaga = function* ({
  payload
}: ISagaAction<ICreatePackage>): SagaIterator {
  try {
    const response = yield call(Api.Shop.createPackage, payload);
    ApiErrors.checkOnApiError(response);

    const lists: IDigitalPricingList[] = yield select(getDigitalPricingLists);

    yield put(
      fetchDigitalPricingListsSuccessAction({
        digitalPricingLists: lists.map((list) => {
          if (list.id === payload.pricingListId) {
            return {
              ...list,
              packages: [
                ...list.packages,
                {
                  tier: Math.max(...list.packages.map((pckg) => pckg.tier)),
                  packageId: response.result.id
                }
              ]
            };
          } else return list;
        })
      })
    );

    const openedList: IShopReducerState['digitalPricing']['currentlyOpenedDigitalList'] =
      yield select(getCurrentlyOpenedDigitalList);
    yield put(
      setCurrentDigitalPricingListSuccessAction({
        ...openedList,
        packages: [
          ...openedList.packages,
          {
            tier: Math.max(...openedList.packages.map((pckg) => pckg.tier)),
            packageId: mapPackateToClient(response.result)
          }
        ]
      })
    );

    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const deletePackageSaga = function* ({
  payload
}: ISagaAction<IDeletePackage>): SagaIterator {
  try {
    const response = yield call(Api.Shop.deletePackage, payload);
    ApiErrors.checkOnApiError(response);

    const lists: IDigitalPricingList[] = yield select(getDigitalPricingLists);
    yield put(
      fetchDigitalPricingListsSuccessAction({
        digitalPricingLists: lists.map((list) => {
          if (list.id === payload.pricingListId) {
            return {
              ...list,
              packages: list.packages.filter((pckg) => pckg.packageId !== payload.packageId)
            };
          } else return list;
        })
      })
    );

    const openedList: IShopReducerState['digitalPricing']['currentlyOpenedDigitalList'] =
      yield select(getCurrentlyOpenedDigitalList);
    yield put(
      setCurrentDigitalPricingListSuccessAction({
        ...openedList,
        packages: openedList.packages.filter((pckg) => pckg.packageId.id !== payload.packageId)
      })
    );

    yield put(showNotifySuccess({}));

    yield call(updateDependentStores, {
      packageId: payload.packageId
    });
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updatePackageSaga = function* ({
  payload
}: ISagaAction<IUpdatePackage>): SagaIterator {
  try {
    const response = yield call(Api.Shop.updatePackage, payload);
    ApiErrors.checkOnApiError(response);

    if ('bestseller' in payload.data) {
      yield put(setCurrentDigitalPricingListAction({ pricingListId: payload.data.pricingListId }));
      yield put(showNotifySuccess({}));

      return;
    }

    const openedList: IShopReducerState['digitalPricing']['currentlyOpenedDigitalList'] =
      yield select(getCurrentlyOpenedDigitalList);
    yield put(
      setCurrentDigitalPricingListSuccessAction({
        ...openedList,
        packages: openedList.packages.map((pckg) => {
          if (pckg.packageId.id === payload.packageId) {
            return {
              ...pckg,
              packageId: mapPackateToClient(response.result)
            };
          } else return pckg;
        })
      })
    );

    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const createDigitalPricingListSaga = function* ({
  payload
}: ISagaAction<ICreateDigitalPricingList>): SagaIterator {
  try {
    const response = yield call(Api.Shop.createDigitalPricingList, payload);
    ApiErrors.checkOnApiError(response);
    const lists = yield select(getDigitalPricingLists);
    yield put(
      fetchDigitalPricingListsSuccessAction({
        digitalPricingLists: [...lists, response.result]
      })
    );
    yield put(setCurrentDigitalPricingListAction({ pricingListId: response.result.id }));
    yield put(setIsCreatePackageModalOpen(true));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const deleteDigitalPricingListSaga = function* ({
  payload
}: ISagaAction<IDeleteDigitalPricingList>): SagaIterator {
  try {
    const response = yield call(Api.Shop.deleteDigitalPricingList, payload);
    ApiErrors.checkOnApiError(response);
    const lists: IDigitalPricingList[] = yield select(getDigitalPricingLists);
    yield put(
      fetchDigitalPricingListsSuccessAction({
        digitalPricingLists: lists.filter((list) => list.id !== payload.digitalPricingListId)
      })
    );
    yield put(showNotifySuccess({}));

    yield call(updateDependentStores, {
      digitalPricingListId: payload.digitalPricingListId
    });
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updateDigitalPricingListSaga = function* ({
  payload
}: ISagaAction<IUpdateDigitalPricingList>): SagaIterator {
  try {
    const response = yield call(Api.Shop.updateDigitalPricingList, payload);
    ApiErrors.checkOnApiError(response);
    const lists: IDigitalPricingList[] = yield select(getDigitalPricingLists);
    yield put(
      fetchDigitalPricingListsSuccessAction({
        digitalPricingLists: lists.map((list) => {
          if (list.id === payload.digitalPricingListId) return response.result;
          else return list;
        })
      })
    );
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const setCurrentDigitalPricingListSaga = function* ({
  payload
}: ISagaAction<ISetCurrentDigitalPricingList>): SagaIterator {
  try {
    const response = yield call(Api.Shop.getDigitalPricingListPackages, payload);
    ApiErrors.checkOnApiError(response);
    const lists: IDigitalPricingList[] = yield select(getDigitalPricingLists);
    const list = lists.find((list) => list.id === payload.pricingListId);
    if (!list) return;
    yield put(
      setCurrentDigitalPricingListSuccessAction({
        ...list,
        packages: list.packages.map((pckg) => ({
          ...pckg,
          packageId: mapPackateToClient(
            response.result.find((imagePackage: IPackage) => imagePackage.id === pckg.packageId)
          )!
        }))
      })
    );
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updateDependentStores = function* ({
  digitalPricingListId,
  packageId
}: IUpdateDependentStores): SagaIterator {
  try {
    const collection = yield select(getCollectionsNew);

    if (collection) {
      const digitalShop = { ...collection.digitalShop };

      if (digitalPricingListId && digitalShop.digitalPricingList === digitalPricingListId) {
        digitalShop.digitalPricingList = null;
        digitalShop.alreadyPurchasedTier = null;
      }

      if (packageId && digitalShop.alreadyPurchasedTier === packageId) {
        digitalShop.alreadyPurchasedTier = null;
      }

      yield put(changeCollectionStateSuccess(set(collection, 'digitalShop', digitalShop)));
    }

    yield put(fetchPresetsAction());
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};
