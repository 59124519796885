import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Texts from '../../../json/texts.json';

const SurveyModalCES = ({ isSurveyModalOpen, setSurveyModalVisibility, surveys }) =>
  isSurveyModalOpen ? (
    <Modal
      centered
      isOpen={isSurveyModalOpen}
      toggle={() => setSurveyModalVisibility(!isSurveyModalOpen)}
      wrapClassName="modal-dark"
      size="lg"
      backdrop="static"
    >
      <ModalHeader toggle={() => setSurveyModalVisibility(!isSurveyModalOpen)}>
        {Texts[window.LANGUAGE_SW].surveys.ces.modalTitle}
      </ModalHeader>
      <ModalBody className="min-h-500">
        <iframe
          className="min-h-500"
          title="survey-frame"
          id="typeform-full"
          width="100%"
          height="100%"
          frameBorder="0"
          allow="camera; microphone; autoplay; encrypted-media;"
          src={`https://scrappbook.typeform.com/to/lur32Q${
            surveys && surveys.ces && surveys.ces.score >= 0
              ? `?prefilled_answer=${surveys.ces.score}`
              : ''
          }`}
        />
      </ModalBody>
    </Modal>
  ) : null;

function mapStateToProps({ message: { isSurveyModalOpen }, auth: { surveys } }) {
  return {
    isSurveyModalOpen,
    surveys
  };
}

export default connect(mapStateToProps, actions)(SurveyModalCES);
