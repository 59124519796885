import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import theme from '../../assets/css/theme';
import media from '../../assets/css/media';
import { IPreset } from '../../modules/reducers/presets';
import { PresetSelects } from './editPresetModal/PresetSelects';
import { PresetSwitches } from './editPresetModal/PresetSwitches';

const Wrapper = styled(Row)`
  border-right: 10px solid ${theme.commonColors.secondBackground};
  background: ${theme.commonColors.mainBackground};
  padding-top: 15px;
  padding-bottom: 5px;
  @media (max-width: ${media.desktop}) {
    border-right: 0;
    margin-bottom: 20px;
  }
`;

interface IProps {
  preset: IPreset;
  onChangeCallback: (data: Partial<IPreset>) => void;
}

export const PresetSetting = ({ preset, onChangeCallback }: IProps) => {
  const changeFormHandler = (data: Partial<IPreset>) => {
    onChangeCallback(data);
  };

  return preset._id.length ? (
    <Wrapper className="pt-gallery-preset-settings-tab-content">
      <Col sm={12} md={6} lg={6}>
        <PresetSelects preset={preset} setFormValueCallback={changeFormHandler} />
      </Col>
      <Col sm={12} md={6} lg={6}>
        <PresetSwitches preset={preset} setFormValueCallback={changeFormHandler} />
      </Col>
    </Wrapper>
  ) : null;
};
