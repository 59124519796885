import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Texts from '../../../json/texts.json';

const MessageModal = ({ isMessageModalOpen, setMessageModalVisibility, messages = [] }) =>
  isMessageModalOpen ? (
    <Modal
      centered
      isOpen={isMessageModalOpen}
      toggle={() => setMessageModalVisibility({ isMessageModalOpen: !isMessageModalOpen })}
      wrapClassName="modal-dark"
      size="md"
      zIndex={999999}
    >
      <ModalHeader
        toggle={() => setMessageModalVisibility({ isMessageModalOpen: !isMessageModalOpen })}
      >
        {Texts[window.LANGUAGE_SW].userMessage.messageModal.title}
      </ModalHeader>
      <ModalBody>
        {messages.map((m, k) => (
          <p key={k}>{m}</p>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() =>
            setMessageModalVisibility({
              isMessageModalOpen: !isMessageModalOpen
            })
          }
          color="primary"
        >
          {Texts[window.LANGUAGE_SW].userMessage.messageModal.confirm}
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;

function mapStateToProps({ message: { isMessageModalOpen, messages } }) {
  return {
    isMessageModalOpen,
    messages
  };
}

export default connect(mapStateToProps, actions)(MessageModal);
