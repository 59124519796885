import { updateFavoritesSuccess } from './../../actions/music';
import * as actions from '../../../modules/actions';
import { IMusic } from '../../../modules/types';
import { createReducer } from 'redux-act';

const initialState: IMusic = {
  music: [],
  filteredData: {},
  favorites: [],
  slideshowSoundtracks: {},
  totalAmount: null,
  isLoadingMore: false,
  isFetchingSlideshowSoundtracks: false
};

export const musicData = createReducer<IMusic>({}, initialState);
musicData.on(actions.fetchMusicSuccess, (state, { data, totalAmount }) => ({
  ...state,
  music: data,
  totalAmount
}));
musicData.on(actions.fetchSlideshowMusicSuccess, (state, { data, slideshowId }) => ({
  ...state,
  slideshowSoundtracks: {
    ...state.slideshowSoundtracks,
    [slideshowId]: data
  }
}));
musicData.on(actions.changeisFetchingSlideshowSoundtracks, (state, payload) => ({
  ...state,
  isFetchingSlideshowSoundtracks: payload
}));

musicData.on(actions.filterMusic, (state, payload) => {
  return {
    ...state,
    filteredData: {
      filter: payload.filter
    }
  };
});
musicData.on(updateFavoritesSuccess, (state, { favorites }) => ({
  ...state,
  favorites
}));
musicData.on(actions.loadMore, (state, payload) => ({
  ...state,
  filteredData: {
    ...state.filteredData,
    ...payload
  }
}));
musicData.on(actions.addMusicToFavorites, (state, { song }) => {
  const updatedFavorites = [...state.favorites];

  updatedFavorites.push(song);

  return {
    ...state,
    favorites: updatedFavorites
  };
});
musicData.on(actions.removeFavorites, (state, payload) => {
  const updatedFavorites = state.favorites.filter((favorite) => favorite.id !== payload);

  return {
    ...state,
    favorites: updatedFavorites
  };
});
musicData.on(actions.changeIsLoadingMoreStatus, (state, payload) => ({
  ...state,
  isLoadingMore: payload
}));
musicData.on(actions.clearMusic, (state) => ({
  ...initialState,
  slideshowSoundtracks: state.slideshowSoundtracks,
  favorites: state.favorites
}));
