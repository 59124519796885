import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import {
  Col,
  Row,
  Form,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Input,
  FormGroup,
  Progress
} from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import InputGroup from '../Forms/InputGroup';
import _Texts from '../../json/texts.json';
import classnames from 'classnames';
import { FaRegTrashAlt } from 'react-icons/fa';
import theme from '../../assets/css/theme';
import { partial } from 'lodash';
import axios from '../../helpers/axios';

let Texts = _Texts[window.LANGUAGE_SW];
let { defaultValues } = Texts;

let contactInfoFields = {};

let socialFields = {};

let legalFields = {};

class SetDefaultValues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'contactInfo'
    };
    this.submit = this.submit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.imageSelected = this.imageSelected.bind(this);
    this.deleteLogoByKey = this.deleteLogoByKey.bind(this);

    Texts = _Texts[window.LANGUAGE_SW];
    defaultValues = Texts.defaultValues;

    contactInfoFields = {
      company: {
        type: 'text',
        title: defaultValues.company,
        placeholder: defaultValues.companyPlaceholder
      },
      website: {
        type: 'text',
        title: defaultValues.website,
        placeholder: defaultValues.website
      },
      email: {
        type: 'text',
        title: defaultValues.email,
        placeholder: defaultValues.emailPlaceholder
      },
      tel: {
        type: 'text',
        title: defaultValues.tel,
        placeholder: defaultValues.telPlaceholder
      }
    };

    socialFields = {
      facebook: {
        type: 'text',
        title: Texts.yourFBAccount,
        placeholder: Texts.yourFBAccountPH
      },
      instagram: {
        type: 'text',
        title: Texts.yourIGAccount,
        placeholder: Texts.yourIGAccountPH
      },
      twitter: {
        type: 'text',
        title: Texts.yourTTAccount,
        placeholder: Texts.yourTTAccountPH
      },
      pinterest: {
        type: 'text',
        title: Texts.yourPRAccount,
        placeholder: Texts.yourPRAccountPH
      },
      youtube: {
        type: 'text',
        title: Texts.yourYTAccount,
        placeholder: Texts.yourYTAccountPH
      }
    };

    legalFields = {
      custom_imprint: {
        type: 'text',
        placeholder: Texts.custom_imprint_placeholder,
        title: Texts.custom_imprint
      },
      custom_privacy: {
        type: 'text',
        placeholder: Texts.custom_privacy_placeholder,
        title: Texts.custom_privacy
      }
    };
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  toggle(activeTab) {
    this.setState({ activeTab });
  }

  imageSelected(type, files) {
    const { auth } = this.props;
    if (auth.defaultValues && auth.defaultValues[type] && auth.defaultValues[type].url) {
      if (window.confirm(defaultValues.rlyUpload)) {
        this.uploadImage(type, files);
      }
    } else this.uploadImage(type, files);
  }

  async deleteLogoByKey(logoType) {
    const { notify, userBrandId } = this.props;
    try {
      console.log('deleteLogoByKey', logoType);

      await axios.delete('/api/userBrand/logo', { params: { userBrandId, logoType } });

      notify({
        title: Texts.success,
        message: Texts.successfullyDeleted,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 3000
      });

      this.setState({
        [logoType]: undefined,
        [`${logoType}Uploading`]: false,
        [`${logoType}Imagename`]: undefined,
        [`${logoType}Deleted`]: true
      });
    } catch (error) {
      console.log(error);
      notify({
        title: Texts.error,
        message: Texts.generalError,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 3000
      });
    }
  }

  async uploadImage(type, files) {
    const { auth, uploadDefaultUserImage, notify, userBrandId } = this.props;
    try {
      this.setState({ [`${type}Uploading`]: true });

      const newImage = await uploadDefaultUserImage({
        type,
        userBrandId: userBrandId || auth.defaultUserBrand._id || auth.defaultUserBrand,
        file: files[0],
        auth,
        notify
      });

      this.setState({
        [type]: newImage,
        [`${type}Uploading`]: false,
        [`${type}Imagename`]: files[0].name,
        [`${type}Deleted`]: false
      });
    } catch (e) {
      console.log(e);
      notify({
        title: Texts.error,
        message: Texts.generalError,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 3000
      });
    }
  }

  async submit(_values, activeTab) {
    const { updateCurrentUser, auth, notify, initialValues, accountView, updateUserBrand } =
      this.props;
    const {
      logo,
      logoLight,
      icon,
      studioLogo,
      buttonStyleColor,
      stylesBackgroundColor,
      customDomain,
      languageApp
    } = this.state;

    let customDomainValue = initialValues.customDomain;
    if (customDomain) {
      if (customDomain === 'select') customDomainValue = null;
      else customDomainValue = customDomain;
    }

    const values = {
      ..._values,
      logo: logo || initialValues.logo,
      logoLight: logoLight || initialValues.logoLight,
      icon: icon || initialValues.icon,
      studioLogo: studioLogo || initialValues.studioLogo,

      language: languageApp || auth.languageApp,

      customDomain: customDomainValue,
      buttonStyle: {
        color: buttonStyleColor || (initialValues.buttonStyle && initialValues.buttonStyle.color)
      },
      styles: {
        backgroundColor:
          stylesBackgroundColor || (initialValues.styles && initialValues.styles.backgroundColor)
      }
    };

    const { custom_imprint, custom_privacy, company } = values;
    delete values.custom_privacy;
    delete values.custom_imprint;

    let redirect = '';
    const setDefaultValues = activeTab === 'legal' || accountView;

    if ((auth.type === 'PP' || auth.type === 'VST') && activeTab === 'legal') {
      redirect = '/dashboard';
    }

    updateUserBrand(values, auth.type === 'SB' && !accountView ? null : notify);

    if (!accountView) {
      updateCurrentUser(
        {
          ...auth,
          // defaultValues: values,
          custom_imprint,
          custom_privacy,
          company: auth.company || company,
          registrationProcess: {
            ...auth.registrationProcess,
            setDefaultValues
          }
        },
        auth.type !== 'SB' ? notify : null,
        auth.type !== 'SB' ? redirect : ''
      );
    }
  }

  renderButton(nextStep) {
    const { accountView } = this.props;

    if (accountView || nextStep === 'save') {
      return (
        <Button color="success" className="px-4 w-100-p m-b-10" type="submit">
          {accountView ? Texts.save : Texts.nextStep}
        </Button>
      );
    }

    return (
      <Row>
        <Col xs="12" className="text-center">
          <Button
            color="success"
            className="px-4 w-100-p m-b-10"
            onClick={() => this.toggle(nextStep)}
          >
            {Texts.nextStep}
          </Button>
          <span onClick={() => this.toggle(nextStep)} className="text-underline cursor-pointer">
            {defaultValues.skip}
          </span>
        </Col>
      </Row>
    );
  }

  renderTab(type) {
    const { initialValues, auth } = this.props;
    switch (type) {
      case 'contactInfo':
        return Object.keys(contactInfoFields).map((fieldKey, index) => (
          <Field
            component={InputGroup}
            key={index}
            name={fieldKey}
            field={contactInfoFields[fieldKey]}
          />
        ));
      case 'socialMedia':
        return Object.keys(socialFields).map((fieldKey, index) => (
          <Field
            component={InputGroup}
            key={index}
            name={fieldKey}
            field={socialFields[fieldKey]}
          />
        ));
      case 'images':
        let logoKeys = ['logo', 'logoLight', 'studioLogo'];
        if (auth.type !== 'SB') logoKeys = ['icon', ...logoKeys];

        return logoKeys.map((logo, key) => (
          <FormGroup key={key} row>
            <Col md="4">
              <Label>{defaultValues[logo]}</Label>
              <br />
              <Label className="text-muted line-height-1">
                <small>{defaultValues[`${logo}Desc`]}</small>
              </Label>
            </Col>
            <Col xs="12" md="8">
              <label
                className={`btn btn-primary btn-sm w-100-p btn-icon text-center pt-upload-brand-${logo}`}
              >
                <Input
                  type="file"
                  onChange={({ target: { files } }) => this.imageSelected(logo, files)}
                  name={logo}
                  accept="image/*"
                  multiple={false}
                  className="hidden-upload"
                />{' '}
                <i className="icon-picture" /> {Texts.selectFile}
              </label>
              {this.state[`${logo}Uploading`] && (
                <Progress className="m-t-10" animated color="success" value="100">
                  {defaultValues.uploading}
                </Progress>
              )}
              {!this.state[`${logo}Deleted`] &&
              !this.state[`${logo}Uploading`] &&
              (this.state[`${logo}Imagename`] ||
                (initialValues && initialValues[logo] && initialValues[logo].originalImageName)) ? (
                <p className="alert alert-success m-t-10">
                  {defaultValues.currentImage}{' '}
                  {this.state[`${logo}Imagename`] || initialValues[logo].originalImageName}
                  <FaRegTrashAlt
                    onClick={partial(this.deleteLogoByKey, logo)}
                    className="float-right cursor-pointer"
                    color={theme.commonColors.second}
                    size={18}
                  />
                </p>
              ) : null}
            </Col>

            <Col xs="12">
              <hr />
            </Col>
          </FormGroup>
        ));
      case 'legal':
        return Object.keys(legalFields).map((fieldKey, index) => (
          <Field component={InputGroup} key={index} name={fieldKey} field={legalFields[fieldKey]} />
        ));
      default:
        return null;
    }
  }

  render() {
    const { handleSubmit, accountView, isMobileDevice } = this.props;
    const { activeTab } = this.state;

    return (
      <Form onSubmit={handleSubmit((values) => this.submit(values, activeTab))}>
        <Nav tabs>
          <NavItem className={!accountView && isMobileDevice ? 'd-md-down-none' : ''}>
            <NavLink
              className={classnames(
                { active: activeTab === 'contactInfo' },
                'help_setupBrand_contact'
              )}
              onClick={() => this.toggle('contactInfo')}
            >
              {defaultValues.contactInfo}
            </NavLink>
          </NavItem>
          <NavItem className={!accountView && isMobileDevice ? 'd-md-down-none' : ''}>
            <NavLink
              className={classnames(
                {
                  active: activeTab === 'images'
                },
                'help_setupBrand_logos'
              )}
              onClick={() => {
                this.toggle('images');
              }}
            >
              {defaultValues.logos}
            </NavLink>
          </NavItem>
          <NavItem className={!accountView && isMobileDevice ? 'd-md-down-none' : ''}>
            <NavLink
              className={classnames(
                {
                  active: activeTab === 'socialMedia'
                },
                'help_setupBrand_social'
              )}
              onClick={() => {
                this.toggle('socialMedia');
              }}
            >
              {defaultValues.socialMedia}
            </NavLink>
          </NavItem>
          <NavItem className={!accountView && isMobileDevice ? 'd-md-down-none' : ''}>
            <NavLink
              className={classnames({
                active: activeTab === 'legal',
                hidden: accountView
              })}
              onClick={() => {
                this.toggle('legal');
              }}
            >
              {defaultValues.legalTitle}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="contactInfo">
            <p className="text-muted">{defaultValues.contactInfo_sub_title}</p>
            {this.renderTab('contactInfo')}
            {this.renderButton('images')}
          </TabPane>
          <TabPane tabId="images">
            <p className="text-muted">{defaultValues.images_sub_title}</p>
            {this.renderTab('images')}
            {this.renderButton('socialMedia')}
          </TabPane>
          <TabPane tabId="socialMedia">
            <p className="text-muted">{defaultValues.social_sub_title}</p>
            {this.renderTab('socialMedia')}
            {this.renderButton('legal')}
          </TabPane>
          <TabPane tabId="legal">
            <p className="text-muted">{defaultValues.legal_sub_title}</p>
            {this.renderTab('legal')}
            {this.renderButton('save')}
          </TabPane>
        </TabContent>
      </Form>
    );
  }
}

SetDefaultValues = reduxForm({
  form: 'setDefaultValuesForm',
  enableReinitialize: true,
  destroyOnUnmount: false
})(SetDefaultValues);

function mapStateToProps(
  { auth, notify, userBrands, environment: { isMobileDevice } },
  { userBrandId }
) {
  const defaults = userBrandId
    ? userBrands[userBrandId]
    : userBrands[auth.defaultUserBrand._id || auth.defaultUserBrand];

  const initialValues = {
    ...defaults,
    custom_imprint: auth.custom_imprint,
    custom_privacy: auth.custom_privacy
  };

  return {
    auth,
    initialValues,
    notify,
    isMobileDevice
  };
}

export default connect(mapStateToProps, actions)(SetDefaultValues);
