import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import { errorsGlobalError, showNotifySuccess } from '../../actions';
import {
  setCurrentMessageAction,
  setIsImageUploadingAction,
  setMessagesAction,
  updateMessageAction
} from '../../actions/message';
import { getMessages } from '../../selectors/message';
import { IImageDimensions, ISagaAction } from '../../types';
import {
  ICreateMessageParams,
  IMessage,
  IUpdateMessageParams,
  IUploadMessageImageParams
} from '../../types/message';
import { Api, processImageDimensions } from '../../utils';
import ApiErrors from '../../utils/API/APIErrors';
import ImageCompressor from '@xkeshi/image-compressor';
import axios from 'axios';

export const fetchMessagesSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Messages.get);
    ApiErrors.checkOnApiError(response);

    yield put(setMessagesAction(response.result));
    if (response.result.length > 0) {
      yield put(setCurrentMessageAction(response.result[0].id));
    }
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const createMessageSaga = function* ({
  payload
}: ISagaAction<ICreateMessageParams>): SagaIterator {
  try {
    const response = yield call(Api.Messages.create, payload);
    ApiErrors.checkOnApiError(response);

    const messages = yield select(getMessages);
    yield put(setCurrentMessageAction(response.result.id));
    yield put(setMessagesAction([...messages, response.result]));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const updateMessageSaga = function* ({
  payload: { id, update, sendToServer }
}: ISagaAction<{ id: string; sendToServer: boolean; update: IUpdateMessageParams }>): SagaIterator {
  try {
    if (sendToServer) {
      const response = yield call(Api.Messages.update, id, update);
      ApiErrors.checkOnApiError(response);
      yield put(showNotifySuccess({}));
    }

    const messages: IMessage[] = yield select(getMessages);
    yield put(
      setMessagesAction(
        messages.map((message) => (message.id === id ? { ...message, ...update } : message))
      )
    );
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const deleteMessageSaga = function* ({ payload: id }: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.Messages.delete, id);
    ApiErrors.checkOnApiError(response);

    const messages: IMessage[] = yield select(getMessages);
    const newMessages = messages.filter((message) => message.id !== id);
    yield put(setMessagesAction(newMessages));
    if (newMessages.length > 0) {
      yield put(setCurrentMessageAction(newMessages[0].id));
    } else {
      yield put(setCurrentMessageAction(null));
    }
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const uploadMessageImageSaga = function* ({
  payload: { messageId, file }
}: ISagaAction<IUploadMessageImageParams>): any {
  try {
    console.log('uploadMessageImageSaga', messageId, file);
    yield put(setIsImageUploadingAction(true));
    const response = yield call(Api.Messages.initiateUpload, messageId, { imageName: file.name });
    ApiErrors.checkOnApiError(response);

    const imageUploadRes = response.result;
    const isGif = imageUploadRes.publicImageKey.toLowerCase().endsWith('.gif');
    let fileToUpload = file;
    if (!isGif) {
      const imageCompressor = new ImageCompressor(file);
      fileToUpload = yield call([imageCompressor, imageCompressor.compress], file, {
        quality: 0.7,
        maxWidth: 1000,
        maxHeight: 1000
      });
    }

    yield call(axios.put, imageUploadRes.signedUrl, fileToUpload);

    const reader = new FileReader();
    const readFileAsDataURL = (file: File) =>
      new Promise((resolve, reject) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    const base64 = yield call(readFileAsDataURL, file);

    if (isGif) {
      const dimensionsResponse: IImageDimensions | null = yield call(processImageDimensions, file);
      let dimensions = dimensionsResponse;

      if (!dimensions) {
        dimensions = { width: 0, height: 0 };
      }

      yield call(Api.Images.setGifMetaData, imageUploadRes.imageId, dimensions);
    }

    yield put(
      updateMessageAction({
        id: messageId,
        update: {
          publicImageKey: imageUploadRes.publicImageKey,
          previewUrl: base64 as string
        },
        sendToServer: false
      })
    );
    yield put(setIsImageUploadingAction(false));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};
