import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData } from '../../modules/selectors';
import { getModalsStates } from '../../modules/selectors/modals';
import { changeModalState } from '../../modules/actions/modals';
import { cancelSubscription } from '../../modules/actions/subscription';
import { radioMap } from './FirstStep/data';
import history from '../../helpers/history';
import { SubscriptionType } from '../../modules/types/subscriptions';

interface IRadioQuestionMap {
  [key: string]: {
    [key: string]: string;
  };
}

const radioQuestionMap: IRadioQuestionMap = {
  'not-using-product-anymore': {
    firstQuestion: 'why-stop-using',
    secondQuestion: 'problems-using-product'
  },
  price: {
    firstQuestion: 'what-you-expected',
    secondQuestion: 'what-price'
  },
  'switching-to-another-product': {
    firstQuestion: 'what-another-product',
    secondQuestion: 'why-another-product'
  },
  'missing-specific-function': {
    firstQuestion: 'missing-functionality',
    secondQuestion: 'function-not-working-as-expected'
  },
  'customers-have-issues': {
    firstQuestion: 'customers-problems'
  },
  'short-term': {
    firstQuestion: 'were-you-sutisfied',
    secondQuestion: 'will-you-return'
  }
};

const useModalHelpers = () => {
  const dispatch = useDispatch();
  const modalData = useSelector(getModalData);
  const {
    activeRadio = '',
    subscriptionType = '',
    firstAnswer = '',
    secondAnswer = '',
    additionalAnswer = ''
  } = modalData;
  const { subscriptionCancelModal: isOpen } = useSelector(getModalsStates);

  const [step, setStep] = useState<number>(1);

  const closeModal = () => {
    dispatch(
      changeModalState({
        key: 'subscriptionCancelModal',
        state: false,
        modalData: {
          activeRadio: '',
          firstAnswer: '',
          secondAnswer: '',
          additionalAnswer: ''
        }
      })
    );
    setStep(1);
  };

  const goNextStep = () => {
    const isRandomlyHere = activeRadio === radioMap.firstRadio;
    const isLastStep = step === 3;

    if (isRandomlyHere) {
      closeModal();

      return history.push('/subscription/pricing');
    }

    if (isLastStep) {
      const isOneQuestion = activeRadio === radioMap.sixthRadio;

      const finalAnswer = [
        { questionId: 'cancel-reason', answerId: activeRadio },
        { questionId: radioQuestionMap[activeRadio].firstQuestion, answer: firstAnswer },
        { questionId: radioQuestionMap[activeRadio].secondQuestion, answer: secondAnswer },
        { questionId: 'other-feedback', answer: additionalAnswer }
      ];

      const finalOneAnswer = [
        { questionId: 'cancel-reason', answerId: activeRadio },
        { questionId: radioQuestionMap[activeRadio].firstQuestion, answer: firstAnswer },
        { questionId: 'other-feedback', answer: additionalAnswer }
      ];

      dispatch(
        cancelSubscription({
          subscriptionType,
          questionnaireAnswers: isOneQuestion ? finalOneAnswer : finalAnswer
        })
      );

      return closeModal();
    }

    setStep((prev: number) => prev + 1);
  };

  const handleValue = (name: string, value: string) => {
    dispatch(
      changeModalState({
        key: 'subscriptionCancelModal',
        state: true,
        modalData: { ...modalData, [name]: value }
      })
    );
  };

  const openModal = useCallback((subscriptionType: SubscriptionType) => {
    dispatch(
      changeModalState({
        key: 'subscriptionCancelModal',
        state: true,
        modalData: { ...modalData, subscriptionType }
      })
    );
    // eslint-disable-next-line
  }, []);

  return {
    step,
    isOpen,
    goNextStep,
    handleValue,
    openModal,
    closeModal,
    activeRadio
  };
};

export default useModalHelpers;
