import React, { useEffect, useState } from 'react';
import { getTranslationKey } from '../modules/utils/helpers';
import { Button, Col, Row } from 'reactstrap';
import Table from '../elements/Table';
import { CreatePresetModal } from './collectionPresets/CreatePresetModal';
import { partial } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPresetsAction } from '../modules/actions';
import { getIsPresetsListFetched, getPresetsListSelector } from '../modules/selectors';
import { usePresetTableData } from '../modules/hooks';
import { EditPresetModal } from './collectionPresets/EditPresetModal';
// tslint:disable-next-line:no-relative-imports
import HelpLink from '../elements/Help/HelpLink';
import Card, { CardBody, CardHeader, CardTitle } from '../components/Card';
import { FaPlus } from 'react-icons/fa';
import theme from '../assets/css/theme';
import { getAccessRules } from '../modules/selectors/accessRules';
import { changeModalState } from '../modules/actions/modals';

const PageLayout = ({ children }: { children: any }) => (
  <div className="animated fadeIn">
    <Row>
      <Col sm="12" md="12" lg="6">
        {children}
      </Col>
    </Row>
  </div>
);

export const CollectionPresets = () => {
  const dispatch = useDispatch();
  const presetsList = useSelector(getPresetsListSelector);
  const isFetched = useSelector(getIsPresetsListFetched);
  const [createModalState, setCreateModalState] = useState(false);
  const { availableRules } = useSelector(getAccessRules);
  const { tableList, tableCols, tableColsNames, sortable } = usePresetTableData(presetsList);
  const [search, setSearch] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  useEffect(() => {
    if (!isFetched) {
      dispatch(fetchPresetsAction());
    }
  }, [isFetched, dispatch]);
  const onSearchHandler = (e: React.BaseSyntheticEvent) => {
    const { value } = e.currentTarget;
    setSearch(value);
  };
  const onChangeItemsPerPage = (e: React.BaseSyntheticEvent) => {
    const { value } = e.currentTarget;
    setItemsPerPage(value);
  };
  const toggleCreatePresetModal = (state: boolean = false) => {
    const isLimitExceeded = availableRules.collectionPresetsLeft === 0;

    if (isLimitExceeded) {
      return dispatch(
        changeModalState({ key: 'upgradeModal', state: true, desiredAction: 'collectionPresets' })
      );
    }

    setCreateModalState(state);
  };

  return (
    <>
      <PageLayout>
        <Card>
          <CardHeader>
            <CardTitle>{getTranslationKey('galleryPresets.route')}</CardTitle>
            <HelpLink
              href="https://support.scrappbook.de/de/articles/61573"
              className=""
              target="_blank"
              hideSmall={false}
            />
          </CardHeader>
          <CardBody>
            <Table
              items={tableList}
              columns={tableCols}
              columnNames={tableColsNames}
              itemsPerPage={itemsPerPage}
              search={search}
              noMatchingRecords={getTranslationKey('galleryPresets.notFound')}
              onSearch={onSearchHandler}
              onChangeItemsPerPage={onChangeItemsPerPage}
              defaultSorting={{ column: '' }}
              button={false}
              sortable={sortable}
              onSort={false}
              footerActions={
                <Button
                  onClick={partial(toggleCreatePresetModal, true)}
                  className="btn btn-light create-new-button"
                >
                  <FaPlus size={15} color={theme.commonColors.second} className="plus-icon mr-2" />
                  {getTranslationKey('galleryPresets.newPresetButton')}
                </Button>
              }
            />
          </CardBody>
        </Card>
      </PageLayout>
      <CreatePresetModal
        onClose={partial(toggleCreatePresetModal, false)}
        isOpen={createModalState}
      />
      <EditPresetModal />
    </>
  );
};

export default CollectionPresets;
