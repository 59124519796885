import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import Texts from '../../../json/texts.json';
import moment from 'moment';
import axios from '../../../helpers/axios';

class SingleDateSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableAppointments: []
    };
    this.onSelectAppointment = this.onSelectAppointment.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadAppointments();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.date !== prevState.date) {
      this.loadAppointments();
    }
  }

  onSelectAppointment(appointment) {
    if (this.props.onSelectAppointment) {
      this.props.onSelectAppointment(appointment);
    }
  }

  async loadAppointments() {
    try {
      this.setState({ loading: true });

      const result = await axios.get('/api/bookingsite/getAvailableSingleAppointments', {
        params: {
          bookingSiteId: this.props.bookingSiteId
        }
      });
      this.setState({ availableAppointments: result.data });
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  renderAvailableAppointments() {
    const selectedAppointment = this.props.selectedAppointment || {};

    return (
      <div className="row">
        <div className="col">
          {this.state.availableAppointments.length === 0 && (
            <p>{Texts[window.LANGUAGE_SW].noAppoinmentsAvailable}</p>
          )}
          {this.state.availableAppointments.map((availableAppointment, index) => (
            <FormGroup key={index} check>
              <Label className="w-100-p" check>
                <Input
                  type="radio"
                  checked={selectedAppointment.index === index}
                  onClick={() =>
                    this.onSelectAppointment({
                      ...availableAppointment,
                      index
                    })
                  }
                />
                <h3 className="inline">
                  {`${Texts[window.LANGUAGE_SW].appointment} ${index + 1}`}
                </h3>
                <p>
                  {`${moment(availableAppointment.start).format('dddd D. MMMM YYYY')} - ${moment(
                    availableAppointment.start
                  ).format('HH:mm')} - ${moment(availableAppointment.end).format('HH:mm')} Uhr`}
                </p>
              </Label>
            </FormGroup>
          ))}
        </div>
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    if (loading) {
      return (
        <div className="text-center">
          <img className="img-fluid" src="/assets/images/loading.gif" alt="Loading..." />
        </div>
      );
    }

    return (
      <div>
        {this.renderAvailableAppointments()}
        <button
          className="btn btn-success w-100-p btn-booking"
          disabled={!this.props.selectedAppointment}
          onClick={this.props.onSelectionEnd}
          type="submit"
        >
          {Texts[window.LANGUAGE_SW].nextStep}
        </button>
      </div>
    );
  }
}

export default SingleDateSelection;
