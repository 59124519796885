import React from 'react';
import styled from 'styled-components';
import { BiText } from 'react-icons/bi';
import theme from '../../../assets/css/theme';
import { partial } from 'lodash';
import { FaSortAlphaDown, FaSortAlphaUpAlt, FaRegTrashAlt } from 'react-icons/fa';
import { EditableTitleField, SelectItem } from '../../../components';
import { IGallery } from '../../../modules/reducers/galleries';
import { IDictionary } from '../../../modules/types';

const Gallery = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  border: 3px solid ${theme.commonColors.second};
  margin-bottom: 10px;
  padding: 0 10px;
`;
const TextIcon = styled(BiText)`
  margin: 0 15px;
  height: 20px;
  cursor: pointer;
`;
const OrderButton = styled.div`
  margin-left: 10px;
  cursor: pointer;
  svg {
    color: ${theme.commonColors.second};
  }
`;
const DeleteButton = styled.div`
  margin-left: auto;
  cursor: pointer;
`;

interface IProp {
  gallery: Partial<IGallery>;
  index: number;
  changeValueCallback: (index: number, path: string, value: any) => void;
  sortingOptions: IDictionary<string>[];
  deleteGalleryCallback: (index: number) => void;
  showTextModalCallback: (index: number, text: string) => void;
}

export const PresetGalleryItem = ({
  gallery,
  index,
  sortingOptions,
  changeValueCallback,
  deleteGalleryCallback,
  showTextModalCallback
}: IProp) => {
  const { title = '', text = '', sortBy = '', sortOrder } = gallery;
  const textIconColor = text ? theme.commonColors.second : '#7b7b7b';

  return (
    <Gallery className={`pt-gallery-preset-album-${index}`}>
      <EditableTitleField
        text={title}
        submitChangedCallBack={partial(changeValueCallback, index, 'title')}
      />
      <TextIcon
        size={20}
        color={textIconColor}
        onClick={partial(showTextModalCallback, index, text)}
      />
      <SelectItem
        options={sortingOptions}
        changeCallback={partial(changeValueCallback, index, 'sortBy')}
        value={sortBy}
        margin="0"
        formTheme="second"
        className="help_collection_sort"
      />
      <OrderButton
        onClick={partial(
          changeValueCallback,
          index,
          'sortOrder',
          sortOrder === 'asc' ? 'desc' : 'asc'
        )}
      >
        {sortOrder === 'asc' ? <FaSortAlphaDown size={20} /> : <FaSortAlphaUpAlt size={20} />}
      </OrderButton>
      <DeleteButton onClick={partial(deleteGalleryCallback, index)}>
        <FaRegTrashAlt color={theme.commonColors.second} size={18} />
      </DeleteButton>
    </Gallery>
  );
};
