import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { SketchPicker } from 'react-color';
import { FaPaintBrush } from 'react-icons/fa';
import { LabelItem } from '../../../components';
import { InputStyled, InputGroupTextStyled } from '../../../components/Forms/InputStyled';
import useOutsideClick from '../../../common/use-outside-click';
import theme from '../../../assets/css/theme';

const ColorIndicator = styled.div<{ color: string }>`
  background: ${(props) => props.color || 'url(/assets/images/transparent-bcg.png)'};
  background-size: contain;
  border-radius: 50%;
  border: 1px solid black;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33px;
  height: 33px;
  cursor: pointer;
`;

const Popover = styled.div`
  position: absolute;
  z-index: 99999;
`;

type Props = {
  color: string;
  id: string;
  label?: string;
  labelActions?: any;
  placeholder?: string;
  onColorChange: (...args: any[]) => void;
  onColorChangeComplete?: (...args: any[]) => void;
  style?: { [key: string]: string };
};

const ColorPickerField = (props: Props) => {
  const wrapperRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  useOutsideClick(wrapperRef, () => setShowPicker(false));

  return (
    <div ref={wrapperRef}>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ paddingRight: '15px' }}
      >
        <LabelItem htmlFor={props.id} isSmall isBold>
          {props.label}
        </LabelItem>
        {props.labelActions}
      </div>
      <div id={props.id} className="d-flex align-items-center">
        <ColorIndicator color={props.color} onClick={() => setShowPicker(!showPicker)} />
        <div className="input-item-group ml-2" style={{ width: '100%', zIndex: 1 }}>
          <InputGroup>
            <InputStyled
              onChange={(e: any) => {
                if (!e.target.value.startsWith('#')) return;
                props.onColorChange({ hex: e.target.value });
                if (props.onColorChangeComplete) {
                  props.onColorChangeComplete({ hex: e.target.value });
                }
              }}
              value={props.color}
              placeholder={props.placeholder}
            />
            <InputGroupAddon addonType="append">
              <InputGroupTextStyled>
                <FaPaintBrush color={theme.commonColors.second} size={15} />
              </InputGroupTextStyled>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </div>
      {showPicker && (
        <Popover style={props.style}>
          <SketchPicker
            color={props.color}
            onChange={props.onColorChange}
            onChangeComplete={props.onColorChangeComplete}
            disableAlpha
          />
        </Popover>
      )}
    </div>
  );
};

export default ColorPickerField;
