import { remove } from 'lodash';

export default function (state = { all: [] }, action) {
  switch (action.type) {
    case 'SET_ALL_LEADS':
      return { ...state, all: action.payload };
    case 'REMOVE_LEAD':
      const removeArray = state.all.slice();
      remove(removeArray, (item) => item._id === action.payload);

      return {
        ...state,
        all: [...removeArray]
      };
    default:
      return state;
  }
}
