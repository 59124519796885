import { SagaIterator } from 'redux-saga';
import { put, call, select, take } from 'redux-saga/effects';
import { getCollectionId, getIsUserCustomer } from '../../../modules/selectors';
import {
  clearStatisticsCollection,
  errorsGlobalError,
  fetchCollectionStatistics,
  fetchCollectionStatisticsGraph,
  fetchCollectionStatisticsGraphSuccess,
  fetchCollectionStatisticsMore,
  fetchCollectionStatisticsOverall,
  fetchCollectionStatisticsOverallSuccess,
  fetchCollectionStatisticsSuccess,
  fetchCollectionSuccess,
  setGraphFilter
} from '../../../modules/actions';
import Api from '../../../modules/utils/API';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import {
  COLLECTION_STATISTIC_LIMIT,
  INITIAL_STATISTICS_BATCH
} from '../../../modules/constants/statistics';
import { ISagaAction } from '../../../modules/types';
import {
  getStatisticsCollection,
  getStatisticsGraphFilters
} from '../../../modules/selectors/statistics';
import { without, get } from 'lodash';

export const statisticsInitialFetchSaga = function* (): SagaIterator {
  yield call(clearStatisticsCollectionSaga);
  yield put(fetchCollectionStatistics());
  yield put(fetchCollectionStatisticsGraph());
  yield put(fetchCollectionStatisticsOverall());
};

export const clearStatisticsCollectionSaga = function* (): SagaIterator {
  yield put(clearStatisticsCollection());
};

export const fetchCollectionStatisticsSaga = function* (
  action: ISagaAction<any>,
  batch: number = INITIAL_STATISTICS_BATCH
): SagaIterator {
  try {
    let collectionID = yield select(getCollectionId);
    const storeStats = yield select(getStatisticsCollection);
    const isCustomer = yield select(getIsUserCustomer);

    if (!Boolean(collectionID.length)) {
      yield take(fetchCollectionSuccess);
    }
    collectionID = yield select(getCollectionId);

    const response = yield call(Api.Statistics.get, {
      collection_id: collectionID,
      limit: isCustomer ? COLLECTION_STATISTIC_LIMIT : 10,
      batch
    });
    ApiErrors.checkOnApiError(response);
    const statistics = get(response, 'statistics', []);
    yield put(fetchCollectionStatisticsSuccess(storeStats.concat(statistics)));

    if (statistics.length >= 20) {
      yield take(fetchCollectionStatisticsMore);
      yield call(fetchCollectionStatisticsSaga, action, batch + 1);
    }
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchCollectionStatisticsGraphSaga = function* (): SagaIterator {
  try {
    let collectionID = yield select(getCollectionId);

    if (!Boolean(collectionID.length)) {
      yield take(fetchCollectionSuccess);
    }
    collectionID = yield select(getCollectionId);

    const response = yield call(Api.Statistics.getGraph, {
      collection_id: collectionID
    });
    ApiErrors.checkOnApiError(response);
    yield put(fetchCollectionStatisticsGraphSuccess(get(response, 'statistics', [])));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchCollectionStatisticsOverallSaga = function* (): SagaIterator {
  try {
    let collectionID = yield select(getCollectionId);

    if (!Boolean(collectionID.length)) {
      yield take(fetchCollectionSuccess);
    }
    collectionID = yield select(getCollectionId);

    const response = yield call(Api.Statistics.getOverall, {
      collection_id: collectionID
    });
    ApiErrors.checkOnApiError(response);

    if (response) {
      yield put(fetchCollectionStatisticsOverallSuccess(response));
    }
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updateGraphFilterSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  let filter = yield select(getStatisticsGraphFilters);
  if (filter.includes(payload)) {
    filter = without(filter, payload);
  } else {
    filter.push(payload);
  }
  yield put(setGraphFilter([...filter]));
};

export const refreshActivitiesSaga = function* (): SagaIterator {
  yield put(fetchCollectionStatisticsSuccess([]));
  yield put(fetchCollectionStatistics());
};
