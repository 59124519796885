import { useEffect, useState } from 'react';
import { useWindowWidth } from './useWindowWidth';

interface IBreakpoints {
  desktop: number;
  desktop_min: number;
  mobile: number;
  // css aquivalents
  xs: number;
  sm: number;
  lg: number;
  xl: number;
}

export const breakpoints: IBreakpoints = {
  desktop: 1000,
  desktop_min: 768, // todo: refactor to md
  mobile: 684,
  xs: 0,
  sm: 576,
  lg: 992,
  xl: 1200
};


export const useBreakpoint = (breakpoint: keyof IBreakpoints | number): boolean => {
  const { width } = useWindowWidth();

  if (typeof breakpoint === 'number') {
    return width > breakpoint;
  }

  const minWidth = breakpoints[breakpoint];

  return width > minWidth;
};
