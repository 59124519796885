import { useEffect, useState } from 'react';

const getCurrentDimension = () => ({ width: window.innerWidth, height: window.innerHeight });

export const useWindowWidth = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  useEffect(() => {
    const updateDimension = () => setScreenSize(getCurrentDimension());

    window.addEventListener('resize', updateDimension);

    return () => window.removeEventListener('resize', updateDimension);
  }, [screenSize]);

  return screenSize;
};
