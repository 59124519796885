import React from 'react';
import Texts from '../../json/texts.json';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/Card';
import * as actions from '../../actions';
import LanguageSelectSoftware from '../../elements/User/Account/LanguageSelectSoftware';
import LanguageSelectApps from '../../elements/User/Account/LanguageSelectApps';

const Language = ({ languageApp, updateDefaultUserLanguages }) => (
  <div className="animated fadeIn">
    <Row>
      <Col sm="12" md="12" lg="6">
        <Card>
          <CardHeader>
            <CardTitle>{Texts[window.LANGUAGE_SW].languageSettings}</CardTitle>
          </CardHeader>
          <CardBody>
            <p>{Texts[window.LANGUAGE_SW].languageSettingsDesc}</p>
            <LanguageSelectSoftware />
            <div className="m-t-10" />
            <LanguageSelectApps
              selectedValue={languageApp}
              onChange={({ target: { value } }) =>
                updateDefaultUserLanguages({
                  languageApp: value
                })
              }
            />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

function mapStateToProps({ auth: { languageApp } }) {
  return { languageApp };
}

export default connect(mapStateToProps, actions)(Language);
