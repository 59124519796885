export default function (state = { all: [], benefits: [], goals: [] }, action) {
  switch (action.type) {
    case 'SET_ALL_STRATEGIES':
      return { ...state, all: action.payload };
    case 'SET_CURRENT_STRATEGY':
      let current = action.payload;
      if (current.id) {
        console.log('findby id');
        current = state.all.find((strategy) => strategy._id === current.id);
      }

      return { ...state, current };
    case 'SET_STRATEGY_MODAL_VISIBILITY':
      return { ...state, isModalOpen: action.payload };
    case 'SET_ALL_BENEFITS':
      return { ...state, benefits: action.payload };
    case 'SET_ALL_GOALS':
      return { ...state, goals: action.payload };
    default:
      return state;
  }
}
