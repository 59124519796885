import React from 'react';
import { Alert } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils';
import { useSelector } from 'react-redux';
import { getPaymentInfo } from '../../modules/selectors/paymentInfo';
import moment from 'moment';
import { useLocation } from 'react-router';

const StripeMigrationNote = () => {
  const location = useLocation();
  const { showStripeMigrationNote, subscriptions } = useSelector(getPaymentInfo);
  const coreSub = subscriptions.find((sub) => sub.subscriptionType === 'core');
  const dateOfNextPayment = moment(coreSub?.nextPaymentAt, 'YYYY-MM-DD').format('DD.MM.YYYY');

  return (
    <Alert
      id="stripe-migration-note"
      className="mb-0"
      isOpen={location.pathname.startsWith('/subscription/pricing') && showStripeMigrationNote}
      color="warning"
    >
      <b>{getTranslationKey('subscription.stripe-migration-note.title')}</b>
      <p>{getTranslationKey('subscription.stripe-migration-note.part-1')}</p>
      <p>{getTranslationKey('subscription.stripe-migration-note.part-2')}</p>
      <i>{getTranslationKey('subscription.stripe-migration-note.footer', { dateOfNextPayment })}</i>
    </Alert>
  );
};

export default StripeMigrationNote;
