import { createSelector } from 'reselect';
import { IStoreState } from '../types';

export const getMusic = (state: IStoreState) => state.musicData;
export const getFavoritesIds = createSelector(getMusic, ({ favorites }) =>
  favorites.map(favoriteSong => favoriteSong.id));

export const getSlideshowMusic = createSelector(
  getMusic,
  ({ slideshowSoundtracks }) => (slideshowId: string) => slideshowSoundtracks[slideshowId] || []
);
