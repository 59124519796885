import React, { useState } from 'react';
import { IPreset } from '../../modules/reducers/presets';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { SORT_IMAGES_OPTIONS } from '../../modules/constants';
import { getTranslationKey } from '../../modules/utils/helpers';
import { remove, uniqueId } from 'lodash';
import { DEFAULT_GALLERY_PRESET } from '../../modules/constants/presets';
import { ChangeGalleryTextPresetModal } from './ChangeGalleryTextModal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { PresetGalleryItem } from './presetGalleries/PresetGalleryItem';
import { usePresetGalleries } from '../../modules/hooks';

const AddGalleryBlock = styled.div`
  width: 100%;
  height: 50px;
  border: 3px dotted ${theme.commonColors.second};
  color: ${theme.commonColors.second};
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    margin-right: 10px;
  }
`;
const defaultModalData = {
  isOpen: false,
  galleryIndex: 0,
  text: ''
};
const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  ...draggableStyle
});

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface IProps {
  preset: IPreset;
  onChangeCallback: (data: Partial<IPreset>) => void;
}

export const PresetGalleries = ({ preset, onChangeCallback }: IProps) => {
  const { galleries } = usePresetGalleries(preset);
  const [textModal, changeTextModal] = useState(defaultModalData);
  const changeGalleryValue = (index: number, path: string, value: any) => {
    galleries[index][path] = value;
    onChangeCallback({
      galleries
    });
  };
  const showTextModal = (index: number, text: string) => {
    changeTextModal({
      isOpen: true,
      galleryIndex: index,
      text
    });
  };
  const sortingOptions = SORT_IMAGES_OPTIONS.map((opt) => ({
    ...opt,
    text: getTranslationKey(opt.key)
  }));
  const addGallery = () => {
    onChangeCallback({
      galleries: [
        ...galleries,
        {
          ...DEFAULT_GALLERY_PRESET,
          id: uniqueId('preset-gallery'),
          order: galleries.length,
          sortOrder: preset.sortOrder,
          sortBy: preset.sortBy,
          shopCtaPlacement: preset.shopCtaPlacement,
          title: `Gallery ${galleries.length + 1}`
        }
      ]
    });
  };
  const deleteGalleryHandler = (index: number) => {
    remove(galleries, (gallery, galleryIndex) => index === galleryIndex);
    onChangeCallback({
      galleries
    });
  };
  const closeModal = () => {
    changeTextModal(defaultModalData);
  };
  const onDragEnd = (result: any) => {
    const reOrderGalleries = reorder(galleries, result.source.index, result.destination.index);
    reOrderGalleries.forEach((gallery, index) => ({
      ...gallery,
      order: index
    }));
    onChangeCallback({
      galleries: reOrderGalleries
    });
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {galleries.map((gallery, index) => (
                <Draggable key={gallery.id} draggableId={gallery.id} index={index}>
                  {(providedChild: any, snapshotChild: any) => (
                    <div
                      ref={providedChild.innerRef}
                      {...providedChild.draggableProps}
                      {...providedChild.dragHandleProps}
                      style={getItemStyle(
                        snapshotChild.isDragging,
                        providedChild.draggableProps.style
                      )}
                    >
                      <PresetGalleryItem
                        gallery={gallery}
                        index={index}
                        changeValueCallback={changeGalleryValue}
                        sortingOptions={sortingOptions}
                        deleteGalleryCallback={deleteGalleryHandler}
                        showTextModalCallback={showTextModal}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <AddGalleryBlock className="pt-gallery-presets-add-album" onClick={addGallery}>
        <i className="fa fa-icon fa-plus" /> {getTranslationKey('addGallery')}
      </AddGalleryBlock>
      <ChangeGalleryTextPresetModal
        modalData={textModal}
        closeCallback={closeModal}
        saveCallback={changeGalleryValue}
      />
    </div>
  );
};
