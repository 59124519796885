import React, { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCouponsAction } from '../../modules/actions';
import { getShopCouponsSelector } from '../../modules/selectors';
import { EditCreateCouponModal } from '../EditCreateCouponModal';
import { CreateCouponModal } from '../EditCreateCouponModal/CreateCouponModal';
import { SelectItem } from '../Forms/SelectItem';
import { getOptionsForSelect, getTranslationKey } from '../../modules/utils';
import { IDictionary } from '../../modules/types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface IProps {
  value: string;
  changeCallback: (value: string) => void;
  isDisabled?: boolean;
  styles?: CSSProperties;
}

export const SalesCouponSelection: React.FC<IProps> = ({
  value,
  changeCallback,
  isDisabled = false,
  styles
}) => {
  const dispatch = useDispatch();
  const couponsList = useSelector(getShopCouponsSelector);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [createModalName, setCreateModalName] = useState('');
  const optionsCoupons = getOptionsForSelect(couponsList as IDictionary<any>[], 'id', 'name');
  const options = [
    {
      text: getTranslationKey('collectionShop.addCouponOption'),
      value: 'create'
    },
    ...optionsCoupons
  ];
  useEffect(() => {
    dispatch(fetchCouponsAction());
  }, [dispatch]);

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCreateModalName('');
  };
  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };
  const createCouponNameHandler = (name: string) => {
    setCreateModalName(name);
    setIsEditModalOpen(true);
  };
  const changeHandler = (selectValue: string) => {
    if (selectValue === 'create') {
      setCreateModalOpen(true);
    } else {
      changeCallback(selectValue);
    }
  };

  return (
    <Wrapper style={styles}>
      <SelectItem
        disabled={isDisabled}
        label={getTranslationKey('collectionShop.couponSelectLabel')}
        isDefaultDisabled={false}
        defaultOptionsText={getTranslationKey('collectionShop.couponSelectPlaceholder')}
        value={value}
        changeCallback={changeHandler}
        options={options}
      />
      <EditCreateCouponModal
        isOpen={isEditModalOpen}
        closeCallback={closeEditModal}
        editID=""
        createName={createModalName}
      />
      <CreateCouponModal
        onClose={closeCreateModal}
        isOpen={isCreateModalOpen}
        onConfirm={createCouponNameHandler}
      />
    </Wrapper>
  );
};
