import { IStoreState } from '../types';
import { createSelector } from 'reselect';
import { chain, filter, find, orderBy, partial } from 'lodash';
import { dateOrderResolver, getCollectionImageURL } from '../utils';
import { IImage, ISortGallery } from '../reducers';
import naturalCompare from 'natural-compare-lite';

export const getImagesList = (state: IStoreState) => state.collectionImages.items;
export const getUploadSessionList = (state: IStoreState) =>
  state.collectionImages.uploadSessionImages;
export const getUploadPauseState = (state: IStoreState) => state.collectionImages.uploadIsPaused;
export const getUploadedImagesList = createSelector(getImagesList, (images) =>
  images.filter(({ status, isCollectionHeader }) => status === 'uploaded' && !isCollectionHeader)
);
export const getSearchImageString = (state: IStoreState) => state.collectionImages.searchImage;
export const getTotalImagesCount = createSelector(getUploadedImagesList, (arr) => arr.length);
export const getResetImagesFilterState = (state: IStoreState) =>
  state.collectionImages.resetSelectionsFilter;

export const getImagesUploadedStatus = (state: IStoreState) =>
  state.collectionImages.items.every((image) => image.status === 'uploaded');

export const getAppIcon = (state: IStoreState) => state.collectionImages.appIcon;

export const getGalleryTitleImage = createSelector(
  [getImagesList, getSearchImageString],
  (images) =>
    (gallery_id: string): IImage | undefined =>
      find(images, { gallery_id, isTitleImage: true })
);

export const getGallerySmallScreenTitleImage = createSelector(
  [getImagesList, getSearchImageString],
  (images) => (gallery_id: string) => find(images, { gallery_id, isSmallScreenTitleImage: true })
);

export const getGalleryImagesByGalleryID = createSelector(
  [getImagesList, getSearchImageString],
  (images, search) => (gallery_id: string, sortBy?: string, sortOrder?: 'asc' | 'desc') => {
    return chain(images)
      .filter({ gallery_id, status: 'uploaded' })
      .filter((img) =>
        search.length ? img.originalImageName.toLowerCase().includes(search.toLowerCase()) : true
      )
      .thru((list) => sortImages(list, sortBy, sortOrder))
      .flatten()
      .value();
  }
);

export const formatImagesForPhotoswipe = (images: IImage[], signedWilCardUrl: string) =>
  images.map((image) => ({
    ...image,
    image,
    msrc: getCollectionImageURL(image, 'M', false, signedWilCardUrl),
    src: getCollectionImageURL(image, 'L', false, signedWilCardUrl),
    w: image.width || 0,
    h: image.height || 0
  }));

export const sortImages = (images: IImage[], sortBy?: string, sortOrder?: 'asc' | 'desc') => {
  const list = [...images];
  if (sortBy === 'originalImageName') {
    list.sort((a: any, b: any) => naturalCompare(a.originalImageName, b.originalImageName));
    if (sortOrder === 'desc') {
      list.reverse();
    }
    return list;
  }
  const uploadedImages = filter(list, { status: 'uploaded' });
  const inUploadImages = filter(
    list,
    (listItem) =>
      ['uploading', 'compressing', 'pre-upload'].indexOf(listItem.uploadingStatus as any) > -1
  );
  const orederedUploadedImages = orderBy(
    uploadedImages,
    partial(dateOrderResolver, sortBy),
    sortBy !== 'manualOrder' ? sortOrder : ['asc']
  );
  const orederedInUploadImages = orderBy(
    inUploadImages,
    partial(dateOrderResolver, sortBy),
    sortBy !== 'manualOrder' ? sortOrder : ['asc']
  );
  return [...orederedUploadedImages, ...orederedInUploadImages];
};

const sortAndOrderImages = (list: IImage[], sortBy: string, sortOrder: 'asc' | 'desc') => {
  const processImages = (statusFilter: any) =>
    orderBy(
      filter(list, statusFilter),
      partial(dateOrderResolver, sortBy),
      sortBy !== 'manualOrder' ? sortOrder : ['asc']
    );

  return [
    ...processImages({ status: 'uploaded' }),
    ...processImages((listItem: IImage) =>
      ['uploading', 'compressing', 'pre-upload'].includes(listItem.uploadingStatus)
    )
  ];
};

export const getGalleryImagesByGalleryIDs = createSelector(
  [getImagesList, getSearchImageString],
  (images, search) => {
    return (galleries: ISortGallery[]) => {
      const galleriesImages = [];

      for (const { gallery_id, sortBy, sortOrder } of galleries) {
        const galleryImages = chain(images)
          .filter(
            (img) =>
              img.gallery_id === gallery_id &&
              (!search.length || img.originalImageName.toLowerCase().includes(search.toLowerCase()))
          )
          .thru((list) => {
            if (sortBy === 'originalImageName') {
              list.sort((a, b) => naturalCompare(a.originalImageName, b.originalImageName));
              if (sortOrder === 'desc') list.reverse();

              return list;
            }
            return sortAndOrderImages(list, sortBy, sortOrder);
          })
          .value();

        galleriesImages.push(...galleryImages);
      }

      return galleriesImages;
    };
  }
);

export const getImagesByGalleryID = createSelector(
  [getImagesList],
  (images) => (gallery_id: string) => {
    return chain(images).filter({ gallery_id }).value();
  }
);

export const getImageByID = createSelector(
  getImagesList,
  (images) => (_id: string) => find(images, { _id }) || {}
);

export const getSelectedImagesIDs = (state: IStoreState) => state.collectionImages.selected;

export const getIsCropped = (state: IStoreState) => state.collectionImages.isCropped;

export const getGalleryImagesDuplicateIDS = createSelector(
  getImagesByGalleryID,
  (getImagesByGalleryIDSelector) =>
    (galleryID: string, files: File[], uploadSessionImages: IImage[]) => {
      const images = getImagesByGalleryIDSelector(galleryID);
      const allImages = [...images, ...uploadSessionImages];
      const filesNames = files.map((file) => file.name);
      const duplicates: Array<{ id: string; name: string }> = [];

      allImages.forEach((image) => {
        if (filesNames.includes(image.originalImageName) && galleryID === image.gallery_id) {
          duplicates.push({
            id: image._id,
            name: image.originalImageName
          });
        }
      });
      return duplicates;
    }
);

export const getImagesByGalleriesUsingGalleryIDs = createSelector(
  [getGalleryImagesByGalleryIDs],
  (getFilteredSortedImages) => (galleries: ISortGallery[]) => {
    const images = getFilteredSortedImages(galleries);

    return images.reduce<Record<string, IImage[]>>((acc, image) => {
      if (!acc[image.gallery_id]) {
        acc[image.gallery_id] = [];
      }

      acc[image.gallery_id].push(image);

      return acc;
    }, {});
  }
);

export const groupImagesByGalleries = (sortedImages: IImage[]) =>
  sortedImages.reduce<Record<string, IImage[]>>((acc, image) => {
    if (!acc[image.gallery_id]) {
      acc[image.gallery_id] = [];
    }

    acc[image.gallery_id].push(image);

    return acc;
  }, {});

export const getCollectionHeaderImage = createSelector([getImagesList], (images) => {
  return (
    images.find((image) => image._collection && image.isCollectionHeader) ||
    images.find((image) => image.isCollectionHeader)
  );
});

export const getFullscreenState = (state: IStoreState) => state.collectionImages.fullscreen;
export const getFullscreenDataState = (state: IStoreState) => state.collectionImages.fullscreenData;
export const getDeletionStatus = (state: IStoreState) =>
  state.collectionImages.imageDeletionInProcess;
