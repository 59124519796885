import { createAction } from 'redux-act';
import { IPayoutAccount, IUpdateTaxAction } from '../../modules/types/user';
import { ICreatePayoutAccountParams, IUpdatePayoutAccountParams } from '../../modules/types';

export const updateUserTaxAction = createAction<IUpdateTaxAction>();
export const updateUserSlideshowOnboardingAction = createAction<any>();

export const fetchPayoutAccountAction = createAction();
export const fetchPayoutAccountSuccessAction = createAction<IPayoutAccount>();
export const createPayoutAccountAction = createAction<ICreatePayoutAccountParams>();
export const updatePayoutAccountAction = createAction<IUpdatePayoutAccountParams>();

export const getAvailableCurrenciesAction = createAction<string | void>();
export const getAvailableCurrenciesSuccessAction = createAction<string[]>();
