import React, { useEffect } from 'react';
import styled from 'styled-components';
import { SwitchItem, SelectItem } from '../../../components';
import { useDispatch, useSelector } from 'react-redux';
import { getTranslationKey } from '../../../modules/utils';
import {
  getCurrentlyOpenedDigitalListWithoutAllImages,
  getDigitalPricingLists
} from '../../../modules/selectors/digitalPricing';
import {
  fetchDigitalPricingListsAction,
  setCurrentDigitalPricingListAction
} from '../../../modules/actions/digital_pricing';
import { FormTooltip } from '../../../components/Forms/InputTooltip';
import { ICollectionsReducerState } from '../../../modules/reducers';

interface IProps {
  changeCallBack: (path: string, value: string | boolean | null) => void;
  digitalShop: ICollectionsReducerState['digitalShop'];
}

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const DigitalShopSettings: React.FC<IProps> = ({ changeCallBack, digitalShop }) => {
  const dispatch = useDispatch();
  const digitalPricingLists = useSelector(getDigitalPricingLists);
  const currentlySelectedPricingList = useSelector(getCurrentlyOpenedDigitalListWithoutAllImages);

  useEffect(() => {
    dispatch(fetchDigitalPricingListsAction());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (digitalShop.digitalPricingList) {
      dispatch(
        setCurrentDigitalPricingListAction({ pricingListId: digitalShop.digitalPricingList })
      );
    }
  }, [digitalShop.digitalPricingList]); // eslint-disable-line

  useEffect(() => {
    if (digitalShop.enabled && !digitalShop.digitalPricingList && digitalPricingLists.length > 0) {
      changeCallBack('digitalPricingList', digitalPricingLists[0].id);
    }
  }, [digitalShop.enabled]); // eslint-disable-line

  return (
    <div className="mt-4">
      <Title>{getTranslationKey('collectionDigitalShop.digitalShopTitle')}</Title>
      <SwitchItem
        id="collectionDigitalShop"
        value={digitalShop.enabled}
        isDisabled={digitalPricingLists.length === 0}
        changeCallback={(value) => changeCallBack('enabled', value)}
        label={getTranslationKey('collectionDigitalShop.toggleLabel')}
        direction="row-reverse"
        margin="15px 0 20px 0"
      />
      {digitalPricingLists.length === 0 && (
        <p>{getTranslationKey('shop.createDigitalPricingListToEnableShop')}</p>
      )}
      {digitalShop.enabled && (
        <>
          <SelectItem
            label={getTranslationKey('shop.selectPricingList')}
            isDefaultDisabled
            defaultOptionsText={getTranslationKey('shop.priceSelectLabel')}
            value={digitalShop.digitalPricingList || ''}
            changeCallback={(value) => changeCallBack('digitalPricingList', value || null)}
            options={digitalPricingLists.map((list) => ({
              text: list.name,
              value: list.id
            }))}
            labelActions={
              <FormTooltip
                isShow
                tooltipID="tt-digitalPricingList"
                tooltipPlacement="bottom"
                tooltipText={getTranslationKey('learnMoreLink', {
                  link: 'https://support.scrappbook.de/de/articles/61917',
                  text_link: 'https://support.scrappbook.de/de/articles/61917'
                })}
              />
            }
          />
          <SelectItem
            label={getTranslationKey('collectionDigitalShop.purchasedPackage')}
            isDefaultDisabled={false}
            defaultOptionsText={getTranslationKey('shop.packageSelectLabel')}
            value={digitalShop.alreadyPurchasedTier || ''}
            changeCallback={(value) => changeCallBack('alreadyPurchasedTier', value || null)}
            options={currentlySelectedPricingList.packages.map((pckg) => ({
              text: pckg.packageId.name,
              value: pckg.packageId.id
            }))}
            labelActions={
              <FormTooltip
                isShow
                tooltipID="tt-alreadyPurchasedTier"
                tooltipPlacement="bottom"
                tooltipText={getTranslationKey('learnMoreLink', {
                  link: 'https://support.scrappbook.de/de/articles/61919',
                  text_link: 'https://support.scrappbook.de/de/articles/61919'
                })}
              />
            }
          />
        </>
      )}
    </div>
  );
};
