import useTracking from './use-tracking';

export default function useTrackTabView() {
  const track = useTracking();

  return (tabId: string, pageId: string, extraPayload?: { [key: string]: any }) => {
    track('tab-opened', {
      page_id: pageId,
      tab_name: tabId,
      ...extraPayload
    });
  };
}
