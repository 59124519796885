import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Container, Form, Input, Row, Alert, Label } from 'reactstrap';
import Texts from '../../../json/texts.json';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { reduxForm } from 'redux-form';
import approve from 'approvejs';
import history from '../../../helpers/history';
import queryString from 'query-string';
import validateEmail from '../../../helpers/validateEmail';

const fields = {
  first_name: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].firstname,
    title: Texts[window.LANGUAGE_SW].firstname,

    rules: {
      required: { message: Texts[window.LANGUAGE_SW].requiredInput }
    }
  },
  email: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].emailPlaceholder,
    title: Texts[window.LANGUAGE_SW].email,

    rules: {
      email: {
        message: Texts[window.LANGUAGE_SW].emailErrorMessage
      }
    }
  },
  password: {
    type: 'password',
    placeholder: Texts[window.LANGUAGE_SW].password,
    title: Texts[window.LANGUAGE_SW].password,

    rules: {
      required: { message: Texts[window.LANGUAGE_SW].requiredInput }
    }
  }
};

class Register extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.onChangeDiscountKey = this.onChangeDiscountKey.bind(this);
    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);

    const parsed = queryString.parse(history.location.search);

    this.discountKey = parsed.discountKey;

    this.state = {
      first_name: parsed.first_name || '',
      password: '',
      email: parsed.email || '',
      discountKey: parsed.discountKey,
      affiliate: parsed.affiliate,
      trialWeeks: parsed.tw
    };
  }

  UNSAFE_componentWillMount() {
    if (
      window.location.hostname.includes('gallerypix') ||
      window.location.hostname.includes('leadsuite')
    ) {
      window.location = '/#/login';
    }
  }

  componentDidMount() {
    this.props.initialize(this.props._initialValues);
  }

  onChangeEmail({ target: { value } }) {
    this.setState({ email: value });
  }

  onChangePassword({ target: { value } }) {
    this.setState({ password: value });
  }

  onChangeDiscountKey({ target: { value } }) {
    this.setState({ discountKey: value });
  }

  onChangeFirstName({ target: { value } }) {
    this.setState({ first_name: value });
  }

  submit() {
    const user = {
      email: this.state.email,
      first_name: this.state.first_name,
      password: this.state.password
    };

    if (!user.first_name) {
      this.props.notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].completeForm,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 5000
      });

      return;
    }

    if (!user.password) {
      this.props.notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].completeForm,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 5000
      });

      return;
    }

    if (!user.email || !validateEmail(user.email)) {
      this.props.notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].emailErrorMessage,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 5000
      });

      return;
    }

    user.email = user.email.toLowerCase();

    const { affiliate, discountKey, trialWeeks } = this.state;

    if (affiliate || discountKey) {
      user.digistore = {
        affiliate,
        discountKey: discountKey ? discountKey.toUpperCase() : null
      };
    }

    if (trialWeeks) user.trialWeeks = trialWeeks;

    if (window.USER_TYPE) user.type = window.USER_TYPE;

    user.s = 'production';

    this.props.registerUser(user, this.props.notify);
  }

  render() {
    const { discountKey } = this.state;

    return (
      <div
        className="app flex-row align-items-center"
        style={{
          backgroundColor: 'gray',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="12" lg="6">
              <Card className="mx-4 primary-highlight-shadow">
                <CardBody className="p-4">
                  <Form onSubmit={this.props.handleSubmit(this.submit)}>
                    <h1>{Texts[window.LANGUAGE_SW].register}</h1>
                    {this.discountKey && (
                      <Alert color="success">
                        {Texts[window.LANGUAGE_SW].discountKeyActivated.replace(
                          '$$code$$',
                          this.discountKey
                        )}
                      </Alert>
                    )}
                    {window.USER_TYPE === 'SB' && (
                      <Row>
                        <Col className="m-t-10 m-b-10">
                          <a href="https://api.scrappbook.de/api/auth/facebook">
                            Mit Facebook registrieren
                          </a>
                        </Col>
                      </Row>
                    )}
                    <Label>{Texts[window.LANGUAGE_SW].firstname}</Label>
                    <Input
                      value={this.state.first_name}
                      onChange={this.onChangeFirstName}
                      placeholder={Texts[window.LANGUAGE_SW].firstname}
                      className="form-group"
                      type="text"
                    />

                    <Label>{Texts[window.LANGUAGE_SW].email}</Label>
                    <Input
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                      placeholder={Texts[window.LANGUAGE_SW].emailPlaceholder}
                      className="form-group"
                      type="text"
                    />

                    <Label>{Texts[window.LANGUAGE_SW].password}</Label>
                    <Input
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      placeholder={Texts[window.LANGUAGE_SW].password}
                      className="form-group"
                      type="password"
                    />

                    {this.discountKey && (
                      <div>
                        <Label>{Texts[window.LANGUAGE_SW].pricingTables.discountKey}</Label>
                        <Input
                          value={discountKey}
                          onChange={this.onChangeDiscountKey}
                          placeholder={Texts[window.LANGUAGE_SW].pricingTables.discountKey}
                          className="form-group"
                          type="text"
                        />
                      </div>
                    )}
                    <Row>
                      <Col xs="12">
                        <Button color="primary" className="w-100-p" type="submit">
                          {Texts[window.LANGUAGE_SW].createAccount}
                        </Button>
                      </Col>
                      <Col xs="12" className="text-center">
                        <Button href="/#/login/" color="link" className="px-0 m-b-0">
                          {Texts[window.LANGUAGE_SW].alreadyAccount}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  Object.keys(fields).forEach((fieldKey) => {
    const result = approve.value(values[fieldKey], fields[fieldKey].rules);
    if (result.errors.length > 0) {
      errors[fieldKey] = result.errors;
    }
  });

  return errors;
}

function mapStateToProps({ auth, notify }) {
  const initialValues = {};

  try {
    var { first_name, email } = queryString.parse(window.location.hash.replace('#/register', '')); // eslint-disable-line
    if (!first_name && !email) {
      console.log(
        'nothing found in hash - check =>',
        window.location.search.replace('#/register', '')
      );

      const parsed = queryString.parse(window.location.search.replace('#/register', ''));
      first_name = parsed.first_name;
      email = parsed.email;

      if (!first_name && !email) {
        console.log(
          'nothing found in search - check =>',
          window.location.href.replace('/#/register', '')
        );
        const parsedUrl = queryString.parseUrl(window.location.href.replace('/#/register', ''));

        first_name = parsedUrl.first_name;
        email = parsedUrl.email;
      }
    }
  } catch (e) {
    console.log(e);
  }

  if (first_name) initialValues.first_name = first_name; // eslint-disable-line
  if (email) initialValues.email = email; // eslint-disable-line

  return {
    auth,
    notify,
    initialValues,
    _initialValues: initialValues
  };
}

Register = reduxForm({
  form: 'RegisterForm',
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate
})(Register);

Register = connect(mapStateToProps, actions)(Register);

export default Register;
