import useTracking from './use-tracking';

export default function useTrackPageView() {
  const track = useTracking();

  return (pageId: string, path: string, extraPayload?: { [key: string]: any }) => {
    track('page-viewed', {
      page_id: pageId,
      path: path,
      href: window.location.href,
      title: document.title,
      ...extraPayload
    });
  };
}
