import React from 'react';
import Texts from '../../json/texts.json';
import classnames from 'classnames';

const PricingItemTitle = ({
  price,
  plan,
  currency,
  discount,
  intervall,
  noMinimumContractLength
}) => {
  let showOldPrice = true;
  if (discount) {
    let yearlySavings = 0;
    let months;
    var discountedPriceSum = 0; // eslint-disable-line
    var discountedPriceFirst = 0; // eslint-disable-line
    var moneyDiscount = false; // eslint-disable-line
    var savedAmount; // eslint-disable-line

    switch (intervall) {
      case 'halfYearly':
        months = 6;
        break;
      case 'yearly':
        months = 12;
        yearlySavings = 60;
        break;
      case 'twoYearly':
        months = 24;
        yearlySavings = 120;
        break;
      default:
        months = 1;
    }

    if (discount[`first_percent_${intervall}`]) {
      discountedPriceFirst = Math.round(price - price * (discount.first_percent / 100));
      discountedPriceFirst = discountedPriceFirst < 0 ? 0 : discountedPriceFirst;

      discountedPriceSum = discountedPriceFirst * months;

      moneyDiscount = true;

      if (discountedPriceFirst !== price) showOldPrice = true;
      else showOldPrice = false;
    }

    if (discount[`first_fixed_${intervall}`]) {
      discountedPriceFirst = (price * months - discount[`first_fixed_${intervall}`]) / months;

      discountedPriceFirst = discountedPriceFirst < 0 ? 0 : discountedPriceFirst;

      discountedPriceSum = Math.round(discountedPriceFirst * months);

      savedAmount = Math.round(price * months - discountedPriceSum + yearlySavings); // eslint-disable-line

      discountedPriceFirst = Math.round(discountedPriceFirst);

      moneyDiscount = true; // eslint-disable-line

      if (discountedPriceFirst !== price) showOldPrice = true;
      else showOldPrice = false;
    }
  }

  return (
    <div className="title">
      <h2>
        {
          Texts[window.LANGUAGE_SW].pricingTables[
            `sb_product_name_${plan}_${intervall}${
              noMinimumContractLength ? '_noMinimumContractLength' : ''
            }`
          ]
        }
      </h2>
      {showOldPrice && (
        // eslint-disable-next-line
        <h1 className={classnames('mb-2', { discount: !!moneyDiscount })}>
          {price}
          <sup>
            {/* eslint-disable-next-line */}
            {currency}
            {/* eslint-disable-next-line */}
            {!moneyDiscount && <span> {Texts[window.LANGUAGE_SW].pricingTables.perMonths}</span>}
          </sup>
        </h1>
      )}
      {/* eslint-disable-next-line */}
      {!moneyDiscount && (
        <p className="text-muted">
          {
            Texts[window.LANGUAGE_SW].pricingTables[
              `sb_product_desc_${plan}_${intervall}${
                noMinimumContractLength ? '_noMinimumContractLength' : ''
              }`
            ]
          }
        </p>
      )}

      {/* eslint-disable-next-line */}
      {moneyDiscount && (
        <div>
          <h1 className="m-b-10">
            {/* eslint-disable-next-line */}
            {discountedPriceFirst}
            <sup>
              {currency}
              <span> {Texts[window.LANGUAGE_SW].pricingTables.perMonths}</span>
            </sup>
          </h1>
          <p className="text-muted">
            {/* eslint-disable-next-line */}
            {Texts[window.LANGUAGE_SW].pricingTables[`sb_product_desc_${plan}_${intervall}_reduced`]
              // eslint-disable-next-line
              .replace('{{reduced_price}}', discountedPriceSum)
              // eslint-disable-next-line
              .replace('{{saved_amount}}', savedAmount)}
          </p>
        </div>
      )}
    </div>
  );
};
export default PricingItemTitle;
