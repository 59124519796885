import React from 'react';
import { Input, InputGroupText } from 'reactstrap';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import theme from '../../assets/css/theme';
import { IDictionary } from '../../modules/types';
import { omit } from 'lodash';

interface IInputStyled {
  isSearch?: boolean;
  formTheme?: 'main' | 'second';
  radius?: string;
}

export const InputTheme: IDictionary<FlattenSimpleInterpolation> = {
  main: css`
    background-color: ${theme.formsColors.mainBackground};
    border-color: ${theme.formsColors.mainBorderColor};
    color: ${theme.formsColors.mainColor};
    &:focus {
      background-color: ${theme.formsColors.mainBackground};
      color: ${theme.formsColors.mainColor};
    }
  `,
  second: css`
    background-color: ${theme.formsColors.secondBackground};
    border-color: ${theme.formsColors.secondBorderColor};
    color: ${theme.formsColors.secondColor};
    &:focus {
      background-color: ${theme.formsColors.secondBackground};
      color: ${theme.formsColors.secondColor};
    }
  `,
  error: css`
    background-color: ${theme.formsColors.mainBackground};
    border-color: red;
    color: ${theme.formsColors.mainColor};
    &:focus {
      background-color: ${theme.formsColors.mainBackground};
      color: ${theme.formsColors.mainColor};
    }
  `
};

export const InputStyled = styled(({ ...rest }) => {
  const props = omit(rest, ['isSearch', 'formTheme']);

  return <Input {...props} />;
})<IInputStyled>`
  border-radius: 20px;
  padding-left: ${(props) => (props.isSearch ? '30px' : '10px')};
  border: 1px solid transparent;
  &:not(:last-child) {
    border-right: none;
  }
  &::placeholder {
    font-weight: normal;
    color: ${theme.commonColors.main};
    opacity: 1;
  }
  &:focus {
    padding-left: 10px;
  }
  ${(props) => InputTheme[props.formTheme || 'main']};
`;

export const InputGroupTextStyled = styled(({ ...rest }) => {
  const props = omit(rest, ['isSearch', 'formTheme']);

  return <InputGroupText {...props} />;
})<IInputStyled>`
  &&&& {
    border-bottom-right-radius: ${(props) => props.radius || '20px'};
    border-top-right-radius: ${(props) => props.radius || '20px'};
    border-left: none;
    ${(props) => InputTheme[props.formTheme || 'main']};
    color: ${theme.formsColors.secondColor};
  }
`;
