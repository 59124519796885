import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getModalsStates } from '../../modules/selectors';
import { useBreakpoint } from '../../hooks/useBreakpoint';
import { FirstRestrictionStep } from './FirstRestrictionStep';
import { setCookie } from '../../helpers/cookies';
import { changeModalState } from '../../modules/actions';
import { SecondRestrictionStep } from './SecondRestrictionStep';

import styled from 'styled-components';

const StyledModal = styled(Modal)`
  margin: 0 !important;

  .modal-content {
    position: fixed !important;
    height: 100% !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
`;

const StyledModalHeader = styled(ModalHeader)`
  background-color: #fff !important;
  border: 0 !important;
  .close {
    color: #000 !important;
  }
`;

const StyledModalBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ImageWrap = styled.div`
  max-width: 500px;
  max-height: 400px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const MobileRestrictionModal = () => {
  const dispatch = useDispatch();
  const isDesktop = useBreakpoint(1024);
  const { mobileRestrictionModal } = useSelector(getModalsStates);
  const [step, setStep] = useState(0);

  const closeModal = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setCookie('rescrictionModalClosed', true);
    dispatch(changeModalState({ key: 'mobileRestrictionModal', state: false }));
  };

  const goToNextStep = () => setStep((prev) => prev + 1);

  const bodySteps: Record<string, JSX.Element> = {
    0: <FirstRestrictionStep closeModal={closeModal} goToNextStep={goToNextStep} />,
    1: <SecondRestrictionStep closeModal={closeModal} />
  };

  return (
    <StyledModal isOpen={!isDesktop && mobileRestrictionModal} toggle={closeModal} fullscreen>
      <StyledModalHeader toggle={closeModal} />
      <StyledModalBody>{bodySteps[step]}</StyledModalBody>
    </StyledModal>
  );
};
