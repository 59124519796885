import React from 'react';
import Texts from '../../../json/texts.json';

const BookingFooter = () => (
  <div className="booking-footer text-center">
    <span>
      Powered by ©{' '}
      <a href="https://leadsuite.eu/?ref=booking" target="_blank" rel="noopener noreferrer">
        LeadSuite
      </a>{' '}
      •{' '}
      <a href="https://leadsuite.eu/impressum" target="_blank" rel="noopener noreferrer">
        {`${Texts[window.LANGUAGE_SW].imprint} & ${Texts[window.LANGUAGE_SW].privacy}`}
      </a>
    </span>
  </div>
);

export default BookingFooter;
