import axios from '../helpers/axios';
import Texts from '../json/texts.json';

export const getMSGroups = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/mailsuite/api/get?query=groups.json');

    if (res.status === 200) {
      dispatch({
        type: 'set_ms_groups',
        payload: res.data
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const getMSForms = (list_id) => async (dispatch) => {
  try {
    if (list_id !== '-1') {
      const res = await axios.get(`/api/mailsuite/api/get?query=groups.json/${list_id}/forms`);

      if (res.status === 200) {
        dispatch({
          type: 'set_ms_forms',
          payload: res.data
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const getMSForm = (form_id) => async () => {
  try {
    if (form_id !== '-1') {
      const res = await axios.get('/api/mailsuite/api/get', {
        params: {
          query: `forms.json/${form_id}/code`,
          data: {
            badget: false,
            embedded: true,
            ssl: true
          }
        }
      });

      return res.data;
    }

    return `<p>${Texts[window.LANGUAGE_SW].mailsuite.formNotFound}</p>`;
  } catch (e) {
    return `<p>${Texts[window.LANGUAGE_SW].mailsuite.formNotFound}</p>`;
  }
};

export const createCleverreachAccount = (notify) => async () => {
  try {
    if (window.confirm(Texts[window.LANGUAGE_SW].mailsuite.rlyCreateAccount)) {
      await axios.get('/api/mailsuite/api/createUserAccount');
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].mailsuite.accountCreated,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 7500
      });
    }
  } catch (e) {
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 7500
    });
    console.log(e);
  }
};
