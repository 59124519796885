import React, { useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupAddon,
  Progress,
  FormFeedback
} from 'reactstrap';
import Label from '../Forms/CustomLabel';
import 'react-widgets/dist/css/react-widgets.css';
import moment from 'moment';
import styled from 'styled-components';
import { FaImage } from 'react-icons/fa';
import { DatePicker, InputItem, SelectItem } from '../../components';
import { IMessage, IUpdateMessageParams } from '../../modules/types/message';
import { useDispatch, useSelector } from 'react-redux';
import { updateMessageAction, uploadMessageImageAction } from '../../modules/actions/message';
import { getTranslationKey } from '../../modules/utils';
import { getIsImageUploading } from '../../modules/selectors/message';

const minExpirationDate = moment().add(1, 'day').toDate();

export const Wrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;

const InlineForm = styled.div`
  display: flex;
  align-items: center;
`;

const SmallInputWrapper = styled.div`
  &:not(:first-child) {
    margin-left: 40px;
  }
  display: flex;
  align-items: center;

  .form-tooltip {
    margin-left: 5px;
  }
`;

const validateCta = (cta: IUpdateMessageParams['cta']): boolean => {
  if (!cta.url) return true;

  switch (cta.type) {
    case 'http':
      const { url } = cta;

      try {
        const parsedUrl = new URL(`https://${url}`);

        return !!parsedUrl;
      } catch (_) {
        return false;
      }
    case 'tel':
      const tel = cta.url;

      return /^\+?[0-9\-\(\)\s]+$/.test(tel);
    case 'mailto':
      const email = cta.url;

      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    default:
      return false;
  }
};

interface EditMessageProps {
  message: IMessage | null;
}
const EditMessage = ({ message }: EditMessageProps) => {
  const dispatch = useDispatch();
  const [ctaTypesIsOpen, setCtaTypesIsOpen] = useState(false);
  const [isCtaInvalid, setIsCtaInvalid] = useState(false);
  const uploading = useSelector(getIsImageUploading);
  const toggleCtaTypes = () => setCtaTypesIsOpen(!ctaTypesIsOpen);

  if (!message) return null;

  const updateMessage = (update: IUpdateMessageParams, sendToServer: boolean = false) => {
    if (update.cta) {
      const isCtaValid = validateCta(update.cta);
      if (!isCtaValid) {
        setIsCtaInvalid(true);
        sendToServer = false;
      } else {
        setIsCtaInvalid(false);
      }
    }

    dispatch(
      updateMessageAction({
        id: message.id,
        update,
        sendToServer
      })
    );
  };

  const onImageSelected = ({ target: { files } }: any) => {
    try {
      console.log('image selected', files);
      const file = files[0];
      dispatch(
        uploadMessageImageAction({
          messageId: message.id,
          file
        })
      );
    } catch (e) {
      console.log('image selected error ', e);
    }
  };

  return (
    <div>
      <Wrapper>
        <Label className="mb-3" label={getTranslationKey('messages.title')}>
          <Input
            type="text"
            value={message.title}
            onChange={({ target: { value } }) =>
              updateMessage({
                title: value
              })
            }
            onBlur={({ target: { value } }) =>
              updateMessage(
                {
                  title: value
                },
                true
              )
            }
            placeholder={getTranslationKey('messages.titlePlaceholder')}
          />
        </Label>
        <Label className="mb-3" label={getTranslationKey('messages.message')}>
          <Input
            type="textarea"
            value={message.message}
            onChange={({ target: { value } }) =>
              updateMessage({
                message: value
              })
            }
            onBlur={({ target: { value } }) =>
              updateMessage(
                {
                  message: value
                },
                true
              )
            }
            placeholder={getTranslationKey('messages.messagePlaceholder')}
          />
        </Label>

        <Label className="mb-3" label={getTranslationKey('messages.image')}>
          <div>
            <label className="btn btn-primary btn-block btn-icon d-flex justify-content-between align-items-center">
              <Input
                type="file"
                onChange={onImageSelected}
                accept="image/*"
                multiple={false}
                className="hidden-upload"
              />
              <span>{getTranslationKey('selectFile')}</span>
              <FaImage />
            </label>
          </div>
          {uploading && (
            <Progress className="m-t-10" animated color="success" value="100">
              {getTranslationKey('defaultValues.uploading')}
            </Progress>
          )}
        </Label>
      </Wrapper>

      <Wrapper>
        <Label className="mb-3" label={getTranslationKey('messages.ctaText')}>
          <Input
            type="text"
            value={message.cta.text}
            onChange={({ target: { value } }) =>
              updateMessage({
                cta: {
                  ...message.cta,
                  text: value
                }
              })
            }
            onBlur={({ target: { value } }) =>
              updateMessage(
                {
                  cta: {
                    ...message.cta,
                    text: value
                  }
                },
                true
              )
            }
            placeholder={getTranslationKey('messages.ctaTextPlaceholder')}
          />
        </Label>
        <Label className="mb-3" label={getTranslationKey('messages.ctaAction')}>
          <InputGroup>
            <InputGroupButtonDropdown
              addonType="prepend"
              isOpen={ctaTypesIsOpen}
              toggle={toggleCtaTypes}
            >
              <DropdownToggle color="primary" caret>
                {getTranslationKey('pleaseSelect')}
              </DropdownToggle>
              <DropdownMenu>
                {Object.keys(getTranslationKey('messages.ctaTypes')).map((type) => (
                  <DropdownItem
                    key={type}
                    onClick={() =>
                      updateMessage(
                        {
                          cta: {
                            ...message.cta,
                            type
                          }
                        },
                        true
                      )
                    }
                  >
                    {getTranslationKey(`messages.ctaTypes.${type}`)}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </InputGroupButtonDropdown>
            <InputGroupAddon addonType="prepend">
              {getTranslationKey(`messages.ctaTypesLabel.${message.cta.type}`)}
            </InputGroupAddon>
            <Input
              type="text"
              invalid={isCtaInvalid}
              value={message.cta.url}
              onChange={({ target: { value } }) => {
                value = value.replace('https://', '').replace('http://', '');

                updateMessage({
                  cta: {
                    ...message.cta,
                    url: value
                  }
                });
              }}
              onBlur={({ target: { value } }) => {
                value = value
                  .replace('https://', '')
                  .replace('http://', '')
                  .replace('tel:', '')
                  .replace('mailto:', '');
                updateMessage(
                  {
                    cta: {
                      ...message.cta,
                      url: value
                    }
                  },
                  true
                );
              }}
              placeholder={getTranslationKey('messages.ctaUrlPlaceholder')}
            />
            {isCtaInvalid && (
              <FormFeedback>
                {getTranslationKey(`messages.validationErrorMessage.${message.cta.type}`)}
              </FormFeedback>
            )}
          </InputGroup>
        </Label>
        <Label className="mb-3" label={getTranslationKey('messages.recipient')}>
          <SelectItem
            value={message.recipient}
            changeCallback={(value) =>
              updateMessage({ recipient: value as IMessage['recipient'] }, true)
            }
            options={[
              {
                value: 'main_customer',
                text: getTranslationKey('messages.recipients.main_customer')
              },
              {
                value: 'all',
                text: getTranslationKey('messages.recipients.all')
              }
            ]}
          />
        </Label>
        <InlineForm>
          <SmallInputWrapper>
            <InputItem
              label={getTranslationKey('messages.minCollectionViews')}
              pattern={/^[0-9]+$/i}
              min={0}
              value={message.minCollectionViews}
              changeCallback={(value) =>
                updateMessage({ minCollectionViews: parseInt(value) }, true)
              }
              rightAddonElement={getTranslationKey('messages.views')}
            />
          </SmallInputWrapper>
          <SmallInputWrapper>
            <InputItem
              label={getTranslationKey('messages.maxMessageViews')}
              pattern={/^[0-9]+$/i}
              min={1}
              value={message.maxMessageViews}
              changeCallback={(value) => updateMessage({ maxMessageViews: parseInt(value) }, true)}
              rightAddonElement={getTranslationKey('messages.times')}
            />
          </SmallInputWrapper>
        </InlineForm>
      </Wrapper>

      <Wrapper>
        <Label className="mb-3" label={getTranslationKey('messages.expirationDate')}>
          <InputGroup className="w-100-p">
            <DatePicker
              placeholder={getTranslationKey('messages.expirationDatePlaceholder')}
              minDate={minExpirationDate}
              wrapperClassName="w-100-p"
              date={message.expirationDate && moment(message.expirationDate).toISOString()}
              dropUp
              callback={(value) => {
                if (moment(value).isBefore(minExpirationDate)) {
                  value = moment().add(1, 'day').toISOString();
                }

                value = moment(value).utcOffset(0).toISOString();

                updateMessage(
                  {
                    expirationDate: value
                  },
                  true
                );
              }}
            />
          </InputGroup>
        </Label>
        <Button
          className="w-100-p mb-3"
          onClick={() => {
            updateMessage(
              {
                active: !message.active
              },
              true
            );
          }}
          color={message.active ? 'danger' : 'success'}
        >
          {message.active
            ? getTranslationKey('messages.deactivate')
            : getTranslationKey('messages.active')}{' '}
          {message.expirationDate && message.active
            ? ` (${getTranslationKey('messages.activateUntil').replace(
                '{{date}}',
                moment(message.expirationDate).format('DD.MM.YYYY')
              )})`
            : ''}
        </Button>
      </Wrapper>
    </div>
  );
};

export default EditMessage;
