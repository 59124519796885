import React from 'react';
import { radioMap } from '../FirstStep/data';
import TextAreaFields from './TextAreaFields';
import DropDownFields from './DropDownFields';
import useModalHelpers from '../useModalHelpers';

const SecondStep = () => {
  const { handleValue, activeRadio = '' } = useModalHelpers();
  const isDropDownStep = activeRadio === radioMap.seventhRadio;

  return (
    <div>
      {!isDropDownStep && <TextAreaFields handleValue={handleValue} />}
      {isDropDownStep && <DropDownFields handleValue={handleValue} />}
    </div>
  );
};

export default SecondStep;
