import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Input } from 'reactstrap';
import * as actions from '../../../actions';
import Texts from '../../../json/texts.json';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const fonts = [
  { value: 'FONT_SANS_32_BLACK', label: 'Open Sans, 32px, schwarz' },
  { value: 'FONT_SANS_64_BLACK', label: 'Open Sans, 64px, schwarz' },
  { value: 'FONT_SANS_128_BLACK', label: 'Open Sans, 128px, schwarz' },
  { value: 'FONT_SANS_32_WHITE', label: 'Open Sans, 32px, weiß' },
  { value: 'FONT_SANS_64_WHITE', label: 'Open Sans, 64px, weiß' },
  { value: 'FONT_SANS_128_WHITE', label: 'Open Sans, 128px, weiß' }
];

class EditWatermarkData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noPositionSelected: false
    };
    this.updateWatermark = this.updateWatermark.bind(this);
    this.updateSelectedPosition = this.updateSelectedPosition.bind(this);
  }

  updateWatermark() {
    this.props.updateWatermark(this.state, this.props.notify);
  }

  updateSelectedPosition(selectedPosition) {
    this.setState({ noPositionSelected: false });
    let positions = [...this.props.watermark.positions];

    if (positions.includes(selectedPosition)) {
      positions = positions.filter((p) => p !== selectedPosition);
    } else {
      positions.push(selectedPosition);
    }

    if (!positions.length) {
      this.setState({ noPositionSelected: true });
      positions.push('Center');
    }

    this.props.updateWatermarkValue({
      watermark_id: this.props.watermark._id,
      key: 'positions',
      value: positions
    });
  }

  render() {
    const { watermark } = this.props;
    if (!watermark) return null;

    return (
      <Col md="6" sm="12" className="border-left">
        <small>
          <b>{Texts[window.LANGUAGE_SW].watermark.description}</b>
        </small>
        <Input
          className="mb-4"
          value={watermark.description}
          type="text"
          disabled={watermark?.isDefault}
          onChange={({ target: { value } }) =>
            this.props.updateWatermarkValue({
              watermark_id: watermark._id,
              key: 'description',
              value
            })
          }
        />

        {watermark.type === 'text' && (
          <div>
            <small>
              <b>{Texts[window.LANGUAGE_SW].watermark.text}</b>
            </small>
            <Input
              className="mb-4"
              value={watermark.text}
              type="text"
              disabled={watermark?.isDefault}
              onChange={({ target: { value } }) =>
                this.props.updateWatermarkValue({
                  watermark_id: watermark._id,
                  key: 'text',
                  value
                })
              }
            />
          </div>
        )}

        {watermark.type === 'image' ? (
          <div>
            <small>
              <b>{Texts[window.LANGUAGE_SW].watermark.scale}</b>
              <b className="float-right">{parseInt(watermark.scale * 100)}%</b>
            </small>
            <Slider
              className="mb-4"
              min={0}
              max={100}
              defaultValue={watermark.scale * 100}
              onChange={(value) =>
                this.props.updateWatermarkValue({
                  watermark_id: watermark._id,
                  key: 'scale',
                  value: value / 100
                })
              }
            />

            <small>
              <b>{Texts[window.LANGUAGE_SW].watermark.opacity}</b>
              <b className="float-right">{parseInt(watermark.opacity * 100)}%</b>
            </small>
            <Slider
              className="mb-4"
              min={0}
              max={100}
              defaultValue={watermark.opacity * 100}
              onChange={(value) =>
                this.props.updateWatermarkValue({
                  watermark_id: watermark._id,
                  key: 'opacity',
                  value: value / 100
                })
              }
            />
          </div>
        ) : (
          <div>
            <small>
              <b>{Texts[window.LANGUAGE_SW].watermark.font}</b>
            </small>
            <div>
              <Input
                onChange={({ target: { value } }) =>
                  this.props.updateWatermarkValue({
                    watermark_id: watermark._id,
                    key: 'jimpFont',
                    value
                  })
                }
                selected={watermark.jimpFont}
                defaultValue={watermark.jimpFont}
                disabled={watermark?.isDefault}
                className="mb-4"
                type="select"
              >
                {fonts.map((font, key) => (
                  <option key={key} value={font.value}>
                    {font.label}
                  </option>
                ))}
              </Input>
            </div>
          </div>
        )}
        <small>
          <b>{Texts[window.LANGUAGE_SW].watermark.positions}</b>
        </small>
        <table className="watermark-positions-table">
          <tbody>
            <tr>
              <td
                className={watermark.positions.includes('NorthWest') ? 'active' : ''}
                onClick={() => this.updateSelectedPosition('NorthWest')}
              />
              <td className="not-allowed" />
              <td
                className={watermark.positions.includes('NorthEast') ? 'active' : ''}
                onClick={() => this.updateSelectedPosition('NorthEast')}
              />
            </tr>
            <tr>
              <td className="not-allowed" />
              <td
                className={watermark.positions.includes('Center') ? 'active' : ''}
                onClick={() => this.updateSelectedPosition('Center')}
              />
              <td className="not-allowed" />
            </tr>
            <tr>
              <td
                className={watermark.positions.includes('SouthWest') ? 'active' : ''}
                onClick={() => this.updateSelectedPosition('SouthWest')}
              />
              <td className="not-allowed" />
              <td
                className={watermark.positions.includes('SouthEast') ? 'active' : ''}
                onClick={() => this.updateSelectedPosition('SouthEast')}
              />
            </tr>
          </tbody>
        </table>

        {this.state.noPositionSelected && (
          <small>{Texts[window.LANGUAGE_SW].watermark.noPosition}</small>
        )}
      </Col>
    );
  }
}

export default connect(null, actions)(EditWatermarkData);
