import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem, Container } from 'reactstrap';
import { connect } from 'react-redux';
import { AppBreadcrumb, AppFooter, AppHeader, AppSidebar, AppSidebarNav } from '@coreui/react';
// sidebar nav config
import getWhiteLabelMenu from '../../data/getWhiteLabelMenu';
import * as actions from '../../actions';
// routes config
// eslint-disable-next-line
import getRoutes from '../../whiteLabelRoutes';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';
import Texts from '../../json/texts.json';
import { DiscountAlert } from '../../elements/DiscountKey';
import MaintenanceAlert from '../../elements/Alerts/MaintenanceAlert';
import history from '../../helpers/history';
import UpgradeAccountModal from '../../elements/User/Account/UpgradeAccountModal';
import UpgradeSlideshowAccountModal from '../../elements/User/Account/UpgradeSlideshowAccountModal';
import { ShopTaxModal } from '../../components/ShopTaxSetup/ShopTaxModal';
// import EasterSaleModal from '../../elements/User/Message/EasterSaleModal';
// import SeasonStartSaleModal from '../../elements/User/Message/SeasonStartSaleModal';
// import NphotoPriceIncreaseModal from '../../elements/User/Message/NphotoPriceIncreaseModal';
import TrialEndsAlert from '../../elements/TrialEndsAlert';
import SubscriptionRoute from '../../components/Route/SubscriptionRoute';
import FeaturesDowngradeAlert from '../../elements/Subscription/FeaturesDowngradeAlert';
import FeaturesDowngradeModal from '../../elements/Subscription/FeaturesDowngradeModal';
import { DEAL_DISCOUNT_KEYS } from '../../modules/constants/subscriptions';
import UpdateAddonsModal from '../../pages/subscription/UpdateAddonsModal';
import { getTranslationKey } from '../../modules/utils';
import StripeMigrationAlert from '../../elements/Subscription/StripeMigrationAlert';
import StripeMigrationNote from '../../elements/Subscription/StripeMigrationNote';

class DefaultLayout extends Component {
  constructor(props) {
    super(props);

    let isDiscountAlertOpen = false;

    if (!!props.auth?.digistore?.discountKey?._id) {
      isDiscountAlertOpen = !DEAL_DISCOUNT_KEYS.includes(props.auth?.digistore?.discountKey?._id);
    }

    this.state = {
      locationIsCollectionEdit: history.location.pathname.startsWith('/collection/edit/'),
      locationIsPortfolioEdit: history.location.pathname.startsWith('/portfolios/edit/'),
      isDiscountAlertOpen,
      routes: []
    };

    history
      .listen((location) => {
        this.setState({
          locationIsCollectionEdit: location.pathname.startsWith('/collection/edit/'),
          locationIsPortfolioEdit: location.pathname.startsWith('/portfolios/edit/')
        });
      })
      .bind(this);
  }

  componentDidMount() {
    this.setState(() => ({ routes: getRoutes(window.LANGUAGE_SW) }));
  }

  getNavigation() {
    const { auth } = this.props;
    const navigation = getWhiteLabelMenu(auth);

    if (auth.isAdmin) {
      return {
        ...navigation,
        items: [
          {
            name: Texts[window.LANGUAGE_SW].adminDashboard.title,
            url: '/admin/dashboard',
            icon: 'icon-diamond'
          },
          ...navigation.items
        ]
      };
    }

    return navigation;
  }

  closeDiscountAlert = () => {
    this.setState({ isDiscountAlertOpen: false });
  };

  render() {
    const { auth } = this.props;
    const { locationIsCollectionEdit, locationIsPortfolioEdit, isDiscountAlertOpen, routes } =
      this.state;

    const isOnEditPages = locationIsCollectionEdit || locationIsPortfolioEdit;

    const isOnTrial = auth?.subscription?.plan === 'free-trial';
    const isNotOnSubscriptionPage = !window.location.href.includes('subscription/pricing');
    const trialEndsAt = auth?.subscription?.freeTrialEndsAt;
    const userIsOnFreeTrial = isOnTrial && isNotOnSubscriptionPage && trialEndsAt;

    return (
      <div className="app">
        <UpgradeAccountModal />
        <UpdateAddonsModal />
        <UpgradeSlideshowAccountModal />
        <FeaturesDowngradeModal />
        <AppHeader fixed>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">
          <AppSidebar data-cy="appSidebar" fixed minimized display="lg">
            <AppSidebarNav data-cy="navigate" navConfig={this.getNavigation()} {...this.props} />
          </AppSidebar>
          <main className="main">
            <MaintenanceAlert />
            {!isOnEditPages && (
              <AppBreadcrumb data-cy="appBreadcrumb" appRoutes={getRoutes(window.LANGUAGE_SW)} />
            )}
            {locationIsPortfolioEdit && (
              <Breadcrumb>
                <BreadcrumbItem>
                  <a href="/#/">Home</a>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <a href="/#/portfolios">{getTranslationKey('portfolio.portfolios')}</a>
                </BreadcrumbItem>
                <BreadcrumbItem active>{getTranslationKey('portfolio.title')}</BreadcrumbItem>
              </Breadcrumb>
            )}
            {!isOnEditPages && (
              <DiscountAlert
                isOpen={isDiscountAlertOpen}
                closeDiscountAlert={this.closeDiscountAlert}
              />
            )}
            {!isOnEditPages && <FeaturesDowngradeAlert />}
            {!isOnEditPages && <StripeMigrationAlert />}
            {!isOnEditPages && <StripeMigrationNote />}
            {!isOnEditPages && userIsOnFreeTrial && (
              <TrialEndsAlert trialEndsAt={auth?.subscription?.freeTrialEndsAt} />
            )}
            <Container fluid style={{ height: 'calc(100% - 70px)', margin: '24px 0 40px 0' }}>
              <Switch>
                {routes.map((route, idx) =>
                  route.component ? (
                    <SubscriptionRoute
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      pageId={route.id}
                      render={(props) => <route.component {...props} />}
                    />
                  ) : null
                )}
                <Redirect exact from="/" to="/dashboard" />
                {!!routes.length && <Redirect from="*" to="/dashboard" />}
              </Switch>
            </Container>
          </main>
          {/* {!isNewSettings ? (
            <AppAside fixed display className="help_collection_settings">
              <EditCollectionAside />
            </AppAside>
          ) : null} */}
        </div>
        {!locationIsCollectionEdit && (
          <AppFooter>
            <DefaultFooter />
          </AppFooter>
        )}
        <ShopTaxModal />
      </div>
    );
  }
}

function mapStateToProps({ auth, environment, accessRules }) {
  return { auth, environment, accessRules };
}

export default connect(mapStateToProps, actions)(DefaultLayout);
