import { IStoreState } from '../types';
import { createSelector } from 'reselect';
import { get } from 'lodash';

export const getUser = (state: IStoreState) => state.auth;
export const getIsUserSubscribed = createSelector(
  [getUser],
  (user) =>
    !['free', 'free-trial', 'freemium', 'starter'].includes(get(user, 'subscription.plan', ''))
);
export const getIsUserCustomer = createSelector(
  [getUser],
  (user) => !['free', 'free-trial', 'freemium'].includes(get(user, 'subscription.plan', ''))
);

export const getIsOrWasUserSubscribed = (state: IStoreState) => {
  const isCustomer = !['free', 'free-trial', 'freemium'].includes(
    get(state, 'auth.subscription.plan', '')
  );
  const wasCustomer = get(state, 'paymentInfo.invoices.length', 0) > 0;
  return isCustomer || wasCustomer;
};

export const getPayoutAccountSelector = (state: IStoreState) => state.user.payoutAccount;
export const getAvailableCurrenciesSelector = (state: IStoreState) =>
  state.user.availableCurrencies;
export const getUserType = createSelector(getUser, ({ type }: any) => type);
