import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import { errorsGlobalError } from '../../../modules/actions';
import { changeModalState } from '../../actions/modals';
import Api from '../../../modules/utils/API';

export const startTrialSaga = function* (): SagaIterator {
  try {
    yield call(Api.Trial.startFreeTrial);
    yield put(changeModalState({ key: 'newCustomerModal', state: false }));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};
