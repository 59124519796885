import React from 'react';
import styled from 'styled-components';
import { getTranslationKey } from '../../../modules/utils/helpers/index';
import { SelectItem } from '../../../components/Forms/SelectItem';

interface IGalleryProducts {
  shopCtaPlacement: string;
  changeCallBack: (path: string, value: string | number | boolean) => void;
}

const Title = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const GalleryProductsSettings: React.FC<IGalleryProducts> = ({
  shopCtaPlacement,
  changeCallBack
}) => {
  const settingsOptions = [
    { value: 'hide', text: getTranslationKey('shop.settingsHideOption') },
    { value: 'after-each', text: getTranslationKey('shop.settingsAfterEachAlbumOption') },
    { value: 'after-last', text: getTranslationKey('shop.settingsAfterLastAlbumOption') }
  ];

  return (
    <div className="mt-4">
      <Title>{getTranslationKey('shop.settingsTitle')}</Title>
      <SelectItem
        label={getTranslationKey('shop.settingsSelectionLabel')}
        value={shopCtaPlacement}
        changeCallback={(value) => changeCallBack('shopCtaPlacement', value)}
        options={settingsOptions}
      />
    </div>
  );
};
