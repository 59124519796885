import axios from '../helpers/axios';

export const getSiteTemplatesBySiteType = (type) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/site_templates/?type=${type}`);

    dispatch({
      type: 'SET_SITE_TEMPLATES_BY_TYPE',
      payload: { type, data: res.data }
    });
  } catch (e) {}
};

export const getSiteTemplates = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/site_templates/');

    dispatch({
      type: 'SET_ALL_SITE_TEMPLATES',
      payload: { data: res.data }
    });
  } catch (e) {}
};

export const deleteSiteTemplateById = (data) => async (dispatch) => {
  try {
    await axios.delete('/api/site_template/', {
      params: { id: data.id }
    });

    dispatch({
      type: 'REMOVE_SITE_TEMPLATE',
      payload: data
    });
  } catch (e) {}
};
