import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './actions';
import * as actionsTs from './modules/actions';
import history from './helpers/history';
import Texts from './json/texts.json';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import theme from './assets/css/theme';
// Styles
import core from './assets/css/core';
// CoreUI Icons Set
import '@coreui/icons/css/coreui-icons.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/dist/styles/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.css';

// Containers
import { SetupAccountLayout, WhiteLabelLayout } from './containers';
// Pages
import { Login, Register, ForgotPassword, ChangePassword } from './views/Pages';
import PublicPricing from './views/Pricing/PublicPricing';
import BookingSuite from './views/Booking/Customer/BookingSite';
import BookingSuiteConfirm from './views/Booking/Customer/BookingConfirm';
import NotificationsSystem from 'reapop';
import NotificationsSystemTheme from 'reapop-theme-bootstrap';
import StrategyModal from './elements/Strategies/StrategyModal';
import DiscountModal from './elements/User/Message/DiscountModal';
import UseDesktopModal from './elements/User/Message/UseDesktopModal';
import SurveyModalCES from './elements/User/Message/SurveyModalCES';
import SurveyModalNPS from './elements/User/Message/SurveyModalNPS';
import MessageModal from './elements/User/Message/MessageModal';
import CancelSubscriptionModal from './components/CancelSubscriptionModal';
import NewCustomerModal from './components/NewCustomerModal';
import { MobileRestrictionModal } from './components/MobileRestrictionModal';
import queryString from 'query-string';
import AppVersionModal from './components/AppVersionModal';
import { NotAuthOnlyRoute, PrivateRoute } from './components';
import { initializeHotjar } from './hotjar';
import moment from 'moment';
import { get } from 'lodash';
import { getCookie } from './helpers/cookies';

const GlobalStyle = createGlobalStyle`${core}`;

class App extends Component {
  constructor(props) {
    super(props);

    const parsed = queryString.parse(history.location.search);
    const {
      openStrategy,
      newsletterConfirmed,
      // openGoogleCalendarSettings,
      openSurveyModalCES,
      openSurveyModalNPS,
      openDowngradeModal,
      destinationPath,
      emailChanged,
      companyID,
      redirect,
      zendeskSSO,
      zendeskReturnTo,
      destinationAfterSignIn,
      intercomArg1,
      intercomArg2
    } = parsed;
    this.openStrategy = openStrategy;
    this.newsletterConfirmed = newsletterConfirmed;
    this.openSurveyModalCES = openSurveyModalCES;
    this.openSurveyModalNPS = openSurveyModalNPS;
    this.openDowngradeModal = openDowngradeModal;
    this.destinationPath = destinationPath;
    this.emailChanged = emailChanged;
    this.cannyCompanyId = companyID;
    this.cannyRedirectUrl = redirect;
    this.zendeskSSO = zendeskSSO;
    this.zendeskReturnTo = zendeskReturnTo;
    this.destinationAfterSignIn = destinationAfterSignIn;
    this.intercomArg1 = intercomArg1;
    this.intercomArg2 = intercomArg2;
  }

  async UNSAFE_componentWillMount() {
    const {
      notify,
      getCurrentUser,
      getStrategy,
      setStrategyModalVisibility,
      checkMaintenance,
      setSurveyModalVisibility,
      changeModalState,
      setSurveyModalNPSVisibility,
      accessRulesAction
    } = this.props;

    const action = await getCurrentUser();

    if (action === 'redirect') return;

    checkMaintenance();
    accessRulesAction();

    if (this.openStrategy) {
      getStrategy({ name: this.openStrategy });
      setStrategyModalVisibility(true);
    }

    if (this.newsletterConfirmed === '1' && notify) {
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].newsletterConfirmed,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 7500
      });
    }

    if (this.emailChanged === '1' && notify) {
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].changedEmailSuccessfully,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 7500
      });
    }

    if (this.openSurveyModalCES) setSurveyModalVisibility(true);
    else if (this.openSurveyModalNPS) setSurveyModalNPSVisibility(true);
    if (this.openDowngradeModal) {
      changeModalState({ key: 'featuresDowngradeModal', state: true });
    }
    if (this.destinationPath) {
      history.push(decodeURIComponent(this.destinationPath));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      auth,
      getStrategy,
      getWatermarks,
      getUserBrands,
      getScrappbookTemplates,
      getCollectionsIds,
      environment,
      setMessageModalVisibility,
      activateNewUserModalState,
      activateMobileRestrictionModal,
      fetchEmailPackages
    } = this.props;

    if (environment.isEdgeOrIe) {
      setMessageModalVisibility({
        isMessageModalOpen: true,
        messages: [Texts[window.LANGUAGE_SW].edgeOrIEWarning]
      });
    }

    if (
      !this.openStrategy &&
      auth.lastStartedStrategy &&
      auth.lastStartedStrategy !== prevProps.auth.lastStartedStrategy
    ) {
      getStrategy({ id: auth.lastStartedStrategy });
    }

    window.setTimeout(() => {
      if (window.Intercom && this.intercomArg1) {
        window.Intercom(this.intercomArg1, this.intercomArg2);
      }
    }, 2000);

    if (
      prevProps.auth?.subscription?.plan !== auth?.subscription?.plan &&
      auth?.subscription?.plan === 'free' &&
      !auth?.digistore?.order_id &&
      new Date(auth?.created_at) - new Date('2022-05-01') > 0 &&
      auth?.type === 'SB'
    ) {
      activateNewUserModalState();
    }

    if (prevProps.auth && auth._id && auth._id !== prevProps.auth._id) {
      getCollectionsIds({ withInitialCollectionsCount: true });
      getWatermarks();
      getUserBrands();
      fetchEmailPackages();

      if (this.destinationAfterSignIn) {
        window.location = decodeURIComponent(this.destinationAfterSignIn);
      }

      if (!getCookie('rescrictionModalClosed')) {
        activateMobileRestrictionModal();
      }

      if (auth.type === 'SB') {
        if (
          moment(auth.created_at).isAfter(moment().subtract(14, 'days')) &&
          get(auth, 'subscription.plan', '').includes('free')
        ) {
          initializeHotjar();
        }

        getScrappbookTemplates();

        if (this.cannyCompanyId && this.cannyRedirectUrl) {
          console.log(
            'canny parameters present =>',
            this.cannyCompanyId,
            this.cannyRedirectUrl,
            'redirect the user'
          );
          window.location = `${
            window.API_URL
          }/api/users/redirect/canny?companyID=${encodeURIComponent(
            this.cannyCompanyId
          )}&redirect=${encodeURIComponent(this.cannyRedirectUrl)}`;
        }

        if (this.zendeskSSO) {
          console.log('zendeskSSO parameters present =>', this.zendeskReturnTo);

          let zendeskUrl = `${window.API_URL}/api/users/redirect/zendesk`;

          if (this.zendeskReturnTo) {
            zendeskUrl += `?zendeskReturnTo=${encodeURIComponent(this.zendeskReturnTo)}`;
          }

          window.location = zendeskUrl;
        }

        if (window.Canny) {
          window.Canny('initChangelog', {
            appID: process.env.REACT_APP_CANNY_APP_ID,
            position: 'bottom',
            align: 'left'
          });
        }
      }
    }
  }

  render() {
    const { auth } = this.props;
    const debugOffline = process.env.NODE_ENV !== 'production' && false;

    return (auth.authenticated && auth.email) || !auth.authenticated || debugOffline ? (
      <div
        onDrop={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
        onDragEnter={(e) => e.preventDefault()}
      >
        <ThemeProvider theme={theme}>
          <GlobalStyle data-cy="GlobalStyle" />
          <StrategyModal data-cy="StrategyModal" />
          <DiscountModal data-cy="DiscountModal" />
          <SurveyModalCES data-cy="SurveyModalCES" />
          <SurveyModalNPS data-cy="SurveyModalNPS" />
          <UseDesktopModal data-cy="UseDesktopModal" />
          <MessageModal data-cy="MessageModal" />
          <AppVersionModal />
          <CancelSubscriptionModal data-cy="CancelSubscriptionModal" />
          <NewCustomerModal />
          <MobileRestrictionModal />
          <NotificationsSystem data-cy="NotificationsSystem" theme={NotificationsSystemTheme} />
          <HashRouter>
            <Switch>
              <NotAuthOnlyRoute exact path="/login" name="Login Page" component={Login} />
              <PrivateRoute exact path="/logout" name="Login Page" component={Login} />
              <NotAuthOnlyRoute exact path="/register" name="Register Page" component={Register} />
              <Route exact path="/pricing" name="Preise" component={PublicPricing} />
              <Route path="/book/do/:id" name="BookingSuite" component={BookingSuite} />
              <Route
                path="/book/confirm/"
                name="BookingSuite Confirm"
                component={BookingSuiteConfirm}
              />
              <Route
                path="/account/forgot-password/"
                name="Forgot Password"
                component={ForgotPassword}
              />
              <Route exact path="/account/change-password/:type?" component={ChangePassword} />
              <PrivateRoute exact path="/account/setup/" component={SetupAccountLayout} />
              <PrivateRoute path="/" name="Home" component={WhiteLabelLayout} />
            </Switch>
          </HashRouter>
        </ThemeProvider>
      </div>
    ) : (
      <div className="loading">
        <div className="loader" />
      </div>
    );
  }
}

function mapStateToProps({ auth, notify, environment }) {
  return { auth, notify, environment };
}

export default connect(mapStateToProps, { ...actions, ...actionsTs })(App);
