import React from 'react';
import styled from 'styled-components';
import { DateTimePicker } from 'react-widgets';
import moment from 'moment';
import { LabelItem } from '../Forms/LabelItem';
import { InputTheme } from '../Forms/InputStyled';
import theme from '../../assets/css/theme';
import { UncontrolledTooltip } from 'reactstrap';

const LabelItemStyled = styled(LabelItem)`
  width: fit-content;
`;

const Wrapper = styled.div`
  margin-bottom: 10px;

  .rw-widget-picker {
    border-radius: 20px;
    border: 1px solid transparent;

    &:not(:last-child) {
      border-right: none;
    }

    &::placeholder {
      font-weight: normal;
      color: ${theme.commonColors.main};
      opacity: 1;
    }

    &:focus {
      padding-left: 10px;
    }

    ${InputTheme.main};
  }

  .rw-select {
    border-left: none;
    padding-right: 4px;

    &:hover {
      background: #ffffff;
    }

    .rw-btn {
      color: ${theme.commonColors.second};
    }
  }

  .tooltip-icon {
    color: ${theme.commonColors.second};
    margin-left: 5px;
  }
`;

interface IProps {
  readonly date: string | null;
  readonly placeholder?: string;
  readonly className?: string;
  readonly callback: (value: string) => void;
  readonly label?: string;
  readonly defaultDate?: string | Date | null;
  readonly withTime?: boolean;
  readonly withTooltip?: boolean;
  readonly id?: string;
  readonly tooltipPlacement?: string;
  readonly tooltipText?: string;
  readonly wrapperClassName?: string;
  readonly minDate?: Date;
  readonly dropUp?: boolean;
}

export const DatePicker = ({
  date,
  placeholder,
  className,
  callback,
  label,
  defaultDate = null,
  withTime = false,
  withTooltip,
  id,
  tooltipPlacement,
  wrapperClassName,
  tooltipText,
  minDate = new Date(1900, 0, 1),
  dropUp = false
}: IProps) => {
  const pickerDate = date ? moment(date).toDate() : defaultDate;
  const tooltipID = `${id}_tooltip`;
  const tooltipIcon =
    withTooltip && id ? <i className="fa fa-icon fa-question-circle tooltip-icon" /> : null;
  const tooltip =
    withTooltip && id ? (
      <UncontrolledTooltip
        autohide={false}
        placement={tooltipPlacement as any}
        target={tooltipID}
        dangerouslySetInnerHTML={{ __html: tooltipText || '' }}
      />
    ) : null;

  return (
    <Wrapper className={wrapperClassName}>
      {label ? (
        <LabelItemStyled isSmall isBold id={tooltipID}>
          {label}
          {tooltipIcon}
        </LabelItemStyled>
      ) : null}
      {tooltip}
      <DateTimePicker
        time={withTime}
        value={pickerDate}
        placeholder={placeholder}
        className={className}
        onChange={callback}
        min={minDate}
        dropUp={dropUp}
      />
    </Wrapper>
  );
};

export default DatePicker;
