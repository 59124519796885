import React, { Component } from 'react';
import Texts from '../../../json/texts.json';
import { Row, Progress, FormGroup, Input, Label, Alert } from 'reactstrap';
import ClientCalender from '../../../elements/Booking/Customer/ClientCalender';
import SingleDateSelection from '../../../elements/Booking/Customer/SingleDateSelection';
import Tracking from '../../../elements/Booking/Customer/Tracking';
import LeadForm from '../../../elements/Booking/Customer/LeadForm';
import ConfirmData from '../../../elements/Booking/Customer/ConfirmData';
import axios from 'axios';
import currencySymbols from '../../../helpers/currency';
import queryString from 'query-string';
import BookingSiteContainer from './BookingSiteContainer';

class BookingSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: {},
      lead: {},
      bookingSite: {}
    };

    this.nextStep = this.nextStep.bind(this);
    this.prevStep = this.prevStep.bind(this);
    this.saveLead = this.saveLead.bind(this);
    this.onVoucherChange = this.onVoucherChange.bind(this);
    this.toggleProduct = this.toggleProduct.bind(this);
    this.submitBooking = this.submitBooking.bind(this);
    this.getAppointmentLength = this.getAppointmentLength.bind(this);
    this.clientCalenderPageChanged = this.clientCalenderPageChanged.bind(this);
    this.onSelectAppointment = this.onSelectAppointment.bind(this);
    this.purchaseOnInvoice = this.purchaseOnInvoice.bind(this);
    this.purchaseByPayPal = this.purchaseByPayPal.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadBookingSite(this.props.match.params.id);

    const parsed = queryString.parse(this.props.location.search);
    const { token } = parsed;
    if (token) alert(Texts[window.LANGUAGE_SW].bookingAborted);
  }

  onVoucherChange(e) {
    if (e.target.value === -1) {
      this.setState({
        product: {}
      });
    } else {
      const product = this.getProductFromBookingSiteById(e.target.value);
      this.setState({
        product
      });
    }
  }

  onSelectAppointment(appointment) {
    this.setState({ appointment });
  }

  getProductFromBookingSiteById(id) {
    return this.state.bookingSite.products.filter((product) => product._id === id)[0];
  }

  getAppointmentLength() {
    const { bookingSite } = this.state;

    return bookingSite.type === 'consult' ? bookingSite.duration : this.state.product.duration;
  }

  async submitBooking() {
    try {
      this.setState({ loading: true });
      const { type, thankYouUrl } = this.state.bookingSite;
      const result = await axios.post(`/api/booking/create/${type}`, this.state);
      const { success, redirectUrl, blockedAppointment } = result.data;
      if (success) {
        if (type === 'consult' || this.state.purchaseOnInvoice) {
          if (thankYouUrl) window.location = thankYouUrl;
          else this.setState({ status: 'success', loading: false });
        } else {
          if (redirectUrl) {
            this.setState({ status: null });
            window.location = redirectUrl;
          } else {
            this.setState({ status: 'danger', loading: false });
          }
        }
      } else {
        if (blockedAppointment) this.setState({ status: 'blocked', loading: false });
        else this.setState({ status: 'danger', loading: false });
      }
    } catch (e) {
      console.log(e);
      this.setState({ status: 'danger', loading: false });
    }
  }

  purchaseByPayPal() {
    this.setState({ purchaseOnInvoice: false }, this.submitBooking);
  }

  purchaseOnInvoice() {
    this.setState({ purchaseOnInvoice: true }, this.submitBooking);
  }

  clientCalenderPageChanged(clientCalenderPage) {
    this.setState({ clientCalenderPage });
  }

  toggleProduct(product) {
    this.setState({
      product: {
        ...product
      }
    });
  }

  saveLead(values) {
    this.setState({
      lead: {
        ...values
      }
    });
    this.nextStep();
  }

  nextStep() {
    this.setState((prevState) => ({ step: prevState.step + 1 }));
  }

  prevStep() {
    this.setState((prevState) => ({
      step: prevState.step - 1,
      status: null
    }));
  }

  async loadBookingSite(bookingSiteId) {
    if (bookingSiteId) {
      try {
        const result = await axios.get(`/api/bookingsite?id=${bookingSiteId}`);
        const bookingSite = result.data;
        const step = 0;
        const maxSteps = bookingSite.type === 'shooting' ? 3 : 2;
        this.setState({ bookingSite, step, maxSteps });
      } catch (e) {}
    }
  }

  renderCompanyInfo() {
    if (this.state.step === 0) {
      const { company, first_name, last_name, street, zipcode, city, email } =
        this.state.bookingSite._user;

      return (
        <div>
          <Row>
            <div className="col-12 col-md-6">
              <h2>{company}</h2>
              <p>
                {first_name + ' ' + last_name}
                <br />
                {street}
                <br />
                {zipcode + ' ' + city}
                <br />
                <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
                  {email}
                </a>
              </p>
            </div>
            <div className="col-12 col-md-6">
              <p>{this.state.bookingSite.description}</p>
            </div>
          </Row>
          <hr />
        </div>
      );
    }
  }

  renderProgress() {
    const { step, maxSteps, bookingSite } = this.state;

    return (
      <Row>
        <div className="col-12">
          <div className="text-center m-b-20">
            <h4>
              {Texts[window.LANGUAGE_SW].step} {step + 1} -{' '}
              {Texts[window.LANGUAGE_SW].bookingSteps[bookingSite.type][step]}
            </h4>
          </div>
          <Progress
            className="m-b-20 h-30"
            animated
            color="success"
            value={step === 0 ? 0.3 : step}
            max={maxSteps}
          />
        </div>
      </Row>
    );
  }

  renderConfirmOverview() {
    const { status, bookingSite } = this.state;

    let bookButtonText = '';
    let bookButtonFunction = null;

    if (bookingSite.type === 'consult') {
      bookButtonText = Texts[window.LANGUAGE_SW].bookButton;
      bookButtonFunction = this.submitBooking;
    } else {
      bookButtonText = Texts[window.LANGUAGE_SW].goToPayPal;
      bookButtonFunction = this.purchaseByPayPal;
    }

    const buttonTexts =
      bookButtonText +
      (bookingSite.purchaseOnInvoice ? '/' + Texts[window.LANGUAGE_SW].purchaseOnInvoice : '');

    return (
      <div>
        <ConfirmData {...this.state} />
        <button
          className="btn btn-primary w-100-p btn-booking m-b-5"
          onClick={bookButtonFunction}
          disabled={status === 'success'}
        >
          {bookButtonText}
        </button>
        {bookingSite.purchaseOnInvoice && (
          <button
            className="btn btn-primary w-100-p btn-booking m-b-5"
            onClick={this.purchaseOnInvoice}
            disabled={status === 'success'}
          >
            {Texts[window.LANGUAGE_SW].purchaseOnInvoice}
          </button>
        )}
        <div className="dataprivacy-hint-wrapper">
          <small>
            {Texts[window.LANGUAGE_SW].dataprivacyHint.replace('$$button$$', buttonTexts)}
          </small>
        </div>
        {status && (
          <Alert className="m-t-10" color={status === 'blocked' ? 'danger' : status}>
            {Texts[0][`booking-${status}`]}
          </Alert>
        )}
      </div>
    );
  }

  renderLeadForm() {
    const { phone_no_required } = this.state.bookingSite;

    return <LeadForm saveLead={this.saveLead} phone_no_required={phone_no_required} />;
  }

  renderShooting() {
    switch (this.state.step) {
      case 2:
        return this.renderLeadForm();
      case 0:
        const { products } = this.state.bookingSite;

        return (
          <div className="row">
            <div className="col">
              {products.map((product, key) => (
                <FormGroup key={key} check>
                  <Label className="w-100-p" check>
                    <Input
                      type="radio"
                      checked={this.state.product._id === product._id}
                      onClick={() => this.toggleProduct(product)}
                      className="form-group-booking-product"
                    />
                    <h3 className="inline">{product.title}</h3>
                    <h3 className="float-right inline">
                      {product.price + currencySymbols(product.currency)}
                    </h3>
                    <p>{product.description}</p>
                  </Label>
                </FormGroup>
              ))}

              <button
                className="btn btn-success w-100-p btn-booking"
                disabled={!this.state.product._id}
                onClick={this.nextStep}
                type="submit"
              >
                {Texts[window.LANGUAGE_SW].nextStep}
              </button>
            </div>
          </div>
        );
      case 1:
        return this.renderAppointmentSelection();
      case 3:
        return this.renderConfirmOverview();
      default:
        return <p>Error</p>;
    }
  }

  renderConsult() {
    switch (this.state.step) {
      case 1:
        return this.renderLeadForm();
      case 0:
        return this.renderAppointmentSelection();
      case 2:
        return this.renderConfirmOverview();
      default:
        return <p>Error</p>;
    }
  }

  renderAppointmentSelection() {
    const { appointment, clientCalenderPage, bookingSite } = this.state;
    const {
      availableTimesType,
      _user,
      buffer,
      _id,
      availableTimes,
      availableAppointmentsCount,
      availableDaysInAdvance,
      bufferBeforeFirstAppointment
    } = bookingSite;

    if (availableTimesType === 'availableTimes') {
      return (
        <ClientCalender
          selectedAppointment={appointment}
          onSelectAppointment={this.onSelectAppointment}
          onSelectionEnd={this.nextStep}
          userId={_user._id}
          buffer={buffer}
          bookingSiteId={_id}
          available={availableTimes}
          availableDaysInAdvance={availableDaysInAdvance}
          bufferBeforeFirstAppointment={bufferBeforeFirstAppointment}
          appointmentLengthMinutes={this.getAppointmentLength()}
          availableAppointmentsCount={availableAppointmentsCount}
          page={clientCalenderPage || 1}
          onSelectedPageChange={this.clientCalenderPageChanged}
        />
      );
    }

    return (
      <SingleDateSelection
        bookingSiteId={_id}
        selectedAppointment={appointment}
        onSelectAppointment={this.onSelectAppointment}
        onSelectionEnd={this.nextStep}
      />
    );
  }

  renderGift() {
    switch (this.state.step) {
      case 1:
        return this.renderLeadForm();
      case 0:
        const { products } = this.state.bookingSite;

        return (
          <div>
            <FormGroup>
              <Label>{Texts[window.LANGUAGE_SW].selectVoucherValue}</Label>
              <Input value={this.state.product._id} onChange={this.onVoucherChange} type="select">
                <option value="-1">{Texts[window.LANGUAGE_SW].pleaseSelect}</option>
                {products.map((product, key) => (
                  <option value={product._id} key={key}>
                    {product.price + currencySymbols(product.currency)}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <button
              className="btn btn-success w-100-p btn-booking"
              disabled={!this.state.product._id}
              onClick={this.nextStep}
              type="submit"
            >
              {Texts[window.LANGUAGE_SW].nextStep}
            </button>
          </div>
        );
      case 2:
        return this.renderConfirmOverview();
      default:
        return <p>Error</p>;
    }
  }

  renderByType() {
    switch (this.state.bookingSite.type) {
      case 'shooting':
        return this.renderShooting();
      case 'consult':
        return this.renderConsult();
      case 'gift':
        return this.renderGift();
      default:
        return this.renderShooting();
    }
  }

  render() {
    const { bookingSite, loading } = this.state;
    if (!bookingSite._id || loading) {
      return (
        <div className="loading">
          <div className="loader" />
        </div>
      );
    }

    if (bookingSite.active === false) {
      return (
        <h1 className="text-center p-20">
          {Texts[window.LANGUAGE_SW].bookingSiteDisabled}
          <br />
          {Texts[window.LANGUAGE_SW].pleaseTryOtherTime}
        </h1>
      );
    }

    return (
      <BookingSiteContainer bookingSite={bookingSite}>
        {this.renderCompanyInfo()}
        {this.renderProgress()}
        {this.renderByType()}
        {this.state.step !== 0 && (
          <div className="text-center m-t-10">
            <span onClick={this.prevStep} className="back-button">
              {Texts[window.LANGUAGE_SW].back}
            </span>
          </div>
        )}
        <Tracking bookingSite={bookingSite} />
      </BookingSiteContainer>
    );
  }
}

export default BookingSite;
