import React from 'react';
import { InputGroupAddon, UncontrolledTooltip } from 'reactstrap';
import { InputGroupTextStyled } from './InputStyled';
import styled from 'styled-components';
import theme from '../../assets/css/theme';

interface IProps {
  isShow: boolean;
  formTheme?: string; // eslint-disable-line
  tooltipID?: string;
  tooltipPlacement?: string;
  tooltipText?: string;
  radius?: string;
}

export const InputTooltip: React.FC<IProps> = ({
  isShow,
  formTheme,
  tooltipID,
  tooltipPlacement,
  tooltipText,
  radius
}) =>
  isShow && tooltipID ? (
    <>
      <InputGroupAddon addonType="append" id={tooltipID}>
        <InputGroupTextStyled formTheme={formTheme} radius={radius}>
          <i className="fa fa-icon fa-question-circle" />
        </InputGroupTextStyled>
      </InputGroupAddon>
      <UncontrolledTooltip autohide={false} placement={tooltipPlacement as any} target={tooltipID}>
        <span dangerouslySetInnerHTML={{ __html: tooltipText || '' }} />
      </UncontrolledTooltip>
    </>
  ) : null;

const FormTooltipWrapper = styled.span`
  i {
    color: ${theme.commonColors.second};
  }
`;

export const FormTooltip: React.FC<Omit<IProps, 'radius'>> = ({
  isShow,
  tooltipID,
  tooltipPlacement,
  tooltipText
}) =>
  isShow && tooltipID ? (
    <FormTooltipWrapper className="form-tooltip">
      <i className="fa fa-icon fa-question-circle" id={tooltipID} />
      <UncontrolledTooltip autohide={false} placement={tooltipPlacement as any} target={tooltipID}>
        <span dangerouslySetInnerHTML={{ __html: tooltipText || '' }} />
      </UncontrolledTooltip>
    </FormTooltipWrapper>
  ) : null;
