import axios from '../helpers/axios';

export default function (
  state = {
    authenticated: false,
    mailsuite: {},
    defaultUserBrand: {},
    upgradeAccountIsOpen: false,
    upgradeSlideshowAccountIsOpen: false,
    maintenance_mode: ''
  },
  action
) {
  switch (action.type) {
    case 'SET_MAINTENANCE_MODE':
      return { ...state, maintenance_mode: action.payload || '' };
    case 'SET_UPGRADE_ACCOUNT_OPEN_STATE':
      if (action.payload.body) {
        axios.post('/api/useractivitylog/logaction', {
          action: 'SHOW_UPGRADE_MODAL',
          logAsHubpostNote: true,
          leadScore: 5
        });

        if (action?.payload?.callback) {
          action.payload.callback();
        }
      }

      return { ...state, upgradeAccountIsOpen: action.payload.body };
    case 'SET_UPGRADE_SLIDESHOW_ACCOUNT_OPEN_STATE':
      return { ...state, upgradeSlideshowAccountIsOpen: action.payload };
    case 'AUTH_USER':
      return { ...state, authenticated: true };
    case 'UNAUTH_USER':
      return { ...state, authenticated: false };
    case 'SET_USER_VALUE':
      return { ...state, [action.payload.key]: [action.payload.value] };
    case 'SET_USER':
      if (typeof action.payload.languageSoftware === 'number') {
        if (action.payload.languageSoftware && action.payload.languageSoftware >= 0) {
          window.LANGUAGE_SW = action.payload.languageSoftware;
        } else {
          window.LANGUAGE_SW = 0;
        }
      }

      if (action.payload.type) {
        window.USER_TYPE = action.payload.type;
        window.setUpMetadataByUserType(action.payload.type);
      }

      return {
        ...state,
        ...action.payload,
        taxSettings: {
          ...state.taxSettings,
          ...action.payload.taxSettings,
          errorMessage: state.taxSettings?.errorMessage
        }
      };
    case 'SET_AUTHKEY':
      return { ...state, authKey: action.payload };
    case 'SET_RECEIPT_URL':
      return { ...state, receipt_url: action.payload };
    case 'USER_ADD_KT_TAG':
      return {
        ...state,
        klickTippTags: [...state.klickTippTags, action.payload]
      };
    case 'USER_SET_KLICKTIPP':
      return { ...state, klickTipp: action.payload };
    case 'USER_ADD_STRATEGY_STEP':
      return {
        ...state,
        strategySteps: [...state.strategySteps, action.payload]
      };
    case 'SET_USER_DIGISTORE':
      return { ...state, digistore: action.payload };
    case 'SET_USER_DIGISTORE_DISCOUNT_KEY':
      return {
        ...state,
        digistore: { ...state.digistore, discountKey: action.payload }
      };
    case 'USER_SET_STRATEGY_STEPS':
      return {
        ...state,
        strategySteps: action.payload
      };
    case 'set_registration_process':
      return {
        ...state,
        registrationProcess: {
          ...state.registrationProcess,
          [action.payload.key]: action.payload.value
        }
      };
    case 'SET_USER_TAX_SETTINGS':
      return {
        ...state,
        taxSettings: {
          ...state.taxSettings,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
