import axios from '../helpers/axios';
import * as errors from '../errors';
import Texts from '../json/texts.json';

export const getPayments = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/bookingPaymentTransactions');
    dispatch({ type: 'SET_ALL_PAYMENTS', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const createPaymentGateway = (data, notify) => async () => {
  try {
    await axios.post('/api/me/paymentgateway', data);
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].refreshedData,
      status: 'success',
      position: 'bl',
      dismissible: true,
      dismissAfter: 3000
    });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const updatePaymentGateway = (data, notify) => async () => {
  try {
    await axios.put('/api/me/paymentgateway', data);
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].refreshedData,
      status: 'success',
      position: 'bl',
      dismissible: true,
      dismissAfter: 3000
    });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const getPaymentGateway = (type) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/me/paymentgateway?type=${type}`);
    dispatch({
      type: 'SET_PAYMENT_GATEWAY',
      payload: { data: res.data[type], type }
    });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const buyOrUpgradeSubscription = (type, product, plan, intervall, notify) => async () => {
  try {
    if (
      product === 'scrappbook' ||
      type !== 'downgrade' ||
      (type === 'downgrade' &&
        window.confirm(Texts[window.LANGUAGE_SW].pricingTables.downgradeHint))
    ) {
      const res = await axios.get(`/api/subscription/${type}/${product}/${plan}/${intervall}`);

      if (res.data.result === 'success') {
        window.location.href = res.data.url;
      } else {
        notify &&
          notify({
            title: Texts[window.LANGUAGE_SW].error,
            message: Texts[window.LANGUAGE_SW].pricingTables.error,
            status: 'error',
            position: 'tc',
            dismissible: true,
            dismissAfter: 7500
          });
      }
    }
  } catch (e) {
    notify &&
      notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].pricingTables.error,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 7500
      });
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};
