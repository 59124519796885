import { MutableRefObject, useEffect } from 'react';

export default function useOutsideClick(ref: MutableRefObject<HTMLElement | null>, callback: Function) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref && ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]); // eslint-disable-line
}
