import { getTranslationKey } from '../../../modules/utils/helpers';

export const radioMap = {
  firstRadio: 'randomly-here',
  secondRadio: 'not-using-product-anymore',
  thirdRadio: 'price',
  fourthRadio: 'switching-to-another-product',
  fifthRadio: 'missing-specific-function',
  sixthRadio: 'customers-have-issues',
  seventhRadio: 'short-term'
};

export const getListData = () => [
  {
    name: radioMap.firstRadio,
    label: getTranslationKey('cancelSubscriptionModal.firstRadio')
  },
  {
    name: radioMap.secondRadio,
    label: getTranslationKey('cancelSubscriptionModal.secondRadio')
  },
  {
    name: radioMap.thirdRadio,
    label: getTranslationKey('cancelSubscriptionModal.thirdRadio')
  },
  {
    name: radioMap.fourthRadio,
    label: getTranslationKey('cancelSubscriptionModal.fourthRadio')
  },
  {
    name: radioMap.fifthRadio,
    label: getTranslationKey('cancelSubscriptionModal.fifthRadio')
  },
  {
    name: radioMap.sixthRadio,
    label: getTranslationKey('cancelSubscriptionModal.sixthRadio')
  },
  {
    name: radioMap.seventhRadio,
    label: getTranslationKey('cancelSubscriptionModal.seventhRadio')
  }
];
