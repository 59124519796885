import axios from '../helpers/axios';
import * as errors from '../errors';
import Texts from '../json/texts.json';

export const getAllBenefits = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/benefits');
    if (res.status === 200) dispatch({ type: 'SET_ALL_BENEFITS', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const getAllGoals = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/goals');
    if (res.status === 200) dispatch({ type: 'SET_ALL_GOALS', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const getAllStrategies = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/strategies');
    if (res.status === 200) dispatch({ type: 'SET_ALL_STRATEGIES', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const getStrategy = (data) => async (dispatch) => {
  try {
    const res = await axios.get('/api/strategy', { params: data });
    if (res.status === 200) dispatch({ type: 'SET_CURRENT_STRATEGY', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const startStrategy = (strategy, notify) => async (dispatch) => {
  try {
    const res = await axios.post('/api/strategy/start/', { id: strategy._id });
    if (res.status === 200) {
      dispatch({ type: 'USER_ADD_STRATEGY_STEP', payload: strategy.name });
      dispatch({ type: 'SET_CURRENT_STRATEGY', payload: { id: strategy._id } });

      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].strategies.pleaseCheckMails,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 5000
      });
    }
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const doStrategyStep = (name) => async (dispatch) => {
  try {
    const res = await axios.post('/api/strategy/doStep/' + name);
    if (res.status === 200) {
      dispatch({ type: 'USER_SET_STRATEGY_STEPS', payload: res.data });
    }
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const showStrategy = (id) => (dispatch) => {
  try {
    if (id) {
      dispatch({ type: 'SET_CURRENT_STRATEGY', payload: { id } });
      dispatch({ type: 'SET_STRATEGY_MODAL_VISIBILITY', payload: true });
    }
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const setStrategyModalVisibility = (payload) => (dispatch) => {
  try {
    dispatch({ type: 'SET_STRATEGY_MODAL_VISIBILITY', payload });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};
