import { SagaIterator } from 'redux-saga';
import { put, call, select } from 'redux-saga/effects';
import { filter, mapValues, isString } from 'lodash';
import axiosWithoutCredentials from 'axios';
import ImageCompressor from '@xkeshi/image-compressor';
import { ISagaAction } from '../../../modules/types';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import {
  changeModalState,
  errorsGlobalError,
  fetchPresetsSuccessAction,
  showNotifySuccess,
  showNotifyFail,
  accessRulesAction
} from '../../../modules/actions';
import Api from '../../../modules/utils/API';
import { getPresetsListSelector } from '../../../modules/selectors';
import { IPreset } from '../../../modules/reducers/presets';
import { multipleAttempts } from '../../../modules/sagas/global';

export const createPresetSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const presets = yield select(getPresetsListSelector);
    const response = yield call(Api.Presets.create, { name: payload });
    ApiErrors.checkOnApiError(response);
    presets.push(response);
    yield put(fetchPresetsSuccessAction([...presets]));
    yield put(showNotifySuccess({}));
    yield put(
      changeModalState({
        key: 'presetsSettingModal',
        state: true,
        modalData: { presetId: response._id }
      })
    );
    yield put(accessRulesAction());
  } catch (e) {
    yield put(
      changeModalState({ key: 'upgradeModal', state: true, desiredAction: 'collectionPresets' })
    );
  }
};

export const fetchPresetsSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Presets.get);
    ApiErrors.checkOnApiError(response);
    yield put(fetchPresetsSuccessAction(response));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const setPresetAsDefaultSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const presets = yield select(getPresetsListSelector);
    const response = yield call(Api.Presets.setDefault, {
      gallerypreset_id: payload
    });
    ApiErrors.checkOnApiError(response);
    const changedDefaultPresets = presets.map((preset: IPreset) =>
      preset._id === response._id
        ? response
        : {
            ...preset,
            default: false
          }
    );
    yield put(fetchPresetsSuccessAction(changedDefaultPresets));
  } catch (e) {
    yield put(
      changeModalState({ key: 'upgradeModal', state: true, desiredAction: 'collectionPresets' })
    );
  }
};

export const deletePresetSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const presets = yield select(getPresetsListSelector);
    const response = yield call(Api.Presets.delete, {
      id: payload
    });
    ApiErrors.checkOnApiError(response);
    const filtered = filter(presets, (preset) => preset._id !== payload);
    yield put(fetchPresetsSuccessAction(filtered));
    yield put(accessRulesAction());
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updatePresetSaga = function* ({ payload }: ISagaAction<IPreset>): SagaIterator {
  try {
    const data: any = mapValues(payload, (presetValue) =>
      isString(presetValue) && !presetValue.length ? null : presetValue
    );
    const presets = yield select(getPresetsListSelector);
    const response = yield call(Api.Presets.update, data);
    ApiErrors.checkOnApiError(response);
    const updated = presets.map((preset: IPreset) =>
      preset._id === response._id ? response : preset
    );
    yield put(fetchPresetsSuccessAction(updated));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
    yield put(showNotifyFail());
  }
};

export const setPresetsHeaderImageSaga = function* ({ payload }: ISagaAction<any>): SagaIterator {
  const { file, existingImage, galleryPresetId } = payload;

  try {
    const currentId: null | string = existingImage ? existingImage._id : undefined;

    const signedUrlResponse = yield call(multipleAttempts(Api.Images.preUploadHeaderImage), {
      originalImageName: file.name,
      imageId: currentId,
      galleryPresetId
    });

    ApiErrors.checkOnApiError(signedUrlResponse);
    const { signedUrl } = signedUrlResponse.result;

    const imageCompressor = new ImageCompressor(file);
    // @ts-ignore
    const imageFile = yield imageCompressor.compress(file, {
      quality: 0.9,
      maxWidth: 2000
    });

    yield call(multipleAttempts(() => axiosWithoutCredentials.put(signedUrl, imageFile)));

    yield call(fetchPresetsSaga);

    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};
