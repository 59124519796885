import React from 'react';
import { connect } from 'react-redux';
import Texts from '../../json/texts.json';
import { UncontrolledAlert } from 'reactstrap';

const MaintenanceAlert = ({ maintenance_mode }) => {
  if (!maintenance_mode) return null;

  let disabledFunctionsText = '';

  maintenance_mode.split(',').forEach((mode) => {
    if (!disabledFunctionsText) {
      disabledFunctionsText = Texts[window.LANGUAGE_SW].maintenance.modes[mode];
    } else disabledFunctionsText += ', ' + Texts[window.LANGUAGE_SW].maintenance.modes[mode];
  });

  return (
    <UncontrolledAlert
      id="discount-key-alert"
      className="discount-alert-margins m-0"
      color="danger"
    >
      {Texts[window.LANGUAGE_SW].maintenance.prefix}
      {disabledFunctionsText}
    </UncontrolledAlert>
  );
};

function mapStateToProps({ auth: { maintenance_mode } }) {
  return { maintenance_mode };
}

export default connect(mapStateToProps, null)(MaintenanceAlert);
