import { createAction } from 'redux-act';
import { IPreset } from '../../modules/reducers/presets';

export const fetchPresetsAction = createAction();
export const fetchPresetsSuccessAction = createAction<IPreset[]>();

export const createPresetAction = createAction<string>();
export const setSinglePresetToStore = createAction<any>();

export const setPresetAsDefault = createAction<string>();
export const deletePreset = createAction<string>();

export const updatePresetAction = createAction<IPreset>();
export const setPresetsHeaderImageSagaAction = createAction<any>();
