import { createReducer } from 'redux-act';
import { IGalleryTag } from '../../../modules/types';
import { fetchGalleriesTagsSuccess, changeGalleryTagState } from '../../../modules/actions';

export interface IGalleryTagState {
  all: IGalleryTag[];
  isFetched: boolean;
  collectionsOverviewSelectedTags: any;
}

const initialState: IGalleryTagState = {
  all: [],
  isFetched: false,
  collectionsOverviewSelectedTags: []
};

export const galleriesTags = createReducer<IGalleryTagState>({}, initialState);

galleriesTags.on(fetchGalleriesTagsSuccess, (state: IGalleryTagState, payload: IGalleryTag[]) => ({
  ...state,
  all: payload,
  isFetched: true
}));

galleriesTags.on(
  changeGalleryTagState,
  (state: IGalleryTagState, payload: Partial<IGalleryTagState>) => ({
    ...state,
    ...payload
  })
);
