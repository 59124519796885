import { IStoreState } from '../types';
import { createSelector } from 'reselect';

export const getDigitalPricingLists = ({ shop }: IStoreState) => shop.digitalPricing.lists;
export const getCurrentlyOpenedDigitalList = ({ shop }: IStoreState) =>
  shop.digitalPricing.currentlyOpenedDigitalList;
export const getCurrentlyOpenedDigitalListWithoutAllImages = createSelector(
  getCurrentlyOpenedDigitalList,
  (list) => {
    const filteredPackages = list.packages.filter(({ packageId }) => !packageId.allImagesIncluded);
    list.packages = filteredPackages;

    return list;
  }
);
export const getIsCreatePacakgeModalOpen = ({ shop }: IStoreState) =>
  shop.digitalPricing.isCreatePackageModalOpen;
