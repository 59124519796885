let languageSW = 0;
if (process.env.REACT_APP_LANGUAGE_SW) {
  languageSW = parseInt(process.env.REACT_APP_LANGUAGE_SW);
}

let language = 0;
if (process.env.REACT_APP_LANGUAGE) {
  language = parseInt(process.env.REACT_APP_LANGUAGE);
}

window.LANGUAGE_SW = languageSW;
window.LANGUAGE = language;
window.CDN_URL = '';
window.API_URL_DL = '';
window.API_URL = '';
window.IS_TEST = '';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

const API_URL = process.env.REACT_APP_API_URL;
const API_URL_DL = process.env.REACT_APP_API_URL_DL;
const API_PICTUREPEOPLE_URL = process.env.REACT_APP_API_PICTUREPEOPLE_URL;
const API_PICTUREPEOPLE_URL_DL = process.env.REACT_APP_API_PICTUREPEOPLE_URL;
const API_GALLERYPIX_URL = process.env.REACT_APP_API_GALLERYPIX_URL;
const API_GALLERYPIX_URL_DL = process.env.REACT_APP_API_GALLERYPIX_URL;

const CDN_URL = process.env.REACT_APP_CDN_URL;
const GALLERYPIX_CDN_URL = process.env.REACT_APP_GALLERYPIX_CDN_URL;
const PICTUREPEOPLE_CDN_URL = process.env.REACT_APP_PICTUREPEOPLE_CDN_URL;

const devEnvironments = ['development', 'staging'];
window.IS_TEST = devEnvironments.includes(ENVIRONMENT);

const { hostname } = window.location;

window.setUpMetadataByUserType = (userType) => {
  let iconUrl = '';

  if (userType === 'VST' || hostname.indexOf('gallerypix') !== -1) {
    document.getElementById('index_title').text = 'VST - GalleryPix';
    document.getElementById('index_description').content =
      'Fasziniere deine Kunden mit einer personalisierten mobilen Web App und hinterlasse damit einen bleibenden Eindruck…';
    window.USER_TYPE = 'VST';
    window.LANGUAGE_SW = 0;

    window.CDN_URL = GALLERYPIX_CDN_URL;
    window.API_URL = API_GALLERYPIX_URL;
    window.API_URL_DL = API_GALLERYPIX_URL_DL;

    iconUrl = `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/builder/logos/VST-Logo.png`;
  } else if (userType === 'PP' || hostname.indexOf('picturepeople') !== -1) {
    document.getElementById('index_title').text = 'PicturePeople';
    document.getElementById('index_description').content =
      'Fasziniere deine Kunden mit einer personalisierten mobilen Web App und hinterlasse damit einen bleibenden Eindruck…';
    window.USER_TYPE = 'PP';
    window.LANGUAGE_SW = 0;

    window.CDN_URL = PICTUREPEOPLE_CDN_URL;
    window.API_URL = window.IS_TEST
      ? API_PICTUREPEOPLE_URL
      : `https://api${hostname.substring(hostname.indexOf('.'))}`;
    window.API_URL_DL = API_PICTUREPEOPLE_URL_DL;

    iconUrl = `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/builder/logos/favicon.ico`;
  } else {
    document.getElementById('index_title').text =
      process.env.REACT_APP_SITE_TITLE || 'scrappbook Builder';
    document.getElementById('index_description').content =
      process.env.REACT_APP_SITE_DESCRIPTION ||
      'Fasziniere deine Kunden mit einer personalisierten mobilen Web App und hinterlasse damit einen bleibenden Eindruck…';

    window.USER_TYPE = 'SB';
    window.CDN_URL = CDN_URL;
    window.API_URL = API_URL;
    window.API_URL_DL = API_URL_DL || API_URL;
    iconUrl = process.env.REACT_APP_SB_ICON_URL;
  }

  let link = document.querySelector("meta[property='og:image']");
  if (!link) {
    link = document.createElement('meta');
    link.setAttribute('property', 'og:image');
    link.content = iconUrl;
    document.getElementsByTagName('head')[0].appendChild(link);
  } else {
    link.content = iconUrl;
  }

  link = document.getElementById('link-shortcut-icon');
  if (!link) {
    link = document.createElement('link');
    link.id = 'link-shortcut-icon';
    link.rel = 'shortcut icon';
    link.href = iconUrl;
    document.head.appendChild(link);
  } else {
    link.href = iconUrl;
  }

  link = document.getElementById('link-icon');
  if (!link) {
    link = document.createElement('link');
    link.id = 'link-icon';
    link.rel = 'icon';
    link.href = iconUrl;
    document.head.appendChild(link);
  } else {
    link.href = iconUrl;
  }

  link = document.getElementById('link-apple-touch-icon');
  if (!link) {
    link = document.createElement('link');
    link.id = 'link-apple-touch-icon';
    link.rel = 'apple-touch-icon';
    link.href = iconUrl;
    document.head.appendChild(link);
  } else {
    link.href = iconUrl;
  }

  link = undefined;
};

window.setUpMetadataByUserType();
