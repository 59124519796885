import React from 'react';
import styled from 'styled-components';
import { Col } from 'reactstrap';
import theme from '../../assets/css/theme';
import media from '../../assets/css/media';

const Wrapper = styled(Col)`
  background: ${theme.commonColors.mainBackground};
  padding-top: 15px;
  padding-bottom: 15px;
  height: 100%;
  @media (max-width: ${media.desktop}) {
    border-right: 0;
    margin-bottom: 20px;
  }
`;

export const CardTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const CardBody = styled.div``;
export const CardFooter = styled.div``;

const Card = (props: any) => <Wrapper {...props}>{props.children}</Wrapper>;

export default Card;
