import React, { Component } from 'react';
import Texts from '../../../json/texts.json';

const TRACKING_STATUS = {
  in: 'opt-in',
  out: 'opt-out'
};

class Tracking extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };

    this.opt = this.opt.bind(this);
    this.loggedGA = false;
    this.loggedFB = false;
    this.loggedGTM = false;
  }

  componentDidMount() {
    this.tracking();

    const trackingStatus = this.getTrackingStatus();
    const visible = !trackingStatus || trackingStatus === TRACKING_STATUS.out;

    this.setState({ visible });
  }

  componentDidUpdate() {
    this.tracking();
  }

  getTrackingStatus() {
    return window.localStorage.getItem(`${this.props.bookingSite._id}-tracking`);
  }

  setTrackingStatus(status) {
    window.localStorage.setItem(`${this.props.bookingSite._id}-tracking`, status);
  }

  tracking() {
    if (window.navigator.onLine) {
      const trackingStatus = this.getTrackingStatus();
      const { ga_id, fb_pixel_id, gtm_id } = this.props.bookingSite;

      if (trackingStatus === TRACKING_STATUS.in) {
        if (ga_id && !this.loggedGA) {
          this.loggedGA = true;
        }
        if (fb_pixel_id && !this.loggedFB) {
          this.loggedFB = true;
        }
        if (gtm_id && !this.loggedGTM) {
          this.loggedGTM = true;
        }
      }
    }
  }

  opt(status) {
    this.setTrackingStatus(status);
    this.setState({ visible: false });
  }

  render() {
    const { ga_id, fb_pixel_id, _user, gtm_id } = this.props.bookingSite;
    const { visible } = this.state;
    if (visible && (ga_id || fb_pixel_id || gtm_id)) {
      return (
        <div className="row">
          <div className="tracking-hint col-sm-12 col-xs-12 col-md-4 col-lg-4 col-xl-3">
            <div className="row">
              <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                {Texts[window.LANGUAGE_SW].trackingHint}{' '}
                <a
                  href={`/api/privacy/view?user_id=${_user._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {Texts[window.LANGUAGE_SW].more}
                </a>
              </div>
              <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12 text-center">
                <button onClick={() => this.opt(TRACKING_STATUS.out)} className="btn-opt-out p-15">
                  {Texts[window.LANGUAGE_SW].optOut}
                </button>
              </div>
              <div className="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                <button
                  onClick={() => this.opt(TRACKING_STATUS.in)}
                  className="site-button w-100-p"
                >
                  {Texts[window.LANGUAGE_SW].optIn}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default Tracking;
