import axios from '../helpers/axios';
import * as errors from '../errors';

export const getLeads = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/leads');
    dispatch({ type: 'SET_ALL_LEADS', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const deleteLead = (id) => async (dispatch) => {
  try {
    await axios.delete('/api/lead/delete', { params: { id } });
    dispatch({ type: 'REMOVE_LEAD', payload: id });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};
