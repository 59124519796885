import { useDispatch } from 'react-redux';
import { trackEventAction } from '../../actions';

export default function useTracking() {
  const dispatch = useDispatch();

  return (eventName: string, payload?: { [key: string]: any }) => {
    dispatch(
      trackEventAction({
        name: eventName,
        payload: payload
      })
    );
  };
}
