import React from 'react';
import { UncontrolledAlert } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils/helpers/index';
import dateHelpers from '../../helpers/date';

const TrialEndsAlert = ({ trialEndsAt }) => {
  const dateOfEnding = new Date(trialEndsAt);
  const millisecondsLeft = dateOfEnding - new Date();
  const daysLeft = dateHelpers.convertMiliseconds(millisecondsLeft, 'd');
  const hoursLeft = dateHelpers.convertMiliseconds(millisecondsLeft, 'h');

  if (daysLeft <= 0 && hoursLeft <= 0) return null;

  const daysWording = daysLeft === 1 ? getTranslationKey('dayLeft') : getTranslationKey('daysLeft');
  const hoursWording =
    hoursLeft === 1 ? getTranslationKey('hourLeft') : getTranslationKey('hoursLeft');

  const trialEndsData = {
    X: daysLeft === 0 ? hoursLeft : daysLeft,
    timeLeft: daysLeft === 0 ? hoursWording : daysWording,
    date: dateOfEnding.toLocaleDateString()
  };

  return (
    <UncontrolledAlert color="primary" className="mb-0">
      {getTranslationKey('trialEnds', trialEndsData)}
    </UncontrolledAlert>
  );
};

export default TrialEndsAlert;
