import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Label, Row } from 'reactstrap';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/Card';
import { getCurrentMessage, getMessages } from '../../modules/selectors/message';
import EditMessage, { Wrapper } from '../../elements/Message/EditMessage';
import MessagePreview from '../../elements/Message/MessagePreview';
import { getTranslationKey } from '../../modules/utils';
import styled from 'styled-components';
import { CreateMessageModal } from '../../elements/Message/CreateMessageModal';
import {
  deleteMessageAction,
  fetchMessagesAction,
  setCurrentMessageAction
} from '../../modules/actions/message';
import { SelectItem } from '../../components';
import { FaTrashAlt } from 'react-icons/fa';
import HelpLink from '../../elements/Help/HelpLink';

const CardTitleStyled = styled(CardTitle)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Message = () => {
  const dispatch = useDispatch();
  const messages = useSelector(getMessages);
  const currentMessage = useSelector(getCurrentMessage);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchMessagesAction());
  }, []);

  const messagesSelectOptions = messages.map((message) => ({
    value: message.id,
    text: message.title
  }));

  return (
    <div className="animated fadeIn">
      <CreateMessageModal isOpen={isOpen} closeCallback={() => setIsOpen(false)} />
      <Row>
        <Col sm="12" md="12" lg="6">
          <Card style={{ paddingLeft: '0', paddingRight: '0' }}>
            <CardHeader style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <CardTitleStyled>
                <span>{getTranslationKey('messages.editTitle')}</span>
                <div>
                  <Button
                    size="md"
                    color="success"
                    className="btn btn-primary"
                    onClick={() => setIsOpen(true)}
                  >
                    {getTranslationKey('messages.new')}
                  </Button>
                  <FaTrashAlt
                    className="font-lg cursor-pointer color-primary ml-3"
                    onClick={() => {
                      if (!currentMessage) return;
                      const isConfirmed = window.confirm(
                        getTranslationKey('messages.confirmDeletionMessage')
                      );

                      if (isConfirmed) {
                        dispatch(deleteMessageAction(currentMessage.id));
                      }
                    }}
                  />
                </div>
              </CardTitleStyled>
              {/* {type === 'SB' && <HelpLink href="https://support.scrappbook.de/de/articles/61597" />} */}
            </CardHeader>
            <CardBody>
              {messages.length > 1 && (
                <Wrapper>
                  <Label className="mb-3" label={getTranslationKey('messages.message')}>
                    <SelectItem
                      value={currentMessage?.id}
                      changeCallback={(value) => dispatch(setCurrentMessageAction(value))}
                      options={messagesSelectOptions}
                      wrapperStyle={{ maxWidth: '200px' }}
                    />
                  </Label>
                </Wrapper>
              )}
              <EditMessage message={currentMessage} />
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" md="12" lg="6">
          <Card>
            <CardHeader>
              <CardTitle>{getTranslationKey('messages.preview')}</CardTitle>
              <HelpLink
                href="https://support.scrappbook.de/de/collections/11560-werbeanzeige"
                className=""
                target="_blank"
                hideSmall={false}
              />
            </CardHeader>
            <CardBody className="pt-0 d-flex justify-content-center">
              <MessagePreview message={currentMessage} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Message;
