import { createSelector } from 'reselect';
import { IStoreState } from '../types';

export const getMessages = (state: IStoreState) => state.messages.messages;
export const getMessage = createSelector(
  getMessages,
  (messages) => (id: string) => messages.find((message) => message.id === id)
);

export const getCurrentMessageId = (state: IStoreState) => state.messages.currentMessage;
export const getCurrentMessage = createSelector(
  [getMessages, getCurrentMessageId],
  (messages, id) => messages.find((message) => message.id === id)
);

export const getIsImageUploading = (state: IStoreState) => state.messages.isImageUploading;
