import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getCollectionId,
  getGalleriesList,
  getSelectionByCollectionId
} from '../../modules/selectors';
import { COLLECTION_IMAGES_FILTER_OPTIONS } from '../../modules/constants';
import { getTranslationKey } from '../../modules/utils/helpers';
import { IDictionary } from '../../modules/types';
import { find } from 'lodash';

export const useScroll = (): any => {
  const [bodyOffset, setBodyOffset] = useState<DOMRect | ClientRect>(
    document.body.getBoundingClientRect()
  );
  const [scrollY, setScrollY] = useState<number>(bodyOffset.top);
  const [scrollX, setScrollX] = useState<number>(bodyOffset.left);
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>();
  const [hasScroll, changeHasScroll] = useState(false);

  const lastScrollTopRef = useRef(0);
  const lastScrollTop = lastScrollTopRef.current;
  const listener = () => {
    setBodyOffset(document.body.getBoundingClientRect());
    setScrollY(-bodyOffset.top);
    setScrollX(bodyOffset.left);
    setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up');
    lastScrollTopRef.current = -bodyOffset.top;
  };

  useEffect(() => {
    changeHasScroll(window.innerHeight > document.getElementsByClassName('main')[0].clientHeight);
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []); // eslint-disable-line

  return {
    hasScroll,
    scrollY,
    scrollX,
    scrollDirection
  };
};

export const useGetImagesFilterOptions = (): any => {
  const collectionID = useSelector(getCollectionId);
  const selections = useSelector(getSelectionByCollectionId)(collectionID);
  const translatedOptions = COLLECTION_IMAGES_FILTER_OPTIONS.map((option) => ({
    ...option,
    label: getTranslationKey(`gallerySelectionDropDown.${option.value}`)
  }));
  const [options, setOptions] = useState(translatedOptions);
  const [initialOptions, setInitialOptions]: any = useState(translatedOptions);
  const defaultSelectionName = getTranslationKey('selections.unnamedSelection');
  const galleries = useSelector(getGalleriesList);
  const [filter, setFilterForOptions] = useState<any>();

  useEffect(() => {
    let tmpOptions = [...translatedOptions];

    if (selections && selections.length) {
      const notEmptySelection = selections.filter((selection) => selection.favorites.length);
      notEmptySelection.forEach((selection) => {
        if (selection.favorites.length) {
          tmpOptions.push({
            label: getTranslationKey(`gallerySelectionDropDown.selection`).replace(
              '{{name}}',
              selection.name || defaultSelectionName
            ),
            value: `selection_${selection._id}`
          });
        }
      });
      if (!notEmptySelection.length) {
        tmpOptions = [tmpOptions[0]];
      }
    }

    if (galleries && galleries.length) {
      // @ts-ignore
      galleries.forEach((gallery) => {
        tmpOptions.push({
          label: getTranslationKey('gallerySelectionDropDown.gallery').replace(
            '{{name}}',
            gallery.title
          ),
          value: `gallery_${gallery._id}`
        });
      });
    }
    setInitialOptions(tmpOptions);
    setOptions(tmpOptions);
    filterOptions(filter);
  }, [selections, galleries]); // eslint-disable-line

  /* tslint:disable:rule1  cyclomatic-complexity  */
  const filterOptions = (filters: IDictionary<string>[]) => {
    const isAll = find(filters, { value: 'all' });
    const isLiked = find(filters, { value: 'liked' });
    const notLiked = find(filters, { value: 'notLiked' });
    const isSelectionSelected = find(filters, (filterOption) =>
      filterOption.value.includes('selection')
    );
    const isGallerySelected = find(filters, (filterOption) =>
      filterOption.value.includes('gallery')
    );
    if (isAll) {
      options[0] && setOptions([options[0]]);
    } else if (isLiked) {
      options[1] && setOptions([options[1]]);
    } else if (notLiked) {
      options[2] && setOptions([options[2]]);
    } else if (isSelectionSelected || isGallerySelected) {
      setOptions(initialOptions.slice(3));
    } else if (filters && !filters.length) {
      setOptions(initialOptions);
    }
    setFilterForOptions(filters);
  };

  const clearFilter = () => {
    setOptions(initialOptions);
  };

  return {
    options,
    filterOptions,
    clearFilter
  };
};

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

interface IUseChangeTitle {
  title?: string;
}

export const useChangeTitle = ({ title = '' }: IUseChangeTitle) => {
  const [editState, changeEditState] = useState(false);
  const [titleState, changeTitleState] = useState(title);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (editState && inputRef.current) {
      changeTitleState(title);
      inputRef.current.focus();
    }
  }, [inputRef, editState]); // eslint-disable-line

  return {
    editState,
    titleState,
    changeEditState,
    changeTitleState,
    inputRef
  };
};
