import styled, { css } from 'styled-components';
import theme from '../../assets/css/theme';

export const ButtonStyled = css``;

export const ButtonPrimary = styled.button`
	${ ButtonStyled };
`;

export const ButtonDanger = styled( ButtonPrimary )`
	background: ${ theme.buttonsColors.danger };
`;

export const ButtonCustom = styled( ButtonPrimary )<{ bgColor: string, color: string }>`
	background-color: ${ props => props.bgColor };
	color: ${ props => props.color };
`;
