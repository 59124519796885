import React from 'react';

export default ({ label, children, className }) => (
  <div className={className}>
    <div style={{ marginBottom: '.4rem' }}>
      <b>{label}</b>
    </div>
    {children}
  </div>
);
