import React from 'react';
import styled from 'styled-components';
import { LabelItem } from './LabelItem';
import { UncontrolledTooltip } from 'reactstrap';
import { AppSwitch } from '@coreui/react';
import theme from '../../assets/css/theme';
import { FormTooltip } from './InputTooltip';

interface ISwitcher {
  disabled: boolean;
  margin?: string;
}

const Switcher = styled(AppSwitch)<ISwitcher>`
  margin: ${(props) => (props.margin || '0 10px 0 0') + '!important'};

  &.switch-sm {
    height: 18px;
    width: 27px;
  }

  .switch-slider {
    background-color: #7b7b7b;
    display: flex;
    align-items: center;

    &:before {
      width: 10px;
      height: 10px;
      top: initial;
    }
  }

  .switch-input {
    &:checked + .switch-slider {
      background-color: ${theme.commonColors.second};
      border-color: ${theme.commonColors.second};

      &:before {
        top: initial;
        width: 10px;
        height: 10px;
        border-color: #fff;
      }
    }
  }
`;

interface IWrapper {
  direction: string;
  margin?: string;
}

const Wrapper = styled.div<IWrapper>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: center;
  margin: ${(props) => props.margin || '0 0 20px 0'};
  justify-content: flex-end;
`;

interface IProps {
  value: boolean | number;
  withTooltip?: boolean;
  label?: any;
  id?: string;
  direction?: string;
  tooltipPlacement?: string;
  isIconTooltip?: boolean;
  tooltipText?: string;
  changeCallback: (value: boolean) => void;
  onBlurCallback?: () => void;
  margin?: string;
  isDisabled?: boolean;
  labelMargin?: string;
  switcherMargin?: string;
}

// tslint:disable-next-line:cyclomatic-complexity
export const SwitchItem = (props: IProps) => {
  const {
    value,
    withTooltip,
    label,
    id,
    direction = 'row',
    tooltipPlacement = 'bottom',
    tooltipText,
    changeCallback,
    margin,
    isDisabled = false,
    isIconTooltip,
    labelMargin,
    switcherMargin
  } = props;
  const tooltipID = `${id}_tooltip`;
  const tooltip =
    !isIconTooltip && withTooltip && id ? (
      <UncontrolledTooltip autohide={false} placement={tooltipPlacement as any} target={tooltipID}>
        {tooltipText}
      </UncontrolledTooltip>
    ) : null;
  const tooltipProps = {
    isShow: Boolean(withTooltip),
    tooltipID,
    formTheme: 'main',
    tooltipPlacement,
    tooltipText
  };
  const labelCursorType = !isIconTooltip && withTooltip ? 'help' : undefined;
  const labelID = !isIconTooltip ? tooltipID : '';
  const showLabel = label ? (
    <LabelItem
      htmlFor={id}
      id={labelID}
      isSmall
      isBold={false}
      margin={direction === 'row' ? `0 ${labelMargin} 0 0` : '0'}
      cursor={labelCursorType}
    >
      {label}
      {isIconTooltip ? <FormTooltip {...tooltipProps} /> : null}
    </LabelItem>
  ) : null;
  const changeHandler = (e: React.BaseSyntheticEvent) => changeCallback(e.currentTarget.checked);

  return (
    <Wrapper direction={direction} margin={margin}>
      {tooltip}
      {showLabel}
      <Switcher
        // @ts-ignore reason: Unknown Heroku issue
        disabled={isDisabled}
        variant="pill"
        size="sm"
        onChange={changeHandler}
        checked={Boolean(value)}
        margin={switcherMargin}
        data-cy="switcher"
      />
    </Wrapper>
  );
};

export default SwitchItem;
