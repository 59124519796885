import { config } from './config';
import { colors, backgroundColors } from './colors';
import site from './site';
import { reducer as notificationsReducer } from 'reapop';
import auth from './auth';
import appointment from './appointment';
import payments from './payments';
import bookingSites from './bookingSites';
import leads from './leads';
import domains from './domains';
import imprints from './imprints';
import privacys from './privacys';
import siteTemplates from './siteTemplates';
import mailsuite from './mailsuite';
import { reducer as formReducer } from 'redux-form';
import strategies from './strategies';
import statistics from './statistics';
import admin from './admin';
import gateways from './gateways';
import images from './images';
import collections from './collections';
import watermarks from './watermarks';
import userBrands from './userBrands';
import environment from './environment';
import message from './message';
import selections from './selections';

export default {
  selections,
  message,
  environment,
  userBrands,
  collections,
  images,
  admin,
  auth,
  config,
  colors,
  leads,
  backgroundColors,
  site,
  appointment,
  payments,
  bookingSites,
  domains,
  imprints,
  privacys,
  siteTemplates,
  mailsuite,
  strategies,
  statistics,
  gateways,
  watermarks,
  notifications: notificationsReducer(),
  form: formReducer
};

// const rootReducer = combineReducers();

// export default (state, action) =>
//   action.type === "UNAUTH_USER"
//     ? rootReducer(undefined, action)
//     : rootReducer(state, action);

// export default rootReducer;
