import { useSelector } from 'react-redux';
import { getPaymentInfo } from '../modules/selectors/paymentInfo';
import { getUser } from '../modules/selectors';

export function useIsPaymentProblem() {
  const { subscriptions, oneTimePurchases } = useSelector(getPaymentInfo);
  let {
    subscription: { state = '' }
  } = useSelector(getUser);

  if (
    subscriptions.some((sub) => sub.isPaymentProblem) ||
    oneTimePurchases.some((sub) => sub.isPaymentProblem)
  ) {
    state = 'paymentproblem';
  }

  return {
    isPaymentProblem: state === 'paymentproblem' || state === 'chargeback-fee-unpaid',
    state
  };
}
