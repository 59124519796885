import React, { Component } from 'react';
import Calendar from 'react-calendar';
import { FormGroup, Input, Label } from 'reactstrap';
import Texts from '../../../json/texts.json';
import moment from 'moment';
import { slice } from 'lodash';
import axios from '../../../helpers/axios';

class ClientCalender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.selectedAppointment && props.selectedAppointment.start,
      appointmentId: props.selectedAppointment && props.selectedAppointment.id,
      blockedAppointments: []
    };
    this.onChange = this.onChange.bind(this);
    this.onSelectAppointment = this.onSelectAppointment.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.loadAppointments();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.date !== prevState.date) {
      this.loadAppointments();
    }
  }

  onChange(date) {
    this.setState({ date, page: 1 });
  }

  onSelectAppointment(appointment) {
    this.setState({ appointmentId: appointment.id });
    if (this.props.onSelectAppointment) {
      this.props.onSelectAppointment(appointment);
    }
  }

  getBlockedAppointments(_moment) {
    return this.state.blockedAppointments.filter(
      (appointment) =>
        moment(appointment.start).diff(_moment, 'days') === 0 ||
        moment(appointment.end).diff(_moment, 'days') === 0
    );
  }

  getAvailableAppointments() {
    const _date = moment(this.state.date);
    const dayOfWeek = _date.day();
    let index = null;
    for (let j = 0; j < this.props.available.length; j++) {
      if (this.props.available[j].weekday === dayOfWeek && this.props.available[j].active) {
        index = j;
      }
    }
    if (index === null) return [];

    const availableAppointments = [];
    const length = this.props.appointmentLengthMinutes;
    let workingHoursStart = moment(this.state.date).set({
      hour: this.props.available[index].start,
      minute: 0
    });
    const workingHoursEnd = moment(this.state.date).set('hour', this.props.available[index].end);
    const todayBlockedAppointments = this.getBlockedAppointments(_date);
    let isFirstAppointment = true;

    if (_date.isSame(moment(), 'day')) {
      let hour = moment(this.state.date).hour();
      let minute = moment(this.state.date).minute();

      if (minute >= 45) {
        hour++;
        minute = 0;
      } else if (minute >= 30) {
        minute = 45;
      } else if (minute >= 15) minute = 30;
      else minute = 15;

      workingHoursStart = moment(this.state.date).set({
        hour,
        minute
      });

      if (this.props.bufferBeforeFirstAppointment) {
        workingHoursStart.add(this.props.bufferBeforeFirstAppointment, 'h');
      }
    }

    while (workingHoursStart < workingHoursEnd) {
      !isFirstAppointment && this.props.buffer && workingHoursStart.add(this.props.buffer, 'm');

      let startAvailableAppointment = moment(workingHoursStart);
      let endAvailableAppointment = moment(workingHoursStart);
      endAvailableAppointment.add(length, 'm');

      for (let i = 0; i < todayBlockedAppointments.length; i++) {
        const blockedAppointment = todayBlockedAppointments[i];
        const currBlockedAppointmentStart = moment(blockedAppointment.start);
        const currBlockedAppointmentEnd = moment(blockedAppointment.end);
        let isAvailable = true;

        if (
          currBlockedAppointmentStart.isBetween(
            startAvailableAppointment,
            endAvailableAppointment
          ) ||
          currBlockedAppointmentEnd.isBetween(startAvailableAppointment, endAvailableAppointment)
        ) {
          isAvailable = false;
        } else if (
          startAvailableAppointment.isBetween(
            currBlockedAppointmentStart,
            currBlockedAppointmentEnd
          ) ||
          endAvailableAppointment.isBetween(currBlockedAppointmentStart, currBlockedAppointmentEnd)
        ) {
          isAvailable = false;
        } else if (
          startAvailableAppointment.isSame(currBlockedAppointmentStart) &&
          endAvailableAppointment.isSame(currBlockedAppointmentEnd)
        ) {
          isAvailable = false;
        }

        if (!isAvailable) {
          workingHoursStart = moment(currBlockedAppointmentEnd);

          this.props.buffer && workingHoursStart.add(this.props.buffer, 'm');

          startAvailableAppointment = moment(workingHoursStart);
          endAvailableAppointment = moment(workingHoursStart);
          endAvailableAppointment.add(length, 'm');
        }
      }

      if (endAvailableAppointment <= workingHoursEnd) {
        availableAppointments.push({
          startAvailableAppointment,
          endAvailableAppointment
        });
      }

      isFirstAppointment = false;
      workingHoursStart.add(length, 'm');
    }

    return availableAppointments;
  }

  changePage(direction) {
    const page = direction === 'next' ? this.props.page + 1 : this.props.page - 1;
    this.props.onSelectedPageChange(page);
  }

  async loadAppointments() {
    try {
      this.setState({ loading: true });
      const date = this.state.date || Date.NOW;

      const month = moment(date).month() + 1;
      const year = moment(date).year();
      if (month !== this.state.month || year !== this.state.year) {
        this.setState({ month, year });

        const result = await axios.get('/api/bookingsite/appointments', {
          params: {
            bookingSiteId: this.props.bookingSiteId,
            month,
            year
          }
        });
        this.setState({ blockedAppointments: result.data });
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ loading: false });
    }
  }

  renderAvailableAppointments() {
    if (!this.state.date) return null;
    const { availableAppointmentsCount, page } = this.props;
    const availableAppointments = this.getAvailableAppointments();
    let currentAvailableAppointments = [];
    if (availableAppointmentsCount) {
      const start = (page - 1) * availableAppointmentsCount;
      currentAvailableAppointments = slice(
        availableAppointments,
        start,
        start + availableAppointmentsCount
      );
    } else {
      currentAvailableAppointments = availableAppointments;
    }

    return (
      <div className="row">
        <div className="col-12">
          <div className="text-center m-t-10">
            <h3>{Texts[window.LANGUAGE_SW].selectedAppointment}</h3>
          </div>
          <FormGroup tag="fieldset" className="m-b-0">
            {availableAppointments.length === 0 && <p>{Texts[window.LANGUAGE_SW].notAvailable}</p>}
            {currentAvailableAppointments.map((availableAppointment, index) => {
              const id = availableAppointment.startAvailableAppointment.toDate().getTime();

              return (
                <FormGroup key={index} check>
                  <Label className="w-100-p" check>
                    {index === 0 && <hr />}
                    <Input
                      type="radio"
                      checked={this.state.appointmentId === id}
                      onClick={() =>
                        this.onSelectAppointment({
                          id,
                          start: availableAppointment.startAvailableAppointment.toDate(),
                          end: availableAppointment.endAvailableAppointment.toDate()
                        })
                      }
                    />

                    <p>
                      {moment(this.state.date).format('dddd D. MMMM YYYY') +
                        ` - ${availableAppointment.startAvailableAppointment.format(
                          'HH:mm'
                        )} - ${availableAppointment.endAvailableAppointment.format('HH:mm')} Uhr`}
                    </p>
                    {index !== availableAppointments.length && <hr />}
                  </Label>
                </FormGroup>
              );
            })}
          </FormGroup>
        </div>

        <div className="col-6">
          {availableAppointmentsCount && page > 1 && (
            <p onClick={() => this.changePage('back')} className="cursor-pointer">
              {Texts[window.LANGUAGE_SW].back}
            </p>
          )}
        </div>
        <div className="col-6">
          {availableAppointmentsCount &&
            page * availableAppointmentsCount < availableAppointments.length && (
              <p onClick={() => this.changePage('next')} className="float-right cursor-pointer">
                {Texts[window.LANGUAGE_SW].booking.moreAppointments}
              </p>
            )}
        </div>

        <div className="col-12">
          <button
            className="btn btn-success w-100-p btn-booking"
            disabled={!this.state.appointmentId}
            onClick={this.props.onSelectionEnd}
            type="submit"
          >
            {Texts[window.LANGUAGE_SW].save}
          </button>
        </div>
      </div>
    );
  }

  render() {
    const { loading, date } = this.state;

    const maxDate = moment().add(this.props.availableDaysInAdvance, 'd').toDate();

    return (
      <div>
        <Calendar
          locale="de-DE"
          minDate={new Date()}
          maxDate={maxDate}
          onChange={this.onChange}
          value={date}
        />
        {loading ? (
          <div className="text-center">
            <img className="img-fluid" src="/assets/images/loading.gif" alt="Loading..." />
          </div>
        ) : (
          this.renderAvailableAppointments()
        )}
      </div>
    );
  }
}

export default ClientCalender;
