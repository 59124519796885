import { createAction } from 'redux-act';
import { ICollectionsReducerState } from '../reducers/collection';
import { ISearchCollection } from '../types';
import {
  ICollectionUpdateAction,
  IDomainUpdateAction,
  IMainCustomersUpdateAction,
  ISearchCollectionsAction,
  IUploadAppIconAction,
  IUploadCollectionHeaderAction
} from '../types/collection';
import { IEndCustomerProps } from '../types/endCustomer';

export const clearCollection = createAction();

export const initialCollectionFetch = createAction();
export const fetchCollection = createAction<string>();
export const fetchCollectionSuccess = createAction<ICollectionsReducerState>();
export const fetchCollectionFailed = createAction();

export const changeCollectionState = createAction<ICollectionUpdateAction>();
export const changeCollectionStateSuccess = createAction<Partial<ICollectionsReducerState>>();

export const updateCollectionByPath = createAction<ICollectionUpdateAction>();
export const updateCollectionDomain = createAction<IDomainUpdateAction>();

export const searchCollectionsAction = createAction<ISearchCollectionsAction>();
export const searchCollectionsSuccessAction = createAction<ISearchCollection[]>();

export const deleteCollectionCustomDomain = createAction();

export const getPin = createAction();

export const setAppIcon = createAction<IUploadAppIconAction>();

export const stopAllUpload = createAction<boolean>('STOP_ALL_UPLOAD');

export const setCollectionHeader = createAction<IUploadCollectionHeaderAction>();

export const updateMainCustomers = createAction<IMainCustomersUpdateAction>();
export const deleteMainCustomer = createAction<string>();

export const setEndCustomerEmailSubscription = createAction<IEndCustomerProps>();
