import React, { useState, useEffect, CSSProperties } from 'react';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import Select, { Theme, components } from 'react-select';
import { FaTimes, FaTag } from 'react-icons/fa';
import { partial, last, lastIndexOf, debounce } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils/helpers';
import LabelItem from '../Forms/LabelItem';
import theme from '../../assets/css/theme';
import {
  getCollectionsNew,
  getGalleriesTags,
  getUniqueTags,
  getPresetByIdSelector,
  getIsTagsListFetched,
  getPortfoliosTags
} from '../../modules/selectors';
import {
  fetchGalleriesTags,
  removeGalleryTagCollectionAction,
  changeGalleryTagState,
  addGalleryTagCollectionAction
} from '../../modules/actions';
import { getCollectionsCurrentUser } from '../../actions';
import { IStoreState, GalleryTagsSourceSection, IGalleryTag } from '../../modules/types';
import { FormTooltip } from '../Forms/InputTooltip';
import { useParams } from 'react-router';

const Filter = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  min-width: 200px;
  margin-right: auto;

  .react-select-overview__control,
  react-select-gtags__control {
    min-height: 0;
  }

  .react-select-gtags__control--is-focused {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(92, 135, 98, 0.25);
  }

  .react-select-overview__control--is-focused {
    box-shadow: 0 0 0 0.25rem rgba(92, 135, 98, 0.25) !important;
  }

  .react-select-overview__menu,
  .react-select-gtags__menu {
    border-radius: 0;
    margin-top: 0;
    border: 1px solid ${theme.formsColors.mainColor};
  }

  .react-select-overview__menu-list,
  .react-select-gtags__menu-list {
    max-height: 250px;
    padding-top: 0;
  }

  .react-select-overview__option,
  .react-select-gtags__option {
    padding: 1px 8px;
  }

  .react-select-overview__option--is-focused,
  .react-select-gtags__option--is-focused {
    background: #1e90fe;
    color: #ffffff;
  }

  .react-select-overview__option--is-selected,
  .react-select-gtags__option--is-selected {
    color: ${theme.textColors.baseText};
    background: #ffffff;
  }

  .react-select-overview__multi-value__remove,
  .react-select-gtags__multi-value__remove {
    height: 100%;
    cursor: pointer;
    color: ${theme.commonColors.second};
  }

  .react-select-overview__menu,
  .react-select-gtags__menu {
    overflow: hidden;
  }

  .react-select-overview__clear-indicator {
    display: none;
  }

  .react-select-overview__placeholder,
  .react-select-gtags__placeholder {
    color: #c7c7c7;
  }

  .react-select-overview__indicator-separator {
    background: #fff;
  }

  .react-select-gtags__indicator-separator {
    display: none;
  }
`;

const getSelectTheme = (selectTheme: Theme) => ({
  ...selectTheme,
  borderRadius: 20,
  colors: {
    ...selectTheme.colors,
    primary25: theme.commonColors.second,
    primary: '#a3c0a7'
  }
});

interface IProps extends GalleryTagsSourceSection {
  tableOptions?: any;
  galleryPreset?: string;
  emitTags?: (galleryTags: IGalleryTag[]) => void;
  onBlurCallback?: () => void;
}

interface IParams {
  portfolio_id: string;
}

const overviewInputStyle = {
  control: (provided: CSSProperties) => ({
    ...provided,
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px',
    borderColor: '#ffffff',
    '&:hover': {
      borderColor: '#ffffff'
    },
    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
    '&:focus': {
      boxShadow: '0 0 0 0.25rem rgba(92, 135, 98, 0.25)',
      outline: 0
    }
  }),
  multiValue: (provided: CSSProperties) => ({
    ...provided,
    borderRadius: '20px'
  }),
  multiValueRemove: (provided: CSSProperties) => ({
    ...provided,
    borderRadius: '20px'
  }),
  input: (provided: any) => ({
    ...provided,
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderTopRightRadius: '20px',
    borderBottomRightRadius: '20px'
  })
};

export const GalleryTagsInput: React.FC<IProps> = ({
  sourceSection,
  tableOptions,
  galleryPreset,
  emitTags,
  onBlurCallback
}) => {
  const dispatch = useDispatch();
  const isTagsListFetched = useSelector(getIsTagsListFetched);
  const params = useParams<IParams>();
  const portfolioId = params?.portfolio_id;

  const portfolioTags = useSelector(getPortfoliosTags);
  const { collectionTags, collectionId } = useSelector((state: IStoreState) => {
    if (sourceSection === 'collection') {
      const { tags, _id } = getCollectionsNew(state);

      return { collectionTags: tags, collectionId: _id };
    }

    return { collectionTags: [], collectionId: null };
  });

  const presetTags = useSelector(getPresetByIdSelector)(galleryPreset)?.tags;

  const { all: userTags } = useSelector(getGalleriesTags);
  const uniqueCollectionTags = useSelector(getUniqueTags)(collectionId);

  const [inputValue, setInputValue] = useState('');
  const [openSelectionMenu, setOpenSelectionMenu] = useState(false);
  const [tagOptions, setTagOptions] = useState<IGalleryTag[]>([]);
  const [tags, setTags] = useState([]);
  const [updateTags, setUpdateTags] = useState(false);

  const getCollections = (newTags: IGalleryTag[]) => {
    dispatch(
      getCollectionsCurrentUser({
        ...tableOptions,
        skip: 0,
        galleryTagsValue: newTags
      })
    );
  };
  const debouncedGetCollections = debounce((newTags: IGalleryTag[]) => {
    getCollections(newTags);
  }, 1000);

  const addTagProcessor = (newTag: IGalleryTag) => {
    const trimmedTag = {
      ...newTag,
      value: newTag.value.trim(),
      label: newTag.value.trim()
    };
    const newTags = [...tags, trimmedTag];
    setTags(newTags);
    setInputValue('');
    setOpenSelectionMenu(false);

    switch (sourceSection) {
      case 'collection':
        dispatch(
          addGalleryTagCollectionAction({
            galleryTag: { ...trimmedTag, collectionId, type: 'collection' }
          })
        );
        break;
      case 'portfolio':
      case 'gallery-overview':
        debouncedGetCollections(newTags);
        emitTags(newTags);
        break;
      case 'marketing-automation':
      case 'gallery-presets':
        emitTags(newTags);
        break;
      default:
    }
  };

  const removeTagProcessor = (tagValue: IGalleryTag, key?: number) => {
    let newTags = tags;

    if (typeof key === 'number' && key >= 0) {
      newTags = tags.slice(0, key).concat(tags.slice(key + 1));
    } else {
      newTags = tags.filter((tag) => tag.value !== tagValue.value);
    }

    setTags(newTags);

    if (!tagValue) return;

    switch (sourceSection) {
      case 'collection':
        dispatch(removeGalleryTagCollectionAction({ galleryTag: { ...tagValue } }));
        break;
      case 'portfolio':
      case 'gallery-overview':
        debouncedGetCollections(newTags);
        emitTags(newTags);
        break;
      case 'marketing-automation':
      case 'gallery-presets':
        emitTags(newTags);
        break;
      default:
    }
  };

  useEffect(() => {
    if (sourceSection !== 'collection') return;

    if (collectionTags.length && !tags.length) setTags(collectionTags);
  }, [collectionTags]);

  useEffect(() => {
    if (sourceSection !== 'portfolio') return;

    if (portfolioTags?.length && !tags.length) {
      const defaultTags = uniqueCollectionTags.filter((tag) => portfolioTags.includes(tag._id));
      setTags(defaultTags);
    }
  }, [portfolioTags]);

  useEffect(() => {
    if (sourceSection !== 'gallery-presets') return;

    const processedTags = presetTags?.map((tag) => ({ value: tag, label: tag })) ?? [];
    setTags(processedTags);
    setUpdateTags(true);
  }, [galleryPreset, updateTags]);

  useEffect(() => {
    const shouldSetTagOptions =
      userTags.length &&
      ((sourceSection === 'collection' && collectionId) ||
        (sourceSection === 'portfolio' && portfolioId) ||
        sourceSection === 'gallery-overview' ||
        sourceSection === 'gallery-presets' ||
        sourceSection === 'marketing-automation');

    if (shouldSetTagOptions) {
      setTagOptions(uniqueCollectionTags);
    }
  }, [userTags, collectionId, portfolioId]);

  useEffect(() => {
    if (!isTagsListFetched) {
      dispatch(fetchGalleriesTags());
    }
  }, [dispatch]);

  useEffect(() => {
    setTags([]);
  }, []);

  useEffect(() => {
    if (sourceSection === 'gallery-overview') {
      dispatch(changeGalleryTagState({ collectionsOverviewSelectedTags: tags }));
    }
  }, [tags]);

  const removeTag = (data: IGalleryTag, e: React.SyntheticEvent) => {
    e.stopPropagation();
    removeTagProcessor(data);
  };

  const MultiValueRemove = (props: any) => (
    <div onClick={partial(removeTag, props.data)} role="button">
      <components.MultiValueRemove {...props}>
        <FaTimes />
      </components.MultiValueRemove>
    </div>
  );

  const onInputHandler = (value: string, meta: any) => {
    if (value === '') {
      setInputValue(value);
    }

    if (/^[a-zA-Z0-9ÄäÖöÜüß\s]+$/.test(value)) {
      if (meta.action === 'input-change') {
        setInputValue(value);
        setOpenSelectionMenu(true);
      }
      if (meta.action === 'set-value') {
        setInputValue(value);
      }
    }
  };

  const onChangeHandler = (newValue: any, actionMeta: any) => {
    switch (actionMeta.action) {
      case 'select-option':
        if (inputValue && inputValue.toLowerCase() === actionMeta.option.label.toLowerCase()) {
          addTagProcessor({ ...actionMeta.option, value: inputValue, label: inputValue });
        } else {
          addTagProcessor(actionMeta.option);
        }
        break;
      case 'create-option':
        addTagProcessor(last(newValue));
        break;
      case 'pop-value':
        removeTagProcessor(actionMeta.removedValue, lastIndexOf(tags, actionMeta.removedValue));
        break;
      default:
    }
  };

  const onBlurHandler = () => {
    onBlurCallback && onBlurCallback();
    setOpenSelectionMenu(false);
  };

  const createSections = ['collection', 'gallery-presets', 'marketing-automation'];
  const isCreateSection = createSections.includes(sourceSection);

  const readSections = ['gallery-overview', 'portfolio'];
  const isReadOnlySection = readSections.includes(sourceSection);

  const labelText =
    sourceSection === 'marketing-automation'
      ? getTranslationKey('shop.sales-automation.tags-input')
      : getTranslationKey('collectionSettings.galleryTags.tags');

  const Placeholder = (props: any) => {
    const translationKey = isReadOnlySection
      ? 'collectionSettings.galleryTags.placeholderOverview'
      : 'collectionSettings.galleryTags.placeholder';

    return (
      <components.Placeholder {...props}>
        {getTranslationKey(translationKey)}
      </components.Placeholder>
    );
  };

  const DropdownIndicator = (props: any) => {
    if (!isCreateSection) return null;

    const tooltipText =
      sourceSection === 'marketing-automation'
        ? 'collectionSettings.galleryTags.indicatorTooltipAutomation'
        : 'collectionSettings.galleryTags.indicatorTooltip';

    return (
      <components.DropdownIndicator {...props}>
        <div className="mr-2">
          <FormTooltip
            isShow
            tooltipID="tooltip"
            tooltipPlacement="right"
            tooltipText={getTranslationKey(tooltipText)}
          />
        </div>
      </components.DropdownIndicator>
    );
  };

  const CreatePortfolioTooltip = (props: any) => {
    if (sourceSection !== 'portfolio') return null;

    return (
      <components.DropdownIndicator {...props}>
        <div className="mr-2">
          <FormTooltip
            isShow
            tooltipID="tooltip"
            tooltipPlacement="right"
            tooltipText={getTranslationKey('portfolio.tagTooltip')}
          />
        </div>
      </components.DropdownIndicator>
    );
  };

  return (
    <Filter>
      {isCreateSection && (
        <>
          <LabelItem isBold>{labelText}</LabelItem>
          <CreatableSelect
            optionClassName="needsclick"
            classNamePrefix="react-select-gtags"
            inputValue={inputValue}
            isSearchable
            isMulti
            value={tags}
            options={tagOptions}
            onChange={onChangeHandler}
            onInputChange={onInputHandler}
            components={{ MultiValueRemove, Placeholder, DropdownIndicator }}
            menuIsOpen={openSelectionMenu}
            onBlur={onBlurHandler}
            closeMenuOnSelect={false}
            hideSelectedOptions
            theme={getSelectTheme}
            isClearable={false}
          />
        </>
      )}

      {isReadOnlySection && (
        <>
          <LabelItem isBold margin="-10px 0 5px 0">
            {labelText}
          </LabelItem>
          <InputGroup className="input-prepend pull-right">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <FaTag />
              </InputGroupText>
            </InputGroupAddon>
            <div className="react-select form-control p-0 h-auto">
              <Select
                isMulti
                options={tagOptions}
                optionClassName="needsclick"
                classNamePrefix="react-select-overview"
                components={{
                  MultiValueRemove,
                  Placeholder,
                  DropdownIndicator: CreatePortfolioTooltip
                }}
                theme={getSelectTheme}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                styles={overviewInputStyle}
                value={tags}
              />
            </div>
          </InputGroup>
        </>
      )}
    </Filter>
  );
};
export default GalleryTagsInput;
