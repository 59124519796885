import React from 'react';
import { getTranslationKey } from '../../modules/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { changeModalState, deletePreset, setPresetAsDefault } from '../../modules/actions';
import { getPresetByIdSelector } from '../../modules/selectors';
import { FaRegCircle, FaRegCheckCircle, FaPen, FaTrash } from 'react-icons/fa';
import theme from '../../assets/css/theme';

interface IProps {
  presetId: string;
}

export const ActionButton = ({ presetId }: IProps) => {
  const preset = useSelector(getPresetByIdSelector)(presetId);
  const dispatch = useDispatch();
  const setDefaultHandler = () => {
    dispatch(setPresetAsDefault(presetId));
  };
  const deletePresetHandler = () => {
    const confirmDelete = window.confirm(getTranslationKey('galleryPresets.presetDeleteConfirm'));
    if (confirmDelete) {
      dispatch(deletePreset(presetId));
    }
  };
  const openEditPresetModal = () => {
    dispatch(
      changeModalState({
        key: 'presetsSettingModal',
        state: true,
        modalData: { presetId }
      })
    );
  };

  return (
    <>
      <FaPen
        style={{ cursor: 'pointer' }}
        className="mr-3"
        title={getTranslationKey('galleryPresets.actionEditButton')}
        color={theme.commonColors.second}
        onClick={openEditPresetModal}
      />

      <FaTrash
        style={{ cursor: 'pointer' }}
        className="mr-3"
        title={getTranslationKey('galleryPresets.actionDeleteButton')}
        color={theme.commonColors.second}
        onClick={deletePresetHandler}
      />
      {preset && preset.default ? (
        <FaRegCheckCircle
          style={{ cursor: 'pointer' }}
          title={getTranslationKey('galleryPresets.actionDefaultSetButton')}
          color={theme.commonColors.second}
          onClick={setDefaultHandler}
        />
      ) : (
        <FaRegCircle
          style={{ cursor: 'pointer' }}
          title={getTranslationKey('galleryPresets.actionDefaultSetButton')}
          color={theme.commonColors.second}
          onClick={setDefaultHandler}
        />
      )}
    </>
  );
};
