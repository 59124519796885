import { createReducer } from 'redux-act';
import { updateSubscriptionStore } from '../../../modules/actions/subscription';
import { IDeal } from '../../../modules/types/subscriptions';

export type TPlan = 'starter' | 'professional';
export type TVideoStorage = 'free' | string;
export type TBillingLength = 'monthly' | 'yearly';

export interface ISubscriptionReducerState {
  plan: TPlan;
  billingCycle: TBillingLength;
  videoStorage: TVideoStorage;
  slideshows: boolean;
  templates: string[];
  deal?: IDeal;
  buyUrl?: string;
  brandsAmount: number;
  domainsAmount: number;
  purchased_templates?: string[];
  isRedirecting?: boolean;
  pricing?: any;
  isLoaded?: boolean;
}

const initialState: ISubscriptionReducerState = {
  plan: 'professional',
  billingCycle: process.env.REACT_APP_REGION === 'eu' ? 'yearly' : 'monthly',
  videoStorage: '',
  slideshows: false,
  templates: [],
  brandsAmount: 1,
  domainsAmount: 1,
  purchased_templates: [],
  isLoaded: false
};

export const subscription = createReducer<ISubscriptionReducerState>({}, initialState);
subscription.on(
  updateSubscriptionStore,
  (state: ISubscriptionReducerState, payload: Partial<ISubscriptionReducerState>) => {
    const newState = {
      ...state,
      ...payload
    };
    window.localStorage.setItem('subscriptionConfig', JSON.stringify(newState));
    return newState;
  }
);
