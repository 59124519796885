import { setCookie, getCookie } from './helpers/cookies';

window.getCookie = getCookie;
window.setCookie = setCookie;

window.getCachedValue = function (t) {
  try {
    const r = (localStorage && localStorage.getItem(t)) || getCookie(t) || null;
    if (r) {
      try {
        return JSON.parse(r);
      } catch (e) {}
    }

    return r;
  } catch (n) {
    console.log(n);

    return null;
  }
};
