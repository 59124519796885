import { SagaIterator } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import {
  errorsGlobalError,
  fetchCollectionSuccess,
  fetchImagesSuccess,
  selectionCleanUpSuccess,
  showNotifySuccess
} from '../../../modules/actions';
import { getCollectionId, getImagesList } from '../../../modules/selectors';
import { ISagaAction } from '../../../modules/types';
import Api from '../../../modules/utils/API';
import {
  ISelectionCleanUpAction,
  ISelectionCleanUpParams
} from '../../../modules/types/selections';
import { isUndefined, filter } from 'lodash';
import { moveImagesToGallerySaga } from '../../../modules/sagas/images';
import ApiErrors from '../../../modules/utils/API/APIErrors';

export const fetchSelectionsSaga = function* (): SagaIterator {
  let collectionID = yield select(getCollectionId);
  if (!collectionID) {
    yield take(fetchCollectionSuccess);
  }
  try {
    collectionID = yield select(getCollectionId);
    const res = yield call(Api.Selections.get, {
      collection_id: collectionID
    });
    yield put({
      type: 'FETCH_COLLECTION_SELECTIONS',
      payload: { selections: res, collection_id: collectionID }
    });
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const selectionCleanUpSaga = function* ({
  payload
}: ISagaAction<ISelectionCleanUpAction>): SagaIterator {
  const collection_id = yield select(getCollectionId);
  const images = yield select(getImagesList);
  const {
    whichImages,
    selection_id,
    gallery_ids,
    deleteSelectionAfterCleanUp,
    action,
    moveToGalleryId,
    imagesList
  } = payload;
  const data: ISelectionCleanUpParams = {
    collection_id,
    selection_id,
    gallery_ids
  };
  if (!isUndefined(deleteSelectionAfterCleanUp)) {
    data.deleteSelectionAfterCleanUp = deleteSelectionAfterCleanUp;
  }

  if (action === 'move') {
    yield call(moveImagesToGallerySaga, {
      galleryID: moveToGalleryId,
      imagesIDs: imagesList
    });
    yield call(fetchSelectionsSaga);
    yield put(selectionCleanUpSuccess(false));
  } else {
    try {
      const response = yield call(Api.Selections.cleanUp, data, whichImages);
      ApiErrors.checkOnApiError(response);
      const { removedImageIds } = response;
      const updatedImages = filter(images, (image) => removedImageIds.indexOf(image._id) === -1);
      yield put(fetchImagesSuccess({ items: updatedImages }));
      yield call(fetchSelectionsSaga);
      yield put(showNotifySuccess({}));
      yield put(selectionCleanUpSuccess(false));
    } catch (e) {
      yield put(errorsGlobalError(e as ErrorEvent));
    }
  }
};
