export default function (state = { all: [] }, action) {
  switch (action.type) {
    case 'SET_SITE_TEMPLATES_BY_TYPE': {
      return { ...state, [action.payload.type]: action.payload.data };
    }
    case 'SET_ALL_SITE_TEMPLATES': {
      return { ...state, all: action.payload.data };
    }
    case 'REMOVE_SITE_TEMPLATE': {
      const { id, type } = action.payload;
      const newAll = state.all.filter((template) => template._id !== id);

      let byType;
      if (state[type] && state[type].length) {
        byType = state[type].filter((tl) => tl._id !== id); // eslint-disable-line
      }

      let newState = { ...state, all: newAll };
      if (byType) {
        newState = { ...newState, [type]: byType };
      }

      return newState;
    }
    default:
      return state;
  }
}
