import dotProp from 'dot-prop-immutable';

export default function (
  state = { scrappbookTemplates: [], ids: [], initialCollectionsAmount: null },
  action
) {
  switch (action.type) {
    case 'SET_SCRAPPBOOK_TEMPLATES': {
      return { ...state, scrappbookTemplates: action.payload };
    }
    case 'SET_COLLECTION_IDS': {
      return { ...state, ids: action.payload };
    }
    case 'SET_INITIAL_COLLECTIONS_AMOUNT': {
      return { ...state, initialCollectionsAmount: action.payload };
    }
    case 'ADD_COLLECTION': {
      const collection = action.payload;

      return { ...state, [collection._id]: collection };
    }
    case 'REMOVE_IMAGE_FROM_COLLECTION': {
      const { image, collection_id } = action.payload;
      const col = state[collection_id];

      if (col) {
        return {
          ...state,
          [collection_id]: {
            ...col,
            images: col.images.filter((i) => i._id !== image._id)
          }
        };
      }

      return state;
    }
    case 'ADD_IMAGE_TO_COLLECTION': {
      const { image, collection_id } = action.payload;
      const col = state[collection_id];

      if (col) {
        const newImages = [...col.images, image];

        return { ...state, [collection_id]: { ...col, images: newImages } };
      }

      return state;
    }
    case 'UPDATE_COLLECTION_BY_PATH': {
      let { path, collection_id, value } = action.payload; // eslint-disable-line
      const col = state[collection_id];

      if (col) {
        while (path.includes('[')) {
          path = path.replace('[', '.').replace(']', '');
        }

        const updatedCol = dotProp.set(col, path, value);

        return { ...state, [collection_id]: updatedCol };
      }

      return state;
    }
    case 'ORDER_COLLECTION_GALLERYS': {
      const { collection_id, sortedGallerys } = action.payload;
      const col = state[collection_id];

      if (col) {
        const updatedCol = dotProp.set(col, 'galleries', sortedGallerys);

        return { ...state, [collection_id]: updatedCol };
      }

      return state;
    }
    case 'ADD_GALLERY_TO_COLLECTION': {
      const { collection_id, gallery } = action.payload;
      const col = state[collection_id];

      if (col) {
        let gallerys = [];

        if (col.galleries) gallerys = [...col.galleries];
        gallerys.push(gallery);

        const updatedCol = dotProp.set(col, 'galleries', gallerys);

        return { ...state, [collection_id]: updatedCol };
      }

      return state;
    }
    case 'DELETE_GALLERY_FROM_COLLECTION': {
      const { collection_id, gallery_id } = action.payload;
      const col = state[collection_id];

      if (col) {
        const updatedCol = dotProp.set(
          col,
          'galleries',
          col.galleries.filter((g) => g._id !== gallery_id)
        );

        return { ...state, [collection_id]: updatedCol };
      }

      return state;
    }
    default:
      return state;
  }
}
