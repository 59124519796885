import { IPreset } from '../../modules/reducers/presets';
import { COLLECTION_SHOP_AUTOMATION_DEFAULTS } from '../../modules/constants/collections';

export const DEFAULT_PRESET_ITEM: IPreset = {
	comments: false,
	default: false,
	download: false,
	downloadPin: false,
	galleries: [],
	galleryStyle: '',
  shopCtaPlacement: 'after-last',
	language: 0,
	name: '',
	sharing: false,
	showAppInstall: false,
	showImageCaptionInGallery: false,
	_userbrand: '',
	_id: '',
	_user: '',
	_domain: '',
	_watermark: '',
	_scrappbookGalleryTemplate: '',
	styles: {
		backgroundColor: 'white',
		buttonColor: 'white'
	},
	ga_id: '',
	gtm_id: '',
  sortBy: '',
  sortOrder: 'asc',
	fb_pixel_id: '',
	shopActive: false,
	_productPricingList: '',
  showOnlyPurchasedPrints: false,
	salesAutomationConfig: COLLECTION_SHOP_AUTOMATION_DEFAULTS,
	design: {
		disableDecorations: false,
		loadFonts: [],
		imageSpacing: '',
		variables: {
			font: '',
			primaryColor: '',
			secondaryColor: ''
		}
	},
	digitalShop: {
		enabled: false,
		manualOrderProcessing: false,
		digitalPricingList: null,
		alreadyPurchasedTier: null
	}
}

export const PRESET_EDIT_TABS = [
  {
    id: 'settings',
    label: 'settings'
  },
  {
    id: 'design',
    label: 'galleryPresets.designTab'
  },
  {
    id: 'album-defaults',
    label: 'galleryPresets.albumDefaultsTab'
  },
  {
    id: 'tacking',
    label: 'defaultValues.trackingTitle'
  },
  {
    id: 'auto-marketing',
    label: 'galleryPresets.autoMarketingTab'
  }
];

export const DEFAULT_PRESET_TAB = 'settings';
export const DEFAULT_TRACKING_INPUT_DATA = {
  tooltip_id: '',
  tooltip_text: '',
  placeholder: '',
  title: ''
};
export const PRESET_TRACKING_DATA = {
  ga_id: {
    tooltip_id: 'ga_id_tooltip',
    tooltip_text: 'siteGoogleAnalyticsDesc',
    placeholder: 'siteGoogleAnalytics',
    title: 'siteGoogleAnalytics'
  },
  fb_pixel_id: {
    tooltip_id: 'fb_pixel_id_tooltip',
    tooltip_text: 'siteFBPixelDesc',
    placeholder: 'siteFBPixel',
    title: 'siteFBPixel'
  },
  gtm_id: {
    tooltip_id: 'gtm_id_tooltip',
    tooltip_text: 'gtm_idDesc',
    placeholder: 'gtm_id',
    title: 'gtm_id'
  }
};
export const DEFAULT_GALLERY_PRESET = {
  title: '',
  text: '',
  order: 0,
  sortOrder: 'asc',
  sortBy: 'uploadedAt',
  shopCtaPlacement: 'after-last'
};
