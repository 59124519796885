import React from 'react';
import { IWatermark } from '../../modules/reducers/watermarks';
import { getTranslationKey } from '../../modules/utils/helpers';
import querystring from 'query-string';
import styled from 'styled-components';

interface IProps {
  watermark: IWatermark;
}

const Wrapper = styled.div`
  max-width: 100%;
  img {
    width: 100%;
  }
`;

export const WatermarkPreview = ({ watermark }: IProps) => {
  const query = querystring.stringify(watermark as any);
  // @ts-ignore
  const previewUrl = window.API_URL + `/api/image/previewWatermark?${query}`;

  return (
    <Wrapper>
      <img src={previewUrl} alt={getTranslationKey('previewLoading')} />
    </Wrapper>
  );
};
