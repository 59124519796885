import { SagaIterator } from 'redux-saga';
import { ISagaAction } from '../../../modules/types';
import {
  fetchEmailPackages,
  fetchEmailPackagesSuccess,
  setEmailPackage,
  setEmailSelectionOptions
} from '../../actions/emailSettings';
import { Api } from '../../utils/API';
import { call, put, select } from 'redux-saga/effects';
import { IEmailPackage } from '../../types/emails';
import { showNotifySuccess } from '../../actions';
import { getTranslationKey } from '../../utils';

export const fetchEmailPackageSaga = function* (): SagaIterator {
  try {
    const { packageSelected } = yield select((state) => state.emailSettings);

    const { result } = yield call(Api.Emails.fetchEmailPackages);
    yield put(fetchEmailPackagesSuccess(result));

    // once default title is written to the db, there is no way to rewrite it each time user changes the system language
    // so we are doing this on FE, to keep the title responsive to the language
    const defaultTemplateTitles = ['Standardpaket', 'Default Package'];
    const getSelectionTitle = (title: string) =>
      defaultTemplateTitles.includes(title)
        ? getTranslationKey('shop.email-settings.defaultPackageTitle')
        : title;

    const selectionOptions = result?.map(({ templates, _id }: IEmailPackage) => ({
      text: getSelectionTitle(templates.templateTitle),
      value: _id
    }));
    yield put(setEmailSelectionOptions(selectionOptions));

    if (!packageSelected) yield put(setEmailPackage(result[0]?._id));
  } catch (e) {
    console.log('e', e);
  }
};

export const createEmailPackageSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const newPackage = yield call(Api.Emails.createNewPackage, payload);
    yield put(showNotifySuccess({}));
    yield put(fetchEmailPackages());
    yield put(setEmailPackage(newPackage.result.id));
  } catch (e) {
    console.log('e', e);
  }
};

export const deleteEmailPackageSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const { emailPackages } = yield select((state) => state.emailSettings);

    yield call(Api.Emails.deletePackage, payload);
    yield put(showNotifySuccess({}));
    yield put(fetchEmailPackages());
    yield put(setEmailPackage(emailPackages[0]?._id));
  } catch (e) {
    console.log('e', e);
  }
};
