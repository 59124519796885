import React from 'react';
import { Table, Thead, Th, Tr, Td } from 'reactable';
import { Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Texts from '../json/texts.json';

const pageHref = (num) => `#page-${num + 1}`;

const Paginator = ({
  onPageChange,
  currentPage,
  pageButtonLimit,
  numPages,
  nextPageLabel,
  previousPageLabel
}) => {
  const handlePrevious = (e) => {
    e.preventDefault();
    onPageChange(currentPage - 1);
  };

  const handleNext = (e) => {
    e.preventDefault();
    onPageChange(currentPage + 1);
  };

  const handlePageButton = (page, e) => {
    e.preventDefault();
    onPageChange(page);
  };

  const renderPrevious = () => {
    if (currentPage > 0) {
      return (
        <a
          className="reactable-previous-page"
          href={pageHref(currentPage - 1)}
          onClick={handlePrevious.bind(this)}
        >
          {previousPageLabel || 'Previous'}
        </a>
      );
    }
  };

  const renderNext = () => {
    if (currentPage < numPages - 1) {
      return (
        <a
          className="reactable-next-page"
          href={pageHref(currentPage + 1)}
          onClick={handleNext.bind(this)}
        >
          {nextPageLabel || 'Next'}
        </a>
      );
    }
  };

  const renderPageButton = (className, pageNum) => (
    <a
      className={className}
      key={pageNum}
      href={pageHref(pageNum)}
      onClick={handlePageButton.bind(this, pageNum)}
    >
      {pageNum + 1}
    </a>
  );

  const pageButtons = [];
  const lowerHalf = Math.round(pageButtonLimit / 2);
  const upperHalf = pageButtonLimit - lowerHalf;

  for (let i = 0; i < numPages; i++) {
    // const showPageButton = false;
    const pageNum = i;
    let className = 'reactable-page-button';

    if (currentPage === i) {
      className += ' reactable-current-page';
    }

    pageButtons.push(renderPageButton(className, pageNum));
  }

  if (currentPage - pageButtonLimit + lowerHalf > 0) {
    if (currentPage > numPages - lowerHalf) {
      pageButtons.splice(0, numPages - pageButtonLimit);
    } else {
      pageButtons.splice(0, currentPage - pageButtonLimit + lowerHalf);
    }
  }

  if (numPages - currentPage > upperHalf) {
    pageButtons.splice(pageButtonLimit, pageButtons.length - pageButtonLimit);
  }

  return (
    <div className="reactable-pagination">
      {renderPrevious()}
      {pageButtons}
      {renderNext()}
    </div>
  );
};

const TableItem = ({
  items,
  columns,
  columnNames,
  noMatchingRecords,
  sortable,
  onSort,
  mappers,
  doubleRows,
  defaultSorting,
  itemsPerPage,
  serverSidePagination,
  search,
  isLoading
}) => (
  <Table
    className="table"
    itemsPerPage={serverSidePagination || itemsPerPage > items.length ? 0 : itemsPerPage}
    pageButtonLimit={5}
    onSort={onSort}
    sortable={sortable}
    defaultSort={defaultSorting}
    nextPageLabel={Texts[window.LANGUAGE_SW].nextPageLabel}
    previousPageLabel={Texts[window.LANGUAGE_SW].previousPageLabel}
    filterable={columns}
    hideFilterInput
    filterBy={search}
    noDataText={noMatchingRecords || Texts[window.LANGUAGE_SW].noMatchingRecords}
    style={{
      opacity: isLoading ? '0.5' : ''
    }}
  >
    <Thead>
      {columns.map((column, i) => (
        <Th key={i} column={column}>
          {columnNames[i]}
        </Th>
      ))}
    </Thead>
    {doubleRows && doubleRows.map((item) => item.content)}

    {!doubleRows &&
      items.map((item, i) => (
        <Tr key={i}>
          {columns.map((column, j) => {
            const data = mappers[column] ? mappers[column](item[column], item) : item[column];

            return (
              <Td key={j} column={column} data={data}>
                <span>{data}</span>
              </Td>
            );
          })}
        </Tr>
      ))}
  </Table>
);

const DefaultTable = ({
  items,
  columns,
  columnNames,
  itemsPerPage,
  search = '',
  onSearch = null,
  onChangeItemsPerPage = null,
  button = null,
  noMatchingRecords = null,
  sortable = null,
  onSort = null,
  serverSidePagination = null,
  footerActions = null,
  mappers = {},
  tableStyles = {},
  bottomInput = null,
  doubleRows = null,
  isLoading = false,
  defaultSorting
}) => (
  <div className="table-wrapper" style={tableStyles}>
    <div className="row d-flex">
      <div className="col-sm-12 col-md-6">{button}</div>
      <div className="col-sm-12 col-md-4 mb-2" />
      {onSearch && (
        <div className="col-sm-12 col-md-4 mb-2">
          <InputGroup className="input-prepend pull-right">
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fa fa-search" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={search}
              onChange={onSearch}
              id="prependedInput"
              size="16"
              type="text"
              placeholder={Texts[window.LANGUAGE_SW].search}
              spellcheck="false"
            />
          </InputGroup>
        </div>
      )}
    </div>
    {bottomInput && (
      <div className="row mb-3">
        <div className="col-sm-12 col-md-4 mb-2">{bottomInput}</div>
      </div>
    )}
    <div className="row" style={{ flexGrow: 2 }}>
      <div className="col-md-12">
        <div className="table-responsive">
          {isLoading && <div className="loader" />}
          {!isLoading && defaultSorting && (
            <TableItem
              items={items}
              columns={columns}
              columnNames={columnNames}
              noMatchingRecords={noMatchingRecords}
              sortable={sortable}
              onSort={onSort}
              search={search}
              mappers={mappers}
              doubleRows={doubleRows}
              defaultSorting={defaultSorting}
              serverSidePagination={serverSidePagination}
              itemsPerPage={itemsPerPage}
              isLoading={isLoading}
            />
          )}
          {/* Second table needed to make 'defaultSorting' field work as expected */}
          {!isLoading && !defaultSorting && (
            <TableItem
              items={items}
              columns={columns}
              columnNames={columnNames}
              noMatchingRecords={noMatchingRecords}
              sortable={sortable}
              onSort={onSort}
              search={search}
              mappers={mappers}
              doubleRows={doubleRows}
              defaultSorting={{ column: '' }}
              serverSidePagination={serverSidePagination}
              itemsPerPage={itemsPerPage}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="table-footer">
          {footerActions}
          {serverSidePagination && !isLoading && (
            <Paginator
              onPageChange={serverSidePagination.onPageChange}
              currentPage={serverSidePagination.currentPage}
              numPages={serverSidePagination.numPages}
              pageButtonLimit={5}
              nextPageLabel={Texts[window.LANGUAGE_SW].nextPageLabel}
              previousPageLabel={Texts[window.LANGUAGE_SW].previousPageLabel}
            />
          )}
          {onChangeItemsPerPage && !isLoading && (
            <div>
              <form onSubmit={(e) => e.preventDefault()} className="pull-right">
                <div className="form-group form-inline">
                  <span className="mr-2">{Texts[window.LANGUAGE_SW].showRecordsPerPage}</span>
                  <select
                    className="custom-select m-r-10"
                    onChange={onChangeItemsPerPage}
                    value={itemsPerPage}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

DefaultTable.defaultProps = {
  items: [],
  columns: [],
  itemsPerPage: 10,
  text: ''
};
export default DefaultTable;
