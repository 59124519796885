import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEmailTemplatesSelector,
  getSelectedEmailTemplateSelector
} from '../../modules/selectors';
import { SelectItem } from '../Forms/SelectItem';
import { getOptionsForSelect, getTranslationKey } from '../../modules/utils';
import { ManageEmailTemplatesModal } from '../ManageEmailTemplatesModal';
import { IDictionary } from '../../modules/types';
import { partial, find } from 'lodash';
import { IEmailTemplate } from '../../modules/types/emails';
import { setSelectedEmailTemplateAction } from '../../modules/actions';
import { DEFAULT_EMAIL_TEMPLATE } from '../../modules/constants/email_templates';

const getEmailTemplateByID = (id: string, items: IEmailTemplate[]) =>
  find(items, { id }) || DEFAULT_EMAIL_TEMPLATE;

interface IProps {
  hideLabel?: boolean;
  callBack?: (emailTemplateId: IEmailTemplate) => void;
  selectedTemplateID?: string;
}

export const EmailTemplateSelect: React.FC<IProps> = ({
  hideLabel,
  callBack,
  selectedTemplateID
}) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const emailTemplatesList = useSelector(getEmailTemplatesSelector);
  const optionsTemplates = getOptionsForSelect(
    emailTemplatesList as IDictionary<any>[],
    'id',
    'name'
  );
  const selectedEmailTemplate = useSelector(getSelectedEmailTemplateSelector);
  const options = [
    {
      text: getTranslationKey('collectionSettings.sendEmail.createNewTempOption'),
      value: 'create'
    },
    ...optionsTemplates
  ];

  useEffect(() => {
    dispatch(setSelectedEmailTemplateAction(DEFAULT_EMAIL_TEMPLATE));
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    if (selectedTemplateID) {
      const emailTemplate = getEmailTemplateByID(selectedTemplateID, emailTemplatesList);
      dispatch(setSelectedEmailTemplateAction(emailTemplate));
    }
  }, [selectedTemplateID]); // eslint-disable-line

  const label = !hideLabel
    ? getTranslationKey('collectionSettings.sendEmail.templateSelectLabel')
    : null;

  const setSelectedHandler = (value: string) => {
    if (value === 'create') {
      setIsModalOpen(true);
    } else {
      const emailTemplate = getEmailTemplateByID(value, emailTemplatesList);
      dispatch(setSelectedEmailTemplateAction(emailTemplate));
      if (callBack) {
        callBack(emailTemplate);
      }
    }
  };

  return (
    <>
      <SelectItem
        label={label}
        isDefaultDisabled
        defaultOptionsText={getTranslationKey('collectionSettings.sendEmail.templateSelectDefault')}
        value={selectedEmailTemplate.id}
        changeCallback={setSelectedHandler}
        options={options}
      />
      <ManageEmailTemplatesModal
        isOpen={isModalOpen}
        closeCallback={partial(setIsModalOpen, false)}
      />
    </>
  );
};
