import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsPresetsListFetched, getPresetsListSelector } from '../../modules/selectors';
import Texts from '../../json/texts.json';
import { Input } from 'reactstrap';
import { fetchPresetsAction } from '../../modules/actions';

let setDefaultPreset = false;

const GalleryPresetSelect = ({ selectedPresetId, onChange }) => {
  const dispatch = useDispatch();
  const galleryPresets = useSelector(getPresetsListSelector);
  const isFetched = useSelector(getIsPresetsListFetched);
  useEffect(() => {
    if (!isFetched) {
      dispatch(fetchPresetsAction());
    }
  }, [isFetched, dispatch]);

  if (galleryPresets.length && !setDefaultPreset) {
    const defaultGalleryPreset = galleryPresets.find((p) => p.default) || galleryPresets[0];
    if (defaultGalleryPreset) {
      setDefaultPreset = true;
      onChange(defaultGalleryPreset);
    }
  }

  return (
    <Input
      type="select"
      onChange={({ target: { value } }) =>
        galleryPresets.length && onChange(galleryPresets.find((p) => p._id === value))
      }
      value={selectedPresetId || 'standard'}
      className="pt-gallery-preset-select-preset-gallery-creation"
    >
      {(!galleryPresets || galleryPresets.length === 0) && (
        <option value="standard">{Texts[window.LANGUAGE].galleryPresets.notFound}</option>
      )}
      {galleryPresets &&
        galleryPresets.map((preset) => (
          <option key={preset._id} value={preset._id}>
            {preset.name}
          </option>
        ))}
    </Input>
  );
};

export default GalleryPresetSelect;
