export default function (state = {}, action) {
  switch (action.type) {
    case 'SET_USER_BRANDS': {
      const values = action.payload;
      const brands = {};
      values.forEach((val) => (brands[val._id] = val)); // eslint-disable-line

      return { ...state, ...brands };
    }
    case 'SET_USER_BRAND': {
      const values = action.payload;

      return { ...state, [values._id]: values };
    }
    case 'SET_USER_BRAND_IMAGE': {
      const { img, userBrandId, type } = action.payload;

      return {
        ...state,
        [userBrandId]: { ...state[userBrandId], [type]: img }
      };
    }
    case 'REMOVE_USER_BRAND': {
      return { ...state, [action.payload]: undefined };
    }
    case 'SET_USER_BRAND_VALUE': {
      const { userBrandId, key, value } = action.payload;

      return {
        ...state,
        [userBrandId]: { ...state[userBrandId], [key]: value }
      };
    }
    default:
      return state;
  }
}
