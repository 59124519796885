import { useDispatch, useSelector } from 'react-redux';
import { getStatisticsGraph, getStatisticsGraphFilters } from '../../modules/selectors/statistics';
import { IDictionary, IGraphData } from '../../modules/types';
import { ACTIVITY_FILTERS_OPTIONS, DEFAULT_GRAPH } from '../../modules/constants/statistics';
import { getTranslationKey } from '../../modules/utils/helpers';
import { setActivitiesFilter } from '../../modules/actions';
import { flatten, uniq, cloneDeep, sortBy, keys, filter, intersection } from 'lodash';
import { useState } from 'react';
import moment from 'moment';

type IUseStatisticsGraphData = [IGraphData, number, string[]];

export const useStatisticsGraphData = (): IUseStatisticsGraphData => {
  const graphStore = useSelector(getStatisticsGraph);
  const graphFilter = useSelector(getStatisticsGraphFilters);
  const allValues: number[][] = [];
  const graph = cloneDeep(DEFAULT_GRAPH);
  graph.datasets.forEach((line: any) => {
    if (!graphFilter.includes(line.key)) {
      return line;
    }
    const lineValues: number[] = [];
    graphStore.forEach((stat: any) => {
      lineValues.push(stat[line.key]);
      graph.labels.push(moment(stat.date).format('MM-DD'));
      allValues.push(lineValues);
    });
    line.label = getTranslationKey(`collectionActivities.overall.graphLabels.${line.key}`);
    line.data = graphStore.map((stat: any) => stat[line.key]);
    return line;
  });
  graph.maxValue = Math.max(...flatten(allValues));
  graph.labels = sortBy(uniq(graph.labels));
  return [graph, graph.maxValue, graphFilter];
};

const autoSelectFilter: IDictionary<any> = {
  'view.view': [
    'view.collection',
    'view.gallery',
    'view.image',
    'view.contact',
    'view.app',
    'view.share'
  ],
  'download.download': [
    'download.collection',
    'download.gallery',
    'download.image',
    'download.selection'
  ],
  'share.share': ['share.collection', 'share.gallery', 'share.image', 'share.selection'],
  'sendEmail.collection': ['sendEmail.collection', 'sendEmail.selection', 'sendEmail.shop']
};

const isSkipSelection = (
  values: string[],
  key: string,
  autoSelectValues: string[],
  autoSelectOption: string[]
) =>
  !values.includes(key) &&
  autoSelectValues.length &&
  autoSelectValues.length !== autoSelectOption.length;
const isSelectAllValues = (values: string[], key: string, isKeyWaySelected: boolean) =>
  values.includes(key) && !isKeyWaySelected;
const unSelectSelectAll = (
  values: string[],
  key: string,
  isKeyWaySelected: boolean,
  autoSelectValues: string[],
  autoSelectOption: string[]
) =>
  values.includes(key) && isKeyWaySelected && autoSelectValues.length !== autoSelectOption.length;

export const useStatisticsActivitiesFilter = (): any => {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const options = ACTIVITY_FILTERS_OPTIONS.map((option) => ({
    label: getTranslationKey(`collectionActivities.activities.title.${option}`),
    value: option
  }));

  const setSelectedFilters = (values: any) => {
    let filterValues = values;
    const autoSelectKeys = keys(autoSelectFilter);
    autoSelectKeys.forEach((key) => {
      const autoSelectOption = autoSelectFilter[key];
      const autoSelectValues: string[] = intersection(autoSelectOption, values);
      const isKeyWaySelected = selected.includes(key as never);
      if (isSkipSelection(values, key, autoSelectValues, autoSelectOption)) {
        return;
      } else if (isSelectAllValues(values, key, isKeyWaySelected)) {
        filterValues = [...values, ...autoSelectOption];
      } else if (
        unSelectSelectAll(values, key, isKeyWaySelected, autoSelectValues, autoSelectOption)
      ) {
        filterValues = filter(values, (val) => val !== key);
      } else if (!values.includes(key) && autoSelectValues.length === autoSelectOption.length) {
        filterValues = filter(values, (val) => !autoSelectOption.includes(val));
      }
    });

    dispatch(setActivitiesFilter(filterValues));
    setSelected(filterValues);
  };

  return [options, selected, setSelectedFilters];
};
