import { createSelector } from 'reselect';
import { IStoreState } from '../types';

export const getGalleriesTags = (state: IStoreState) => state.galleriesTags;
export const getPortfoliosTags = (state: IStoreState) => state.portfolios.selected.portfolioTags;

export const getUniqueTags = createSelector(
  getGalleriesTags,
  ({ all }) =>
    (galleryCollectionId?: string) => {
      const tagsMap = new Map();

      all.forEach((tag) => {
        const { collectionId, label, _id } = tag;

        if (tagsMap.get(label.toLowerCase())) {
          if (collectionId === galleryCollectionId) {
            tagsMap.set(label.toLowerCase(), { label, value: label, _id });
          }
        } else {
          tagsMap.set(label.toLowerCase(), { label, value: label, _id });
        }
      });

      return Array.from(tagsMap.values());
    }
);

export const getCollectionTagsMap = createSelector(getGalleriesTags, ({ all }) => () => {
  const collectionMap = all.reduce((map, tagObj) => {
    if (map.has(tagObj.collectionId)) {
      let current = map.get(tagObj.collectionId);
      let newVal = current.concat(tagObj);
      return map.set(tagObj.collectionId, newVal);
    } else {
      return map.set(tagObj.collectionId, [tagObj]);
    }
  }, new Map());

  return collectionMap;
});

export const getIsTagsListFetched = createSelector(
  getGalleriesTags,
  (galleriesTagsStore) => galleriesTagsStore.isFetched
);
