import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../modules/selectors';
import { setCookie } from '../../helpers/cookies';

export const PrivateRoute = (props: RouteProps) => {
  const { pathname, search } = useLocation();
  const { authenticated, registrationProcess } = useSelector(getUser);
  if (pathname === '/account/setup') {
    return <Route {...props} />;
  }
  const isRegistrationProcess =
    registrationProcess &&
    (!registrationProcess?.setPassword || !registrationProcess?.setDefaultValues);
  let redirectURL = '/login';
  let query = '';
  if (isRegistrationProcess) {
    redirectURL = '/account/setup';
  }
  const isRedirect = !authenticated || isRegistrationProcess;

  if (search && URLSearchParams) {
    query = '?' + new URLSearchParams(search).toString();
  }

  if (!isRegistrationProcess && !authenticated && redirectURL !== pathname) {
    // @ts-ignore
    setCookie('authenticatedUrlTarget', pathname + query, 1);
  }

  return !isRedirect ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: redirectURL,
        state: { from: props.location }
      }}
    />
  );
};
