import React from 'react';
import { Col, Row } from 'reactstrap';
import Texts from '../../../json/texts.json';
import _strategySteps from '../../../data/mailjetTemplateKeysForStrategys';

const AppStrategy = ({ userStrategySteps, doStrategyStep }) => {
  if (!userStrategySteps) return null;

  const strategySteps = _strategySteps.apps;
  const checked = {
    step1: userStrategySteps.includes(strategySteps[0]),
    step2: userStrategySteps.includes(strategySteps[1]),
    step3: userStrategySteps.includes(strategySteps[2]),
    step4: userStrategySteps.includes(strategySteps[3]),
    step5: userStrategySteps.includes(strategySteps[4]),
    step6: userStrategySteps.includes(strategySteps[5])
  };

  return (
    <Row>
      <Col xs="12">
        <strong>{Texts[window.LANGUAGE_SW].step} 1</strong>
        <div className="p-10">
          <div className="checkbox position-relative form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                checked={checked.step1}
                disabled={checked.step1}
                onChange={() => doStrategyStep(strategySteps[0])}
              />

              {Texts[window.LANGUAGE_SW].strategies.apps.steps.createTemplate}
            </label>
            <ul className="m-t-10">
              <li>
                {Texts[window.LANGUAGE_SW].strategies.useThisLink}:
                <a
                  href="https://help.leadsuite.eu/article/2-7a-leadsuite-vorlagen-erstellen-und-verwenden/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://help.leadsuite.eu/article/2-7a-leadsuite-vorlagen-erstellen-und-verwenden
                </a>
              </li>
              {Object.keys(Texts[window.LANGUAGE_SW].strategies.apps.steps.createTemplateHints).map(
                (key) => (
                  <li key={key}>
                    {Texts[window.LANGUAGE_SW].strategies.apps.steps.createTemplateHints[key]}
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </Col>
      <Col xs="12" className={!checked.step1 ? 'text-muted' : ''}>
        <strong>{Texts[window.LANGUAGE_SW].step} 2</strong>
        <div className="p-10">
          <div className="checkbox position-relative form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                checked={checked.step2}
                disabled={!checked.step1 || checked.step2}
                onChange={() => doStrategyStep(strategySteps[1])}
              />
              {Texts[window.LANGUAGE_SW].strategies.apps.steps.bestCustomers}
            </label>
          </div>
        </div>
      </Col>
      <Col xs="12" className={!checked.step2 ? 'text-muted' : ''}>
        <strong>{Texts[window.LANGUAGE_SW].step} 3</strong>
        <div className="p-10">
          <div className="checkbox position-relative form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                checked={checked.step3}
                disabled={!checked.step2 || checked.step3}
                onChange={() => doStrategyStep(strategySteps[2])}
              />
              {Texts[window.LANGUAGE_SW].strategies.apps.steps.bestImages}
            </label>
          </div>
        </div>
      </Col>
      <Col xs="12" className={!checked.step3 ? 'text-muted' : ''}>
        <strong>{Texts[window.LANGUAGE_SW].step} 4</strong>
        <div className="p-10">
          <div className="checkbox position-relative form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                checked={checked.step4}
                disabled={!checked.step3 || checked.step4}
                onChange={() => doStrategyStep(strategySteps[3])}
              />
              {Texts[window.LANGUAGE_SW].strategies.apps.steps.createGallerySuite}
            </label>
          </div>
        </div>
      </Col>
      <Col xs="12" className={!checked.step4 ? 'text-muted' : ''}>
        <strong>{Texts[window.LANGUAGE_SW].step} 5</strong>
        <div className="p-10">
          <div className="checkbox position-relative form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                checked={checked.step5}
                disabled={!checked.step4 || checked.step5}
                onChange={() => doStrategyStep(strategySteps[4])}
              />
              {Texts[window.LANGUAGE_SW].strategies.apps.steps.sendLink}
            </label>
          </div>
        </div>
      </Col>
      <Col xs="12" className={!checked.step5 ? 'text-muted' : ''}>
        <strong>{Texts[window.LANGUAGE_SW].step} 6</strong>
        <div className="p-10">
          <div className="checkbox position-relative form-check">
            <label className="form-check-label">
              <input
                type="checkbox"
                className="form-check-input"
                checked={checked.step6}
                disabled={!checked.step5 || checked.step6}
                onChange={() => doStrategyStep(strategySteps[5])}
              />
              {Texts[window.LANGUAGE_SW].strategies.apps.steps.results}
            </label>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AppStrategy;
