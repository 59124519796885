import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import Api from '../../../modules/utils/API';
import {
  errorsGlobalError,
  fetchTemplatesFailed,
  fetchTemplatesSuccess,
  fetchTemplatesFontsSuccess
} from '../../../modules/actions';
import ApiErrors from '../../../modules/utils/API/APIErrors';

export const fetchTemplatesSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Templates.get);
    ApiErrors.checkOnApiError(response);
    yield put(fetchTemplatesSuccess(response));
  } catch (e) {
    yield put(fetchTemplatesFailed());
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchTemplatesFontsSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Templates.getTempalteFonts);
    ApiErrors.checkOnApiError(response);
    yield put(fetchTemplatesFontsSuccess(response.result.fonts));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};
