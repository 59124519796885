import React from 'react';
import styled from 'styled-components';
import { getTranslationKey } from '../../modules/utils/helpers';

const ReloadText = styled.a`
  margin-top: 150px;
  display: block;
`;

const LoadingSpinner = () => {
  const reload = (e: React.SyntheticEvent) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <div className="text-center">
      <div className="loader" />
      <ReloadText href="#" onClick={reload}>
        {getTranslationKey('clickIfLoadingTakesLong')}
      </ReloadText>
    </div>
  );
};

export default LoadingSpinner;
