import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import Api from '../../../modules/utils/API';
import {
  errorsGlobalError,
  fetchDomainsFailed,
  fetchDomainsSuccess
} from '../../../modules/actions';
import ApiErrors from '../../../modules/utils/API/APIErrors';

export const fetchDomainsSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Domains.get);
    ApiErrors.checkOnApiError(response);
    yield put(fetchDomainsSuccess(response));
  } catch (e) {
    yield put(fetchDomainsFailed());
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};
