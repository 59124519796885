import { IPortfolioReducerState } from '../reducers/portfolios';
import { IStoreState } from '../types';
import { createSelector } from 'reselect';

export const getPortfolios = (state: IStoreState): IPortfolioReducerState => state.portfolios;

export const getPortfolioItems = createSelector(
  [getPortfolios],
  (portfolio) => portfolio.portfolios
);
export const getSelectedPortfolio = createSelector(
  [getPortfolios],
  (portfolio) => portfolio.selected
);
export const getPaginationState = createSelector(
  [getPortfolios],
  (portfolio) => portfolio.paginationState
);
