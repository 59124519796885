export default function (state = {}, action) {
  switch (action.type) {
    case 'FETCH_COLLECTION_SELECTIONS':
      return {
        ...state,
        [action.payload.collection_id]: action.payload.selections
      };

    case 'ADD_COLLECTION_SELECTION':
      return {
        ...state,
        [action.payload._collection]: [action.payload, ...state[action.payload._collection]]
      };

    case 'REMOVE_COLLECTION_SELECTION':
      if (state[action.payload.collection_id] && state[action.payload.collection_id].length) {
        return {
          ...state,
          [action.payload.collection_id]: state[action.payload.collection_id].filter(
            (s) => s._id !== action.payload.selection._id
          )
        };
      }

      return state;

    case 'UPDATE_COLLECTION_SELECTION':
      const { selection, key, value } = action.payload;

      return {
        ...state,
        [selection._collection]: state[selection._collection].map((s) => {
          if (s._id === selection._id) {
            return { ...s, [key]: value || selection[key] };
          }

          return s;
        })
      };

    case 'REMOVE_IMAGES_FROM_SELECTIONS':
      const { collection_id, gallery_id, image_id, image_ids } = action.payload;

      return {
        ...state,
        [collection_id]: state[collection_id].map((s) => {
          s.favorites = s.favorites.filter((fav) => {
            if (fav._image) {
              if (image_id) {
                return fav._image._id !== image_id;
              }
              if (image_ids) {
                return !image_ids.includes(fav._image._id);
              }
              if (gallery_id) {
                return fav._image.gallery_id !== gallery_id;
              }
            }

            return fav;
          });

          return s;
        })
      };

    default:
      return state;
  }
}
