import React, { useEffect, useState } from 'react';
import {
  Col,
  ListGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  ListGroupItem
} from 'reactstrap';
import { getTranslationKey } from '../../modules/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  getEmailTemplatesSelector,
  getSelectedEmailTemplateSelector
} from '../../modules/selectors';
import styled from 'styled-components';
import { partial, isEqual } from 'lodash';
import { InputItem } from '../Forms/InputItem';
import { IEmailTemplate } from '../../modules/types/emails';
import {
  createEmailTemplateAction,
  deleteEmailTemplateAction,
  fetchEmailTemplatesAction,
  updateEmailTemplateAction
} from '../../modules/actions/email_templates';
import { DEFAULT_EMAIL_TEMPLATE } from '../../modules/constants/email_templates';
import theme from '../../assets/css/theme';
import { FaRegTrashAlt } from 'react-icons/fa';

const SaveButton = styled.button`
  margin-left: auto;
`;
const TemplatesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .list-group-item-action:hover,
  .list-group-item-action:focus {
    color: #fff;
    background-color: ${theme.commonColors.second};
  }
`;
const CreateButton = styled.button`
  margin-top: 10px;
`;
const InlineInputs = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  & > div {
    width: 49%;
  }
`;
const FormWrapper = styled(Col)`
  textarea {
    flex: 1;
  }
`;

const EmailListItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EmailTemplateNameWrapper = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 90%;
`;

const DeleteEmailTemplateButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  transition: background 0.3s ease-in-out;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

interface IProps {
  isOpen: boolean;
  closeCallback: () => void;
}

// tslint:disable-next-line:max-func-body-length
export const ManageEmailTemplatesModal: React.FC<IProps> = ({ isOpen, closeCallback }) => {
  const dispatch = useDispatch();
  const emailTemplatesListStore = useSelector(getEmailTemplatesSelector);
  const selectedEmailTemplate = useSelector(getSelectedEmailTemplateSelector);
  const [selectedTemplate, setSelectedTemplate] = useState<IEmailTemplate>(DEFAULT_EMAIL_TEMPLATE);
  const [emailTemplatesList, setEmailTemplatesList] = useState<IEmailTemplate[]>([]);
  const isCreateDisable =
    !selectedTemplate.name || !selectedTemplate?.html || !selectedTemplate.subject;

  useEffect(() => {
    dispatch(fetchEmailTemplatesAction({}));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (selectedEmailTemplate !== selectedTemplate) {
      setSelectedTemplate(selectedEmailTemplate);
    }
  }, [selectedEmailTemplate]); // eslint-disable-line

  useEffect(() => {
    setEmailTemplatesList(emailTemplatesListStore);
    if (emailTemplatesListStore.length > 0) {
      setSelectedTemplate(emailTemplatesListStore[0]);
    } else {
      setSelectedTemplate(DEFAULT_EMAIL_TEMPLATE);
    }
  }, [emailTemplatesListStore]); // eslint-disable-line

  const changeTemplate = (field: string, value: string) => {
    const newTemplateData = {
      ...selectedTemplate,
      [field]: value
    };
    setSelectedTemplate(newTemplateData);
    setEmailTemplatesList(
      emailTemplatesList.map((temp) => (temp.id === selectedTemplate.id ? newTemplateData : temp))
    );
  };
  const setTextHandler = (e: React.SyntheticEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget;
    changeTemplate('html', value.replace(/(?:\r\n|\r|\n)/g, '<br>'));
  };
  const onCloseCleanUp = () => {
    closeCallback();
    setSelectedTemplate(DEFAULT_EMAIL_TEMPLATE);
    setEmailTemplatesList(emailTemplatesListStore);
  };
  const saveTemplateHandler = () => {
    if (selectedTemplate.id) {
      dispatch(updateEmailTemplateAction(selectedTemplate));
    } else {
      dispatch(createEmailTemplateAction(selectedTemplate));
    }

    closeCallback();
  };

  const getDeleteTemplateHandler = (templateId: string) => (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!window.confirm(getTranslationKey('collectionSettings.sendEmail.deleteQuestion'))) return;
    if (templateId) {
      dispatch(deleteEmailTemplateAction(templateId));
    }
  };

  const createTemplateHandler = () => {
    const emailTemplates = emailTemplatesList.filter((template) => template.id);
    const newTemplate = {
      ...DEFAULT_EMAIL_TEMPLATE,
      name: getTranslationKey('collectionSettings.sendEmail.defaultTemplateName')
    };
    emailTemplates.push(newTemplate);
    setEmailTemplatesList(emailTemplates);
    setSelectedTemplate(newTemplate);
  };

  return (
    <Modal isOpen={isOpen} toggle={onCloseCleanUp} wrapClassName="modal-dark" size="lg" centered>
      <ModalHeader toggle={onCloseCleanUp}>
        {getTranslationKey('collectionSettings.sendEmail.manageTemplateTitle')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={4}>
            <TemplatesWrapper>
              <ListGroup>
                {emailTemplatesList.map((item) => (
                  <ListGroupItem
                    key={item.id}
                    active={isEqual(item.id, selectedTemplate.id)}
                    tag="button"
                    action
                    onClick={partial(setSelectedTemplate, item)}
                  >
                    <EmailListItemWrapper>
                      <EmailTemplateNameWrapper>{item.name}</EmailTemplateNameWrapper>
                      <DeleteEmailTemplateButtonWrapper onClick={getDeleteTemplateHandler(item.id)}>
                        <FaRegTrashAlt />
                      </DeleteEmailTemplateButtonWrapper>
                    </EmailListItemWrapper>
                  </ListGroupItem>
                ))}
              </ListGroup>
              <CreateButton className="btn btn-success" onClick={createTemplateHandler}>
                {getTranslationKey('collectionSettings.sendEmail.createTempButton')}
              </CreateButton>
            </TemplatesWrapper>
          </Col>
          <FormWrapper lg={8}>
            <InlineInputs>
              <InputItem
                label={getTranslationKey('collectionSettings.sendEmail.manageTempTitleLabel')}
                value={selectedTemplate.name}
                changeCallback={partial(changeTemplate, 'name')}
              />
              <InputItem
                label={getTranslationKey('collectionSettings.sendEmail.manageTempSubjLabel')}
                value={selectedTemplate.subject}
                changeCallback={partial(changeTemplate, 'subject')}
              />
            </InlineInputs>
            <textarea
              style={{ height: '250px' }}
              value={selectedTemplate?.html?.replace(/<br\s*[\/]?>/gi, '\n')}
              className="form-control"
              placeholder={getTranslationKey(
                'collectionSettings.sendEmail.manageTempTextPlaceholder'
              )}
              onChange={setTextHandler}
            />
          </FormWrapper>
        </Row>
      </ModalBody>
      <ModalFooter>
        <SaveButton
          className="btn btn-success"
          onClick={saveTemplateHandler}
          disabled={isCreateDisable}
        >
          {getTranslationKey('collectionSettings.sendEmail.saveTempButton')}
        </SaveButton>
      </ModalFooter>
    </Modal>
  );
};
