import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUser } from '../../modules/selectors';
import { getCookie, setCookie } from '../../helpers/cookies';

export const NotAuthOnlyRoute = (props: RouteProps) => {
  const { authenticated } = useSelector(getUser);

  // @ts-ignore
  const authenticatedUrlTarget = authenticated && getCookie('authenticatedUrlTarget');
  if (authenticatedUrlTarget) {
    // @ts-ignore
    setCookie('authenticatedUrlTarget', '', 1);
  }

  return authenticated ? (
    <Redirect to={authenticatedUrlTarget || '/dashboard'} />
  ) : (
    <Route {...props} />
  );
};
