import axios from '../helpers/axios';
import * as errors from '../errors';
import Texts from '../json/texts.json';
import axiosWithoutCredentials from 'axios';
import ImageCompressor from '@xkeshi/image-compressor';
import { accessRulesAction, fetchPresetsAction } from '../modules/actions';
import { changeModalState } from '../modules/actions/modals';

export const updateWatermarkValue = (payload) => (dispatch) =>
  dispatch({
    type: 'UPDATE_WATERMARK_VALUE',
    payload
  });

export const getWatermarks = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/watermarks');
    dispatch({ type: 'SET_WATERMARKS', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const createWatermark = (watermark, notify) => async (dispatch) => {
  try {
    const res = await axios.put('/api/watermark/', { watermark });
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyEdited,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'ADD_WATERMARK', payload: res.data });
    dispatch(accessRulesAction());

    return res.data;
  } catch (e) {
    dispatch(
      changeModalState({ key: 'upgradeModal', state: true, desiredAction: 'customWatermark' })
    );
  }
};

export const updateWatermark = (watermark, notify) => async () => {
  try {
    await axios.post('/api/watermark/', { watermark });
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyEdited,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });

    return true;
  } catch (e) {
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });

    return false;
  }
};

export const deleteWatermark = (watermark_id, collections, notify) => async (dispatch) => {
  // eslint-disable-line
  try {
    if (window.confirm(Texts[window.LANGUAGE_SW].watermark.rlyDelete)) {
      await axios.delete('/api/watermark/', { params: { watermark_id } });
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].successfullyDeleted,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 3000
      });
      dispatch({ type: 'REMOVE_WATERMARK', payload: watermark_id });
      dispatch(accessRulesAction());
      dispatch(fetchPresetsAction());

      return true;
    }

    return false;
  } catch (e) {
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });

    return false;
  }
};

export const uploadWatermarkImage = (watermark_id, file, notify) => async (dispatch) => {
  try {
    const res = await axios.get('/api/upload/watermarkImage', {
      params: { watermark_id, objectName: file.name }
    });
    const imageUploadRes = res.data;
    const imageCompressor = new ImageCompressor();
    const imageFile = await imageCompressor.compress(file, {
      quality: 0.7,
      maxWidth: 1500,
      maxHeight: 1500
    });

    await axiosWithoutCredentials.put(imageUploadRes.signedUrl, imageFile);

    const checkIfWatermarkIsOnS3 = async function (tryToFind = 0) {
      if (tryToFind === 10) {
        return notify({
          title: Texts[window.LANGUAGE_SW].error,
          message: Texts[window.LANGUAGE_SW].generalError,
          status: 'error',
          position: 'tc',
          dismissible: true,
          dismissAfter: 15000
        });
      }

      try {
        await axios.get('/api/image/checkIfExistsOnS3/', {
          params: { image_id: imageUploadRes.imageId }
        });
        dispatch({
          type: 'UPDATE_WATERMARK_VALUE',
          payload: {
            watermark_id,
            key: 'watermarkPublicKey',
            value: res.data.watermarkPublicKey
          }
        });

        notify({
          title: Texts[window.LANGUAGE_SW].success,
          message: Texts[window.LANGUAGE_SW].successfullyUploaded,
          status: 'success',
          position: 'tc',
          dismissible: true,
          dismissAfter: 3000
        });
      } catch (error) {
        window.setTimeout(() => checkIfWatermarkIsOnS3(tryToFind + 1), 500);
      }
    };

    window.setTimeout(checkIfWatermarkIsOnS3, 750);
  } catch (e) {
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 15000
    });
  }
};
