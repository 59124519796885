import { ITrack, FetchSlideshowMusic } from './../../types/music';
import { put, call, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getMusic } from '../../../modules/selectors';
import Api from '../../../modules/utils/API';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import {
  errorsGlobalError,
  updateFavoritesSuccess,
  fetchMusicSuccess,
  changeLoadingStatus,
  changeIsLoadingMoreStatus,
  fetchSlideshowMusicSuccess,
  changeLoadingSlideShowMusic,
  changeisFetchingSlideshowSoundtracks
} from '../../../modules/actions';
import qs from 'qs';
import { ILoadMoreAction, ISagaAction } from '../../../modules/types';

const prepareFilteredDataForRequest = (data: any) => {
  const updatedData = JSON.parse(JSON.stringify(data));
  const filterKeys = Object.keys(updatedData.filter);

  filterKeys.forEach((filterKey) => {
    if (updatedData.filter[filterKey].length) {
      if (filterKey !== 'duration') {
        updatedData.filter[filterKey] = updatedData.filter[filterKey].join(',');
      } else {
        const value = { ...updatedData.filter[filterKey] };
        updatedData.filter[filterKey] = value[0];
      }
    } else {
      delete updatedData.filter[filterKey];
    }
  });

  return updatedData;
};

export const fetchMusicSaga = function* (): SagaIterator {
  try {
    const res = yield call(Api.Music.get, {}, 'songs');
    ApiErrors.checkOnApiError(res);

    yield put(
      fetchMusicSuccess({
        data: res.result.data,
        totalAmount: res.result.meta.totalAmount
      })
    );

    yield put(changeLoadingSlideShowMusic(false));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchSlideshowMusicSaga = function* ({
  payload
}: ISagaAction<FetchSlideshowMusic>): SagaIterator {
  try {
    yield put(changeisFetchingSlideshowSoundtracks(true));
    const res = yield call(
      Api.Music.get,
      {},
      `songs?${qs.stringify({
        songsIds: payload.songsIds
      })}`
    );
    ApiErrors.checkOnApiError(res);

    yield put(
      fetchSlideshowMusicSuccess({
        data: res.result.data,
        slideshowId: payload.slideshowId
      })
    );

    yield put(changeisFetchingSlideshowSoundtracks(false));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const filterMusicSaga = function* (): SagaIterator {
  try {
    yield put(changeLoadingStatus(true));

    const { filteredData } = yield select(getMusic);
    const updatedData = prepareFilteredDataForRequest(filteredData);

    const res = yield call(Api.Music.get, {}, `songs?${qs.stringify(updatedData)}`);
    ApiErrors.checkOnApiError(res);

    yield put(
      fetchMusicSuccess({
        data: res.result.data,
        totalAmount: res.result.meta.totalAmount
      })
    );
    yield put(changeLoadingStatus(false));
    yield put(changeLoadingSlideShowMusic(false));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const fetchFavoriteMusicSaga = function* (): SagaIterator {
  try {
    const res = yield call(Api.Music.get, {}, 'songs/favorites');
    ApiErrors.checkOnApiError(res);

    yield put(updateFavoritesSuccess({ favorites: res.result }));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const addMusicToFavoritesSaga = function* ({
  payload
}: {
  payload: { song: ITrack };
}): SagaIterator {
  try {
    const { song } = payload;

    const res = yield call(Api.Music.post, { songId: song.id });
    ApiErrors.checkOnApiError(res);
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const removeFavoriteMusicSaga = function* ({ payload }: any): SagaIterator {
  try {
    const res = yield call(Api.Music.delete, { songId: payload });
    ApiErrors.checkOnApiError(res);
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const loadMoreSaga = function* ({ payload }: { payload: ILoadMoreAction }): SagaIterator {
  try {
    yield put(changeIsLoadingMoreStatus(true));
    const { filteredData } = yield select(getMusic);
    const updatedData = {
      ...prepareFilteredDataForRequest(filteredData),
      ...payload
    };

    const res = yield call(Api.Music.get, {}, `songs?${qs.stringify(updatedData)}`);
    ApiErrors.checkOnApiError(res);

    yield put(
      fetchMusicSuccess({
        data: res.result.data,
        totalAmount: res.result.meta.totalAmount
      })
    );
    yield put(changeIsLoadingMoreStatus(false));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};
