import React from 'react';
import Texts from '../../json/texts.json';
import { Col, Row } from 'reactstrap';
import { Watermarks } from '../../elements/Brand/Watermark';
import { Brands } from '../../elements/Brand/Brands';
import HelpLink from '../../elements/Help/HelpLink';
import { connect } from 'react-redux';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/Card';

const WatermarkAndBrand = ({ type }: { type: string }) => (
  <div className="animated fadeIn">
    <Row>
      <Col sm="12" md="12" lg="6">
        <Card style={{ height: '100%' }}>
          <CardHeader>
            {/* @ts-ignore */}
            <CardTitle>{Texts[window.LANGUAGE_SW].userBrand.myBrands}</CardTitle>
            {type === 'SB' && <HelpLink href="https://support.scrappbook.de/de/articles/61592" />}
          </CardHeader>
          <CardBody>
            <Brands />
          </CardBody>
        </Card>
      </Col>
      <Col sm="12" md="12" lg="6">
        <Card style={{ height: '100%' }}>
          <CardHeader>
            {/* @ts-ignore */}
            <CardTitle>{Texts[window.LANGUAGE_SW].watermark.titleCard}</CardTitle>
            {type === 'SB' && (
              <HelpLink href="https://support.scrappbook.de/de/collections/11559" />
            )}
          </CardHeader>
          <CardBody>
            <Watermarks />
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

function mapStateToProps({ auth: { type } }: { auth: { type: string } }) {
  return { type };
}

export default connect(mapStateToProps, null)(WatermarkAndBrand);
