import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Row } from 'reactstrap';
import Texts from '../../../json/texts.json';
import { connect } from 'react-redux';
import InputGroup from '../../../elements/Forms/InputGroup';
import * as actions from '../../../actions';
import { Field, reduxForm } from 'redux-form';
import approve from 'approvejs';

const fields = {
  password: {
    type: 'password',
    title: Texts[window.LANGUAGE_SW].newPassword,
    placeholder: Texts[window.LANGUAGE_SW].newPassword,
    rules: {
      required: { message: Texts[window.LANGUAGE_SW].requiredInput }
    }
  },
  passwordConfirmation: {
    type: 'password',
    placeholder: Texts[window.LANGUAGE_SW].passwordConfirm,
    title: Texts[window.LANGUAGE_SW].passwordConfirm,
    rules: {
      required: { message: Texts[window.LANGUAGE_SW].requiredInput },
      equal: {
        value: '',
        field: 'password',
        message: Texts[window.LANGUAGE_SW].passwordConfirmError
      }
    }
  }
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    this.props.changeCurrentUserPassword(values.password, this.props.notify);
  }

  renderUserMessage() {
    switch (this.props.match.params.type) {
      case 'forgot':
        return Texts[window.LANGUAGE_SW].forgotPasswordMessage;
      case 'change':
        return Texts[window.LANGUAGE_SW].changePasswordMessage;
      case 'onboarding':
        return Texts[window.LANGUAGE_SW].onboardingPasswordMessage;
      default:
        return Texts[window.LANGUAGE_SW].changePasswordMessage;
    }
  }

  render() {
    return (
      <div
        className="app flex-row align-items-center"
        style={{
          backgroundColor: 'gray',
          backgroundPosition: 'bottom'
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="10">
              <CardGroup className="primary-highlight-shadow">
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.props.handleSubmit(this.submit)}>
                      <h1> {Texts[window.LANGUAGE_SW].changePassword}</h1>
                      <p className="text-muted">{this.renderUserMessage()}</p>
                      {Object.keys(fields).map((fieldKey, index) => (
                        <Field
                          component={InputGroup}
                          key={index}
                          name={fieldKey}
                          field={fields[fieldKey]}
                        />
                      ))}
                      <Row>
                        <Col xs="12">
                          <Button
                            color="primary"
                            className="px-4 w-100-p"
                            disabled={
                              this.props.pristine || this.props.invalid || this.props.submitting
                            }
                            type="submit"
                          >
                            {Texts[window.LANGUAGE_SW].changePassword}
                          </Button>
                        </Col>
                        <Col xs="12">
                          {this.props.match.params.type === 'change' && (
                            <Button href="/#/account" color="link" className="px-0">
                              {Texts[window.LANGUAGE_SW].back}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  fields.passwordConfirmation.rules.equal.value = values.password;
  console.log(values);
  Object.keys(fields).forEach((fieldKey) => {
    const result = approve.value(values[fieldKey], fields[fieldKey].rules);
    if (result.errors.length > 0) {
      errors[fieldKey] = result.errors;
    }
  });

  return errors;
}

ChangePassword = reduxForm({
  form: 'changePasswordForm',
  validate
})(ChangePassword);

ChangePassword = connect(
  (state) => ({
    notify: state.notify
  }),
  actions
)(ChangePassword);

export default ChangePassword;
