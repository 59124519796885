import React from 'react';
import Card, { CardBody } from '../../components/Card';
import { useSelector, connect } from 'react-redux';
import { getTranslationKey } from '../../modules/utils';
import { SelectItem, SwitchItem } from '../../components';
import { getDevelopedTemplates } from '../../modules/selectors/templates';
import { ICollectionsReducerState } from '../../modules/reducers';
import * as actions from '../../actions';
import DesignForm from './Edit/DesignForm';
import { TemplatesList } from './TemplatesList';

const DEFAULT_VALUES = {
  galleryStyle: 'justified',
  disableDecorations: false
};

type Props = {
  formValue: {
    selectedTemplateId: string;
    design: ICollectionsReducerState['design'];
    galleryStyle: string;
  };
  setValue: (value: Partial<Props['formValue']>, isOnlyColor?: boolean) => void;
  submitColor?: (arg: { [key: string]: string }) => void;
  availableRules: any;
  setUpgradeAccountOpenState: (obj: any) => void;
};

let timer: any = null;
export const TemplateConfigurationForm = ({
  formValue,
  setValue,
  submitColor,
  availableRules = {},
  setUpgradeAccountOpenState
}: Props) => {
  const templates = useSelector(getDevelopedTemplates);
  const galleryStyleOptions = [
    {
      value: 'justified',
      text: getTranslationKey('galleryStyleOptions.option1')
    },
    {
      value: 'masonry',
      text: getTranslationKey('galleryStyleOptions.option2')
    }
  ];

  const submit = (data: any) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    timer = setTimeout(() => {
      if (submitColor) {
        submitColor(data);
      }
    }, 1500);
  };

  return (
    <Card className="pt-gallery-preset-design-tab-content">
      <CardBody>
        <SelectItem
          label={getTranslationKey('templatesTable')}
          value={formValue.selectedTemplateId}
          changeCallback={(id) => setValue({ selectedTemplateId: id })}
          options={[
            { value: '', text: '' },
            ...templates
              .filter((template) => template.userIsAllowedToUseTemplate)
              .map((template) => ({ value: template._id, text: template.displayName }))
          ]}
        />
        <TemplatesList
          values={{ selectedTemplateId: formValue.selectedTemplateId }}
          setValue={({ selectedTemplateId }) => setValue({ selectedTemplateId })}
        />
        <DesignForm
          templateId={formValue.selectedTemplateId}
          design={{
            disableDecorations: formValue.design.disableDecorations,
            loadFonts: formValue.design.loadFonts,
            imageSpacing: formValue.design.imageSpacing,
            variables: {
              ...formValue.design.variables
            }
          }}
          setDesign={(design) => {
            if (design.loadFonts || (design.variables && design.variables.font !== undefined)) {
              const variables = design.variables || {};
              const { font } = variables;
              setValue({
                design: {
                  ...formValue.design,
                  loadFonts: design.loadFonts || [],
                  variables: {
                    ...formValue.design.variables,
                    font,
                    headingFont: font
                  }
                }
              });
            }

            if (design.imageSpacing !== undefined) {
              setValue({
                design: {
                  ...formValue.design,
                  imageSpacing: design.imageSpacing
                }
              });
            }

            if (design.variables && design.variables.primaryColor !== undefined) {
              setValue(
                {
                  design: {
                    ...formValue.design,
                    variables: {
                      ...formValue.design.variables,
                      primaryColor: design.variables.primaryColor
                    }
                  }
                },
                true
              );
            }

            if (design.variables && design.variables.secondaryColor !== undefined) {
              setValue(
                {
                  design: {
                    ...formValue.design,
                    variables: {
                      ...formValue.design.variables,
                      secondaryColor: design.variables.secondaryColor
                    }
                  }
                },
                true
              );
            }
          }}
          submitColor={({ primaryColor, secondaryColor }) => {
            if (typeof primaryColor === 'string') {
              setValue(
                {
                  design: {
                    ...formValue.design,
                    variables: {
                      ...formValue.design.variables,
                      primaryColor
                    }
                  }
                },
                true
              );
              submit({ primaryColor });
            }

            if (typeof secondaryColor === 'string') {
              setValue(
                {
                  design: {
                    ...formValue.design,
                    variables: {
                      ...formValue.design.variables,
                      secondaryColor
                    }
                  }
                },
                true
              );
              submit({ secondaryColor });
            }
          }}
        />
        <div className="mt-3">
          <SelectItem
            label={getTranslationKey('galleryStyleOptions.label')}
            value={formValue.galleryStyle}
            changeCallback={(value) => {
              if (!availableRules.canCustomimzeDesign && value !== DEFAULT_VALUES.galleryStyle) {
                return setUpgradeAccountOpenState({
                  key: 'upgradeModal',
                  state: true,
                  desiredAction: 'customDesign'
                });
              }

              setValue({ galleryStyle: value });
            }}
            options={galleryStyleOptions}
          />
        </div>
        {formValue.selectedTemplateId !== 'ScrAppBook_dark' && (
          <>
            <div className="mt-3">
              <SwitchItem
                value={!formValue.design.disableDecorations}
                changeCallback={(value) => {
                  if (
                    !availableRules.canCustomimzeDesign &&
                    value !== !DEFAULT_VALUES.disableDecorations
                  ) {
                    return setUpgradeAccountOpenState({
                      key: 'upgradeModal',
                      state: true,
                      desiredAction: 'customDesign',
                      modalData: { addon: 'template' }
                    });
                  }

                  setValue({ design: { ...formValue.design, disableDecorations: !value } });
                }}
                label={getTranslationKey('collectionDesign.showDecorativeElements')}
                direction="row-reverse"
                id="show-decorative-elements"
                withTooltip
                tooltipText={getTranslationKey('collectionDesign.decorativeElementsTooltipText')}
                isIconTooltip
              />
            </div>
            <div className="mt-3">
              <SwitchItem
                value={formValue.design.showTrademark}
                changeCallback={(value) => {
                  setValue({ design: { ...formValue.design, showTrademark: value } });
                }}
                label={getTranslationKey('collectionDesign.photographerMention')}
                direction="row-reverse"
                id="show-photographer-mention"
                withTooltip
                tooltipText={getTranslationKey('collectionDesign.photographerMentionTooltip')}
                isIconTooltip
              />
            </div>
          </>
        )}
      </CardBody>
    </Card>
  );
};

function mapStateToProps({ accessRules: { availableRules } }: { accessRules: any }) {
  return { availableRules };
}

export default connect(mapStateToProps, actions)(TemplateConfigurationForm);
