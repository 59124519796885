import React, { useEffect } from 'react';
import { Alert } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTemplates,
  putPaymentInfoAction,
  updateSubscriptionStore
} from '../../modules/actions';
import { getUser } from '../../modules/selectors';
import { useIsPaymentProblem } from '../../common/use-is-payment-problem';
import { getPaymentInfo } from '../../modules/selectors/paymentInfo';
import { useHistory, useLocation } from 'react-router';
import { getAccessRules } from '../../modules/selectors/accessRules';
import { getTemplatesList } from '../../modules/selectors/templates';
import { useSubscriptionBreakdown } from '../../pages/subscription/helpers/useSubscriptionBreakdown';

const StripeMigrationAlert = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { subscription } = useSelector(getUser);
  const { paymentProvider, subscriptions } = useSelector(getPaymentInfo);
  const { isPaymentProblem } = useIsPaymentProblem();
  const { planConfig } = useSelector(getAccessRules);
  const templates = useSelector(getTemplatesList);
  const breakdown = useSubscriptionBreakdown();

  const userCanMigrateToStripeWithCurrentDigistoreBalance =
    breakdown.remainingDigistoreBalance === 0;

  const coreSub = subscriptions.find((sub) => sub.subscriptionType === 'core');
  const daysUntilNextPayment = Math.floor(
    moment.duration(moment(coreSub?.nextPaymentAt, moment.ISO_8601).diff(new Date())).asDays()
  );

  const shouldMigrate =
    paymentProvider !== 'stripe' &&
    !!coreSub &&
    !isPaymentProblem &&
    (subscription.plan === 'starter' || subscription.plan === 'professional') &&
    daysUntilNextPayment >= 0 &&
    daysUntilNextPayment <= 14 &&
    !coreSub.isCanceled &&
    userCanMigrateToStripeWithCurrentDigistoreBalance;

  const isOpen = !location.pathname.startsWith('/subscription/pricing') && shouldMigrate;

  const setSubscription = () => {
    const subscriptionConfig: any = {
      plan: subscription.plan,
      billingCycle: subscription.intervall,
      videoStorage: subscription.videoPlan === 'free' ? '' : subscription.videoPlan,
      slideshows: subscription.slideshowsPlan === 'unlimited',
      templates: [],
      brandsAmount: planConfig.brands,
      domainsAmount: planConfig.domains,
      purchased_templates: []
    };

    localStorage.setItem('subscriptionConfig', JSON.stringify(subscriptionConfig));

    dispatch(updateSubscriptionStore(subscriptionConfig));
  };

  const redirectUserAndSetSubscription = () => {
    setSubscription();
    dispatch(putPaymentInfoAction({ showStripeMigrationNote: true }));
    history.push('/subscription/pricing/payment');
  };

  useEffect(() => {
    if (isOpen && daysUntilNextPayment <= 4 && !location.pathname.startsWith('/subscription')) {
      redirectUserAndSetSubscription();
    }
  }, [daysUntilNextPayment, location.pathname]);

  useEffect(() => {
    if (shouldMigrate && daysUntilNextPayment <= 4) {
      setSubscription();
    }
  }, [planConfig.brands, planConfig.domains, subscription.plan]);

  useEffect(() => {
    if (!templates.length) {
      dispatch(fetchTemplates());
    }
  }, [templates]);

  return (
    <Alert
      id="stripe-migration-alert"
      className="cursor-pointer mb-0"
      isOpen={isOpen}
      dismissible={false}
      onClick={redirectUserAndSetSubscription}
      color="warning"
    >
      <div>{getTranslationKey('subscription.stripe-migration-alert')}</div>
    </Alert>
  );
};

export default StripeMigrationAlert;
