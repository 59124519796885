import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLaboratoriesSelector, getProductPrisingListSelector } from '../../modules/selectors';
import { partial } from 'lodash';
import { SwitchItem } from '../Forms/SwitchItem';
import { SelectItem } from '../Forms/SelectItem';
import { getTranslationKey } from '../../modules/utils';
import { fetchLaboratoriesAction, fetchProductPricingListAction } from '../../modules/actions';
import getPricingSelectOptions from '../../common/get-pricing-select-options';

interface IProps {
  isShopActive: boolean;
  _productPricingList?: string;
  changeCallBack: (path: string, value: string | number | boolean) => void;
  hidePricingList?: boolean;
  showOnlyPurchasedPrints: boolean;
}

export const ShopSettingsToggle: React.FC<IProps> = ({
  isShopActive,
  changeCallBack,
  _productPricingList,
  hidePricingList = false,
  showOnlyPurchasedPrints
}) => {
  const dispatch = useDispatch();
  const laboratories = useSelector(getLaboratoriesSelector);
  const pricingList = useSelector(getProductPrisingListSelector);
  const selectOptions = getPricingSelectOptions(pricingList, laboratories);

  useEffect(() => {
    dispatch(fetchLaboratoriesAction());

    if (!pricingList.length) {
      dispatch(fetchProductPricingListAction());
    }
  }, []); // eslint-disable-line

  return (
    <div>
      <SwitchItem
        id="shopActive"
        value={isShopActive}
        changeCallback={partial(changeCallBack, 'shopActive')}
        label={getTranslationKey('collectionShop.toggleLabel')}
        direction="row-reverse"
        margin="15px 0 20px 0"
      />

      {isShopActive && !hidePricingList && (
        <SelectItem
          label={getTranslationKey('shop.selectPricingList')}
          isDefaultDisabled
          defaultOptionsText={getTranslationKey('shop.priceSelectLabel')}
          value={_productPricingList || ''}
          changeCallback={partial(changeCallBack, '_productPricingList')}
          options={selectOptions}
        />
      )}
      {!hidePricingList && (
        <SwitchItem
          id="showOnlyPurchasedPrints"
          value={showOnlyPurchasedPrints}
          changeCallback={partial(changeCallBack, 'showOnlyPurchasedPrints')}
          label={getTranslationKey('shop.showOnlyPurchasedPrints')}
          direction="row-reverse"
          margin="15px 0 20px 0"
        />
      )}
    </div>
  );
};
