import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import approve from 'approvejs';
import { useLocation } from 'react-router-dom';

import Card, { CardBody } from '../../../components/Card';
import { getTranslationKey } from '../../../modules/utils';
import { getCountriesList } from '../../../modules/selectors/countries';
import { fetchCountriesAction } from '../../../modules/actions/countries';
import { ICountry, IDictionary } from '../../../modules/types';
import {
  fetchPaymentInfoAction,
  showNotifyFail,
  updateBillingAddress,
  updateUserTaxAction
} from '../../../modules/actions';
import { getPaymentInfo } from '../../../modules/selectors/paymentInfo';
import { getUser } from '../../../modules/selectors';
import { IAdressInterface } from '../../../modules/types/paymentInfo';
import InputGroup from '../../../elements/Forms/InputGroup';
import Select from '../../../elements/Forms/Select';
import { InputItem } from '../../../components';
import { validations } from '../../../modules/utils/helpers/validations';
import { isEqual } from 'lodash';
import { useStateChange } from '../../../hooks/useStateChange';

interface IProps {
  showVatId?: boolean;
  showTitle?: boolean;
  country?: string;
  isSubmit?: boolean;
  setIsSubmit?: (isSubmit: boolean) => void;
  setVatIdHasUnsavedChanges: (vatIdHasUnsavedChanges: boolean) => void;
  setBillingAddressHasUnsavedChanges: (billingAddressHasUnsavedChanges: boolean) => void;
}

interface Ifields {
  field: string;
  type: string;
  placeholder: string;
  title: string;
  rules: any;
  validators?: any[];
}

interface seperateStreetFieldResponse {
  street: string | null;
  streetNumber: string | null;
}

function getValidate(rules: any) {
  return (value: any) => {
    const result = approve.value(value, rules);
    if (result.errors.length > 0) {
      return result.errors;
    }

    return null;
  };
}

function validateZip(zipCode: any, formValues: any) {
  const errors = [];
  const error = validations.onlyNumber(zipCode, { formValues, titleKey: 'zipcode' });
  if (error) {
    errors.push(error);
  }

  const maxLengthZipCodeMap: { [key: string]: number } = { germany: 5, austria: 4, switzerland: 4 };
  const { country } = formValues;
  if (maxLengthZipCodeMap[country] && zipCode.length !== maxLengthZipCodeMap[country]) {
    errors.push(
      getTranslationKey('requiredLength', {
        amount: maxLengthZipCodeMap[country]
      })
    );
  }
  if (errors.length > 0) {
    return errors;
  }

  return null;
}

const getEmailField = () => ({
  field: 'email',
  type: 'email',
  rules: {
    required: { message: getTranslationKey('requiredInput') },
    email: {
      message: getTranslationKey('requiredEMailInput')
    }
  }
});

const getCompanyField = () => ({
  field: 'company',
  type: 'text',
  rules: {
    required: { message: getTranslationKey('requiredInput') }
  }
});

const getCol6Fields = () =>
  [
    {
      field: 'firstName',
      type: 'text'
    },
    {
      field: 'lastName',
      type: 'text'
    },
    {
      field: 'street',
      type: 'text'
    },
    {
      field: 'streetNumber',
      type: 'text'
    },
    {
      field: 'zipcode',
      type: 'text'
    },
    {
      field: 'city',
      type: 'text'
    }
  ].map((field) => ({
    ...field,
    rules: {
      required: { message: getTranslationKey('requiredInput') }
    }
  }));

function useFormFields() {
  const emailFieldTranslated: Ifields = {
    ...getEmailField(),
    placeholder: getTranslationKey('email'),
    title: `${getTranslationKey('email')}*`,
    rules: {
      required: { message: getTranslationKey('requiredInput') },
      email: {
        message: getTranslationKey('requiredEMailInput')
      }
    }
  };
  emailFieldTranslated.validators = [getValidate(emailFieldTranslated.rules)];

  const companyFieldTranslated: Ifields = {
    ...getCompanyField(),
    placeholder: getTranslationKey('company'),
    title: `${getTranslationKey('company')}*`,
    rules: {
      required: { message: getTranslationKey('requiredInput') }
    }
  };
  companyFieldTranslated.validators = [getValidate(companyFieldTranslated.rules)];

  const col6Fields = getCol6Fields();
  const col6FieldsTranslated: Ifields[] = [
    {
      ...col6Fields[0],
      placeholder: getTranslationKey('firstname'),
      title: `${getTranslationKey('firstname')}*`,
      rules: {
        required: { message: getTranslationKey('requiredInput') }
      }
    },
    {
      ...col6Fields[1],
      placeholder: getTranslationKey('lastname'),
      title: `${getTranslationKey('lastname')}*`,
      rules: {
        required: { message: getTranslationKey('requiredInput') }
      }
    },
    {
      ...col6Fields[2],
      placeholder: getTranslationKey('street'),
      title: `${getTranslationKey('street')}*`,
      rules: {
        required: { message: getTranslationKey('requiredInput') }
      }
    },
    {
      ...col6Fields[3],
      placeholder: getTranslationKey('streetNumber'),
      title: `${getTranslationKey('streetNumber')}*`,
      rules: {
        required: { message: getTranslationKey('requiredInput') }
      }
    },
    {
      ...col6Fields[4],
      placeholder: getTranslationKey('zipcode'),
      title: `${getTranslationKey('zipcode')}*`,
      rules: {
        required: { message: getTranslationKey('requiredInput') }
      }
    },
    {
      ...col6Fields[5],
      placeholder: getTranslationKey('city'),
      title: `${getTranslationKey('city')}*`,
      rules: {
        required: { message: getTranslationKey('requiredInput') }
      }
    }
  ];
  col6FieldsTranslated.forEach((field) => {
    field.validators =
      field.field === 'zipcode'
        ? [getValidate(field.rules), validateZip]
        : [getValidate(field.rules)];
  });

  return {
    emailField: emailFieldTranslated,
    companyField: companyFieldTranslated,
    col6Fields: col6FieldsTranslated
  };
}

const getCountriesOptions = (list: ICountry[]) =>
  list.map((country) => ({
    value: country.id,
    displayValue: country.name
  }));

const seperateStreetField = (street: string): seperateStreetFieldResponse => {
  const streetArr = street.match(/(\D+)\s+(\d+)/);

  return {
    street: streetArr && streetArr[1] ? streetArr[1] : street,
    streetNumber: streetArr && streetArr[2] ? streetArr[2] : null
  };
};

const BillingAddressFormUnwrapped: React.FunctionComponent<IProps & InjectedFormProps> = ({
  handleSubmit,
  initialize,
  showVatId,
  country,
  showTitle = true,
  isSubmit,
  setIsSubmit,
  invalid,
  setVatIdHasUnsavedChanges,
  setBillingAddressHasUnsavedChanges
}) => {
  const dispatch = useDispatch();
  const countriesList = useSelector(getCountriesList);
  const countriesOptions = getCountriesOptions(countriesList);
  const [vatId, setVatId] = useState('');
  const userAuth = useSelector(getUser);
  const { emailField, companyField, col6Fields } = useFormFields();
  const location = useLocation();
  const {
    address: billingaddress = {
      city: '',
      company: '',
      country: '',
      email: '',
      firstName: '',
      lastName: '',
      street: '',
      zipcode: '',
      streetNumber: ''
    },
    updatingAddress = false
  }: { address: IAdressInterface; updatingAddress: boolean } = useSelector(getPaymentInfo);

  const countryIsEuOrCHNotGermany =
    (countriesList.find((c) => c.id === country)?.isEU && country !== 'germany') ||
    country === 'switzerland';

  // submit functions
  const submitVatId = (vatId: string) => {
    dispatch(
      updateUserTaxAction({
        isEdit: true,
        isPaymentPage: true,
        form: { ...userAuth.taxSettings, vatId }
      })
    );
  };
  const vatIdTimerRef = useRef(null);
  const updateVatId = (vatId: string, timeout: number = 2000) => {
    if (vatId === userAuth.taxSettings.vatId) {
      setVatIdHasUnsavedChanges(false);
    } else {
      setVatIdHasUnsavedChanges(true);
    }

    setVatId(vatId);
    clearTimeout(vatIdTimerRef.current);

    if (typeof vatId === 'string') {
      if (timeout === 0) {
        submitVatId(vatId);
      } else {
        vatIdTimerRef.current = setTimeout(() => {
          submitVatId(vatId);
        }, timeout);
      }
    }
  };
  useEffect(() => () => clearTimeout(vatIdTimerRef.current), []);

  const getSubmitAddress =
    (updateTax: boolean = true) =>
    (values: IDictionary<string>) => {
      try {
        if (!isEqual(values, billingaddress)) {
          dispatch(
            updateBillingAddress({
              email: values.email,
              country: values.country,
              firstName: values.firstName,
              lastName: values.lastName,
              company: values.company,
              street: values.street,
              streetNumber: values.streetNumber,
              zipcode: values.zipcode,
              city: values.city,
              routerPath: location.pathname,
              justUpdate: true,
              waitForTaxSettingsUpdate: updateTax && showVatId && countryIsEuOrCHNotGermany
            })
          );
        } else {
          setBillingAddressHasUnsavedChanges(false);
        }

        if (
          updateTax &&
          showVatId &&
          countryIsEuOrCHNotGermany &&
          vatId !== userAuth.taxSettings.vatId
        ) {
          updateVatId(vatId, 0);
        } else {
          dispatch({
            type: 'SET_USER_TAX_SETTINGS',
            payload: {
              ...userAuth.taxSettings,
              vatIdIsValid: true
            }
          });
        }
      } catch (e) {
        dispatch(showNotifyFail());
      }
    };
  const submit = (isSubmitFromPaymentElement = false) => {
    if (invalid && !isSubmitFromPaymentElement) return;
    handleSubmit(getSubmitAddress())();
  };
  const submitAddressWithoutTax = getSubmitAddress(false);
  const submitWithoutTax = () => {
    if (invalid) return;
    handleSubmit(submitAddressWithoutTax)();
  };

  // Fetch countries and payment info
  useEffect(() => {
    dispatch(fetchPaymentInfoAction());
  }, []);

  useEffect(() => {
    if (!countriesList.length) {
      dispatch(fetchCountriesAction());
    }
  }, [dispatch, countriesList]);

  // initialize form
  useEffect(() => {
    initialize({
      company: billingaddress.company || userAuth.company,
      email: billingaddress.email || userAuth.email,
      country: billingaddress.country || userAuth._country,
      city: billingaddress.city || userAuth.city,
      firstName: billingaddress.firstName || userAuth.first_name,
      lastName: billingaddress.lastName || userAuth.last_name,
      street: billingaddress.street || seperateStreetField(userAuth.street).street,
      streetNumber:
        billingaddress.streetNumber || seperateStreetField(userAuth.street).streetNumber,
      zipcode: billingaddress.zipcode || userAuth.zipcode
    });
  }, [billingaddress]); // eslint-disable-line

  useEffect(() => {
    if (!vatId && !userAuth.isUpdating) {
      setVatId(userAuth.taxSettings.vatId);
    }
  }, [userAuth]);

  // if the form is invalid we are not ready to submit
  const validateVatId = showVatId && countryIsEuOrCHNotGermany;
  useEffect(() => {
    if (
      (invalid ||
        userAuth.taxSettings.errorMessage ||
        (validateVatId ? userAuth.taxSettings.vatIdIsValid === false : false)) &&
      isSubmit
    ) {
      setIsSubmit(false);
    }
  }, [invalid, userAuth.taxSettings.errorMessage, userAuth.taxSettings.vatIdIsValid, isSubmit]);

  // if a user has clicked on confirm button we should submit the form
  useStateChange(isSubmit, (isSubmitPrev, isSubmit) => {
    if (isSubmitPrev !== isSubmit && isSubmit) {
      submit(true);
    }
  });

  // if the country has changed we should submit the form without tax
  useStateChange(country, (countryPrev, country) => {
    if (countryPrev !== country) {
      submitWithoutTax();
    }
  });

  // if the user has changed the form we should set the unsaved changes flag
  // and set if back to false when form is submitted
  const onFieldChange = () => {
    setBillingAddressHasUnsavedChanges(true);
  };
  useStateChange(userAuth.isUpdating, (isUpdatingPrev, isUpdating) => {
    if (isUpdatingPrev && !isUpdating) {
      setVatIdHasUnsavedChanges(false);
    }
  });
  useStateChange(updatingAddress, (updatingAddressPrev, updatingAddress) => {
    if (updatingAddressPrev && !updatingAddress) {
      setBillingAddressHasUnsavedChanges(false);
    }
  });

  const countryField = {
    field: 'country',
    options: countriesOptions,
    title: `${getTranslationKey('shop.taxConfig.locationLabel')}*`,
    labelClass: 'font-weight-bold'
  };

  return (
    <form onSubmit={() => submit()}>
      {showTitle && (
        <>
          <i className="nav-icon icon-user mr-1" /> {getTranslationKey('billingAddressModalTitle')}
        </>
      )}
      <div className="animated fadeIn">
        <Row>
          <Col sm="12" md="12" lg="12" className="p-0">
            <Card style={{ paddingBottom: '0', paddingTop: '0', marginBottom: '0' }}>
              <CardBody>
                <Row>
                  <Col xs="12" lg="12">
                    <Field
                      component={InputGroup}
                      name={emailField.field}
                      validate={getValidate(emailField.rules)}
                      field={emailField}
                      normalize={(value: any) => (/^\s/.test(value) ? value.trim() : value)}
                      onBlur={submitWithoutTax}
                      onChange={onFieldChange}
                      disabled={updatingAddress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Field
                      component={Select}
                      name={countryField.field}
                      field={countryField}
                      onChange={onFieldChange}
                      disabled={updatingAddress}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Field
                      component={InputGroup}
                      name={companyField.field}
                      validate={getValidate(companyField.rules)}
                      field={companyField}
                      normalize={(value: any) => (/^\s/.test(value) ? value.trim() : value)}
                      onBlur={submitWithoutTax}
                      onChange={onFieldChange}
                      disabled={updatingAddress}
                    />
                  </Col>
                </Row>
                <Row>
                  {col6Fields.map((field, index) => (
                    <Col key={index} sm="12" md="6">
                      <Field
                        component={InputGroup}
                        name={field.field}
                        validate={
                          field.field === 'zipcode'
                            ? [getValidate(field.rules), validateZip]
                            : getValidate(field.rules)
                        }
                        field={field}
                        normalize={(value: any) => (/^\s/.test(value) ? value.trim() : value)}
                        onBlur={submitWithoutTax}
                        onChange={onFieldChange}
                        disabled={updatingAddress}
                      />
                    </Col>
                  ))}
                </Row>
                {showVatId && countryIsEuOrCHNotGermany && (
                  <Row>
                    <Col lg={12}>
                      <InputItem
                        id="vat-id"
                        withTooltip
                        tooltipText={getTranslationKey('settingsSection.tax.vatIDTooltip')}
                        label={getTranslationKey('settingsSection.tax.vatIDLabel')}
                        type="text"
                        changeCallback={updateVatId}
                        value={vatId}
                        placeholder="-"
                        margin="10px 0 10px 0"
                        onBlurCallback={() => updateVatId(vatId, 2000)}
                        errorText={userAuth.taxSettings.errorMessage}
                      />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </form>
  );
};

const selector = formValueSelector('billindAddressForm');
const mapStateToProps = (state: any) => {
  // Get the value of a specific field named 'myField'
  const country = selector(state, 'country');

  // Return an object which will be merged into the component's props
  return {
    country
    // You can get multiple values by adding more fields here
  };
};

export const BillingAddressForm = connect(mapStateToProps)(
  reduxForm<IProps, any>({
    form: 'billindAddressForm',
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true
  })(BillingAddressFormUnwrapped)
);
