import React from 'react';
import { Input, Label, FormGroup, InputProps } from 'reactstrap';
import { FormTooltip } from './InputTooltip';

interface IProps extends InputProps {
  text?: string;
  tooltipText?: string;
  tooltipID?: string;
}

export const CheckBox = ({ text, tooltipText, tooltipID, ...rest }: IProps) => (
  <FormGroup check>
    <Label check data-test="component-label">
      <Input type="checkbox" {...rest} />
      <small>
        {text}{' '}
        <FormTooltip
          isShow={Boolean(tooltipText)}
          tooltipText={tooltipText}
          tooltipID={tooltipID}
        />
      </small>
    </Label>
  </FormGroup>
);
