import React from 'react';
import styled from 'styled-components';
import { getTranslationKey } from '../../../modules/utils/helpers';
import { FormGroup, Label, Input } from 'reactstrap';
import { getListData } from './data';
import useModalHelpers from '../useModalHelpers';

const FirstStepContainer = styled.div``;
const TextContent = styled.div`
  margin-bottom: 10px;
`;
const StyledLabel = styled(Label)`
  &:not(:last-child) {
    margin-bottom: 5px;
  }
`;

const FirstStep = () => {
  const { handleValue, activeRadio = '' } = useModalHelpers();
  const listData = getListData();

  return (
    <FirstStepContainer>
      <TextContent>{getTranslationKey('cancelSubscriptionModal.text')}</TextContent>

      <FormGroup check>
        {listData.map(({ name, label }) => (
          <StyledLabel className="w-100-p cursor-pointer" check key={name}>
            <Input
              type="radio"
              name={name}
              checked={activeRadio === name || false}
              onChange={() => handleValue('activeRadio', name)}
            />
            <span className="inline">{label}</span>
          </StyledLabel>
        ))}
      </FormGroup>
    </FirstStepContainer>
  );
};

export default FirstStep;
