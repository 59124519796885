import React, { useEffect, useState } from 'react';
import { IStoreState } from '../../modules/types';
import { FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../Table';
import { getTranslationKey } from '../../modules/utils';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import approve from 'approvejs';
import InputGroup from '../Forms/InputGroup';
import { createPrivacy, deletePrivacy, editPrivacy, notify as notifyAction } from '../../actions';
import { Privacy } from '../../modules/reducers/privacies';
import theme from '../../assets/css/theme';

const fields = {
  description: {
    type: 'text',
    placeholder: getTranslationKey('description'),
    tooltip_id: 'imprintDescription',
    tooltip_text: getTranslationKey('customDomains.descriptionTooltip'),
    title: getTranslationKey('description'),
    rules: {
      required: { message: getTranslationKey('requiredInput') }
    }
  },
  url: {
    type: 'text',
    placeholder: getTranslationKey('customDomains.url'),
    tooltip_id: 'urlToolTipPrivacy',
    tooltip_text: getTranslationKey('customDomains.urlToolTipPrivacy'),
    title: getTranslationKey('customDomains.url'),
    rules: {}
  },
  privacy: {
    type: 'textarea',
    placeholder: getTranslationKey('customDomains.privacy'),
    tooltip_id: 'privacyTooltip',
    tooltip_text: getTranslationKey('customDomains.privacyTooltip'),
    title: getTranslationKey('customDomains.privacy'),
    rules: {}
  }
};

const PrivacyTable = (props: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState('edit');
  const [currentPrivacyId, setCurrentPrivacyId] = useState('');
  const privacies = useSelector(({ privacys }: IStoreState) => privacys.all);
  const toggle = () => setIsOpen(!isOpen);
  const notify = (...args: any[]) => dispatch(notifyAction.apply(null, args)); // eslint-disable-line

  useEffect(() => {
    if (!isOpen) {
      props.reset!();
      setCurrentPrivacyId('');
    }
  }, [isOpen]); // eslint-disable-line

  const openEdit = (privacy: Privacy) => {
    props.change!('description', privacy.description);
    props.change!('url', privacy.url);
    props.change!('privacy', privacy.privacy);
    setCurrentPrivacyId(privacy._id);
    setMode('edit');
    toggle();
  };

  const onDeletePrivacy = (privacyId: string) => {
    dispatch(deletePrivacy(privacyId, notify));
    if (isOpen) toggle();
  };

  const submit = (values: any) => {
    if (mode === 'edit') {
      dispatch(editPrivacy({ ...values, _id: currentPrivacyId }, notify));
    } else dispatch(createPrivacy(values, notify));
    toggle();
  };

  const items: any[] = privacies.map((privacy) => ({
    ...privacy,
    action: (
      <div className="d-flex justify-content-end">
        <FaPen
          style={{ cursor: 'pointer' }}
          className="mr-3"
          color={theme.commonColors.second}
          onClick={() => openEdit(privacy)}
        />
        <FaTrash
          style={{ cursor: 'pointer' }}
          color={theme.commonColors.second}
          onClick={() => onDeletePrivacy(privacy._id)}
        />
      </div>
    )
  }));

  items.push({
    description: (
      <button
        className="btn btn-light w-100-p create-new-button"
        onClick={() => {
          toggle();
          setMode('create');
        }}
      >
        <FaPlus size={15} color={theme.commonColors.second} className="plus-icon mr-2" />
        {getTranslationKey('customDomains.newPrivacy')}
      </button>
    )
  });

  const label =
    mode === 'edit'
      ? getTranslationKey('customDomains.editPrivacy')
      : getTranslationKey('customDomains.createPrivacy');

  return (
    <>
      <Table
        items={items}
        columns={['description', 'action']}
        columnNames={['', '']}
        defaultSorting={{ column: '' }}
        noMatchingRecords={getTranslationKey('customDomains.noMatchingRecords')}
      />
      <Modal centered isOpen={isOpen} toggle={toggle} wrapClassName="modal-primary" size="default">
        <ModalHeader toggle={toggle}>{label}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={props.handleSubmit!(submit)}>
                {Object.keys(fields).map((fieldKey, index) => (
                  <Field
                    component={InputGroup}
                    key={index}
                    name={fieldKey}
                    field={fields[fieldKey as keyof typeof fields]}
                  />
                ))}

                <button
                  className="btn btn-primary w-100-p"
                  type="submit"
                  disabled={props.pristine || props.invalid || props.submitting}
                >
                  {label}
                </button>
              </form>
              <a
                href="https://dsgvo-muster-datenschutzerklaerung.dg-datenschutz.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {getTranslationKey('customDomains.privacyGenerator')}
              </a>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {mode === 'edit' && (
            <small className="cursor-pointer " onClick={() => onDeletePrivacy(currentPrivacyId)}>
              <i className="sli-trash m-r-5" />
              {`${getTranslationKey('privacy')} ${getTranslationKey('delete')}`}
            </small>
          )}
          <button className="btn btn-danger pull-left" onClick={toggle}>
            {getTranslationKey('abort')}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

function validate(values: any) {
  const errors: any = {};

  if (!values.url && !values.privacy) {
    const error = [getTranslationKey('minOneRequired')];
    errors.privacy = error;
    errors.url = error;
  }

  Object.keys(fields).forEach((fieldKey: string) => {
    const result = approve.value(values[fieldKey], fields[fieldKey as keyof typeof fields].rules);
    if (result.errors.length > 0) {
      errors[fieldKey] = result.errors;
    }
  });

  return errors;
}

export default reduxForm({
  form: 'privacyCreationForm',
  validate
})(PrivacyTable);
