import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { getTranslationKey } from '../../modules/utils';
import Label from '../Forms/CustomLabel';
import { createMessageAction } from '../../modules/actions/message';

interface IProps {
  isOpen: boolean;
  closeCallback: () => void;
}

export const CreateMessageModal: React.FC<IProps> = ({ isOpen, closeCallback }) => {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');
  const closeModal = () => {
    setTitle('');
    closeCallback();
  };

  const createMessage = () => {
    dispatch(createMessageAction({ title }));
    closeModal();
  };

  return (
    <Modal centered isOpen={isOpen} toggle={closeModal} wrapClassName="modal-dark">
      <ModalHeader toggle={closeModal}>{getTranslationKey('messages.createMessage')}</ModalHeader>
      <ModalBody>
        <Label className="mb-3" label={getTranslationKey('messages.title')}>
          <Input
            type="text"
            value={title}
            onChange={({ target: { value } }) => setTitle(value)}
            placeholder={getTranslationKey('messages.titlePlaceholder')}
          />
        </Label>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={closeModal}>
          {getTranslationKey('messages.cancel')}
        </Button>
        <Button color="primary" onClick={createMessage}>
          {getTranslationKey('messages.create')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
