import React, { Component } from 'react';
import Texts from '../../json/texts.json';
import Table from '../../elements/Table';
import NewDomain from '../../elements/CustomDomains/NewDomain';
import Imprint from '../../elements/CustomDomains/Imprint';
import Privacy from '../../elements/CustomDomains/Privacy';
import HelpLink from '../../elements/Help/HelpLink';
import { connect } from 'react-redux';
import { FaTrash } from 'react-icons/fa';
import * as actions from '../../actions';
import Card, { CardBody, CardHeader, CardTitle } from '../../components/Card';
import { Row, Col } from 'reactstrap';
import ImprintTable from '../../elements/CustomDomains/ImprintTable';
import PrivacyTable from '../../elements/CustomDomains/PrivacyTable';
import theme from '../../assets/css/theme';

class CustomDomain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      itemsPerPage: 10
    };

    this.columns = ['hostname', 'cname', 'imprint', 'privacy', 'action'];

    this.columnNames = [
      Texts[window.LANGUAGE_SW].customDomains.domain,
      Texts[window.LANGUAGE_SW].customDomains.domainTarget,
      Texts[window.LANGUAGE_SW].customDomains.imprint,
      Texts[window.LANGUAGE_SW].customDomains.privacy,
      Texts[window.LANGUAGE_SW].dashboardTable.action
    ];

    this.onDeleteDomain = this.onDeleteDomain.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.getDomains();
    this.props.getImprints();
    this.props.getPrivacys();
  }

  onDeleteDomain(domain_id) {
    if (window.confirm(Texts[window.LANGUAGE_SW].customDomains.deleteDomainConfirmation)) {
      this.props.deleteDomain(domain_id, this.props.notify);
    }
  }

  // onChangeItemsPerPage(e) {
  //   e.preventDefault();
  //   this.setState({
  //     itemsPerPage: e.target.value
  //   });

  //   return false;
  // }

  // onSearch(e) {
  //   e.preventDefault();
  //   this.setState({
  //     search: e.target.value
  //   });

  //   return false;
  // }

  render() {
    const items = this.props.domains.all.map((domain) => ({
      ...domain,
      imprint: (
        <Imprint
          domain={domain._id}
          selectedImprint={domain.imprint ? domain.imprint.id || domain.imprint : undefined}
        />
      ),
      privacy: (
        <Privacy
          domain={domain._id}
          selectedPrivacy={(domain.privacy && domain.privacy.id) || domain.privacy}
        />
      ),
      action: (
        <FaTrash
          style={{ cursor: 'pointer' }}
          color={theme.commonColors.second}
          onClick={() => this.onDeleteDomain(domain._id)}
        />
      )
    }));

    items.push({
      hostname: <NewDomain />
    });

    return (
      <div className="animated fadeIn">
        <Row>
          <Col md="12" lg="12" xl="12">
            <Card>
              <CardHeader>
                <CardTitle>{Texts[window.LANGUAGE_SW].customDomain}</CardTitle>
                <div>
                  {this.props.userType === 'SB' && (
                    <HelpLink
                      href="https://support.scrappbook.de/de/collections/11561"
                      className="float-left mr-3"
                      video
                    />
                  )}
                </div>
              </CardHeader>
              <CardBody>
                <Table
                  items={items}
                  columns={this.columns}
                  columnNames={this.columnNames}
                  itemsPerPage={this.state.itemsPerPage}
                  search={this.state.search}
                  noMatchingRecords={Texts[window.LANGUAGE_SW].customDomains.noMatchingRecords}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12" lg="12" xl="6" style={{ marginTop: '30px' }}>
            <Card>
              <CardHeader>
                <CardTitle>{Texts[window.LANGUAGE_SW].customDomains.imprint}</CardTitle>
              </CardHeader>
              <CardBody>
                <ImprintTable />
              </CardBody>
            </Card>
          </Col>
          <Col md="12" lg="12" xl="6" style={{ marginTop: '30px' }}>
            <Card>
              <CardHeader>
                <CardTitle>{Texts[window.LANGUAGE_SW].customDomains.privacy}</CardTitle>
              </CardHeader>
              <CardBody>
                <PrivacyTable />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = ({ domains, notify, auth: { type } }) => ({
  domains,
  notify,
  userType: type
});
export default connect(mapStateToProps, actions)(CustomDomain);
