import React from 'react';
import { connect } from 'react-redux';
import Texts from '../../json/texts.json';
import history from '../../helpers/history';
import { Alert } from 'reactstrap';
import getPricingPageUrl from './PricingPageUrl';
import * as actions from '../../actions';

const DiscountAlert = ({
  digistore,
  className,
  userType,
  logUserActivity,
  utm_medium,
  utm_campaign,
  utm_source,
  afterClick,
  isOpen,
  closeDiscountAlert
}) => {
  if (digistore && digistore.discountKey && digistore.discountKey._id) {
    let type = '';
    let text = '';
    const value = digistore.discountKey.first_fixed || digistore.discountKey.first_percent;

    if (digistore.discountKey.free_months) {
      type = 'freeMonths';
      text = Texts[window.LANGUAGE_SW].freeMonthsActive.replace(
        '$$free_months$$',
        digistore.discountKey.free_months
      );
    } else {
      if (digistore.discountKey.first_fixed) {
        type = '€';
      } else {
        type = '%';
      }

      text = Texts[window.LANGUAGE_SW].discountKeyActive
        .replace('$$value$$', value)
        .replace('$$type$$', type)
        .replace(
          '$$noMinimumContractLength$$',
          digistore.discountKey.noMinimumContractLength
            ? ` ${Texts[window.LANGUAGE_SW].noMinimumContractLength}`
            : ''
        );
    }

    return (
      <Alert
        id="discount-key-alert"
        className={className || 'cursor-pointer'}
        isOpen={isOpen}
        toggle={closeDiscountAlert}
        onClick={({ target: { className } }) => {
          if (className) {
            if (userType === 'SB') {
              logUserActivity({
                action: 'CLICK_DISCOUNT_KEY',
                logAsHubpostNote: true,
                leadScore: 8
              });

              window.location = getPricingPageUrl(digistore, {
                utm_medium: utm_medium || 'discount-alert',
                utm_campaign: utm_campaign || 'builder-discounts',
                utm_source: utm_source || 'builder'
              });
              afterClick && afterClick();
            } else {
              history.push('/subscription/pricing');
            }
          }
        }}
        color="primary"
      >
        {text}
      </Alert>
    );
  }

  return null;
};

function mapStateToProps({ auth: { digistore, type } }) {
  return { digistore, userType: type };
}

export default connect(mapStateToProps, actions)(DiscountAlert);
