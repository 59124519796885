import React from 'react';
import { Button, Alert } from 'reactstrap';
import { IMessage } from '../../modules/types/message';
import { getTranslationKey } from '../../modules/utils';
import styled from 'styled-components';

const PreviewTitle = styled.h5`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const PreviewMessage = styled.p`
  word-break: break-all;
`;

const ModalBody = styled.div`
  max-height: 460px;
  overflow-y: scroll;
`;

interface MessagePreviewProps {
  message: IMessage | null;
}
const MessagePreview = ({ message }: MessagePreviewProps) =>
  message ? (
    <div>
      <img className="img-fluid" src={`${window.CDN_URL}/public/ios-screen.png`} alt="" />

      <div className="modal-dark message-preview">
        <div className="modal-content text-center">
          <div className="modal-header">
            <PreviewTitle className="modal-title">
              {message.title || getTranslationKey('messages.titlePreviewPlaceholder')}
            </PreviewTitle>
          </div>
          <ModalBody className="modal-body">
            {message.message ? (
              <PreviewMessage>{message.message}</PreviewMessage>
            ) : (
              <Alert color="warning">
                {getTranslationKey('messages.messagePreviewPlaceholder')}
              </Alert>
            )}

            {message.previewUrl || message.publicImageKey ? (
              <img
                className="img-fluid mb-3"
                alt=""
                src={message.previewUrl || `${window.CDN_URL}/public/${message.publicImageKey}`}
              />
            ) : (
              <Alert color="warning">{getTranslationKey('messages.imagePreviewPlaceholder')}</Alert>
            )}

            <Button color="success" size="lg">
              {message.cta.text || getTranslationKey('messages.ctaPreviewPlaceholder')}
            </Button>
          </ModalBody>
        </div>
      </div>
    </div>
  ) : null;

export default MessagePreview;
