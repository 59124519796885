import moment from 'moment';
import Texts from '../json/texts.json';
import { remove } from 'lodash';

function castAppointmentDates(appointment) {
  if (appointment.start) appointment.start = moment(appointment.start).toDate();
  if (appointment.end) appointment.end = moment(appointment.end).toDate();
}

function setAppoinmentTitle(appointment) {
  if (!appointment.title) {
    appointment.title = Texts[window.LANGUAGE_SW].shootingTypes[appointment.type] || '';
    if (appointment.lead) {
      const { lead } = appointment;
      appointment.title +=
        (appointment.title.length > 0 && ' - ') + lead.first_name + ' ' + lead.last_name;
    }
  }
}

export default function (state = { allAppointments: [], isTimeSlotPopUpOpen: false }, action) {
  switch (action.type) {
    case 'SET_ALL_APPOINTMENTS':
      const allAppointments = action.payload.map((appointment) => {
        castAppointmentDates(appointment);
        setAppoinmentTitle(appointment);

        return appointment;
      });

      return { ...state, allAppointments };
    case 'TOGGLE_TIMESLOT_POPUP':
      return { ...state, isTimeSlotPopUpOpen: !state.isTimeSlotPopUpOpen };
    case 'ADD_APPOINTMENT':
      const appointment = action.payload;
      castAppointmentDates(appointment);
      setAppoinmentTitle(appointment);

      return {
        ...state,
        allAppointments: [action.payload, ...state.allAppointments]
      };
    case 'REMOVE_APPOINTMENT':
      const removeArray = state.allAppointments.slice();
      remove(removeArray, (item) => item._id === action.payload);

      return {
        ...state,
        allAppointments: [...removeArray]
      };
    case 'EDIT_APPOINTMENT':
      const changedAppointment = action.payload;
      const editedAppointments = state.allAppointments.map((appointment) => {
        if (appointment._id !== changedAppointment._id) return appointment;
        castAppointmentDates(changedAppointment);
        setAppoinmentTitle(changedAppointment);

        return changedAppointment;
      });

      return {
        ...state,
        allAppointments: editedAppointments
      };
    default:
      return state;
  }
}
