import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { UncontrolledTooltip } from 'reactstrap';
import Texts from '../../../json/texts.json';
import { SwitchItem } from '../../../components';

const SetDefaultBrandSwitch = ({
  user,
  userBrand,
  notify,
  className,
  updateCurrentUser,
  setUserBrandValue
}) => (
  <div id={`defaultBrandSwitch${userBrand._id}`} className={'display-inline ' + className || ''}>
    <SwitchItem
      value={user.defaultUserBrand._id === userBrand._id}
      isDisabled={user.defaultUserBrand._id === userBrand._id}
      margin="0"
      changeCallback={async (checked) => {
        if (checked) {
          setUserBrandValue({
            userBrandId: userBrand._id,
            key: 'defaultValues',
            value: true
          });

          setUserBrandValue({
            userBrandId: user.defaultUserBrand._id,
            key: 'defaultValues',
            value: false
          });

          await updateCurrentUser(
            {
              ...user,
              defaultUserBrand: userBrand
            },
            notify,
            null
          );
        }
      }}
    />
    {user.defaultUserBrand._id !== userBrand._id && (
      <UncontrolledTooltip
        autohide={false}
        placement="right"
        target={`defaultBrandSwitch${userBrand._id}`}
      >
        {Texts[window.LANGUAGE_SW].userBrand.setAsStandard}
      </UncontrolledTooltip>
    )}
  </div>
);

function mapStateToProps({ notify, auth }) {
  return { notify, user: auth };
}

export default connect(mapStateToProps, actions)(SetDefaultBrandSwitch);
