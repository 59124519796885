import axios from '../helpers/axios';
import axiosWithoutCredentials from 'axios';
import * as errors from '../errors';
import history from '../helpers/history';
import ImageCompressor from '@xkeshi/image-compressor';

export const getBookingSites = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/bookingsites');
    dispatch({ type: 'SET_ALL_BOOKINGSITES', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const createBookingSite = (bookingSite, notify) => async () => {
  try {
    const res = await axios.post('/api/bookingsite/', bookingSite);

    const { _id } = res.data;
    if (_id) {
      history.push(`/bookings/sites/edit/${_id}`);
    }
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const cloneBookingSiteById = (bookingSiteId) => async (dispatch) => {
  try {
    const res = await axios.put('/api/bookingsite/clone', { bookingSiteId });
    dispatch({ type: 'ADD_BOOKINGSITE', payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

export const getBookingProducts = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/bookingsite/products');
    dispatch({ type: 'SET_ALL_BOOKING_PRODUCTS', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const editBookingProduct = (product) => async (dispatch) => {
  try {
    const res = await axios.put('/api/bookingsite/product', { product });
    dispatch({
      type: 'EDIT_BOOKING_PRODUCT',
      payload: res.data
    });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const deleteBookingProduct = (id) => async (dispatch) => {
  try {
    await axios.delete('/api/bookingsite/product', { params: { id } });
    dispatch({
      type: 'REMOVE_BOOKING_PRODUCT',
      payload: id
    });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const createBookingProduct =
  (product, addToSelection = false) =>
  async (dispatch) => {
    try {
      const res = await axios.post('/api/bookingsite/product', product);
      dispatch({
        type: 'ADD_BOOKING_PRODUCT',
        payload: { ...res.data, addToSelection }
      });
    } catch (e) {
      errors.handleErrorWithRedirectIfNotAuth(e);
    }
  };

export const uploadBookingBackground = (file, bookingSiteId) => async () => {
  try {
    const res = await axios.get('/api/bookingsite/upload/backgroundImage', {
      params: {
        objectName: file.name,
        bookingSiteId
      }
    });

    const imageUploadRes = res.data;

    let imageFile;
    if (!imageUploadRes.compress) imageFile = file;
    else {
      const imageCompressor = new ImageCompressor();
      imageFile = await imageCompressor.compress(file, {
        quality: 0.7,
        maxWidth: 1920,
        maxHeight: 1080
      });
    }
    await axiosWithoutCredentials.put(imageUploadRes.signedUrl, imageFile);

    return imageUploadRes.cryptImageName;
  } catch (e) {
    console.log(e);
  }
};
