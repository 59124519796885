import { ILogoSlidePayload } from './../../types/slideshow';
import { IImage } from './../../reducers/images/index';
import { SagaIterator } from 'redux-saga';
import { put, call, select, take } from 'redux-saga/effects';
import Api from '../../../modules/utils/API';
import {
  ISagaAction,
  ICreateSlideshowAction,
  IFetchSlideshowAction,
  ISlide,
  IColor,
  ISlideshowStatePresent
} from '../../../modules/types';
import {
  addPhotosSuccess,
  changeModalState,
  duplicatesAction,
  errorsGlobalError,
  fetchSlideshowSuccess,
  selectSlide,
  showNotifySuccess,
  updateSlideshowSuccess,
  undoActionSuccess,
  redoActionSuccess,
  updateWholeState,
  changeLoadingStatus,
  setIsShowBeatMatch,
  clearUndoRedoHistory,
  getRenderServicStatusSuccessAction,
  clearAllDraggableState,
  selectImageForSlideshow,
  updateSeekedTime,
  setPlaySlideshowPlayer,
  setIsFitAll,
  changeLoadingSlideShowMusic
} from '../../../modules/actions';
import { accessRulesAction } from '../../actions/accessRules';
import ApiErrors from '../../../modules/utils/API/APIErrors';

import {
  getSlideshow,
  getDuplicatesImagesIDS,
  getDraggableSlideState,
  getModalData,
  getCollectionsNew
} from '../../../modules/selectors';
import {
  prepareSlidesForRequest,
  updateSoundtracksForRequest,
  handleCreateSoundtrackOptions,
  updateSlideId,
  handleSoundtracksDuration,
  getCollectionImageURL
} from '../../../modules/utils';

const createOptions = (newAsset: any, type: string) => ({
  asset: {
    ...newAsset,
    type
  },
  transition: {
    in: { type: 'fade' },
    out: { type: 'fade' }
  }
});

const updateSlideshowState = (slideshow: ISlideshowStatePresent) => {
  return {
    name: slideshow.name,
    soundtracks: updateSoundtracksForRequest(slideshow.soundtracks),
    slides: prepareSlidesForRequest(slideshow.slides),
    background: slideshow.background,
    speed: slideshow.speed,
    crossfade: slideshow.crossfade
  };
};

const updateSlideshowResponse = (res: any) => {
  const { slides, soundtracks } = res.result;

  res.result.slides = updateSlideId(slides);
  res.result.tracksDuration = handleSoundtracksDuration(soundtracks);
  res.result.soundtracks = handleCreateSoundtrackOptions(soundtracks);

  return res.result;
};

const undoRedoSlideshowSaga = function* (): SagaIterator {
  const slideshowState = yield select(getSlideshow);
  const updatedSlideshowState = updateSlideshowState(slideshowState);

  const res = yield call(Api.Slideshows.update, { ...updatedSlideshowState }, slideshowState.id);
  ApiErrors.checkOnApiError(res);

  res.result = updateSlideshowResponse(res);

  yield put(updateWholeState(res));
  yield put(selectSlide(null));
  yield put(changeLoadingStatus(false));
  yield put(setPlaySlideshowPlayer(false));
  yield put(showNotifySuccess({}));
  yield put(accessRulesAction());
};

export const fetchSlideshowSaga = function* ({
  payload
}: ISagaAction<IFetchSlideshowAction>): SagaIterator {
  try {
    const { slideshowId } = payload;
    const startTime = 0.01;

    const res = yield call(Api.Slideshows.get, { slideshowId });
    ApiErrors.checkOnApiError(res);

    res.result.slides = updateSlideId(res.result.slides);
    res.result.soundtracks = handleCreateSoundtrackOptions(res.result.soundtracks);
    const slideForSelect = res.result.slides[0];

    if (res.result.speed.includes('beat')) {
      yield put(setIsShowBeatMatch(true));
    }

    if (res.result.speed === 'fit-all-slides-in-track') {
      yield put(setIsFitAll(true));
    }

    yield put(fetchSlideshowSuccess(res));
    yield put(clearUndoRedoHistory());
    yield put(selectSlide(slideForSelect));
    yield put(updateSeekedTime(startTime));
    yield put(accessRulesAction());
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const createSlideshowSaga = function* ({
  payload
}: ISagaAction<ICreateSlideshowAction>): SagaIterator {
  try {
    const { collectionId, history, name } = payload;
    const { targetID } = yield select(getModalData);

    const res = yield call(Api.Slideshows.create, {
      name,
      albumId: targetID,
      collectionId: collectionId
    });

    if (res?.response?.data?.error?.name === 'UpgradeRequired') {
      yield put(
        changeModalState({
          key: 'upgradeModal',
          state: true,
          desiredAction: 'slideshows',
          modalData: { addon: 'slideshows' }
        })
      );
    } else {
      ApiErrors.checkOnApiError(res);
    }

    history.push(`/collection/edit/${collectionId}/images/slideshow/${res.result.id}`);

    yield put(fetchSlideshowSuccess(res));
    yield put(accessRulesAction());
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updateSlideshowSaga = function* ({ payload }: any): SagaIterator {
  try {
    yield put(changeLoadingSlideShowMusic(true));
    yield put(changeLoadingStatus(true));
    // const { currentTime } = yield select(getSlideshow);
    // const defaultTime = 0.01;
    const res = yield call(
      Api.Slideshows.update,
      { [payload.key]: payload[payload.key] },
      payload.id
    );
    ApiErrors.checkOnApiError(res);

    // const { durationSeconds } = res.result;

    res.result = updateSlideshowResponse(res);

    // if (durationSeconds < currentTime) {
    // yield put(updateSeekedTime(defaultTime));
    // yield put(setPlaySlideshowPlayer(false));
    // }

    if (res.result.speed.includes('beat')) {
      yield put(setIsShowBeatMatch(true));
    } else {
      yield put(setIsShowBeatMatch(false));
    }

    yield put(changeLoadingStatus(false));

    yield put(updateSlideshowSuccess(res));
    yield put(accessRulesAction());
    yield put(showNotifySuccess({}));
    yield put(changeLoadingSlideShowMusic(false));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const changeSlideshowParametersSaga = function* ({ payload }: any): SagaIterator {
  try {
    const res = yield call(
      Api.Slideshows.update,
      { [payload.key]: payload[payload.key] },
      payload.id
    );
    ApiErrors.checkOnApiError(res);

    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const removeSlidesSaga = function* ({ payload }: any): SagaIterator {
  try {
    const { updatedSlides, id } = payload;

    const res = yield call(
      Api.Slideshows.update,
      { slides: prepareSlidesForRequest(updatedSlides) },
      id
    );
    ApiErrors.checkOnApiError(res);

    res.result = updateSlideshowResponse(res);

    yield put(updateSlideshowSuccess(res));
    yield put(showNotifySuccess({}));
    yield put(accessRulesAction());
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const reorderSlidesSaga = function* ({ payload }: any): SagaIterator {
  try {
    const { slides, id } = payload;
    const slidesForRequest = prepareSlidesForRequest(slides);

    const res = yield call(Api.Slideshows.update, { slides: slidesForRequest }, id);
    ApiErrors.checkOnApiError(res);

    res.result.slides = updateSlideId(res.result.slides);
    res.result.soundtracks = handleCreateSoundtrackOptions(res.result.soundtracks);

    yield put(updateSlideshowSuccess(res));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const reorderSoundtracksSaga = function* ({ payload }: any): SagaIterator {
  try {
    const { id } = yield select(getSlideshow);

    const updatedSoundtracks = updateSoundtracksForRequest(payload.soundtracks);

    const res = yield call(Api.Slideshows.update, { soundtracks: updatedSoundtracks }, id);
    ApiErrors.checkOnApiError(res);

    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const addPhotosSaga = function* (): SagaIterator {
  try {
    const { selectedSlides } = yield select(getDraggableSlideState);
    const { signedWilCardUrl } = yield select(getCollectionsNew);

    const newSelectedSlides = selectedSlides.map((image: IImage) => ({
      asset: {
        type: 'image',
        imageId: image._id,
        src: getCollectionImageURL(image, 'L', false, signedWilCardUrl)
      },
      transition: {
        in: { type: 'fade' },
        out: { type: 'fade' }
      }
    }));

    yield put(selectImageForSlideshow(newSelectedSlides));

    const { selectedImagesForSlideshow, slides, id } = yield select(getSlideshow);
    let duplicates = yield select(getDuplicatesImagesIDS);
    let imagesList: ISlide[] = selectedImagesForSlideshow;

    yield put(
      changeModalState({
        key: 'chooseCollectionPhotosModal',
        state: false
      })
    );

    if (duplicates.length) {
      yield put(
        changeModalState({
          key: 'duplicatesImagesModal',
          state: true
        })
      );

      const { payload: duplicatesPayload } = yield take(duplicatesAction);

      if (duplicatesPayload === 'skip') {
        const duplicatesIDS = duplicates.map((item: any) => item.id);
        imagesList = selectedImagesForSlideshow.filter(
          (slide: ISlide) => !duplicatesIDS.includes(slide.asset.imageId)
        );
        imagesList = [...slides, ...imagesList];
      }

      if (duplicatesPayload === 'replace') {
        const duplicatesIDS = duplicates.map((item: any) => item.id);
        const imagesForReplace = selectedImagesForSlideshow.filter((image: ISlide) =>
          duplicatesIDS.includes(image.asset.imageId)
        );
        const imagesForReplaceIDS = imagesForReplace.map((image: ISlide) => image.asset.imageId);
        const imagesNotReplace = selectedImagesForSlideshow.filter(
          (image: ISlide) => !duplicatesIDS.includes(image.asset.imageId)
        );

        const newFiles = slides.filter(
          (slide: ISlide) => !imagesForReplaceIDS.includes(slide.asset.imageId)
        );
        imagesList = [...imagesNotReplace, ...newFiles, ...imagesForReplace];
      }
    } else {
      imagesList = [...slides, ...imagesList];
    }

    const updatedSlides = prepareSlidesForRequest(imagesList);

    const res = yield call(Api.Slideshows.update, { slides: updatedSlides }, id);
    ApiErrors.checkOnApiError(res);

    res.result.slides = updateSlideId(res.result.slides);

    yield put(
      addPhotosSuccess({
        slideshowOnboarding: res.result.slideshowOnboarding,
        slides: res.result.slides,
        durationSeconds: res.result.durationSeconds
      })
    );
    yield put(showNotifySuccess({}));
    yield put(clearAllDraggableState());
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const addTitleSlideSaga = function* ({
  payload
}: {
  payload: {
    asset: {
      textColor: string;
      text: string;
      background: IColor;
    };
  };
}): SagaIterator {
  try {
    const { slides, selectedSlide, id } = yield select(getSlideshow);
    const { asset } = payload;

    const newTextSlide: any = createOptions(asset, 'title');
    const updatedSlides: any = prepareSlidesForRequest(slides);
    let slideIndex;

    if (selectedSlide) {
      slideIndex = slides.findIndex((slide: ISlide) => slide.slideId === selectedSlide.slideId);

      updatedSlides.splice(slideIndex, 0, newTextSlide);
    } else {
      updatedSlides.splice(0, 0, newTextSlide);
    }

    const res = yield call(Api.Slideshows.update, { slides: updatedSlides }, id);
    ApiErrors.checkOnApiError(res);

    res.result = updateSlideshowResponse(res);
    const newSelectedSlide = res.result.slides[slideIndex];

    yield put(updateSlideshowSuccess(res));
    yield put(showNotifySuccess({}));
    yield put(selectSlide(newSelectedSlide));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const editTitleSlideSaga = function* ({ payload: { asset } }: any): SagaIterator {
  try {
    const { editableSlide, id, slides } = yield select(getSlideshow);
    const updatedSlide = {
      ...editableSlide,
      asset: {
        ...editableSlide.asset,
        ...asset
      }
    };

    const updatedSlides = slides.map((slide: ISlide) => {
      if (slide.slideId === editableSlide.slideId || slide.startTime === editableSlide.startTime) {
        return updatedSlide;
      }

      return slide;
    });

    const res = yield call(
      Api.Slideshows.update,
      { slides: prepareSlidesForRequest(updatedSlides) },
      id
    );
    ApiErrors.checkOnApiError(res);

    res.result.slides = updateSlideId(res.result.slides);

    yield put(selectSlide(updatedSlide));
    yield put(updateSlideshowSuccess(res));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const removeSountrackSaga = function* ({ payload }: any): SagaIterator {
  try {
    const { id, speed } = yield select(getSlideshow);

    const soundtracksForRequest = updateSoundtracksForRequest(payload);

    const requestPaylad: any = {
      soundtracks: soundtracksForRequest
    };

    if (soundtracksForRequest.length === 0 && speed === 'fit-all-slides-in-track') {
      requestPaylad.speed = 'normal';
    }

    const res = yield call(Api.Slideshows.update, requestPaylad, id);
    ApiErrors.checkOnApiError(res);

    res.result = updateSlideshowResponse(res);

    if (res.result.speed.includes('beat')) {
      yield put(setIsShowBeatMatch(true));
    } else {
      yield put(setIsShowBeatMatch(false));
    }

    yield put(updateSlideshowSuccess(res));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const undoSlideshowSaga = function* (): SagaIterator {
  try {
    yield put(undoActionSuccess());
    yield put(changeLoadingStatus(true));
    yield call(undoRedoSlideshowSaga);
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const redoSlideshowSaga = function* (): SagaIterator {
  try {
    yield put(redoActionSuccess());
    yield put(changeLoadingStatus(true));
    yield call(undoRedoSlideshowSaga);
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const scheduleRenderSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const res = yield call(Api.Slideshows.scheduleRender, payload);
    ApiErrors.checkOnApiError(res);

    res.result = updateSlideshowResponse(res);

    yield put(updateSlideshowSuccess(res));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const cancelRenderSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const res = yield call(Api.Slideshows.cancelRender, payload);
    ApiErrors.checkOnApiError(res);

    res.result = updateSlideshowResponse(res);

    yield put(updateSlideshowSuccess(res));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const getRenderServicStatusSaga = function* (): SagaIterator {
  try {
    const res = yield call(Api.Slideshows.getRenderServiceStatus);
    ApiErrors.checkOnApiError(res);

    yield put(getRenderServicStatusSuccessAction(res.result));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const getRenderStatusSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const res = yield call(Api.Slideshows.getRenderStatus, payload);
    ApiErrors.checkOnApiError(res);

    yield put(
      updateSlideshowSuccess({
        result: {
          render: res.result
        }
      })
    );
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const updateUserSlideshowOnboardingSaga = function* ({
  payload
}: ISagaAction<any>): SagaIterator {
  try {
    yield call(updateSlideshowSaga, {
      payload: {
        key: 'slideshowOnboarding',
        slideshowOnboarding: payload.slideshowOnboarding,
        id: payload.id
      }
    });
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const addLogoSlideSaga = function* ({
  payload
}: {
  payload: ILogoSlidePayload;
}): SagaIterator {
  try {
    const { slides, id, selectedSlide } = yield select(getSlideshow);

    const updatedSlides: any = prepareSlidesForRequest(slides);
    const { size, backgroundColor, isAddingSlideToTheEnd, brandId, src, imageId } = payload;
    const asset = {
      brandId,
      imageId,
      logoSize: size,
      src,
      background: {
        color: backgroundColor
      }
    };

    const newSlide = createOptions(asset, 'logo');
    let slideIndex = 0;

    if (isAddingSlideToTheEnd) {
      updatedSlides.push(newSlide);
    } else if (selectedSlide) {
      slideIndex = slides.findIndex((slide: ISlide) => slide.slideId === selectedSlide.slideId);

      updatedSlides.splice(slideIndex, 0, newSlide);
    } else {
      updatedSlides.splice(slideIndex, 0, newSlide);
    }

    const res = yield call(Api.Slideshows.update, { slides: updatedSlides }, id);
    ApiErrors.checkOnApiError(res);

    res.result = updateSlideshowResponse(res);

    yield put(updateSlideshowSuccess(res));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const editLogoSlideSaga = function* ({ payload }: { payload: ILogoSlidePayload }) {
  try {
    const { slides, id, selectedSlide } = yield select(getSlideshow);

    const { size, backgroundColor, isAddingSlideToTheEnd, brandId, src, imageId } = payload;
    const asset = {
      brandId,
      imageId,
      logoSize: size,
      src,
      background: {
        color: backgroundColor
      }
    };

    const updatedSlide = {
      ...selectedSlide,
      asset: {
        ...selectedSlide.asset,
        ...asset
      }
    };

    let updatedSlides = [...slides];

    let slideIndex = slides.findIndex((slide: ISlide) => slide.slideId === selectedSlide.slideId);

    if (isAddingSlideToTheEnd) {
      updatedSlides.splice(slideIndex, 1);
      updatedSlides.push(updatedSlide);
    } else {
      updatedSlides = slides.map((slide: ISlide) => {
        if (
          slide.slideId === selectedSlide.slideId ||
          slide.startTime === selectedSlide.startTime
        ) {
          return updatedSlide;
        }

        return slide;
      });
    }

    //@ts-ignore
    const res = yield call(
      Api.Slideshows.update,
      { slides: prepareSlidesForRequest(updatedSlides) },
      id
    );
    ApiErrors.checkOnApiError(res);

    res.result = updateSlideshowResponse(res);

    yield put(updateSlideshowSuccess(res));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};
