import React from 'react';
import { getTranslationKey } from '../../modules/utils/helpers';
import { useDispatch } from 'react-redux';
import { deleteCoupon } from '../../modules/actions';
import { FaPen, FaTrash } from 'react-icons/fa';
import theme from '../../assets/css/theme';

interface IProps {
  couponID?: string;
  isUsedInSalesAutomation?: boolean;
  actionHandler: (id: string) => void;
}

export const CouponActionsButton = ({
  couponID,
  actionHandler,
  isUsedInSalesAutomation
}: IProps) => {
  const dispatch = useDispatch();
  const deletePresetHandler = () => {
    const translationKey = isUsedInSalesAutomation
      ? 'shop.coupons.deleteQuestionWithWarning'
      : 'shop.coupons.deleteQuestion';
    const confirmDelete = window.confirm(getTranslationKey(translationKey));
    if (confirmDelete && couponID) {
      dispatch(deleteCoupon(couponID));
    }
  };
  const openEditPresetModal = () => {
    actionHandler(couponID || '');
  };

  return (
    <>
      <FaPen
        style={{ cursor: 'pointer' }}
        className="mr-3"
        title={getTranslationKey('galleryPresets.actionEditButton')}
        color={theme.commonColors.second}
        onClick={openEditPresetModal}
      />
      <FaTrash
        style={{ cursor: 'pointer' }}
        className="mr-3"
        title={getTranslationKey('galleryPresets.actionDeleteButton')}
        color={theme.commonColors.second}
        onClick={deletePresetHandler}
      />
    </>
  );
};
