import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Texts from '../../../json/texts.json';
import moment from 'moment';

const UseDesktopModal = ({
  isUseDesktopModalIsOpen,
  setUseDesktopModalVisibility,
  userCreatedAt
}) =>
  isUseDesktopModalIsOpen ? (
    <Modal
      centered
      isOpen={isUseDesktopModalIsOpen}
      toggle={() => setUseDesktopModalVisibility(!isUseDesktopModalIsOpen)}
      wrapClassName="modal-dark"
      size="md"
    >
      <ModalHeader toggle={() => setUseDesktopModalVisibility(!isUseDesktopModalIsOpen)}>
        {Texts[window.LANGUAGE_SW].useDesktopModal.title}
      </ModalHeader>
      <ModalBody>
        <p>{Texts[window.LANGUAGE_SW].useDesktopModal.text}</p>
        {moment(userCreatedAt).isAfter(moment().subtract(7, 'days')) && (
          <div className="m-t-3">
            <strong>{Texts[window.LANGUAGE_SW].useDesktopModal.forNewLeads}</strong>
            <p>{Texts[window.LANGUAGE_SW].useDesktopModal.confirmEmailHint}</p>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => setUseDesktopModalVisibility(!isUseDesktopModalIsOpen)}
          className="w-100-p"
          color="success"
        >
          {Texts[window.LANGUAGE_SW].useDesktopModal.confirmed}
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;

function mapStateToProps({ message: { isUseDesktopModalIsOpen }, auth }) {
  return {
    isUseDesktopModalIsOpen,
    userCreatedAt: auth.created_at || moment()
  };
}

export default connect(mapStateToProps, actions)(UseDesktopModal);
