import React from 'react';
import { FaBookOpen } from 'react-icons/fa';
import Texts from '../../json/texts.json';
import { get } from 'lodash';

const onClickHelpLink = function (e) {
  try {
    const href = get(e, 'target.href', '');

    if (href.includes('articles') && get(window, 'Intercom.booted')) {
      const articleId = href.substring(href.lastIndexOf('/') + 1);

      if (articleId) {
        // @ts-ignore
        window.Intercom('showArticle', articleId);
        e.preventDefault();
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const HelpLink = ({ href, className = '', target = '', hideSmall = false, video = false }) => (
  <span>
    <a
      href={href || 'https://support.scrappbook.de'}
      target={target || '_blank'}
      className={
        className
          ? `${className} d-none d-md-block help-link`
          : 'float-right d-none d-md-block help-link'
      }
      onClick={onClickHelpLink}
    >
      {video ? Texts[window.LANGUAGE_SW].videoTutorial : Texts[window.LANGUAGE_SW].showHelp}
      {video ? (
        <i className="fa fa-play-circle" style={{ marginLeft: '0.5rem' }} />
      ) : (
        <FaBookOpen style={{ marginLeft: '0.5rem' }} size={18} />
      )}
    </a>
    {!hideSmall && (
      <a
        href={href || 'https://support.scrappbook.de'}
        target={target || '_blank'}
        className={
          className ? `${className} d-md-none help-link` : 'float-right d-md-none help-link'
        }
        onClick={onClickHelpLink}
      >
        {video ? (
          <i className="fa fa-play-circle" style={{ marginLeft: '0.3rem' }} />
        ) : (
          <FaBookOpen style={{ marginLeft: '0.3rem' }} size={18} />
        )}
      </a>
    )}
  </span>
);

export default HelpLink;
