import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';

export default ({ input, field: { text, checked } }) => (
  <FormGroup check>
    <Label check>
      <Input type="checkbox" {...input} checked={checked} />
      <small>{text}</small>
    </Label>
  </FormGroup>
);
