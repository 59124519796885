import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../assets/css/theme';
import { Theme, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import LabelItem from '../Forms/LabelItem';
import { getTranslationKey } from '../../modules/utils';
import { partial, dropRight } from 'lodash';
import { FaTimes, FaSearch } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getCollectionsNew, getRecipientsListSelector } from '../../modules/selectors';
import { fetchRecipientsAction, showNotifyMessage } from '../../modules/actions';
// tslint:disable-next-line:no-implicit-dependencies
import validateEmail from '../../helpers/validateEmail';
import { Exist } from '../Exist';
import useDeliveryForm from './useDeliveryForm';

const Filter = styled.div`
  margin-bottom: 10px;
  width: 100%;
  min-width: 200px;
  margin-right: auto;

  .react-select__option--is-focused {
    background: #ffffff;
  }

  .react-select__option--is-selected {
    color: ${theme.textColors.baseText};
    background: #ffffff;
  }

  .react-select__multi-value__remove {
    height: 100%;
    cursor: pointer;
    color: ${theme.commonColors.second};
  }

  .react-select__indicator {
    display: none;
  }

  .react-select__menu {
    overflow: hidden;
  }

  .react-select__placeholder {
    color: #c7c7c7;
  }

  .react-select__indicators {
    display: none;
  }
`;
const SearchIcon = styled(FaSearch)`
  margin-right: 5px;
`;
const ErrorWrapper = styled.div`
  color: #ff003a;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
`;

const getSelectTheme = (selectTheme: Theme) => ({
  ...selectTheme,
  borderRadius: 20,
  colors: {
    ...selectTheme.colors,
    primary25: theme.commonColors.second,
    primary: theme.commonColors.second,
    primary50: '#fff'
  }
});

const Placeholder = (props: any) => (
  <components.Placeholder {...props}>
    <SearchIcon />
    {getTranslationKey('collectionSettings.sendEmail.recipientsPlaceholder')}
  </components.Placeholder>
);

interface IProps {
  errorText?: string;
}

interface IEmailItem {
  label: string;
  value: string;
}

// tslint:disable-next-line:max-func-body-length
export const EmailRecipientField: React.FC<IProps> = ({ errorText }) => {
  const dispatch = useDispatch();
  const [deliveryForm, setDeliveryForm] = useDeliveryForm();
  const emails = deliveryForm.recipientsEmails.map((item) => ({ label: item, value: item }));
  const setEmails = (emails: IEmailItem[]) =>
    setDeliveryForm({ recipientsEmails: emails.map((email) => email.value.toLowerCase()) });
  const [openRecipientsState, changeOpenRecipientsState] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const recipients = useSelector(getRecipientsListSelector);
  const collection = useSelector(getCollectionsNew);
  const [recipientsOptions, setRecipientsOptions] = useState<any>([]);

  useEffect(() => {
    // @ts-ignore
    const options = recipients.map((item) => ({ label: item, value: item }));
    setRecipientsOptions(options);
  }, [recipients]);

  useEffect(() => {
    if (recipientsOptions.length) {
      changeOpenRecipientsState(true);
    }
  }, [recipientsOptions]);

  useEffect(() => {
    if (!collection.mainCustomers?.length) return;

    const mainCustomersEmails = collection.mainCustomers.map((customer) => ({
      label: customer.email,
      value: customer.email
    }));

    setEmails(mainCustomersEmails);
  }, [collection.mainCustomers]);

  const onSearchHandler = (value: string, meta: any) => {
    const inputSearchValue = value.replace(',', '').trim();
    if (meta.action === 'input-change') {
      setSearchValue(inputSearchValue);
      if (value.length) {
        dispatch(fetchRecipientsAction(inputSearchValue));
      }
    }
    if (meta.action === 'set-value') {
      setSearchValue(inputSearchValue);
    }
  };

  const onKeyChange = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 32 || e.keyCode === 188) {
      const inputSearchValue = searchValue.replace(',', '').trim();
      if (!validateEmail(searchValue)) {
        dispatch(showNotifyMessage('The text should be an email type'));

        return;
      }
      const uniqEmail = emails.filter((email) => email.value !== inputSearchValue);
      uniqEmail.push({ label: inputSearchValue, value: inputSearchValue });
      setEmails(uniqEmail);
      setSearchValue('');
    }

    if ([8, 46].indexOf(e.keyCode) > -1 && !searchValue) {
      setEmails(dropRight(emails, 1));
    }
  };

  const removeEmail = (data: string, e: React.SyntheticEvent) => {
    e.stopPropagation();
    setEmails(emails.filter((email) => email.value !== data));
  };

  const MultiValueRemove = (props: any) => (
    <div onClick={partial(removeEmail, props.data.value)} role="button">
      <components.MultiValueRemove {...props}>
        <FaTimes />
      </components.MultiValueRemove>
    </div>
  );

  const changeRecipientsHandler = (data: any, meta: any) => {
    if (!meta || !meta.option) return;
    setEmails(data);
  };

  const onBlurHandler = () => {
    setRecipientsOptions([]);
    changeOpenRecipientsState(false);
    if (!searchValue.length) return;

    const uniqEmail = emails.filter((email) => email.value !== searchValue);
    uniqEmail.push({ label: searchValue, value: searchValue });
    setEmails(uniqEmail);
    setSearchValue('');
  };

  return (
    <Filter>
      <LabelItem isBold>
        {getTranslationKey('collectionSettings.sendEmail.recipientsLabel')}
      </LabelItem>
      <CreatableSelect
        optionClassName="needsclick"
        classNamePrefix="react-select"
        inputValue={searchValue}
        isSearchable
        isMulti
        value={emails}
        onChange={changeRecipientsHandler}
        onInputChange={onSearchHandler}
        onKeyDown={onKeyChange}
        options={recipientsOptions}
        components={{ MultiValueRemove, Placeholder }}
        menuIsOpen={openRecipientsState}
        onBlur={onBlurHandler}
        closeMenuOnSelect={false}
        hideSelectedOptions
        theme={getSelectTheme}
      />
      <Exist when={Boolean(errorText)}>
        <ErrorWrapper>{errorText}</ErrorWrapper>
      </Exist>
    </Filter>
  );
};
