import { createReducer } from 'redux-act';
import { IAccessRules } from '../../../modules/types';
import { PUT_ACCESS_RULES } from '../../actions';

const initialState: IAccessRules = {
  availableRules: {
    brandsLeft: null,
    canCreateCustomWatermark: false,
    canCustomimzeDesign: false,
    collectionPresetsLeft: null,
    collectionsLeft: null,
    imageStorageLeftMb: null,
    name: '',
    domainsLeft: null,
    slideshowsLeft: null,
    videoStorageLeftMb: null
  },
  isLoadingAccessRules: false,
  planConfig: {
    availableCollectionTemplates: [],
    brands: null,
    collectionPresets: null,
    collections: null,
    customDesign: false,
    customWatermark: false,
    domains: null,
    slideshows: null,
    imageStorageMb: null,
    videoStorageMb: null,
    name: ''
  },
  planUsage: {
    storageSpaceConsumptionMb: null,
    videoStorageSpaceConsumptionMb: null,
    collections: null,
    collectionsWithCustomDesign: null,
    brands: null,
    watermarks: null,
    gallerypresets: null,
    domains: null,
    slideshows: null,
    userId: null
  }
};

export const accessRules = createReducer<IAccessRules>({}, initialState);

accessRules.on(PUT_ACCESS_RULES, (state: IAccessRules, payload: any) => payload || {});
