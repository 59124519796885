import { IDictionary, IShopSalesAutomation } from '../../modules/types';

export const COLLECTION_SETTINGS_TABS = [
  {
    id: 'images',
    label: 'images'
  },
  {
    id: 'settings',
    label: 'settings'
  },
  {
    id: 'design',
    label: 'design',
    filterUserType: 'VST'
  },
  {
    id: 'selections',
    label: 'selections'
  },
  {
    id: 'activities',
    label: 'activities'
  },
  {
    id: 'shop',
    label: 'shop'
  },
  {
    id: 'delivery',
    label: 'delivery'
  }
];
export const DEFAULT_SETTINGS_TAB = 'images';

export const COLLECTION_LINK_BY_USER_TYPE: IDictionary<string> = {
  Basic: 'https://app.leadsuite.eu/',
  Studio: 'https://apps.leadsuite.eu/',
  PP: 'https://app.picturepeople.de/',
  SB: 'https://apps.scrappbook.de/',
  VST: 'https://www.gallerypix.de/'
};

export const COLLECTION_SHARE_URL: IDictionary<string> = {
  facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
  twitter: 'https://twitter.com/intent/tweet?url=',
  pinterest: 'https://www.pinterest.com/pin/create/button/?url=',
  yahoo: 'mailto:?body=',
  default: 'mailto:?body='
};

export const COLLECTION_SHARE_BUTTONS = [
  { id: 'facebook', target: '_blank', label: 'Facebook' },
  { id: 'twitter', target: '_blank', label: 'Twitter' },
  { id: 'pinterest', target: '_blank', label: 'Pinterest' }
];

export const COLLECTION_IMAGES_FILTER_OPTIONS = [
  {
    label: 'Select all images',
    value: 'all'
  },
  {
    label: 'All images with likes',
    value: 'liked'
  },
  {
    label: 'All images without likes',
    value: 'notLiked'
  }
];

export const COLLECTION_SHOP_AUTOMATION_DEFAULTS: IShopSalesAutomation = {
  couponCodeAdvertising: {
    enabled: false,
    startInDays: 0,
    endsAfterDays: 1,
    couponId: null,
    amountOfImagesBetweenBanners: 10
  },
  abandonedShoppingCartReminder: {
    enabled: false
  },
  emptyCartProductSuggestion: {
    enabled: false
  },
  freeShipping: {
    enabled: false,
    startsFrom: 99
  },
  couponCodeAfterPurchase: {
    enabled: false,
    couponId: null
  },
  galleryExpires: {
    enabled: false,
    expirationDate: null,
    expiresInDays: null
  },
  emailPackage: ''
};
