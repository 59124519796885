import { useEffect, useRef } from 'react';

/**
 * Executes a callback when a state changes
 *
 * @param state - the state to watch for changes
 * @param cb - the callback wich will be called when the state changes
 * Callback params:
 * @param prevState - the previous state value
 * @param currState - the current state value
 */
export function useStateChange<TState>(
  state: TState,
  cb: (prevState: TState, currState: TState) => void
) {
  const ref = useRef(state);
  useEffect(() => {
    cb(ref.current, state);
    ref.current = state;
  }, [state, cb]);
}
