import React from 'react';
import { getTranslationKey } from '../../../../modules/utils/helpers/index';
import { getModalData } from '../../../../modules/selectors';
import { useSelector } from 'react-redux';
import { getAnswersData } from '../data';
import styled from 'styled-components';

interface ITextAreaFields {
  handleValue: (name: string, value: string) => void;
}

const StyledTextArea = styled.textarea`
  && {
    height: 85px;
    resize: none;
  }
`;

const TextAreaFields = ({ handleValue }: ITextAreaFields) => {
  const modalData = useSelector(getModalData);
  const { activeRadio, firstAnswer = '', secondAnswer = '' } = modalData;
  const answersMap = getAnswersData();

  return (
    <div>
      <div>
        <span>{answersMap[activeRadio].answer1}</span>
        <div className="form-group m-b-5 mt-1">
          <StyledTextArea
            className="form-control"
            name="firstAnswer"
            placeholder={getTranslationKey('cancelSubscriptionModal.answers.placeholder')}
            value={firstAnswer}
            onChange={({ target: { name, value } }) => handleValue(name, value)}
          />
        </div>
      </div>

      {answersMap[activeRadio].answer2 && (
        <div className="mt-2">
          <span>{answersMap[activeRadio].answer2}</span>
          <div className="form-group m-b-5 mt-1">
            <StyledTextArea
              className="form-control"
              name="secondAnswer"
              placeholder={getTranslationKey('cancelSubscriptionModal.answers.placeholder')}
              value={secondAnswer}
              onChange={({ target: { name, value } }) => handleValue(name, value)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TextAreaFields;
