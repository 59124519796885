import React from 'react';
import { IPreset } from '../../../modules/reducers/presets';
import { getTranslationKey } from '../../../modules/utils/helpers';
import { Col, Row } from 'reactstrap';
import { IDictionary } from '../../../modules/types';
import { partial } from 'lodash';
import { SwitchItem } from '../../../components';
import styled from 'styled-components';

const switchDirection = 'row-reverse';
const switchMargin = '0 0 7px';

const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

interface IProps {
  preset: IPreset;
  setFormValueCallback: (data: IDictionary<string | number | boolean>) => void;
}

// tslint:disable-next-line:max-func-body-length
export const PresetSwitches = ({ preset, setFormValueCallback }: IProps) => {
  const { download, downloadPin, showAppInstall, sharing, comments, showImageCaptionInGallery } =
    preset;

  // useEffect( () => {
  // 	if( pricingOptions.length && !_productPricingList ) {
  // 		const firstPriceID = get( pricingList, '[0]._id', '' );
  // 		setFormValueCallback( {
  // 			_productPricingList: firstPriceID
  // 		} )
  // 	}
  // }, [pricingOptions] )
  const changeFormHandler = (path: string, value: string | number | boolean) => {
    setFormValueCallback({
      [path]: value
    });
  };

  return (
    <Row>
      <ColStyled sm={6}>
        <SwitchItem
          value={download}
          changeCallback={partial(changeFormHandler, 'download')}
          label={getTranslationKey('collectionSettings.download')}
          direction={switchDirection}
          margin={switchMargin}
        />
        <SwitchItem
          value={showAppInstall}
          changeCallback={partial(changeFormHandler, 'showAppInstall')}
          label={getTranslationKey('collectionSettings.appInstallHint')}
          direction={switchDirection}
          margin={switchMargin}
        />
        <SwitchItem
          value={comments}
          changeCallback={partial(changeFormHandler, 'comments')}
          label={getTranslationKey('collectionSettings.comment')}
          direction={switchDirection}
          margin={switchMargin}
        />
      </ColStyled>
      <ColStyled sm={6}>
        <SwitchItem
          value={downloadPin}
          changeCallback={partial(changeFormHandler, 'downloadPin')}
          label={getTranslationKey('collectionSettings.downloadPin')}
          direction={switchDirection}
          margin={switchMargin}
        />
        <SwitchItem
          value={sharing}
          changeCallback={partial(changeFormHandler, 'sharing')}
          label={getTranslationKey('collectionSettings.sharing')}
          direction={switchDirection}
          margin={switchMargin}
        />
        <SwitchItem
          value={showImageCaptionInGallery}
          changeCallback={partial(changeFormHandler, 'showImageCaptionInGallery')}
          label={getTranslationKey('collectionSettings.showImageCaptionInGallery')}
          direction={switchDirection}
          margin={switchMargin}
        />
      </ColStyled>
    </Row>
  );
};
