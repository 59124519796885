import { createSelector } from 'reselect';
import { IStoreState } from '../types';
import { ifImplementsKeys } from '../../helpers/objectFunctions';
import { BILLING_ADD_FIELDS } from '../../modules/constants/subscriptions';
import { IAdressInterface } from '../../modules/types/paymentInfo';

export const getPaymentInfo = (state: IStoreState) => state.paymentInfo;
export const getPaymentsWithProblem = (state: IStoreState) => [
  ...getPaymentInfo(state).subscriptions.filter(
    (item) =>
      item.isPaymentProblem &&
      (state.paymentInfo.paymentProvider === 'stripe' ? item.receiptUrl : item.renewUrl)
  ),
  ...getPaymentInfo(state).oneTimePurchases.filter((item) => item.isPaymentProblem && item.renewUrl)
];

export const getIsBillingAddressFilled = createSelector(getPaymentInfo, ({ address, userId }) => {
  let isFilled = true;

  if (userId) {
    if (ifImplementsKeys(address, BILLING_ADD_FIELDS)) {
      Object.keys(address).forEach((key) => {
        if (
          !address[key as keyof IAdressInterface] ||
          address[key as keyof IAdressInterface] === ''
        ) {
          isFilled = false;
        }
      });
    } else {
      return false;
    }
  }

  return isFilled;
});

export const getIsSepaPending = (state: IStoreState) =>
  getPaymentInfo(state).subscriptions.some((item) => item.isSepaPending) ||
  getPaymentInfo(state).oneTimePurchases.some((item) => item.isSepaPending);
