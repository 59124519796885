import React from 'react';
import { Col, Row } from 'reactstrap';
import { getTranslationKey } from '../modules/utils';
import { ShopTaxSetupForm } from '../components/ShopTaxSetup/ShopTaxSetupForm';
import Card, { CardBody, CardHeader, CardTitle } from '../components/Card';

const PageLayout = ({ children }: { children: any }) => (
  <div className="animated fadeIn">
    <Row>
      <Col sm="12" md="12" lg="6">
        {children}
      </Col>
    </Row>
  </div>
);

export const TaxSettings = () => (
  <PageLayout>
    <Card>
      <CardHeader>
        <CardTitle>{getTranslationKey('settingsSection.taxSettingMenuLabel')}</CardTitle>
      </CardHeader>
      <CardBody>
        <ShopTaxSetupForm isFullForm />
      </CardBody>
    </Card>
  </PageLayout>
);

export default TaxSettings;
