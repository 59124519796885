import React from 'react';
import { connect } from 'react-redux';
import Texts from '../../../json/texts.json';
import * as actions from '../../../actions';
import { Input, Label } from 'reactstrap';

const LanguageSelectApps = ({ onChange, selectedValue, label }) => (
  <div>
    <Label className="font-weight-bold">
      {label || Texts[window.LANGUAGE_SW].selectLanguageApps}:
    </Label>
    <Input type="select" value={selectedValue} onChange={onChange}>
      {Object.keys(Texts[window.LANGUAGE_SW].languages).map((languageKey) => (
        <option key={languageKey} value={languageKey}>
          {Texts[window.LANGUAGE_SW].languages[languageKey]}
        </option>
      ))}
    </Input>
  </div>
);

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps, actions)(LanguageSelectApps);
