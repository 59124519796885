import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../modules/selectors';
import { getPaymentInfo } from '../modules/selectors/paymentInfo';
import { useEffect } from 'react';
import { fetchPaymentInfoAction } from '../modules/actions';

export function useUserIsStripeCustomer() {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { subscriptions } = useSelector(getPaymentInfo);
  const coreSub = subscriptions?.find((sub) => sub.subscriptionType === 'core');

  useEffect(() => {
    dispatch(fetchPaymentInfoAction());
  }, []);
  const userIsStripeCustomer =
    !!user.subscription?.stripe?.customerId &&
    !!coreSub &&
    !coreSub?.isCanceled &&
    !coreSub?.isPaymentProblem;

  return userIsStripeCustomer;
}
