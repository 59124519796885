import axios from '../helpers/axios';
import Texts from '../json/texts.json';
import { handleErrorWithRedirectIfNotAuth } from '../errors/generalError';
import { addNotification as notify } from 'reapop';
import { accessRulesAction } from '../modules/actions';
import { changeModalState } from '../modules/actions/modals';

export const createCollection =
  ({ title, name, eventDate, userBrand, templateId, galleryPresetId }) =>
  async (dispatch) => {
    try {
      const res = await axios.post('/api/collection/create/', {
        title,
        name,
        eventDate,
        userBrand,
        templateId,
        galleryPresetId
      });

      dispatch(accessRulesAction());

      return { collection_id: res.data._id };
    } catch (e) {
      if (e.response.status === 402) {
        dispatch(changeModalState({ key: 'collectionModal', state: false }));
        dispatch(
          changeModalState({ key: 'upgradeModal', state: true, desiredAction: 'collections' })
        );
        dispatch({ type: 'end_current_help_campaign_step' });

        return { err: 'paymentRequired' };
      }
      if (e.response.status === 426) {
        return { err: 'templatePurchase' };
      }
      handleErrorWithRedirectIfNotAuth(e);

      return { err: 'generalError' };
    }
  };

export const getScrappbookTemplates = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/scrappbookGalleryTemplates');

    dispatch({
      type: 'SET_SCRAPPBOOK_TEMPLATES',
      payload: res.data
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCollectionsIds =
  ({ withInitialCollectionsCount }) =>
  async (dispatch) => {
    try {
      const collectionsIds = await axios.get('/api/collections/ids');

      if (collectionsIds.data.error) {
        throw collectionsIds.data.error;
      }
      dispatch({ type: 'SET_COLLECTION_IDS', payload: collectionsIds.data.result });

      if (withInitialCollectionsCount) {
        dispatch({
          type: 'SET_INITIAL_COLLECTIONS_AMOUNT',
          payload: collectionsIds.data.result.length
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

export const getCollectionPasswordById = (collection_id) => async (dispatch) => {
  try {
    const res = await axios.get('/api/collection/password', {
      params: { collection_id }
    });

    dispatch({
      type: 'UPDATE_COLLECTION_BY_PATH',
      payload: { path: 'password', collection_id, value: res.data.password }
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCollectionDownloadPinById = (collection_id) => async (dispatch) => {
  try {
    const res = await axios.get('/api/collection/downloadPin', {
      params: { collection_id }
    });

    dispatch({
      type: 'UPDATE_COLLECTION_BY_PATH',
      payload: {
        path: 'downloadPin',
        collection_id,
        value: res.data.downloadPin
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCollectionById = (collection_id) => async (dispatch) => {
  try {
    const res = await axios.get('/api/collection', {
      params: {
        id: collection_id,
        ownerRequest: true
      }
    });

    // this is a workaround that could be done nicer
    // ideally we would fetch images separately in their own action
    const { data: images } = await axios.get('/api/images', {
      params: {
        collection_id
      }
    });

    const site = res.data.result.data;

    site.images = images;

    dispatch({ type: 'ADD_COLLECTION', payload: site });
    dispatch({
      type: 'FETCH_COLLECTION_GALLERYS',
      payload: site
    });
  } catch (e) {
    console.log(e);
  }
};

export const updateCollectionValueByPath =
  (collection_id, path, value, sendToServer = false) =>
  async (dispatch) => {
    try {
      let res = null;
      if (sendToServer) {
        res = await axios.put('/api/collection/update', {
          collection_id,
          path,
          value
        });

        dispatch(
          notify({
            title: Texts[window.LANGUAGE_SW].success,
            message: Texts[window.LANGUAGE_SW].saved,
            status: 'success',
            position: 'tc',
            dismissible: true,
            dismissAfter: 1500
          })
        );

        dispatch({
          type: 'UPDATE_COLLECTION_BY_PATH',
          payload: {
            path: res.data.path,
            value: res.data.value,
            collection_id
          }
        });
      } else {
        dispatch({
          type: 'UPDATE_COLLECTION_BY_PATH',
          payload: {
            path,
            value,
            collection_id
          }
        });
      }
    } catch (e) {
      console.log(e);
      handleErrorWithRedirectIfNotAuth(e);
    }
  };

export const deleteGalleryFromCollection = (data) => async (dispatch) => {
  try {
    await axios.delete('/api/collection/gallery', { params: data });

    dispatch({
      type: 'DELETE_GALLERY_FROM_COLLECTION',
      payload: data
    });

    dispatch({
      type: 'REMOVE_IMAGES_FROM_SELECTIONS',
      payload: data
    });

    dispatch(
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].saved,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 1500
      })
    );

    return true;
  } catch (e) {
    console.log(e);

    return false;
  }
};

export const orderGallerys = (data) => async (dispatch) => {
  try {
    axios.post('/api/collection/gallery/order', {
      gallerys: data.sortedGallerys.map((gal) => gal._id),
      collection_id: data.collection_id
    });

    dispatch({
      type: 'ORDER_COLLECTION_GALLERYS',
      payload: data
    });

    dispatch(
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].saved,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 1500
      })
    );
  } catch (e) {
    console.log(e);
  }
};
