import { createAction } from 'redux-act';
import {
  ICreateMessageParams,
  IInitiateMessageImageUploadParams,
  IMessage,
  IUpdateMessageParams,
  IUploadMessageImageParams
} from '../types/message';

export const fetchMessagesAction = createAction();
export const setMessagesAction = createAction<IMessage[]>();
export const createMessageAction = createAction<ICreateMessageParams>();
export const updateMessageAction =
  createAction<{ id: string; sendToServer: boolean; update: IUpdateMessageParams }>();
export const initiateMessageImageUploadAction = createAction<
  string,
  IInitiateMessageImageUploadParams
>();
export const uploadMessageImageAction = createAction<IUploadMessageImageParams>();
export const deleteMessageAction = createAction<string>();
export const setCurrentMessageAction = createAction<string>();
export const setIsImageUploadingAction = createAction<boolean>();
