import { IStoreState } from '../types';
import { createSelector } from 'reselect';
import { find } from 'lodash';

export const getPresetsStore = ({ presets }: IStoreState) => presets;
export const getPresetsListSelector = createSelector(
  getPresetsStore,
  (presetsStore) => presetsStore.presetsItems
);
export const getIsPresetsListFetched = createSelector(
  getPresetsStore,
  (presetsStore) => presetsStore.isListFetched
);
export const getPresetByIdSelector = createSelector(
  getPresetsListSelector,
  (presets) => (presetId: string) => find(presets, { _id: presetId })
);
export const getDefaultPreset = createSelector(getPresetsListSelector, (presets) =>
  find(presets, { default: true })
);
