import { createReducer } from 'redux-act';
import { ISlideshowStatePresent, ISong } from '../../../modules/types/slideshow';

import * as actions from '../../../modules/actions';

const initialState: ISlideshowStatePresent = {
  name: 'slideshow',
  goToSlideshowPage: false,
  background: {
    color: '#000000'
  },
  slides: [],
  durationSeconds: 0,
  isSlidesFetched: false,
  collectionId: '',
  selectedImagesForSlideshow: [],
  soundtracks: [],
  id: '',
  tracksDuration: null,
  crossfade: 'normal',
  speed: 'nomral',
  isLoading: false,
  isShowBeatMatch: false,
  isFitAll: false,
  albumId: '',
  seekedTime: null,
  isSlideshowPlaying: false,
  playSlideshowPlayer: null,
  logoModalAdd: false,
  status: '',
  render: {
    status: 'none'
  },
  isLoadingSlideshowSoundtracks: true
};

export const slideshow = createReducer<ISlideshowStatePresent>({}, initialState);
slideshow.on(actions.setIsSlideshowPlaying, (state, payload) => ({
  ...state,
  isSlideshowPlaying: payload
}));
slideshow.on(actions.goToSlideshow, (state: ISlideshowStatePresent, payload: boolean) => ({
  ...state,
  goToSlideshowPage: payload
}));
slideshow.on(actions.fetchSlideshowSuccess, (state: ISlideshowStatePresent, payload: any) => {
  const tracksDuration = payload.result.soundtracks
    ? payload.result.soundtracks.reduce(
        (acc: number, track: ISong) => acc + track.durationSeconds,
        0
      )
    : null;
  return {
    ...state,
    ...payload.result,
    isSlidesFetched: true,
    tracksDuration
  };
});
slideshow.on(
  actions.updateSlideshowBackground,
  (state: ISlideshowStatePresent, payload: string) => ({
    ...state,
    background: { color: payload }
  })
);
slideshow.on(actions.updateSlideshowSuccess, (state, payload) => {
  const newState = {
    ...state,
    ...payload.result
  };
  newState.isFitAll = newState.speed === 'fit-all-slides-in-track';
  newState.isShowBeatMatch = newState.speed.includes('beat');

  return newState;
});
slideshow.on(actions.removeSlides, (state, payload) => ({
  ...state,
  slides: payload.updatedSlides
}));
slideshow.on(actions.updateWholeState, (state, payload) => ({
  ...state,
  ...payload.result
}));
slideshow.on(actions.selectSlide, (state, payload) => ({
  ...state,
  selectedSlide: payload
}));
slideshow.on(actions.addTitleSlideSuccess, (state, payload) => ({
  ...state,
  slides: payload.result.slides
}));
slideshow.on(actions.updateSeekedTime, (state, payload) => ({
  ...state,
  seekedTime: payload
}));
slideshow.on(actions.selectImageForSlideshow, (state, payload) => ({
  ...state,
  selectedImagesForSlideshow: payload
}));
slideshow.on(actions.addPhotosSuccess, (state, payload) => ({
  ...state,
  slides: payload.slides,
  slideshowOnboarding: payload.slideshowOnboarding,
  durationSeconds: payload.durationSeconds
}));
slideshow.on(actions.reorderSlideshow, (state, payload) => ({
  ...state,
  slides: payload.slides
}));
slideshow.on(actions.changeSlideshowParameters, (state, payload: any) => ({
  ...state,
  [payload.key]: payload[payload.key]
}));
slideshow.on(actions.reorderTracks, (state, payload) => {
  return {
    ...state,
    soundtracks: payload.soundtracks,
    tracksDuration: payload.tracksDuration ? payload.tracksDuration : state.tracksDuration
  };
});
slideshow.on(actions.changeLoadingStatus, (state, payload) => ({
  ...state,
  isLoading: payload
}));
slideshow.on(actions.setIsShowBeatMatch, (state, payload) => ({
  ...state,
  isShowBeatMatch: payload
}));
slideshow.on(actions.setIsFitAll, (state, payload) => ({
  ...state,
  isFitAll: payload
}));
slideshow.on(actions.setPlaySlideshowPlayer, (state, payload) => ({
  ...state,
  playSlideshowPlayer: payload
}));
slideshow.on(actions.setEditableSlide, (state, payload) => ({
  ...state,
  editableSlide: payload
}));
slideshow.on(actions.setLogoModalAddStatus, (state, payload) => ({
  ...state,
  logoModalAdd: payload
}));
slideshow.on(actions.clearAll, () => initialState);
slideshow.on(actions.changeLoadingSlideShowMusic, (state, payload) => ({
  ...state,
  isLoadingSlideshowSoundtracks: payload
}));
