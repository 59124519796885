/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { InputItem } from '../Forms/InputItem';
import { SwitchItem } from '../Forms/SwitchItem';
import { partial, set, debounce } from 'lodash';
import { getTranslationKey } from '../../modules/utils';
import { SalesCouponSelection } from './SalesCouponSelection';
import { COLLECTION_SHOP_AUTOMATION_DEFAULTS } from '../../modules/constants';
import { IShopSalesAutomation } from '../../modules/types';
import { FormTooltip } from '../Forms/InputTooltip';
import styled from 'styled-components';
import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getUser } from '../../modules/selectors';
import { SelectItem } from '../Forms/SelectItem';
import { IStoreState } from '../../modules/types/global';

const SelectWrapper = styled.div`
  max-width: 360px;
`;

const InlineForm = styled.div<{ offsetLeft?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) => props.offsetLeft && 'margin-left: 38px;'}
`;
const SmallInputWrapper = styled.div`
  &:not(:first-child) {
    margin-left: 20px;
  }
  max-width: 140px;
  display: flex;
  align-items: center;

  .form-tooltip {
    margin-left: 5px;
  }
`;

const switchDirection = 'row-reverse';
const formMargin = '15px 0 20px 0';
const codeAdvLink = 'https://support.scrappbook.de/de/articles/61943';
const abandonedCartLink = 'https://support.scrappbook.de/de/articles/61945';
const emptyCartSuggestionLink = 'https://support.scrappbook.de/de/articles/61946';
const freeShippingLink = 'https://support.scrappbook.de/de/articles/61947';
const afterPurchaseLink =
  'https://support.scrappbook.de/de/articles/61948-automatischer-gutscheincode-nach-dem-kauf';

interface IProps {
  isShopActive: boolean;
  updateCallback?: (data: IShopSalesAutomation) => void;
  values: IShopSalesAutomation;
  debounceUpdates?: boolean;
  updateWithInvalidValues?: boolean;
}

// tslint:disable-next-line:max-func-body-length
export const SalesAutomationForm: React.FC<IProps> = ({
  isShopActive,
  updateCallback,
  values,
  debounceUpdates = true,
  updateWithInvalidValues = false
}) => {
  const { selectionOptions } = useSelector((state: IStoreState) => state.emailSettings);
  const [automationState, setAutomationState] = useState(COLLECTION_SHOP_AUTOMATION_DEFAULTS);
  const user = useSelector(getUser);
  const { couponCodeAdvertising } = automationState;
  const isFormDisabled = !isShopActive;
  const callUpdateCallback = (value: any) => {
    if (updateCallback) {
      updateCallback(value);
    }
  };

  let debouncedCallback;

  debouncedCallback = useCallback(debounce(callUpdateCallback, 1000), [values]);
  if (!debounceUpdates) {
    debouncedCallback = callUpdateCallback;
  }

  const saveValues = debouncedCallback;

  useEffect(() => {
    if (values && values !== automationState) {
      setAutomationState(values);
    }
  }, [values]); // eslint-disable-line

  const validateData = (data: IShopSalesAutomation) => {
    const { endsAfterDays, startInDays } = data.couponCodeAdvertising;
    const isEndsAfterDaysValidated = endsAfterDays < startInDays;
    const isStartInDaysValidated = couponCodeAdvertising.startInDays < 0;
    if (!isEndsAfterDaysValidated) {
      return;
    }

    return {
      ...automationState,
      couponCodeAdvertising: {
        ...couponCodeAdvertising,
        // endsAfterDays,
        // startInDays
        endsAfterDays: isEndsAfterDaysValidated ? Number(startInDays) + 1 : Number(endsAfterDays),
        startInDays: isStartInDaysValidated ? 0 : Number(startInDays)
      }
    };
  };

  const isOneCouponUsedTwice =
    automationState.couponCodeAdvertising.couponId &&
    automationState.couponCodeAfterPurchase.couponId &&
    automationState.couponCodeAfterPurchase.couponId ===
      automationState.couponCodeAdvertising.couponId;

  const updateFormValue = (fieldPath: string, value: unknown) => {
    const isNumValue = [
      'couponCodeAdvertising.startInDays',
      'couponCodeAdvertising.endsAfterDays',
      'freeShipping.startsFrom',
      'couponCodeAdvertising.amountOfImagesBetweenBanners',
      'galleryExpires.expiresInDays'
    ].includes(fieldPath);
    const fieldValue = isNumValue && value ? Number(value) : value;
    const data = set({ ...automationState }, fieldPath, fieldValue);

    setAutomationState(data);

    const isOneCouponUsedTwice =
      data.couponCodeAdvertising.couponId &&
      data.couponCodeAfterPurchase.couponId &&
      data.couponCodeAfterPurchase.couponId === data.couponCodeAdvertising.couponId;

    const isInvalid = isOneCouponUsedTwice || (isNumValue && value === '');
    if (!updateWithInvalidValues && isInvalid) return;

    const validated = validateData(data);
    saveValues(validated || data);
  };

  return (
    <>
      <SelectWrapper>
        <SelectItem
          label={getTranslationKey('shop.email-settings.package-title')}
          options={selectionOptions}
          value={automationState?.emailPackage}
          changeCallback={partial(updateFormValue, 'emailPackage')}
        />
      </SelectWrapper>
      <SwitchItem
        value={automationState.couponCodeAdvertising?.enabled}
        isDisabled={isFormDisabled}
        changeCallback={partial(updateFormValue, 'couponCodeAdvertising.enabled')}
        label={getTranslationKey('collectionShop.couponToggleLabel')}
        direction={switchDirection}
        margin="15px 0 5px 0"
        id="couponCodeAdvertising-switch"
        withTooltip
        tooltipText={getTranslationKey('learnMoreLink', {
          link: codeAdvLink,
          text_link: codeAdvLink
        })}
        isIconTooltip
      />
      <InlineForm offsetLeft>
        <SalesCouponSelection
          isDisabled={isFormDisabled}
          value={String(automationState.couponCodeAdvertising?.couponId)}
          changeCallback={partial(updateFormValue, 'couponCodeAdvertising.couponId')}
        />
        <SmallInputWrapper>
          <InputItem
            disabled={isFormDisabled}
            label={getTranslationKey('collectionShop.frequency')}
            type="number"
            min={0}
            max={1000}
            value={automationState.couponCodeAdvertising?.amountOfImagesBetweenBanners}
            changeCallback={partial(
              updateFormValue,
              'couponCodeAdvertising.amountOfImagesBetweenBanners'
            )}
            id="couponCodeAdvertising-amountOfImagesBetweenBanners"
            withTooltip
            tooltipText={getTranslationKey('collectionShop.frequencyTooltip')}
            tooltipIconPlacement="label"
          />
        </SmallInputWrapper>
      </InlineForm>
      <InlineForm offsetLeft>
        <SmallInputWrapper>
          <InputItem
            disabled={isFormDisabled}
            label={getTranslationKey('collectionShop.startInLabel')}
            pattern={/^[0-9]+$/i}
            min={0}
            value={automationState.couponCodeAdvertising?.startInDays}
            changeCallback={partial(updateFormValue, 'couponCodeAdvertising.startInDays')}
            rightAddonElement={getTranslationKey('collectionShop.daysPostfix')}
          />
        </SmallInputWrapper>
        <SmallInputWrapper>
          <InputItem
            disabled={isFormDisabled}
            label={getTranslationKey('collectionShop.endInLabel')}
            pattern={/^[0-9]+$/i}
            min={1}
            value={automationState.couponCodeAdvertising?.endsAfterDays}
            changeCallback={partial(updateFormValue, 'couponCodeAdvertising.endsAfterDays')}
            rightAddonElement={getTranslationKey('collectionShop.daysPostfix')}
          />
        </SmallInputWrapper>
      </InlineForm>
      <SwitchItem
        value={automationState.abandonedShoppingCartReminder?.enabled}
        isDisabled={isFormDisabled}
        changeCallback={partial(updateFormValue, 'abandonedShoppingCartReminder.enabled')}
        label={getTranslationKey('collectionShop.abandonedShoppingLabel')}
        direction={switchDirection}
        margin={formMargin}
        id="abandonedShoppingCartReminder-switch"
        withTooltip
        tooltipText={getTranslationKey('learnMoreLink', {
          link: abandonedCartLink,
          text_link: abandonedCartLink
        })}
        isIconTooltip
      />
      <SwitchItem
        value={automationState.emptyCartProductSuggestion?.enabled}
        isDisabled={isFormDisabled}
        changeCallback={partial(updateFormValue, 'emptyCartProductSuggestion.enabled')}
        label={getTranslationKey('collectionShop.emptyCartLProductLabel')}
        direction="row-reverse"
        margin={formMargin}
        id="emptyCartProductSuggestion-switch"
        withTooltip
        tooltipText={getTranslationKey('learnMoreLink', {
          link: emptyCartSuggestionLink,
          text_link: emptyCartSuggestionLink
        })}
        isIconTooltip
      />
      <InlineForm>
        <SwitchItem
          value={automationState.freeShipping?.enabled}
          isDisabled={isFormDisabled}
          changeCallback={partial(updateFormValue, 'freeShipping.enabled')}
          label={getTranslationKey('collectionShop.freeShippingLabel')}
          direction={switchDirection}
          margin={formMargin}
          id="freeShipping-switch"
        />
        <SmallInputWrapper>
          <InputItem
            pattern={/^[0-9]+$/i}
            disabled={isFormDisabled}
            changeCallback={partial(updateFormValue, 'freeShipping.startsFrom')}
            value={automationState.freeShipping?.startsFrom}
            placeholder="-"
            min={0}
            step="0.1"
            margin="0"
            rightAddonElement={user.currency}
          />
          <FormTooltip
            isShow
            formTheme="main"
            tooltipText={getTranslationKey('learnMoreLink', {
              link: freeShippingLink,
              text_link: freeShippingLink
            })}
            tooltipID="freeShipping-switch"
          />
        </SmallInputWrapper>
      </InlineForm>
      <SwitchItem
        value={automationState.couponCodeAfterPurchase?.enabled}
        isDisabled={isFormDisabled}
        changeCallback={partial(updateFormValue, 'couponCodeAfterPurchase.enabled')}
        label={getTranslationKey('collectionShop.couponCodeAfterLabel')}
        direction={switchDirection}
        margin="15px 0 5px 0"
        id="couponCodeAfterPurchase-switch"
        withTooltip
        tooltipText={getTranslationKey('learnMoreLink', {
          link: afterPurchaseLink,
          text_link: afterPurchaseLink
        })}
        isIconTooltip
      />
      <SalesCouponSelection
        isDisabled={isFormDisabled}
        value={String(automationState.couponCodeAfterPurchase?.couponId)}
        changeCallback={partial(updateFormValue, 'couponCodeAfterPurchase.couponId')}
        styles={{
          marginLeft: '38px'
        }}
      />
      {isOneCouponUsedTwice && (
        <Alert color="danger" style={{ marginLeft: '38px' }}>
          {getTranslationKey('collectionShop.couponCannotBeUsedTwice')}
        </Alert>
      )}
    </>
  );
};
