import React from 'react';
import styled, { css } from 'styled-components';

const LabelTitle = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
`;

interface IElementWrapperProps {
  isDisabled?: boolean;
  label?: string;
}

const getDisabledElementWrapper = ({ isDisabled }: IElementWrapperProps) =>
  isDisabled &&
  css`
    > * {
      opacity: 0.7;
      cursor: not-allowed;
    }
  `;

export const Wrapper = styled.div<IElementWrapperProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex-direction: column;
  margin-bottom: 10px;

  > input,
  select {
    width: 100%;
  }

  ${getDisabledElementWrapper};

  &.error {
    input,
    select {
      border-color: #e02120;
      color: #e02120;
    }
  }
`;
const ErrorWrapper = styled.div`
  color: #ff003a;
  font-size: 12px;
  line-height: 18px;
  margin-top: 5px;
`;

interface IProps {
  label?: string;
  className?: string;
  isDisabled?: boolean;
  errorText?: string;
  children?: any;
}

export const FormPart: React.FC<IProps> = ({
  errorText,
  label,
  children,
  className = '',
  isDisabled
}) => (
  <Wrapper className={`${className} ${errorText ? 'error' : ''}`} isDisabled={isDisabled}>
    {label ? <LabelTitle>{label}</LabelTitle> : null}
    {children}
    {errorText ? <ErrorWrapper>{errorText}</ErrorWrapper> : null}
  </Wrapper>
);
