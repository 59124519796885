import React from 'react';
import { getAnswersData } from '../data';
import { useSelector } from 'react-redux';
import { getModalData } from '../../../../modules/selectors';
import { getTranslationKey } from '../../../../modules/utils/helpers';
import { SelectItem } from '../../../Forms/SelectItem';

interface IDropDownFields {
  handleValue: (name: string, value: string) => void;
}

const DropDownFields = ({ handleValue }: IDropDownFields) => {
  const modalData = useSelector(getModalData);
  const answersMap = getAnswersData();
  const { activeRadio, firstAnswer = '', secondAnswer = '' } = modalData;

  const options = [
    { text: getTranslationKey('yes'), value: 'yes' },
    { text: getTranslationKey('no'), value: 'no' }
  ];

  return (
    <div>
      <div>
        <span>{answersMap[activeRadio].answer1}</span>
        <SelectItem
          value={firstAnswer}
          defaultOptionsText={getTranslationKey('shop.priceSelectLabel')}
          changeCallback={(value) => handleValue('firstAnswer', value)}
          options={options}
        />
      </div>

      <div className="mt-2">
        <span>{answersMap[activeRadio].answer2}</span>
        <SelectItem
          value={secondAnswer}
          defaultOptionsText={getTranslationKey('shop.priceSelectLabel')}
          changeCallback={(value) => handleValue('secondAnswer', value)}
          options={options}
        />
      </div>
    </div>
  );
};

export default DropDownFields;
