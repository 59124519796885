import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import Texts from '../../json/texts.json';
import { AppStrategy, CommonStrategy } from './StrategyModals';

function getStrategyComponent(componentData) {
  switch (componentData.currentStrategy.name) {
    case 'apps':
      return <AppStrategy {...componentData} />;
    default:
      return <CommonStrategy {...componentData} />;
  }
}

const StrategyModal = ({
  currentStrategy,
  isModalOpen,
  setStrategyModalVisibility,
  userStrategySteps,
  doStrategyStep
}) =>
  currentStrategy ? (
    <Modal
      centered
      isOpen={isModalOpen}
      toggle={() => setStrategyModalVisibility(!isModalOpen)}
      wrapClassName="modal-primary"
      size="lg"
    >
      <ModalHeader toggle={() => setStrategyModalVisibility(!isModalOpen)}>
        {currentStrategy && Texts[window.LANGUAGE_SW].strategies[currentStrategy.name].title}
      </ModalHeader>
      <ModalBody className="strategy-modal-body">
        {getStrategyComponent({
          currentStrategy,
          userStrategySteps,
          doStrategyStep
        })}
      </ModalBody>
      <ModalFooter className="text-center">
        <Button onClick={() => setStrategyModalVisibility(!isModalOpen)} color="primary">
          {Texts[window.LANGUAGE_SW].strategies.close}
        </Button>
      </ModalFooter>
    </Modal>
  ) : null;

function mapStateToProps({ strategies: { current, isModalOpen }, auth: { strategySteps } }) {
  return {
    currentStrategy: current,
    isModalOpen,
    userStrategySteps: strategySteps
  };
}

export default connect(mapStateToProps, actions)(StrategyModal);
