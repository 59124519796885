import { createReducer } from 'redux-act';
import {
  setCurrentMessageAction,
  setIsImageUploadingAction,
  setMessagesAction
} from '../../actions/message';
import { IMessage } from '../../types/message';

export interface IMessagesReducerState {
  messages: IMessage[];
  currentMessage: string | null;
  isImageUploading: boolean;
}

const initialState: IMessagesReducerState = {
  messages: [],
  currentMessage: null,
  isImageUploading: false
};

export const messages = createReducer<IMessagesReducerState>({}, initialState);
messages.on(setMessagesAction, (state: IMessagesReducerState, payload: IMessage[]) => ({
  ...state,
  messages: payload
}));
messages.on(setCurrentMessageAction, (state: IMessagesReducerState, payload: string) => ({
  ...state,
  currentMessage: payload
}));
messages.on(setIsImageUploadingAction, (state: IMessagesReducerState, payload: boolean) => ({
  ...state,
  isImageUploading: payload
}));
