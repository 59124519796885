import axios from '../helpers/axios';
import { addNotification as notify } from 'reapop';
import Texts from '../json/texts.json';

export const getSelectionsByCollection = (data) => async (dispatch) => {
  try {
    const res = await axios.get('/api/selections/collection', {
      params: {
        collection_id: data.collection._id
      }
    });

    dispatch({
      type: 'FETCH_COLLECTION_SELECTIONS',
      payload: { selections: res.data, collection_id: data.collection._id }
    });
  } catch (e) {
    console.log(e);
  }
};

export const openSelection = (selection) => async (dispatch) => {
  try {
    const res = await axios.post('/api/selection/open', {
      selection_id: selection._id
    });

    dispatch({
      type: 'UPDATE_COLLECTION_SELECTION',
      payload: { selection: res.data, key: 'completed' }
    });
  } catch (e) {
    console.log(e);
  }
};

export const deleteSelection =
  (selection, deleteFromServer = true) =>
  async (dispatch) => {
    try {
      let result = { status: 200 };

      if (deleteFromServer) {
        result = await axios.delete('/api/selection', {
          params: { selection_id: selection._id }
        });
      }

      dispatch({
        type: 'REMOVE_COLLECTION_SELECTION',
        payload: {
          selection,
          collection_id: selection._collection._id || selection._collection
        }
      });

      return result;
    } catch (e) {
      console.log(e);

      return e.response || e;
    }
  };

export const selectionCleanUp = (whatAction, whichImages, params) => async () => {
  try {
    const result = await axios.delete(`/api/selection/cleanup/${whatAction}/${whichImages}`, {
      params
    });

    return result;
  } catch (e) {
    console.log(e);

    return e.response || e;
  }
};

export const createSelectionRequest = (data) => async (dispatch) => {
  try {
    const res = await axios.put('/api/selection/createSelectionRequest', data);

    dispatch({
      type: 'ADD_COLLECTION_SELECTION',
      payload: res.data
    });

    dispatch(
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].saved,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 1500
      })
    );

    return res.data;
  } catch (e) {
    console.log(e);

    return null;
  }
};
