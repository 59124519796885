import { IStoreState } from '../types';
import { createSelector } from 'reselect';

export const getCollectionsNew = (state: IStoreState) => state.collectionNew;
export const getCollectionsCustomDomain = createSelector(
  [getCollectionsNew],
  (collection) => collection.customDomain
);
export const getCollectionId = createSelector([getCollectionsNew], (collection) => collection._id);
export const getCollectionTab = createSelector([getCollectionsNew], (collection) => collection._id);
export const getActiveTabSelector = createSelector(getCollectionsNew, ({ activeTab }) => activeTab);

export const getAllCollections = (state: IStoreState) => state.site.allCollections;
export const getCollectionsLoadingStatus = (state: IStoreState) => state.site.isLoading;
export const getTotalCollectionsCount = (state: IStoreState) => state.site.totalCollectionsCount;
export const getOldCollections = (state: IStoreState) => state.collections;
