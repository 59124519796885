import { IDictionary } from '../../modules/types';
import { TVidePlansOptions, IDeal } from '../../modules/types/subscriptions';
import moment from 'moment';
import { TBillingLength, TPlan } from '../reducers';

export const SUBSCRIPTION_PLAN_OPTIONS = [
  {
    text: 'subscription.starterLabel',
    value: 'starter'
  },
  {
    text: 'subscription.professionalLabel',
    value: 'professional'
  }
];

const signUpDiscountKeys = (process.env.REACT_APP_SIGN_UP_DISCOUNT_KEY || '').split(',');
const flasSaleDiscountKeys = (process.env.REACT_APP_FLASH_SALE_DISCOUNT_KEY || '').split(',');

export const DEAL_DISCOUNT_KEYS = [...signUpDiscountKeys, ...flasSaleDiscountKeys];

const dealStartDate = process.env.REACT_APP_DEAL_START_DATE; // Format = 2023-07-11
const dealEndDate = process.env.REACT_APP_DEAL_END_DATE;

export const IS_SHOW_DEALS_DATE =
  dealStartDate &&
  dealEndDate &&
  moment().isAfter(moment(dealStartDate)) &&
  moment().isBefore(moment(dealEndDate));

export const AVAILABLE_DEALS = [
  {
    text: 'subscription.noDealLabel',
    value: '',
    subscriptionStoreUpdate: {
      deal: undefined as IDeal,
      templates: process.env.REACT_APP_REGION === 'eu' ? ['ScrAppBook2_VickyBaumannFineart'] : []
    }
  },
  {
    // text: 'subscription.deal1LabelBF',
    text: 'subscription.deal1Label',
    value: 'deal1',
    subscriptionStoreUpdate: {
      plan: 'professional' as TPlan,
      billingCycle: 'yearly' as TBillingLength,
      deal: {
        id: 'deal1',
        freeSlideshow: true,
        enableBillingCycleSelection: false,
        enablePlanSelection: false,
        freeTemplates: []
      } as IDeal,
      slideshows: true
    }
  },
  {
    // text: 'subscription.deal2LabelBF',
    text: 'subscription.deal2Label',
    value: 'deal2',
    subscriptionStoreUpdate: {
      plan: 'starter' as TPlan,
      billingCycle: 'yearly' as TBillingLength,
      deal: {
        id: 'deal2',
        freeSlideshow: true,
        enableBillingCycleSelection: false,
        enablePlanSelection: false,
        freeTemplates: []
      } as IDeal,
      slideshows: true
    }
  }
];

/*
// Summer Sale
export const AVAILABLE_DEALS = [
  {
    text: 'subscription.noDealLabel',
    value: '',
    subscriptionStoreUpdate: {
      templates: new Array(),
      deal: undefined as IDeal
    }
  },
  {
    text: 'subscription.deal1Label',
    value: 'deal1',
    subscriptionStoreUpdate: {
      plan: 'professional' as TPlan,
      billingCycle: 'yearly' as TBillingLength,
      deal: {
        id: 'deal1',
        discountFixedAmount: 50,
        discountType: 'fixed',
        freeTemplates: ['ScrAppBook2_VickyBaumannFineart']
      } as IDeal,
      templates: ['ScrAppBook2_VickyBaumannFineart']
    }
  },
  {
    text: 'subscription.deal2Label',
    value: 'deal2',
    subscriptionStoreUpdate: {
      plan: 'starter' as TPlan,
      billingCycle: 'yearly' as TBillingLength,
      deal: {
        id: 'deal2',
        discountFixedAmount: 25,
        discountType: 'fixed',
        freeTemplates: ['ScrAppBook2_VickyBaumannFineart']
      } as IDeal,
      templates: ['ScrAppBook2_VickyBaumannFineart']
    }
  }
];
*/

export const VIDEO_ADDON_OPTIONS: IDictionary<TVidePlansOptions> = {
  monthly: [
    {
      text: '100GB - 5{currencySymbol}',
      value: 'hobby',
      price: 5
    },
    {
      text: '250GB - 10{currencySymbol}',
      value: 'basic',
      price: 10
    },
    {
      text: '500GB - 15{currencySymbol}',
      value: 'plus',
      price: 15
    }
  ],
  yearly: [
    {
      text: '100GB - 55{currencySymbol}',
      value: 'hobby',
      price: 55
    },
    {
      text: '250GB - 110{currencySymbol}',
      value: 'basic',
      price: 110
    },
    {
      text: '500GB - 165{currencySymbol}',
      value: 'plus',
      price: 165
    }
  ]
};

export const VIDEO_ADDONS_STORAGE_SIZE = {
  hobby: 100,
  starter: 250,
  professional: 500,
  default: 5
};

export const TEMPLATE_PRICE = 29;

export const SUBSCRIPTION_PLAN_PRICES: IDictionary<any> =
  process.env.REACT_APP_REGION === 'eu'
    ? {
        starter_monthly: {
          price: 19,
          galleries: 15,
          domain: 5,
          brandsPrice: 5
        },
        starter_yearly: {
          price: 174,
          galleries: 15,
          domain: 50,
          brandsPrice: 50
        },
        professional_monthly: {
          price: 39,
          galleries: 'unlimited',
          domain: 5,
          brandsPrice: 5
        },
        professional_yearly: {
          price: 348,
          galleries: 'unlimited',
          domain: 50,
          brandsPrice: 50
        }
      }
    : {
        starter_monthly: {
          price: 15,
          galleries: 15,
          domain: 5,
          brandsPrice: 5
        },
        starter_yearly: {
          price: 144,
          galleries: 15,
          domain: 50,
          brandsPrice: 50
        },
        professional_monthly: {
          price: 30,
          galleries: 'unlimited',
          domain: 5,
          brandsPrice: 5
        },
        professional_yearly: {
          price: 288,
          galleries: 'unlimited',
          domain: 50,
          brandsPrice: 50
        }
      };

export const SUBSCRIPTION_INTERVALS = {
  monthly: 'monthly',
  yearly: 'yearly'
};

// Comment any option to remove button
export const CONTRACT_LENGTH = [
  {
    key: 'monthly',
    label: 'subscription.contractMonthOption'
  },
  {
    key: 'yearly',
    label: 'subscription.contractYearOption'
  },
  {
    key: 'twoYearly',
    label: 'subscription.contract2YearOption'
  } /*,
  {
    key: "lifetime",
    label: "subscription.contractLifetime"
  }*/
];

export const SLIDESHOWS_OPTIONS = {
  starter: {
    monthly: 10,
    yearly: 100
  },
  professional: {
    monthly: 10,
    yearly: 100
  }
};

export const BILLING_ADD_FIELDS = [
  'email',
  'country',
  'firstName',
  'lastName',
  'company',
  'street',
  'streetNumber',
  'zipcode',
  'city'
];

export const FREE_PLAN_OPTIONS = {
  FREE: 'free',
  FREE_TRIAL: 'free-trial',
  FREEMIUM: 'freemium'
};
