import axios from '../helpers/axios';
import * as errors from '../errors';
import Texts from '../json/texts';

export const createAppointment = (appointment, notify) => async (dispatch) => {
  try {
    const res = await axios.post('/api/appointment', appointment);
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].timeslotPopUp.appointmentCreated,
      status: 'success',
      position: 'bl',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'ADD_APPOINTMENT', payload: res.data });
    dispatch({ type: 'TOGGLE_TIMESLOT_POPUP' });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const editAppointment = (appointment, notify) => async (dispatch) => {
  try {
    const res = await axios.put('/api/appointment', { appointment });
    dispatch({ type: 'EDIT_APPOINTMENT', payload: res.data });
    dispatch({ type: 'TOGGLE_TIMESLOT_POPUP' });
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].timeslotPopUp.appointmentEdited,
      status: 'success',
      position: 'bl',
      dismissible: true,
      dismissAfter: 3000
    });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const getAppointments = (range, notify) => async (dispatch) => {
  try {
    const res = await axios.get('/api/appointments', { params: range });
    dispatch({ type: 'SET_ALL_APPOINTMENTS', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const deleteAppointment = (appointment, notify) => async (dispatch) => {
  try {
    await axios.delete('/api/appointment/', { data: { appointment } });
    dispatch({ type: 'REMOVE_APPOINTMENT', payload: appointment._id });
    dispatch({ type: 'TOGGLE_TIMESLOT_POPUP' });
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].timeslotPopUp.appointmentDeleted,
      status: 'info',
      position: 'bl',
      dismissible: true,
      dismissAfter: 3000
    });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const toggleTimeslotPopUp = () => (dispatch) => {
  try {
    dispatch({ type: 'TOGGLE_TIMESLOT_POPUP' });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};
