import React from 'react';
import { Col } from 'reactstrap';
import Texts from '../../json/texts.json';

const PricingItem = ({ children, lg, xl, md, className, bestPrice, mostSelected }) => (
  <Col xl={xl || 4} lg={lg || 5} md={md || 6} sm="12" className="pr-md-0 pl-md-0 m-b-20">
    {(mostSelected || bestPrice) && (
      <div className="pricing-item-badge">
        <h4 className="pricing-item-badge-text">
          {bestPrice
            ? Texts[window.LANGUAGE_SW].pricingTables.best_price
            : mostSelected
            ? Texts[window.LANGUAGE_SW].pricingTables.most_selected
            : ''}
        </h4>
      </div>
    )}

    <div className={'pricing-item' + (className ? ' ' + className : '')}>{children}</div>
  </Col>
);

export default PricingItem;
