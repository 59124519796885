import { SagaIterator } from 'redux-saga';
import { put, call } from 'redux-saga/effects';
import Api from '../../../modules/utils/API';
import { errorsGlobalError } from '../../../modules/actions';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import { fetchCountriesSuccessAction } from '../../../modules/actions/countries';

export const fetchCountriesSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.Countries.get);
    ApiErrors.checkOnApiError(response);
    yield put(fetchCountriesSuccessAction(response.result));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};
