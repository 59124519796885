import { createAction } from 'redux-act';
import {
  ISountrackAction,
  ISlide,
  IColor,
  SlideshowOnboarding,
  ILogoSlidePayload
} from '../../modules/types';

// Action Types

export const UNDO_SLIDESHOW = 'UNDO_SLIDESHOW';
export const REDO_SLIDESHOW = 'REDO_SLIDESHOW';
export const UPDATE_SLIDESHOW_SUCCESS = 'UPDATE_SLIDESHOW_SUCCESS';
export const ADD_PHOTOS_SUCCESS = 'ADD_PHOTOS_SUCCESS';
export const REORDER_TRACKS = 'REORDER_TRACKS';
export const REORDER_SLIDES = 'REORDER_SLIDES';
export const FETCH_SLIDESHOW_SUCCESS = 'FETCH_SLIDESHOW_SUCCESS';
export const FETCH_SLIDESHOW = 'FETCH_SLIDESHOW';
export const CHANGE_SLIDESHOW_PARAMETERS = 'CHANGE_SLIDESHOW_PARAMETERS';
export const UPDATE_SEEKED_TIME = 'UPDATE_SEEKED_TIME';
export const CLEAR_UNDO_REDO_HISTORY = 'CLEAR_UNDO_REDO_HISTORY';

// Actions

const getPayload = (payload: any) => payload;
export const goToSlideshow = createAction(getPayload);
export const fetchSlideshow = createAction(FETCH_SLIDESHOW, getPayload);
export const fetchSlideshowSuccess = createAction(FETCH_SLIDESHOW_SUCCESS, getPayload);
export const createSlideshow = createAction(getPayload);
export const updateSlideshowBackground = createAction(getPayload);
export const updateSlideshow = createAction<any>('UPDATE_SLIDESHOW');
export const removeSlides = createAction<{ updatedSlides: ISlide[]; id: string }>('REMOVE_SLIDES');
export const updateSlideshowSuccess = createAction(UPDATE_SLIDESHOW_SUCCESS, getPayload);
export const selectSlide = createAction<ISlide | undefined | null>('SELECT_SLIDE');
export const selectImageForSlideshow = createAction('SELECT_IMAGE_FOR_SLIDESHOW', getPayload);
export const addAdditionalPhotos = createAction(getPayload);
export const addTitleSlide = createAction<{
  asset: {
    textColor: string;
    background: IColor;
    text: string;
  };
}>('ADD_TITLE_SLIDE');
export const addTitleSlideSuccess = createAction(getPayload);
export const addPhotosSuccess = createAction<{
  slideshowOnboarding: SlideshowOnboarding;
  slides: ISlide[];
  durationSeconds: number;
}>(ADD_PHOTOS_SUCCESS);
export const reorderSlideshow = createAction(REORDER_SLIDES, getPayload);
export const editSlideTitle = createAction(getPayload);
export const fetchSoundTracks = createAction(getPayload);
export const updateSeekedTime = createAction<number | null>(UPDATE_SEEKED_TIME);
export const reorderTracks = createAction(REORDER_TRACKS, getPayload);
export const removeSoundtrack = createAction<ISountrackAction[]>();
export const changeLoadingStatus = createAction<boolean>();
export const clearUndoRedoHistory = createAction(CLEAR_UNDO_REDO_HISTORY);
export const changeLoadingSlideShowMusic = createAction<boolean>();

export const undoAction = createAction();
export const redoAction = createAction();
export const undoActionSuccess = createAction(UNDO_SLIDESHOW);
export const redoActionSuccess = createAction(REDO_SLIDESHOW);
export const updateWholeState = createAction<any>();
export const changeSlideshowParameters = createAction<{
  id: string;
  name?: string;
  background?: IColor;
  key: string;
}>(CHANGE_SLIDESHOW_PARAMETERS);
export const setIsShowBeatMatch = createAction<boolean>('CHANGE_BEAT_MATCH_STATUS');
export const setIsFitAll = createAction<boolean>('CHANGE_IS_FIT_ALL');
export const scheduleRenderAction = createAction<string>();
export const cancelRenderAction = createAction<string>();
export const getRenderServiceStatusAction = createAction();
export const getRenderStatusAction = createAction<string>();
export const getRenderServicStatusSuccessAction = createAction<string>();
export const setIsSlideshowPlaying = createAction<boolean>('CHANGE_SLIDESHOW_PLAYING_STATUS');
export const setPlaySlideshowPlayer = createAction<boolean | null>('PLAY_SLIDESHOW_PLAYER');
export const setEditableSlide = createAction<ISlide | null>('SET_EDITABLE_SLIDE');
export const setLogoModalAddStatus = createAction<boolean>('SET_LOGO_MODAL_ADD_STATUS');
export const addLogoSlide = createAction<ILogoSlidePayload>('ADD_LOGO_SLIDE');
export const editLogoSlide = createAction<ILogoSlidePayload>('EDIT_LOGO_SLIDE');

export const clearAll = createAction('CLEAR_ALL');
