import React from 'react';
import { Card, CardBody } from 'reactstrap';
import styled from 'styled-components';
import { getTranslationKey } from '../../modules/utils';
import { ShopTaxSetupForm } from './ShopTaxSetupForm';

const Title = styled.h1``;
const Description = styled.p``;
export const ShopTaxSetup = () => (
  <Card>
    <CardBody>
      <Title>{getTranslationKey('shop.taxConfig.shopFormTitle')}</Title>
      <Description className="text-muted">
        {getTranslationKey('shop.taxConfig.shopFormDesc')}
      </Description>
      <ShopTaxSetupForm isOnboardingForm />
    </CardBody>
  </Card>
);
