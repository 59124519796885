const moment = require('moment');

exports.scrollElementIntoView = (elementId) => {
  try {
    const elem = document.getElementById(elementId);
    if (elem) elem.scrollIntoView();
  } catch (e) {
    console.log(e);
  }
};

exports.getObjectKeysCount = (obj) => {
  try {
    return Object.keys(obj).length;
  } catch (e) {
    console.log(e);

    return 0;
  }
};

exports.getBookingUrl = (id) => {
  if (process.env.NODE_ENV === 'development') {
    return `//${window.location.host}/#/book/do/${id}`;
  }

  return `https://booking.leadsuite.eu/#/book/do/${id}`;
};

exports.setStylesForClass = (classname, styles) => {
  const elements = document.getElementsByClassName(classname);
  let i = elements.length;
  while (i--) {
    elements[i].setAttribute('style', styles);
  }
};

exports.removeStylesForClass = (classname, styleToRemove) => {
  const elements = document.getElementsByClassName(classname);
  let i = elements.length;
  while (i--) {
    if (elements[i] && elements[i].style && elements[i].style[styleToRemove]) {
      elements[i].style[styleToRemove] = null;
    }
  }
};

exports.getCdnImageUrl = ({ image, size = 'L', useWatermark = false, wildcardSize = false }) => {
  try {
    if (image.isPublic) {
      return `${window.CDN_URL}/public/${image.identifier}.${image.extension}`;
    }

    let key = (image._user._id || image._user) + '/';
    key += image.imgsFolder + '/';

    if (useWatermark && image.watermarkIdentifier) {
      key += image.watermarkIdentifier + '/';
      key += 'wm/';
    } else {
      key += image.identifier + '/';
    }

    if (
      !(image.extension === 'gif' && moment(image.uploadedAt).isAfter(moment('2022-11-16 13:40')))
    ) {
      key += wildcardSize ? '$size$' : size;
    }

    key += `.${image.extension}`;

    return `${window.CDN_URL}/${key}`;
  } catch (e) {
    console.log(e);

    return '';
  }
};

exports.getSiteOrCustomUrl = (user, site) => {
  let url = '';

  if (site.customDomain && site.customDomain.url) {
    url = site.customDomain.url;
  } else if (user.custom_domain && user.custom_domain !== '') {
    url = `${user.custom_domain}/${site._id}`;
  } else {
    if (user.type === 'Basic') url = `https://app.leadsuite.eu/${site._id}`;
    else if (user.type === 'Studio') {
      url = `https://apps.leadsuite.eu/${site._id}`;
    } else if (user.type === 'PP') {
      url = `https://app.picturepeople.de/${site._id}`;
    } else if (user.type === 'SB') url = `https://apps.scrappbook.de/${site._id}`;
    else if (user.type === 'VST') url = `https://www.gallerypix.de/${site._id}`;
  }

  return url;
};

exports.getQRCodeUrl = (user, collection) => {
  try {
    let url = '';

    if (collection.customDomain && collection.customDomain.url) {
      const pathToReplace = collection.customDomain.path
        ? collection.customDomain.path.toLowerCase()
        : '';

      const domain = collection.customDomain.url ? collection.customDomain.url.toLowerCase() : '';

      url = `${domain.replace(pathToReplace, '')}api/collection/open/${collection._id}`;
    } else if (user.custom_domain && user.custom_domain !== '') {
      url = `${user.custom_domain}/api/collection/open/${collection._id}`;
    } else {
      url = `${window.API_URL}/api/collection/open/${collection._id}`;
    }

    return url;
  } catch (e) {
    console.log(e);

    return null;
  }
};

exports.removeElementsByClass = (className) => {
  const elements = document.getElementsByClassName(className);
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0]);
  }
};

exports.sleep = (milliseconds) => new Promise((resolve) => setTimeout(resolve, milliseconds));

exports.detectIE = () => {
  try {
    if (window.ieVersion) return window.ieVersion;
    const ua = window.navigator.userAgent;

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      window.ieVersion = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      window.ieVersion = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      window.ieVersion = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    if (window.ieVersion) return window.ieVersion;
    window.ieVersion = 'false';
  } catch (e) {
    window.ieVersion = 'false';
  }
};

exports.downloadFile = function (sUrl) {
  const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
  const isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;

  // If in Chrome or Safari - download via virtual link click
  if (isChrome || isSafari) {
    // Creating new link node.
    const link = document.createElement('a');
    link.href = sUrl;

    if (link.download !== undefined) {
      // Set HTML5 download attribute. This will prevent file from opening if supported.
      const fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
      link.download = fileName;
    }

    // Dispatching click event.
    if (document.createEvent) {
      const e = document.createEvent('MouseEvents');
      e.initEvent('click', true, true);
      link.dispatchEvent(e);

      return true;
    }
  }

  window.open(sUrl, '_self');

  return true;
};
