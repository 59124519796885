import { get, size, map, trim, isFunction, head, compact, eq } from 'lodash';
import { getTranslationKey } from '../../../modules/utils/helpers/index';
import { IDictionary } from '../../../modules/types';

const required = (value: string) => !Boolean(value) || !value.length;

interface IValidateOptions {
  formValues: IDictionary<string>;
  titleKey: string;
}

type TValidation = (value: any, options: IValidateOptions) => string | undefined;
const maxFunction = (value: string, max: number) => {
  if (size(value) > max) {
    return `Max ${max} Characters`;
  }
  return;
};
export const validations: IDictionary<TValidation> = {
  required: (value: string, options: IValidateOptions) => {
    console.log(value);
    if (required(value)) {
      const title = get(options, 'titleKey', '');
      return `${getTranslationKey(title)} ${getTranslationKey('baseValidations.required')}`;
    }
    return;
  },
  onlyLetters: (value: string, options: IValidateOptions) => {
    const regrxp = /^[a-zA-Z\s\-üäöâáß]+$/;
    const test = value.match(regrxp);
    if (!test) {
      const title = get(options, 'titleKey', '');
      return `${getTranslationKey(title)}`;
    }
    return;
  },
  max250: (value: string) => {
    return maxFunction(value, 250);
  },
  emailFormat: (value: string, options: IValidateOptions) => {
    const regrxp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const test = value.match(regrxp);
    if (!test) {
      const title = get(options, 'titleKey', '');
      return getTranslationKey(title);
    }
    return;
  },
  onlyNumber: (value: string, options: IValidateOptions) => {
    const regrxp = /^[0-9]+$/;
    const test = value.match(regrxp);
    if (!test) {
      const title = get(options, 'titleKey', '');
      return `${getTranslationKey(title)} ${getTranslationKey('baseValidations.onlyNumbers')}`;
    }
    return;
  },
  onlyAlfaNumeric: (value: string, options: IValidateOptions) => {
    const regrxp = /^[a-z0-9]+$/;
    const test = value.match(regrxp);
    if (!test) {
      const title = get(options, 'titleKey', '');
      return `${getTranslationKey(title)} should contain only letters and digits`;
    }
    return;
  },
  matchPassword: (value: string, { formValues }: IValidateOptions) => {
    const password = get(formValues, 'password.value', '');
    if (!eq(password, value)) {
      return 'Password does not match';
    }

    return;
  },
  emailsList: (value: string[]) => {
    const regrxp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let foundInvalid = false;
    let i = 0;
    do {
      const testValue = value[i] || '';
      foundInvalid = !Boolean(testValue.trim().match(regrxp));
      i++;
    } while (!foundInvalid && value.length > i);
    if (foundInvalid) {
      return getTranslationKey('baseValidations.invalidEmails');
    }
    return;
  }
};

export const valueOfElement = (target: Element) => {
  const is_checkbox = get(target, 'type') === 'checkbox';
  const checkboxValue = get(target, 'checked', 0) ? 1 : 0;
  return is_checkbox ? checkboxValue : get(target, 'value', '');
};

export const validateField = (e: HTMLFormElement, formValues: IDictionary<string>) => {
  const value = valueOfElement(e);
  const name = e.getAttribute('name');
  const titleKey = e.getAttribute('data-required') || e.getAttribute('title') || '';
  const validate_options = {
    formValues,
    titleKey,
    name
  };

  if (!name) {
    return false;
  }

  const element_validations = e.getAttribute('data-validations') || '';
  const functions = [...element_validations.split(',')];
  if (size(functions)) {
    const errors = map(functions, (f: string) => {
      const func = validations[trim(f)];
      return isFunction(func) && func(value, validate_options);
    });
    const top_error = head(compact(errors));
    if (top_error) {
      return {
        name,
        errorText: top_error
      };
    }
  }
  return;
};

type TFormValue = string | number | boolean | string[];

export interface IFormFieldData {
  value: TFormValue;
  error: string;
  validations?: string;
  titleKey?: string;
}

export const validateFieldStore = (
  name: string,
  formField: IFormFieldData,
  formValues: IDictionary<string>
): { name: string; errorText: string } | undefined => {
  const { value, titleKey = '', validations: fieldValidations = '' } = formField;
  const validate_options = {
    formValues,
    titleKey,
    name
  };
  if (!name) {
    return;
  }
  const functions = [...fieldValidations.split(',')];
  if (size(functions)) {
    const errors = map(functions, (f: string) => {
      const func = validations[trim(f)];
      return isFunction(func) && func(value as any, validate_options);
    });
    const top_error = head(compact(errors));
    if (top_error) {
      return {
        name,
        errorText: top_error
      };
    }
  }
  return;
};
