import { IShopCoupon, IShopCouponDiscount, ShopCouponTypesEnum } from '../../modules/types';

export const DEFAULT_DISCOUNT: IShopCouponDiscount = {
  type: ShopCouponTypesEnum.Total,
  valueType: 'percent',
  productGroupId: null,
  quantityMax: null,
  valueFixed: 0,
  valuePercent: 20
};

export const DEFAULT_COUPON: IShopCoupon = {
  id: '',
  user: '',
  name: '',
  description: '',
  active: true,
  code: '',
  codeLowerCase: '',
  validUntilDate: '',
  minimumOrderValue: null,
  isUsedInSalesAutomation: false,
  discounts: [DEFAULT_DISCOUNT]
};
