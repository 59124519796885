export default function (state = { all: [] }, action) {
  switch (action.type) {
    case 'SET_ALL_PRIVACYS':
      return { ...state, all: action.payload };
    case 'ADD_PRIVACY':
      return { ...state, all: [action.payload, ...state.all] };
    case 'EDIT_PRIVACY':
      const newPrivacy = action.payload;

      return {
        ...state,
        all: state.all.map((privacy) => {
          if (privacy._id !== newPrivacy._id) {
            return privacy;
          }

          return newPrivacy;
        })
      };
    case 'REMOVE_PRIVACY':
      return {
        ...state,
        all: state.all.filter((privacy) => privacy._id !== action.payload)
      };
    default:
      return state;
  }
}
