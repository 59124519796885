import React from 'react';
import Texts from '../../json/texts.json';

const PricingSwitch = ({ intervall, onClick }) => (
  <ul className="select-price text-center" onClick={onClick}>
    <li className={intervall === 'monthly' && 'active'}>
      {Texts[window.LANGUAGE_SW].pricingTables.monthlyPlan}
    </li>
    <li className={intervall === 'yearly' && 'active'}>
      {Texts[window.LANGUAGE_SW].pricingTables.yearlyPlan}
    </li>
  </ul>
);

export default PricingSwitch;
