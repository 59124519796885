import { ISelection } from '../../modules/types/selections';
import { get, filter } from 'lodash';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDraggableSlideState, getImagesList } from '../../modules/selectors';
import { selectImages, updateSelection } from '../../modules/actions';
import { IImage } from '../reducers/images';

type IUseSelectedGalleriesIDs = [string[], (val: string[]) => void];

export const useSelectedGalleriesIDs = (selection: ISelection): IUseSelectedGalleriesIDs => {
  const favorites = get(selection, 'favorites', []);
  const initialIDS: string[] = [];
  favorites.forEach((favorite) => {
    if (!initialIDS.includes(favorite._image.gallery_id)) {
      initialIDS.push(favorite._image.gallery_id);
    }
  });
  const [selectedGalleryIds, changeSelectedGalleryIds] = useState(initialIDS);
  const setSelectedGalleryIds = (selectedGalleries: string[]) =>
    changeSelectedGalleryIds(selectedGalleries);
  return [selectedGalleryIds, setSelectedGalleryIds];
};

type IUseAffectedSelectionCleanUpImages = [number, string[]];

export const useAffectedSelectionCleanUpImages = (
  selectedGalleryIds: string[],
  whichImages: string,
  selection: ISelection
): IUseAffectedSelectionCleanUpImages => {
  const images = useSelector(getImagesList);
  const { favorites } = selection;
  const favoritesImagesID = filter(favorites, (favorite) =>
    selectedGalleryIds.includes(favorite._gallery)
  ).map((favorite) => favorite._image._id);
  const otherImagesIDs = filter(
    images,
    (image) =>
      selectedGalleryIds.includes(image.gallery_id) && !favoritesImagesID.includes(image._id)
  ).map((image) => image._id);

  const count: number =
    whichImages === 'favorites' ? favoritesImagesID.length : otherImagesIDs.length;
  const imagesList = whichImages === 'favorites' ? favoritesImagesID : otherImagesIDs;
  return [count, imagesList];
};

export const useMultipleSlideSelection = () => {
  const dispatch = useDispatch();
  const { lastSelectedIndex, selectedSlides } = useSelector(getDraggableSlideState);

  const handleItemSelection = (
    e: React.MouseEvent<Element, MouseEvent>,
    index: number,
    items: any,
    callback?: (finalList: IImage[]) => void
  ) => {
    let newSelectedImages: any[] = [];
    const image: any = index < 0 ? '' : items[index];
    const newLastSelectedIndex = index;
    const { metaKey, shiftKey, ctrlKey } = e;

    if (!metaKey && !shiftKey && !ctrlKey) {
      newSelectedImages = [image];
    } else if (shiftKey) {
      if (lastSelectedIndex >= index) {
        newSelectedImages = [].concat.apply(
          selectedSlides,
          (items as any).slice(index, lastSelectedIndex)
        );
      } else {
        newSelectedImages = [].concat.apply(
          selectedSlides,
          (items as any).slice(lastSelectedIndex + 1, index + 1)
        );
      }
    } else if (metaKey || ctrlKey) {
      const foundIndex = selectedSlides.findIndex((img: any) => img._id === image._id);
      if (foundIndex >= 0) {
        newSelectedImages = [
          ...selectedSlides.slice(0, foundIndex),
          ...selectedSlides.slice(foundIndex + 1)
        ];
      } else {
        newSelectedImages = [...selectedSlides, image];
      }
    }

    const finalList = items
      ? items.filter((f: IImage) => newSelectedImages.find((a: any) => a._id === f._id))
      : [];

    dispatch(updateSelection({ newSelectedCards: finalList, newLastSelectedIndex }));

    callback && callback(finalList);
  };

  return handleItemSelection;
};
