import React from 'react';
import moment from 'moment';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Texts from '../../../json/texts.json';
import { DiscountAlert, getPricingPageUrl } from '../../DiscountKey';

const DiscountModal = ({
  isDiscountModalOpen,
  setDiscountModalVisibility,
  first_name,
  digistore,
  logUserActivity
}) =>
  isDiscountModalOpen ? (
    <Modal
      centered
      isOpen={isDiscountModalOpen}
      toggle={() => setDiscountModalVisibility(!isDiscountModalOpen)}
      wrapClassName="modal-dark"
      size="md"
    >
      <ModalHeader toggle={() => setDiscountModalVisibility(!isDiscountModalOpen)}>
        {Texts[window.LANGUAGE_SW].userMessage.discountTitle.replace('$$first_name$$', first_name)}
      </ModalHeader>
      <ModalBody className="text-center">
        <a
          href={getPricingPageUrl(digistore, {
            utm_medium: 'discount-popup',
            utm_campaign: 'builder-discounts',
            utm_source: 'builder'
          })}
          onClick={() => {
            logUserActivity({
              action: 'CLICK_DISCOUNT_KEY',
              logAsHubpostNote: true,
              leadScore: 8
            });

            setDiscountModalVisibility(!isDiscountModalOpen);
          }}
        >
          <img
            className="img-fluid"
            src={
              moment().isBetween('2021-11-23', '2021-11-29')
                ? `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/builder/dashboard/banner/black-friday-21-new-customers-mobile.jpg`
                : `${window.CDN_URL}/public/scrappbook/gift-discount-active.jpg`
            }
            alt="Gift Img"
          />
        </a>

        {!moment().isBetween('2021-11-23', '2021-11-29') && (
          <DiscountAlert
            className="cursor-pointer"
            utm_medium="discount-popup"
            afterClick={() => setDiscountModalVisibility(!isDiscountModalOpen)}
          />
        )}
      </ModalBody>
    </Modal>
  ) : null;

function mapStateToProps({ message: { isDiscountModalOpen }, auth: { first_name, digistore } }) {
  return {
    isDiscountModalOpen,
    first_name,
    digistore
  };
}

export default connect(mapStateToProps, actions)(DiscountModal);
