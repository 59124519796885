import _ from 'lodash';
import { getCdnImageUrl } from '../helpers/globalFunctions';

export default function (
  state = {
    allCollections: [],
    currentSite: {},
    currentSiteEditMenu: {},
    totalCollectionsCount: 0,
    isLoading: false
  },
  action
) {
  switch (action.type) {
    case 'CURRENT_SITE':
      return { ...state, currentSite: action.payload };
    case 'UPDATE_CURRENT_SITE_VALUE_BY_PATH':
      const update_value_by_path = { ...state.currentSite };
      _.set(update_value_by_path, action.payload.path, action.payload.data);
      _.set(update_value_by_path, 'updated_at', Date.now());

      return { ...state, currentSite: update_value_by_path };
    case 'ALL_SITES':
      return {
        ...state,
        allCollections: action.payload.collections,
        totalCollectionsCount: action.payload.totalCollectionsCount,
        isLoading: false
      };
    case 'REMOVE_SITE':
      const removeArray = state.allCollections.slice();
      _.remove(removeArray, (item) => item._id === action.payload);

      return {
        ...state,
        allCollections: [...removeArray],
        totalCollectionsCount: state.totalCollectionsCount--
      };
    case 'ADD_ICON_TO_CURRENT_SITE':
      const newGalleryArray = state.currentSite.images.slice();
      const newImage = {
        ...action.payload
      };
      newGalleryArray.splice(state.currentSite.images, 0, newImage);

      return {
        ...state,
        currentSite: {
          ...state.currentSite,
          images: [...newGalleryArray],
          updated_at: Date.now()
        }
      };
    case 'REFRESH_IMAGES_CURRENT_SITE':
      const images = action.payload.map((img) => ({
        ...img,
        url: img.signedWilCardUrl
          ? img.signedWilCardUrl.replace('*', img.cryptImageName)
          : getCdnImageUrl({
              collection: state.currentSite,
              image: img,
              publicPath: img.isTemplateImage,
              size: 'XS'
            })
      }));

      return {
        ...state,
        currentSite: { ...state.currentSite, images: [...images] }
      };
    case 'UPDATE_LOADING_STATUS':
      return {
        ...state,
        isLoading: action.payload
      };
    default:
      return state;
  }
}
