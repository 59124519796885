import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { getModalData, getModalState } from '../../modules/selectors';
import { changeModalState } from '../../modules/actions';
import { getTranslationKey } from '../../modules/utils';
import Usage from '../../pages/OverviewTab/Usage';
import useTrackClick from '../../hooks/tracking/use-track-click';

const FeaturesDowngradeModal = () => {
  const dispatch = useDispatch();
  const trackClick = useTrackClick();
  const { daysUntilDownGrade } = useSelector(getModalData);
  const isOpen = useSelector(getModalState)('featuresDowngradeModal');
  const toggle = () =>
    dispatch(changeModalState({ key: 'featuresDowngradeModal', state: !isOpen }));

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} wrapClassName="modal-dark">
      <ModalHeader toggle={toggle}>
        {getTranslationKey('subscription.featuresDowngrade.modal-title')}
      </ModalHeader>
      <ModalBody>
        <p>
          {daysUntilDownGrade === 0 ? (
            getTranslationKey('subscription.featuresDowngrade.alert-banner-text-zero-days')
          ) : (
            <>
              {getTranslationKey('subscription.featuresDowngrade.alert-banner-text')}
              <b> {daysUntilDownGrade} </b>
              {getTranslationKey('subscription.featuresDowngrade.days')}.
            </>
          )}
        </p>
        <Usage
          showOnlyFeaturesAboveLimits
          showMessageUnderFeaturesAboveLimits
          onUpgradeLinkClick={toggle}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          block
          className="primary-highlight-shadow"
          color="primary"
          href="/#/subscription/pricing"
          onClick={() => {
            toggle();
            trackClick('upgrade-account', { placement: 'features-downgrade-modal' });
          }}
        >
          <i className="fa fa-icon fa-rocket" />{' '}
          <span className="d-sm-down-none">
            {getTranslationKey('upgradeAccount')}
            {' + '}
          </span>
          {getTranslationKey('pricingPage')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FeaturesDowngradeModal;
