import axios from '../helpers/axios';
import * as errors from '../errors';

export const toggleGoogleCalendarSettingsModal = () => (dispatch) => {
  try {
    dispatch({ type: 'TOGGLE_GC_SETTINGS_MODAL' });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const setSyncCalendars = (syncCalendarIds) => async (dispatch) => {
  try {
    const res = await axios.put('/api/google/calender/setSyncCalendars', {
      syncCalendarIds
    });

    dispatch({ type: 'SET_USER', payload: res.data.user });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};
