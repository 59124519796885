import React, { useState } from 'react';
import { InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { IDictionary } from '../../modules/types';
import { partial } from 'lodash';
import styled from 'styled-components';

interface IProps {
  buttonLabel: string;
  options: IDictionary<string>[];
  callback: (val: string) => void;
  disabled?: boolean;
}

const LabelWrapper = styled.div`
  overflow-x: hidden;
  white-space: nowrap;
  width: 100%;
  display: block;
  text-overflow: ellipsis;
`;

const InputGroupButtonDropdownStyled = styled(InputGroupButtonDropdown)`
  max-width: 50%;
`;

const DropdownToggleStyled = styled(DropdownToggle)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const InputItemDropdown: React.FC<IProps> = ({
  buttonLabel,
  options,
  callback,
  disabled = false
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);
  const selectHandler = (value: string) => callback(value);

  return (
    <InputGroupButtonDropdownStyled
      addonType="append"
      isOpen={dropdownOpen}
      disabled={disabled}
      toggle={toggleDropDown}
    >
      <DropdownToggleStyled caret className={`btn btn-success ${disabled && 'disabled'}`}>
        <LabelWrapper>{buttonLabel}</LabelWrapper>
      </DropdownToggleStyled>
      <DropdownMenu>
        {options.map((option) => (
          <DropdownItem key={option.value} onClick={partial(selectHandler, option.value)}>
            {option.text}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </InputGroupButtonDropdownStyled>
  );
};
