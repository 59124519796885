import { IDictionary, IGraphData } from '../../modules/types';
import theme from '../../assets/css/theme';

export const COLLECTION_STATISTIC_LIMIT = 20;
export const INITIAL_STATISTICS_BATCH = 1;

export const DEFAULT_OVERALL = {
  _id: '',
  singleDownloads: 0,
  zipDownloads: 0,
  collectionViews: 0,
  shareViews: 0,
  appViews: 0,
  appInstalls: 0,
  shares: 0,
  socialClicks: 0,
  websiteClicks: 0,
  telephoneClicks: 0,
  emailClicks: 0,
  contactDataViews: 0
};

export const DEFAULT_GRAPH: IGraphData = {
  maxValue: 0,
  labels: [],
  datasets: [
    {
      label: 'View',
      backgroundColor: 'transparent',
      borderColor: theme.commonColors.second,
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: [],
      key: 'views'
    },
    {
      label: 'shares',
      backgroundColor: 'transparent',
      borderColor: '#009fe3',
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: [],
      key: 'shares'
    },
    {
      label: 'appInstalls',
      backgroundColor: 'transparent',
      borderColor: '#ceb2ef',
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: [],
      key: 'appInstalls'
    },
    {
      label: 'downloads',
      backgroundColor: 'transparent',
      borderColor: '#5ca8d7',
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: [],
      key: 'downloads'
    },
    {
      label: 'contactDataViews',
      backgroundColor: 'transparent',
      borderColor: '#4193A3',
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: [],
      key: 'contactDataViews'
    },
    {
      label: 'clicks',
      backgroundColor: 'transparent',
      borderColor: '#41BA7D',
      pointHoverBackgroundColor: '#fff',
      borderWidth: 2,
      data: [],
      key: 'clicks'
    }
  ]
};

const getLabelColor = (tooltipItem: any, chart: any) => ({
  backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor
});

export const GRAPH_OPTIONS: any = {
  tooltips: {
    enabled: true,
    intersect: true,
    mode: 'index',
    position: 'nearest',
    callbacks: {
      labelColor: getLabelColor
    }
  },
  maintainAspectRatio: false,
  legend: {
    display: false
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          drawOnChartArea: false
        }
      }
    ]
  },
  elements: {
    point: {
      radius: 0,
      hitRadius: 10,
      hoverRadius: 4,
      hoverBorderWidth: 3
    }
  }
};

const galleryKey = '%_gallery.title%';
const galleryProp = '_gallery.title';
const imageKey = '%_image.originalImageName%';
const imageProp = '_image.originalImageName';
const selectionKey = '%_selection.name%';
const selectionProp = '_selection.name';
const actionDataKey = '%actionData%';
const actionDataProp = 'actionData';
export const STATISTICS_ACTIVITY_ACTIONS = {
  view: {
    gallery: [
      {
        replaceKey: galleryKey,
        prop: galleryProp
      }
    ],
    image: [
      {
        replaceKey: imageKey,
        prop: imageProp
      }
    ],
    share: [
      {
        replaceKey: '%share.policy.type%',
        prop: '_share.policy.type'
      },
      {
        replaceKey: '%data%',
        prop: 'none'
      }
    ]
  },
  download: {
    gallery: [
      {
        replaceKey: galleryKey,
        prop: galleryProp
      }
    ],
    image: [
      {
        replaceKey: imageKey,
        prop: imageProp
      }
    ],
    selection: [
      {
        replaceKey: selectionKey,
        prop: selectionProp
      }
    ]
  },
  click: {
    contact: [
      {
        replaceKey: actionDataKey,
        prop: actionDataProp
      }
    ]
  },
  share: {
    collection: [
      {
        replaceKey: actionDataKey,
        prop: actionDataProp
      }
    ],
    'all-images': [
      {
        replaceKey: actionDataKey,
        prop: actionDataProp
      }
    ],
    gallery: [
      {
        replaceKey: galleryKey,
        prop: galleryProp
      },
      {
        replaceKey: actionDataKey,
        prop: actionDataProp
      }
    ],
    image: [
      {
        replaceKey: imageKey,
        prop: imageProp
      },
      {
        replaceKey: actionDataKey,
        prop: actionDataProp
      }
    ],
    selection: [
      {
        replaceKey: selectionKey,
        prop: selectionProp
      },
      {
        replaceKey: actionDataKey,
        prop: actionDataProp
      }
    ]
  },
  create: {
    selection: [
      {
        replaceKey: selectionKey,
        prop: selectionProp
      }
    ]
  },
  complete: {
    selection: [
      {
        replaceKey: selectionKey,
        prop: selectionProp
      }
    ]
  },
  delete: {
    selection: [
      {
        replaceKey: selectionKey,
        prop: selectionProp
      }
    ]
  },
  like: {
    selection: [
      {
        replaceKey: imageKey,
        prop: imageProp
      },
      {
        replaceKey: selectionKey,
        prop: selectionProp
      }
    ]
  },
  dislike: {
    selection: [
      {
        replaceKey: imageKey,
        prop: imageProp
      },
      {
        replaceKey: selectionKey,
        prop: selectionProp
      }
    ]
  },
  hide: {
    image: [
      {
        replaceKey: imageKey,
        prop: imageProp
      }
    ]
  },
  unhide: {
    image: [
      {
        replaceKey: imageKey,
        prop: imageProp
      }
    ]
  },
};

export const ACTIVITY_SHARE_POLICY_TYPES: IDictionary<string> = {
  gallery: '_gallery.title',
  image: '_image.originalImageName',
  selection: '_selection.name'
};

export const ACTIVITY_FILTERS_OPTIONS = [
  'view.view',
  'view.collection',
  'view.gallery',
  'view.image',
  'view.contact',
  'view.app',
  'view.share',
  'view.slideshow',
  'download.download',
  'download.collection',
  'download.gallery',
  'download.image',
  'download.selection',
  'download.slideshow',
  'click.contact',
  'appInstall.app',
  'share.share',
  'share.collection',
  'share.gallery',
  'share.image',
  'share.selection',
  'create.selection',
  'complete.selection',
  'delete.selection',
  'like.selection',
  'dislike.selection',
  'hide.image',
  'unhide.image',
  'sendEmail.collection'
];
