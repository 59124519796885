import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import SetDefaultValues from './SetDefaultValues';
import SetPassword from './SetPassword';
import Texts from '../../json/texts.json';
import * as actions from '../../actions';
import { ShopTaxSetup } from '../../components/ShopTaxSetup';

class SetupAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showVideo: !props.environment.isMobileDevice && props.languageSoftware === 0
    };
  }

  getVideoId(registrationProcess, userType) {
    if (userType === 'VST' || userType === 'PP' || userType === 'SB') return null;

    if (registrationProcess.setPassword === false) return 'RQLdoaoiWiQ';
    if (registrationProcess.setDefaultValues === false) return 'Q8AoJjE_nwA';

    return 'KvSvEAanrL4';
  }

  renderStepDescription(registrationProcess) {
    if (registrationProcess.setPassword === false) {
      return (
        <div>
          <h1>{Texts[window.LANGUAGE_SW].defaultValues.welcome}</h1>
          <div className="max-w-500">
            <br />
            <p>{Texts[window.LANGUAGE_SW].defaultValues.password}</p>
            <p>{Texts[window.LANGUAGE_SW].defaultValues.password2}</p>
            <p>{Texts[window.LANGUAGE_SW].defaultValues.password3}</p>
          </div>
        </div>
      );
    }
    if (registrationProcess.setDefaultValues === false) {
      return (
        <div>
          <h1>{Texts[window.LANGUAGE_SW].defaultValues.title}</h1>
          <div className="max-w-500">
            <br />
            <p>{Texts[window.LANGUAGE_SW].defaultValues.values}</p>
            <p>{Texts[window.LANGUAGE_SW].defaultValues.values2}</p>
            <strong>
              <p>{Texts[window.LANGUAGE_SW].defaultValues.values3}</p>
            </strong>
          </div>
        </div>
      );
    }

    return (
      <div>
        <h1>{Texts[window.LANGUAGE_SW].defaultValues.newsletter}</h1>
        <div className="max-w-500">
          <br />
          <p>{Texts[window.LANGUAGE_SW].defaultValues.newsletter2}</p>
          <p>{Texts[window.LANGUAGE_SW].defaultValues.newsletter3}</p>
          <strong>
            <p>{Texts[window.LANGUAGE_SW].defaultValues.newsletter4}</p>
          </strong>
        </div>
      </div>
    );
  }

  getStepElement(registrationProcess) {
    if (registrationProcess.setPassword === false) {
      return (
        <Col sm="12" md="12" lg="5" className="mb-4">
          <SetPassword />
        </Col>
      );
    }
    if (!registrationProcess.setTaxDetails) {
      return (
        <Col sm="12" md="12" lg="5" className="mb-4">
          <ShopTaxSetup />
        </Col>
      );
    }
    if (registrationProcess.setDefaultValues === false) {
      return (
        <Col sm="12" md="12" lg="5" className="mb-4">
          <SetDefaultValues />
        </Col>
      );
    }

    return null;
  }

  getLoomVideoId(registrationProcess) {
    if (registrationProcess.setPassword === false) return '5d4b28c7b29140e5a53060cf11921f7d';
    if (registrationProcess.setDefaultValues === false) return 'e5ef98de187a4363971f710afde6478b';

    return '';
  }

  renderTextVideoSwitch() {
    return (
      <button
        className="mt-4"
        onClick={(e) => {
          e.preventDefault();
          this.setState({ showVideo: !this.state.showVideo });
        }}
      >
        {this.state.showVideo
          ? Texts[window.LANGUAGE_SW].defaultValues.showText
          : Texts[window.LANGUAGE_SW].defaultValues.showVideo}
      </button>
    );
  }

  render() {
    const { registrationProcess, taxSettings } = this.props;

    return registrationProcess ? (
      <div className="animated fadeIn m-t-30">
        <Row className="justify-content-center">
          {this.getStepElement(registrationProcess, taxSettings)}
        </Row>
      </div>
    ) : null;
  }
}

function mapStateToProps({
  auth: { registrationProcess, whiteLabel, type, languageSoftware, first_name },
  notify,
  environment
}) {
  return {
    registrationProcess,
    notify,
    whiteLabel,
    userType: type,
    environment,
    languageSoftware,
    first_name
  };
}

export default connect(mapStateToProps, actions)(SetupAccount);
