import { SagaIterator, eventChannel, END } from 'redux-saga';
import { put, call, select, take, race, cancelled, delay, fork, join } from 'redux-saga/effects';
import { Task } from '@redux-saga/types';
import Api from '../../../modules/utils/API';
import {
  cancelVideoUploading,
  deleteGalleryVideo,
  errorsGlobalError,
  fetchCollectionSuccess,
  fetchGalleriesSuccess,
  fetchImagesSuccess,
  galleryUploadingClearSuccess,
  setImagesToMoveOnCreate,
  showNotifySuccess,
  stopAllUpload,
  fetchImages
} from '../../../modules/actions';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import {
  getCollectionId,
  getGalleriesList,
  getImagesByGalleryID,
  getImagesList,
  getImagesToMoveOnCreate,
  getOrderedGalleries,
  sortImages
} from '../../../modules/selectors';
import {
  IChangeGalleryOrderAction,
  ICreateGalleryAction,
  IDeleteGalleryAction,
  IDeleteVideoAction,
  IGalleriesWatermarksUpdateAction,
  IGalleryAction,
  IGalleryUploadingAction,
  IGalleryUploadingClearAction,
  ISagaAction,
  IStartUploadGalleryVideoAction,
  IToggleGalleryWatermarkAction,
  IUpdateGalleryAction
} from '../../../modules/types';
import { get, find } from 'lodash';
import { IGallery } from '../../../modules/reducers/galleries';
import { IImage } from '../../../modules/reducers/images';
import { arrayMoveHelper, getTranslationKey } from '../../../modules/utils/helpers';
import axios, { CancelTokenSource } from 'axios';
import { DEFAULT_VIDEO_OBJECT } from '../../../modules/constants';
import { moveImagesToGallerySaga } from '../../../modules/sagas/images';
import { trackEventAction } from '../../../modules/actions/analytics';

export const fetchGalleriesSaga = function* (): SagaIterator {
  try {
    let collectionID = yield select(getCollectionId);

    if (!Boolean(collectionID.length)) {
      yield take(fetchCollectionSuccess);
    }
    const galleries = yield select(getGalleriesList);
    collectionID = yield select(getCollectionId);
    const response = yield call(Api.Galleries.get, {
      collection_id: collectionID,
      ownerRequest: true
    });
    ApiErrors.checkOnApiError(response);

    const updated = response.map((gal: IGallery) => {
      const storeGallery = find(galleries, { _id: gal._id });
      const uploadingProcess = storeGallery ? get(storeGallery, 'uploadingProcess', {}) : {};
      return {
        ...gal,
        uploadingProcess
      };
    });

    yield put(fetchGalleriesSuccess(updated));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const fetchGallerySaga = function* ({ payload }: ISagaAction<IGalleryAction>): SagaIterator {
  try {
    let collectionID = yield select(getCollectionId);

    if (!Boolean(collectionID.length)) {
      yield take(fetchCollectionSuccess);
    }
    collectionID = yield select(getCollectionId);
    const galleries = yield select(getGalleriesList);
    const response = yield call(Api.Galleries.getById, {
      collection_id: collectionID,
      ...payload
    });

    ApiErrors.checkOnApiError(response);

    const updatedGalleries = galleries.map((gallery: IGallery) => ({
      ...gallery,
      ...(gallery._id === response._id ? { ...response } : {})
    }));
    yield put(fetchGalleriesSuccess(updatedGalleries));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const createGallerySaga = function* ({
  payload
}: ISagaAction<ICreateGalleryAction>): SagaIterator {
  try {
    const collectionID = yield select(getCollectionId);
    const galleries = yield select(getGalleriesList);
    const imagesToMove = yield select(getImagesToMoveOnCreate);
    const response = yield call(Api.Galleries.create, {
      collection_id: collectionID,
      ...payload
    });

    ApiErrors.checkOnApiError(response);
    response.unfoldState = true;
    galleries.forEach((gallery: IGallery) => gallery.order++);

    yield put(fetchGalleriesSuccess([...galleries, response]));
    if (imagesToMove.length) {
      yield call(moveImagesToGallerySaga, {
        galleryID: response._id,
        imagesIDs: imagesToMove,
        isSilent: true
      });
      yield put(setImagesToMoveOnCreate([]));
    }

    yield put(showNotifySuccess({}));

    const createdGallery = document.getElementById(`${response._id}`);
    if (createdGallery) {
      yield delay(100);
      createdGallery.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const deleteGallerySaga = function* ({
  payload
}: ISagaAction<IDeleteGalleryAction>): SagaIterator {
  try {
    const collectionID = yield select(getCollectionId);
    const galleries = yield select(getGalleriesList);
    const response = yield call(Api.Galleries.delete, {
      collection_id: collectionID,
      ...payload
    });
    ApiErrors.checkOnApiError(response);
    const filteredGalleries = galleries.filter(
      (gallery: IGallery) => gallery._id !== payload.gallery_id
    );
    yield put(fetchGalleriesSuccess(filteredGalleries));
    yield put(fetchImages());
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const updateGallerySaga = function* ({
  payload
}: ISagaAction<IUpdateGalleryAction>): SagaIterator {
  try {
    const { isSilent } = payload;
    const collectionID = yield select(getCollectionId);

    const response = yield call(Api.Galleries.update, {
      collection_id: collectionID,
      ...payload
    });
    ApiErrors.checkOnApiError(response);

    yield call(setGalleryValues, response, response._id);

    if (payload.data.sortBy && payload.data.sortBy !== 'manualOrder') {
      const images: IImage[] = yield select(getImagesList);
      const galleryImagesSorted = sortImages(
        images.filter((i) => i.gallery_id === payload.gallery_id),
        response.sortBy,
        response.sortOrder
      ).map((i) => i._id);

      yield put(
        fetchImagesSuccess({
          items: images.map((img: IImage) =>
            img.gallery_id === payload.gallery_id
              ? { ...img, manualOrder: galleryImagesSorted.indexOf(img._id) }
              : img
          )
        })
      );
    }

    if (!isSilent) {
      yield put(showNotifySuccess({}));
    }
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const setGalleryValues = function* (
  props: Partial<IGallery>,
  gallery_id: string
): SagaIterator {
  const galleries = yield select(getGalleriesList);
  const updated = galleries.map((gallery: IGallery) =>
    gallery_id === gallery._id
      ? {
          ...gallery,
          ...props
        }
      : gallery
  );
  yield put(fetchGalleriesSuccess(updated));
};

export const changeGalleryOrderSaga = function* ({
  payload
}: ISagaAction<IChangeGalleryOrderAction>): SagaIterator {
  try {
    const { drag_id, drop_id } = payload;
    const collectionID = yield select(getCollectionId);
    const galleries = yield select(getOrderedGalleries);
    const dragGalleryIndex = galleries.findIndex((gallery: IGallery) => gallery._id === drag_id);
    const dropIndex = galleries.findIndex((gallery: IGallery) => gallery._id === drop_id);

    let newGal = arrayMoveHelper(galleries, dragGalleryIndex, dropIndex);

    newGal = newGal.map((gallery: IGallery, index: number) => ({
      ...gallery,
      order: index
    }));

    yield put(fetchGalleriesSuccess(newGal));

    const response = yield call(Api.Galleries.changeOrder, {
      collection_id: collectionID,
      galleries: newGal.map(({ _id, order }) => ({
        _id,
        order
      }))
    });
    ApiErrors.checkOnApiError(response);
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const toggleGalleryWatermarkSaga = function* ({
  payload
}: ISagaAction<IToggleGalleryWatermarkAction>): SagaIterator {
  try {
    const collectionID = yield select(getCollectionId);
    const { gallery_id, watermark_id, isNoRequest } = payload;

    if (!isNoRequest) {
      const response = yield call(Api.Galleries.toggleWatermark, {
        collection_id: collectionID,
        ...payload
      });
      ApiErrors.checkOnApiError(response);
    }

    const images = yield select(getImagesList);
    const galleries = yield select(getGalleriesList);
    const getUpdatedWatermark = (gallery: IGallery) => {
      const galleryWatermark = watermark_id ? watermark_id : '';
      return gallery._id === gallery_id ? galleryWatermark : gallery._watermark;
    };
    const updatedGalleries = galleries.map((gallery: IGallery) => ({
      ...gallery,
      _watermark: getUpdatedWatermark(gallery)
    }));

    const getImageWatermark = (image: IImage) => {
      const galleryWatermark = watermark_id ? watermark_id : '';
      return image.gallery_id === gallery_id ? galleryWatermark : image._watermark;
    };
    const updatedImages = images.map((image: IImage) => ({
      ...image,
      _watermark: getImageWatermark(image)
    }));

    yield put(fetchGalleriesSuccess(updatedGalleries));
    yield put(fetchImagesSuccess({ items: updatedImages }));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const galleryUploadingActionSaga = function* ({
  payload
}: ISagaAction<IGalleryUploadingAction>): SagaIterator {
  try {
    const { gallery_id, action = '', uploaded, imagesLength } = payload;
    const galleries = yield select(getGalleriesList);
    const getUploadingProcess = (gallery: IGallery) => {
      const actionValue = get(gallery, `uploadingProcess.${action}`, false);
      const uploadedValue = get(gallery, `uploadingProcess.uploaded`, 0);
      const imagesLengthValue = get(gallery, `uploadingProcess.imagesLength`, 0);
      return gallery._id === gallery_id
        ? {
            ...gallery.uploadingProcess,
            [action]: !actionValue,
            imagesLength: imagesLength ? imagesLength : imagesLengthValue,
            uploaded: uploaded ? uploaded : uploadedValue
          }
        : gallery.uploadingProcess;
    };

    const updatedGalleries = galleries.map((gallery: IGallery) => ({
      ...gallery,
      uploadingProcess: getUploadingProcess(gallery)
    }));

    yield put(fetchGalleriesSuccess(updatedGalleries));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const galleryUploadingClearActionSaga = function* ({
  payload
}: ISagaAction<IGalleryUploadingClearAction>): SagaIterator {
  try {
    const { gallery_id } = payload;
    const galleries = yield select(getGalleriesList);
    const images = yield select(getImagesList);
    const filteredStoreImages = images.filter((storeImage: IImage) => !storeImage.stopUploading);
    yield put(fetchImagesSuccess({ items: filteredStoreImages }));

    const getUploadingProcess = (gallery: IGallery) =>
      gallery._id === gallery_id
        ? {
            start: false,
            pause: false,
            imagesLength: 0,
            uploaded: 0
          }
        : gallery.uploadingProcess;

    const updatedGalleries = galleries.map((gallery: IGallery) => ({
      ...gallery,
      uploadingProcess: getUploadingProcess(gallery)
    }));

    yield put(galleryUploadingClearSuccess(updatedGalleries));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const galleriesUploadingClearActionSaga = function* (): SagaIterator {
  const galleries = yield select(getGalleriesList);
  const images = yield select(getImagesList);
  const filteredStoreImages = images.filter((storeImage: IImage) => !storeImage.stopUploading);
  yield put(fetchImagesSuccess({ items: filteredStoreImages }));
  const updatedGalleries = galleries.map((gallery: IGallery) => ({
    ...gallery,
    uploadingProcess: {
      start: false,
      pause: false,
      imagesLength: 0,
      uploaded: 0
    }
  }));
  yield put(fetchGalleriesSuccess(updatedGalleries));
};

export const multipleGalleryUpdateSaga = function* ({ payload }: ISagaAction<any>): SagaIterator {
  try {
    const galleries = yield select(getGalleriesList);
    const collectionID = yield select(getCollectionId);
    const gallery_ids = galleries.map((gallery: IGallery) => gallery._id);

    const response = yield call(Api.Galleries.updateMultiple, {
      collection_id: collectionID,
      gallery_ids,
      data: {
        ...payload
      }
    });
    ApiErrors.checkOnApiError(response);

    yield put(fetchGalleriesSuccess(response));

    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const multipleWatermarkGalleryUpdateSaga = function* ({
  payload
}: ISagaAction<IGalleriesWatermarksUpdateAction>): SagaIterator {
  try {
    const { watermark_id } = payload;
    const galleries = yield select(getGalleriesList);
    const collectionID = yield select(getCollectionId);
    const gallery_ids = galleries.map((gallery: IGallery) => gallery._id);

    const data: any = {};
    if (watermark_id) {
      data.watermark_id = watermark_id;
    }
    const response = yield call(Api.Galleries.toggleWatermarks, {
      collection_id: collectionID,
      gallery_ids,
      ...data
    });
    ApiErrors.checkOnApiError(response);

    const images = yield select(getImagesList);
    const updatedImages = images.map((image: IImage) => ({
      ...image,
      _watermark: watermark_id
    }));

    yield put(fetchGalleriesSuccess(response));
    yield put(fetchImagesSuccess({ items: updatedImages }));

    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const getVideoSignUrlSaga = function* ({
  gallery_id,
  file
}: IStartUploadGalleryVideoAction): SagaIterator {
  const collection_id = yield select(getCollectionId);
  const { name, size } = file;

  try {
    const response = yield call(Api.Galleries.getVideoSignUrl, {
      fileName: name,
      collection_id,
      gallery_id,
      fileSize: size
    });
    ApiErrors.checkOnApiError(response);
    return response;
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const uploadGalleryVideoSaga = function* ({
  payload
}: ISagaAction<IStartUploadGalleryVideoAction>): SagaIterator {
  const { gallery_id, file } = payload;
  try {
    yield call(
      setGalleryValues,
      {
        videoUploadingProcess: {
          isUploading: true,
          progress: 0
        }
      },
      gallery_id
    );

    const [signResponse, isSignedCancel, stopAll] = yield race([
      call(getVideoSignUrlSaga, { gallery_id, file }),
      take(cancelVideoUploading),
      take(stopAllUpload)
    ]);

    const { storageLimitReached = false } = signResponse;
    if (storageLimitReached) {
      yield call(
        setGalleryValues,
        {
          videoError: getTranslationKey('storage.imageUploadError'),
          videoUploadingProcess: { isUploading: false, progress: 0 }
        },
        gallery_id
      );
      return;
    }

    if (isSignedCancel || (stopAll !== undefined && stopAll.payload)) {
      yield call(
        setGalleryValues,
        {
          videoUploadingProcess: { isUploading: false, progress: 0 }
        },
        gallery_id
      );
      return;
    }
    const { _video, multipartUpload, signedUrl, signedUrls, partSizeMb, uploadId } = signResponse;
    yield put(
      trackEventAction({
        name: 'video-upload-started',
        payload: {
          album_id: gallery_id,
          size: partSizeMb
        }
      })
    );
    const [uploadedParts, isS3Canceled] = yield race([
      multipartUpload
        ? call(multipartUploadVideoToS3, signedUrls, file, partSizeMb, gallery_id)
        : call(uploadVideoToS3, signedUrl, gallery_id, file),
      take(cancelVideoUploading)
    ]);

    if (isS3Canceled) {
      yield call(
        setGalleryValues,
        {
          videoUploadingProcess: { isUploading: false, progress: 0 }
        },
        gallery_id
      );
      yield put(deleteGalleryVideo({ gallery_id, video_id: _video._id }));

      if (multipartUpload) {
        yield call(Api.Galleries.abortMultipartUpload, {
          uploadId,
          videoId: _video._id
        });
      }

      yield put(
        trackEventAction({
          name: 'video-upload-canceled',
          payload: {
            album_id: gallery_id,
            size: partSizeMb
          }
        })
      );
      return;
    }

    if (!isS3Canceled && multipartUpload) {
      yield call(Api.Galleries.completeMultipartUpload, {
        uploadId,
        parts: uploadedParts,
        videoId: _video._id
      });
    }

    const statusResponse = yield call(Api.Galleries.updateVideoStatus, {
      video_id: _video._id,
      data: {
        status: 'uploaded'
      }
    });
    ApiErrors.checkOnApiError(statusResponse);

    yield put(
      trackEventAction({
        name: 'video-upload-finished',
        payload: {
          album_id: gallery_id,
          size: partSizeMb
        }
      })
    );
    yield call(
      setGalleryValues,
      {
        videoUploadingProcess: { isUploading: false, progress: 0 },
        _video: statusResponse,
        videoEmbedCode: ''
      },
      gallery_id
    );
  } catch (e) {
    yield call(
      setGalleryValues,
      { videoUploadingProcess: { isUploading: false, progress: 0 } },
      gallery_id
    );
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const deleteGalleryVideoSaga = function* ({
  payload
}: ISagaAction<IDeleteVideoAction>): SagaIterator {
  try {
    const { gallery_id, video_id } = payload;
    const response = yield call(Api.Galleries.deleteVideo, { video_id });
    ApiErrors.checkOnApiError(response);

    yield call(
      setGalleryValues,
      {
        _video: DEFAULT_VIDEO_OBJECT
      },
      gallery_id
    );
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const uploadVideoToS3 = function* (signedUrl: string, galleryId: string, file: File) {
  // @ts-ignore
  const ajaxRequest = axios.CancelToken.source();
  try {
    const channel = eventChannel((emitter) => {
      axios
        .put(signedUrl, file, {
          cancelToken: ajaxRequest.token,
          onUploadProgress: function (progressEvent) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log({ percentCompleted });
            emitter({ percentCompleted });
          }
        })
        .then(() => emitter(END))
        .catch((error) => {
          emitter({ error });
          emitter(END);
        });

      return () => {};
    });

    while (true) {
      const { percentCompleted, error } = yield take(channel);
      if (error) throw error;

      yield call(
        setGalleryValues,
        {
          videoUploadingProcess: {
            isUploading: true,
            progress: percentCompleted
          }
        },
        galleryId
      );
    }
  } finally {
    // @ts-ignore
    const can = yield cancelled();
    if (can) {
      // @ts-ignore
      ajaxRequest.cancel();
    }
  }
};

export const multipartUploadVideoToS3 = function* (
  signedUrls: string[],
  file: File,
  partSizeMb: number,
  galleryId: string
) {
  const runningRequests: Array<{
    url: string;
    request: CancelTokenSource;
  }> = [];
  const promises: Array<{
    url: string;
    promise: Task;
  }> = [];

  type Part = {
    headers: {
      etag: string;
    };
  };

  try {
    yield call(
      setGalleryValues,
      {
        videoUploadingProcess: {
          isUploading: true,
          progress: 0
        }
      },
      galleryId
    );

    const axiosInstance = axios.create();
    delete axiosInstance.defaults.headers.put['Content-Type'];

    const partSizeBytes = partSizeMb * 1024 * 1024;
    const maxPartsUploadedInParallel = 3;

    const urls = [...signedUrls];
    const parts: Array<{ partNumber: number; part: Part }> = [];

    let index = 0;

    while (urls.length > 0) {
      if (promises.length < maxPartsUploadedInParallel) {
        const url = urls.shift();
        if (!url) break;

        const start = index * partSizeBytes;
        const end = (index + 1) * partSizeBytes;
        const blob = index < signedUrls.length - 1 ? file.slice(start, end) : file.slice(start);

        // @ts-ignore
        const promise = yield fork(function* () {
          // eslint-disable-line
          // eslint-disable-line
          const request = axios.CancelToken.source();
          runningRequests.push({ url, request: request });

          const partNumber = index + 1;
          const part: Part = yield axiosInstance.put(url, blob, { cancelToken: request.token });
          parts.push({
            partNumber,
            part: part
          });

          yield call(
            setGalleryValues,
            {
              videoUploadingProcess: {
                isUploading: true,
                progress: (parts.length / signedUrls.length) * 100
              }
            },
            galleryId
          );

          const promiseIndex = promises.findIndex((promise) => promise.url === url);
          promises.splice(promiseIndex, 1);

          const requestIndex = runningRequests.findIndex((request) => request.url === url);
          runningRequests.splice(requestIndex, 1);
        });

        promises.push({
          promise,
          url
        });

        index++;
      } else {
        yield join(promises[0].promise);
      }
    }

    yield join(promises.map(({ promise }) => promise));

    yield call(
      setGalleryValues,
      {
        videoUploadingProcess: {
          isUploading: false,
          progress: 0
        }
      },
      galleryId
    );

    return parts.map(({ part, partNumber }) => ({
      ETag: part.headers.etag,
      PartNumber: partNumber
    }));
  } finally {
    // @ts-ignore
    const can = yield cancelled();

    if (can) {
      runningRequests.forEach((request) => request.request.cancel());
    }
  }
};
