import React, { Component } from 'react';
import { connect } from 'react-redux';
import Texts from '../../json/texts.json';
import * as actions from '../../actions';
import { Col, Row, Button, UncontrolledTooltip } from 'reactstrap';
import {
  PricingItem,
  PricingItemTitleSB as PricingItemTitle,
  PricingItemListItem,
  PricingItemList,
  PricingSwitch
} from '../../elements/Pricing';
import { RedeemDiscountKey } from '../../elements/DiscountKey';
import queryString from 'query-string';

const pricingPlans = [
  {
    order: 1,
    type: 'ultimate',
    yearly: 24,
    monthly: 29,
    intervall: 'yearly',
    months: 12
  },
  {
    order: 2,
    type: 'ultimate',
    yearly: 19,
    monthly: 24,
    intervall: 'twoYearly',
    months: 24
  }
];

class ScrAppBookPricing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intervall: 'yearly'
    };
    this.onClickPricingSwitch = this.onClickPricingSwitch.bind(this);
  }

  onClickPricingSwitch({ target: { className } }) {
    if (!className) {
      this.setState((prevState) => ({
        intervall: prevState.intervall === 'monthly' ? 'yearly' : 'monthly'
      }));
    }
  }

  renderPriceItemList(plan, intervall, yearlyPlan = false) {
    return (
      <PricingItemList>
        <PricingItemListItem noBulletPoint>
          <h3>
            <strong>
              {Texts[window.LANGUAGE_SW].pricingTables[`sb_storage_${plan}`]}{' '}
              {Texts[window.LANGUAGE_SW].pricingTables.storage}
            </strong>
          </h3>
        </PricingItemListItem>

        {yearlyPlan && (
          <PricingItemListItem>
            {intervall === 'twoYearly'
              ? Texts[window.LANGUAGE_SW].pricingTables.sb_features['0_twoYearly']
              : Texts[window.LANGUAGE_SW].pricingTables.sb_features['0']}

            <i id={intervall + '_0'} className="m-l-5 fa fa-icon fa-question-circle" />
            <UncontrolledTooltip placement="right" target={intervall + '_0'}>
              {intervall === 'twoYearly'
                ? Texts[window.LANGUAGE_SW].pricingTables.sb_features['0_twoYearly_tt']
                : Texts[window.LANGUAGE_SW].pricingTables.sb_features['0_tt']}
            </UncontrolledTooltip>
          </PricingItemListItem>
        )}

        <PricingItemListItem>
          {Texts[window.LANGUAGE_SW].pricingTables.sb_features['1']}
          <i id={intervall + '_1'} className="m-l-5 fa fa-icon fa-question-circle" />
          <UncontrolledTooltip placement="right" target={intervall + '_1'}>
            {Texts[window.LANGUAGE_SW].pricingTables.sb_features['1_tt']}
          </UncontrolledTooltip>
        </PricingItemListItem>
        <PricingItemListItem>
          {Texts[window.LANGUAGE_SW].pricingTables.sb_features['2']}
          <i id={intervall + '_2'} className="m-l-5 fa fa-icon fa-question-circle" />
          <UncontrolledTooltip placement="right" target={intervall + '_2'}>
            {Texts[window.LANGUAGE_SW].pricingTables.sb_features['2_tt']}
          </UncontrolledTooltip>
        </PricingItemListItem>

        <PricingItemListItem>
          {Texts[window.LANGUAGE_SW].pricingTables.sb_features['3']}
          <i id={intervall + '_3'} className="m-l-5 fa fa-icon fa-question-circle" />
          <UncontrolledTooltip placement="right" target={intervall + '_3'}>
            {Texts[window.LANGUAGE_SW].pricingTables.sb_features['3_tt']}
          </UncontrolledTooltip>
        </PricingItemListItem>

        <PricingItemListItem>
          {intervall === 'twoYearly'
            ? Texts[window.LANGUAGE_SW].pricingTables.sb_features['4_twoYearly']
            : Texts[window.LANGUAGE_SW].pricingTables.sb_features['4']}

          <i id={intervall + '_4'} className="m-l-5 fa fa-icon fa-question-circle" />
          <UncontrolledTooltip placement="right" target={intervall + '_4'}>
            {intervall === 'twoYearly'
              ? Texts[window.LANGUAGE_SW].pricingTables.sb_features['4_twoYearly_tt']
              : Texts[window.LANGUAGE_SW].pricingTables.sb_features['4_tt']}
          </UncontrolledTooltip>
        </PricingItemListItem>

        <PricingItemListItem>
          {Texts[window.LANGUAGE_SW].pricingTables.sb_features['5']}
          <i id={intervall + '_5'} className="m-l-5 fa fa-icon fa-question-circle" />
          <UncontrolledTooltip placement="right" target={intervall + '_5'}>
            {Texts[window.LANGUAGE_SW].pricingTables.sb_features['5_tt']}
          </UncontrolledTooltip>
        </PricingItemListItem>

        <PricingItemListItem>
          {intervall === 'twoYearly'
            ? Texts[window.LANGUAGE_SW].pricingTables.sb_features['6_twoYearly']
            : Texts[window.LANGUAGE_SW].pricingTables.sb_features['6']}

          <i id={intervall + '_6'} className="m-l-5 fa fa-icon fa-question-circle" />
          <UncontrolledTooltip placement="right" target={intervall + '_6'}>
            {Texts[window.LANGUAGE_SW].pricingTables.sb_features['6_tt']}
          </UncontrolledTooltip>
        </PricingItemListItem>
      </PricingItemList>
    );
  }

  renderPricingButton(intervall, plan) {
    const {
      auth: { digistore, authenticated }
    } = this.props;

    // const parsedHash = queryString.parse(window.location.hash);

    const query = queryString.stringify({
      discountKey: digistore && digistore.discountKey && digistore.discountKey._id,
      affiliate: digistore && digistore.discountKey && digistore.discountKey.affiliate
    });

    if (!authenticated) {
      return (
        <Button
          color="success"
          href={
            `${window.API_URL}/api/guest/subscription/buy/scrappbook/${plan}/${intervall}` +
            (query ? '?' + query : '')
          }
        >
          {Texts[window.LANGUAGE_SW].pricingTables.startNow}
        </Button>
      );
    }

    return (
      <Button
        size="xl"
        color="success"
        href={
          `${window.API_URL}/api/subscription/buy/scrappbook/${plan}/${intervall}` +
          (query ? '?' + query : '')
        }
      >
        {Texts[window.LANGUAGE_SW].pricingTables.startNow}
      </Button>
    );

    // up and downgrade is handled on server
  }

  // renderFreePlan({ xl, lg, md }) {
  //   return (
  //     <PricingItem xl={xl || '2'} lg={lg || '6'} md={md || '12'}>
  //       <div className="title">
  //         <h6>{Texts[window.LANGUAGE_SW].pricingTables.sb_product_name_free}</h6>
  //         <p className="text-muted">für immer</p>
  //         <h2 className="mb-2">
  //           <sup>€</sup>0
  //         </h2>
  //       </div>
  //       <PricingItemList>
  //         <PricingItemListItem noBulletPoint>
  //           <h4>
  //             {Texts[window.LANGUAGE_SW].pricingTables.sb_storage_free}
  //             <br />
  //             {Texts[window.LANGUAGE_SW].pricingTables.storage}
  //           </h4>
  //         </PricingItemListItem>
  //         {Object.keys(Texts[window.LANGUAGE_SW].pricingTables.sb_features_free).map(
  //           (key, index) => (
  //             <PricingItemListItem notAvailable={index === 7} key={index}>
  //               {Texts[window.LANGUAGE_SW].pricingTables.sb_features_free[key]}
  //             </PricingItemListItem>
  //           )
  //         )}
  //       </PricingItemList>
  //       <Button
  //         color={this.props.auth.authenticated ? 'primary' : 'success'}
  //         href={this.props.auth.authenticated ? '/#/subscription' : '/#/register'}
  //       >
  //         {this.props.auth.authenticated
  //           ? Texts[window.LANGUAGE_SW].pricingTables.managePlan
  //           : Texts[window.LANGUAGE_SW].pricingTables.startNow}
  //       </Button>
  //     </PricingItem>
  //   );
  // }

  renderPricingPlans() {
    const { intervall } = this.state;
    const {
      auth: { digistore }
    } = this.props;
    const noMinimumContractLength =
      digistore.discountKey && digistore.discountKey.noMinimumContractLength;

    return intervall === 'monthly' ? (
      <Row className="justify-content-center">
        {pricingPlans.map((plan) =>
          plan.monthly && (!noMinimumContractLength || plan.months === 12) ? (
            <PricingItem xl="4" lg="5" md="12" key={plan.order}>
              <PricingItemTitle
                plan={plan.type}
                price={plan.monthly}
                currency="€"
                discount={digistore.discountKey}
                noMinimumContractLength={noMinimumContractLength}
                intervall={'monthly' + plan.intervall.capitalize()}
              />
              {this.renderPriceItemList(plan.type, plan.intervall)}
              {this.renderPricingButton('monthly' + plan.intervall.capitalize(), plan.type)}
            </PricingItem>
          ) : null
        )}
      </Row>
    ) : (
      <Row className="justify-content-center">
        {pricingPlans.map((plan) => (
          <PricingItem
            xl="4"
            lg="5"
            md="12"
            key={plan.order}
            className={plan.intervall === 'yearly' ? 'best-choice' : ''}
            mostSelected={plan.intervall === 'yearly'}
            bestPrice={plan.intervall === 'twoYearly'}
          >
            <PricingItemTitle
              plan={plan.type}
              price={plan.yearly}
              currency="€"
              discount={digistore.discountKey}
              intervall={plan.intervall}
            />
            {this.renderPriceItemList(plan.type, plan.intervall, true)}
            {this.renderPricingButton(plan.intervall, plan.type)}
          </PricingItem>
        ))}
      </Row>
    );
  }

  render() {
    const { intervall } = this.state;
    const { auth } = this.props;
    const { digistore } = auth;

    console.log(digistore.discountKey);

    // noMinimumContractLength

    return digistore ? (
      <div className="sb-pricing animated fadeIn">
        <div className="pricing-view">
          <Row className="justify-content-center">
            <Col xs="8" className="text-center">
              <h1 className="m-b-20">{Texts[window.LANGUAGE_SW].pricingTables.sb_title}</h1>
              <h4 className="m-b-15">{Texts[window.LANGUAGE_SW].pricingTables.sb_subtitle}</h4>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col sm="12" md="6" className="text-center">
              <PricingSwitch intervall={intervall} onClick={this.onClickPricingSwitch} />
            </Col>
          </Row>
          <Row className="justify-content-center text-center">
            <div className="p-r-15 p-l-15">
              <p className="text-muted">{Texts[window.LANGUAGE_SW].pricingTables.netPrices}</p>
            </div>
          </Row>
          {this.renderPricingPlans()}

          {auth.authenticated && (
            <Row className="justify-content-center m-t-40 text-center">
              <div className="p-r-15 p-l-15">
                <RedeemDiscountKey />
                {Texts[window.LANGUAGE_SW].pricingTables.howToVideo && (
                  <p>
                    {Texts[window.LANGUAGE_SW].pricingTables.howToVideo}
                    {': '}
                    <a
                      href="https://help.scrappbook.de/article/automatische-loeschung-der-hochaufloesenden-bilder/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {Texts[window.LANGUAGE_SW].pricingTables.howToVideoLink}
                    </a>
                  </p>
                )}
              </div>
            </Row>
          )}
        </div>
      </div>
    ) : null;
  }
}

function mapStateToProps({ auth, notify }, { authentication }) {
  return { auth: authentication || auth, notify };
}

export default connect(mapStateToProps, actions)(ScrAppBookPricing);
