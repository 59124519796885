import { createAction } from 'redux-act';
import { IPaymentInfo } from '../types/paymentInfo';
import {
  ICalculateDiscountParams,
  IGetProrationsParams,
  IUpdateAddonsParams,
  IUpdateSubscriptionParams
} from '../types/subscription';

export const fetchPaymentInfoAction = createAction();
export const putPaymentInfoAction = createAction<Partial<IPaymentInfo>>();

export const fetchPricingAction = createAction();
export const calculateDiscountAction = createAction<ICalculateDiscountParams>();
export const getProrationsAction = createAction<IGetProrationsParams>();
export const updateSubscriptionAction = createAction<IUpdateSubscriptionParams>();
export const updateAddonsAction = createAction<IUpdateAddonsParams>();
export const getDigistoreBalanceAction = createAction<number>();
