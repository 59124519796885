import axios from '../helpers/axios';
import Texts from '../json/texts.json';
import { handleErrorWithRedirectIfNotAuth } from '../errors/generalError';
import { accessRulesAction } from '../modules/actions';

export const sendEmail = (siteId, notify) => async () => {
  try {
    const res = await axios.post('/api/lightroom/mail/sendLinkToCustomer', {
      siteId
    });
    if (res.status === 200) {
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].sendEmailSuccess,
        status: 'success',
        position: 'bl',
        dismissible: true,
        dismissAfter: 5000
      });
    } else {
      notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].sendEmailError,
        status: 'danger',
        position: 'bl',
        dismissible: true,
        dismissAfter: 5000
      });
    }
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const loadImagesForCurrentSite = () => async (dispatch, getState) => {
  try {
    const id = getState().site.currentSite._id;
    if (id) {
      const res = await axios.get('/api/site/images?id=' + id);
      if (res.status === 200) dispatch({ type: 'REFRESH_IMAGES_CURRENT_SITE', payload: res.data });
    }
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);
  }
};

export const addUploadedIconToCurrentSite = (image) => async (dispatch) => {
  try {
    dispatch({
      type: 'ADD_ICON_TO_CURRENT_SITE',
      payload: image
    });
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);
  }
};

export const updateCurrentSiteValueByPath = (site_id, path, value) => async (dispatch) => {
  try {
    const res = await axios.put('/api/site/update', {
      site_id,
      path,
      value
    });
    if (res.status === 200) {
      if (res.data.path) {
        dispatch({
          type: 'UPDATE_CURRENT_SITE_VALUE_BY_PATH',
          payload: { path: res.data.path, data: res.data.payload }
        });
      } else {
        dispatch({
          type: 'UPDATE_CURRENT_SITE_VALUE_BY_PATH',
          payload: { path, data: value }
        });
      }
    }
  } catch (e) {
    console.log(e);
    handleErrorWithRedirectIfNotAuth(e);
  }
};

export const deleteCustomDomainSettingsFromSite = (site_id) => async (dispatch) => {
  try {
    await axios.delete('/api/site/domain', {
      params: { site_id }
    });
    dispatch({
      type: 'UPDATE_CURRENT_SITE_VALUE_BY_PATH',
      payload: { path: 'customDomain', data: null }
    });
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);
  }
};

export const updateCurrentSiteSectionOrder = (site_id, value) => async (dispatch) => {
  try {
    await axios.put('/api/site/update', {
      site_id,
      path: 'sections',
      value
    });

    dispatch({
      type: 'UPDATE_CURRENT_SITE_VALUE_BY_PATH',
      payload: { path: 'sections', data: value }
    });
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);
  }
};

export const getAllSites = (notify) => async (dispatch) => {
  try {
    const res = await axios.get('/api/sites');

    dispatch({ type: 'ALL_SITES', payload: res.data });
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const getCollectionsCurrentUser =
  (
    data = {
      skip: 0,
      limit: 10,
      titleAndNameFilter: '',
      sortBy: 'updated_at',
      sortOrder: 'desc',
      galleryTagsValue: null
    }
  ) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: 'UPDATE_LOADING_STATUS', payload: true });
      const { galleriesTags } = getState();

      const tagsValue = (
        data.galleryTagsValue || galleriesTags.collectionsOverviewSelectedTags
      ).map((tag) => tag.value.toLowerCase());

      const res = await axios.get('/api/collections', {
        params: {
          skip: data.skip,
          limit: data.limit,
          titleAndNameFilter: data.titleAndNameFilter,
          sortBy: data.sortBy,
          sortOrder: data.sortOrder,
          galleryTags: tagsValue.join(',') || undefined
        }
      });

      dispatch({ type: 'ALL_SITES', payload: res.data });
      dispatch({ type: 'UPDATE_LOADING_STATUS', payload: false });
    } catch (e) {
      const { notify } = getState();
      handleErrorWithRedirectIfNotAuth(e, notify);
      dispatch({ type: 'UPDATE_LOADING_STATUS', payload: false });
    }
  };

export const cloneSiteById = (site_id, notify) => async (dispatch) => {
  try {
    const res = await axios.put('/api/site/clone', { site_id });
    setTimeout(() => dispatch({ type: 'ADD_SITE_TO_ALL_SITES', payload: res.data }), 5000);
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const deleteSiteById = (site_id) => async (dispatch) => {
  try {
    await axios.delete('/api/site/', { params: { site_id } });
    dispatch({ type: 'REMOVE_SITE', payload: site_id });
    dispatch(accessRulesAction());
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);
  }
};

export const addVideo = (site_id, after_section) => async (dispatch) => {
  try {
    const res = await axios.post('/api/site/video/add/', {
      site_id,
      after_section
    });
    if (res.status === 200) dispatch({ type: 'add_video', payload: res.data });
  } catch (e) {
    console.log(e);
  }
};

export const deleteVideo = (section, site_id, video_id) => async (dispatch) => {
  try {
    if (window.confirm(Texts[window.LANGUAGE_SW].weddingSuite.rlyDelete)) {
      const res = await axios.delete('/api/site/video', {
        params: {
          section,
          site_id,
          video_id
        }
      });
      if (res.status === 200) dispatch({ type: 'remove_video', payload: res.data });
    }
  } catch (e) {
    console.log(e);
  }
};
export const addAgenda = (site_id, after_section) => async (dispatch) => {
  try {
    const res = await axios.post('/api/site/agenda/add', {
      site_id,
      after_section
    });
    if (res.status === 200) {
      dispatch({
        type: 'add_agenda',
        payload: res.data
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteAgenda = (section, site_id, agenda_id) => async (dispatch) => {
  try {
    if (window.confirm(Texts[window.LANGUAGE_SW].weddingSuite.rlyDelete)) {
      const res = await axios.delete('/api/site/agenda', {
        params: {
          section,
          site_id,
          agenda_id
        }
      });
      if (res.status === 200) {
        dispatch({
          type: 'remove_agenda',
          payload: res.data
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
};
export const addContactPersons = (site_id, after_section) => async (dispatch) => {
  try {
    const res = await axios.post('/api/site/persons/add', {
      site_id,
      after_section
    });
    if (res.status === 200) {
      dispatch({
        type: 'add_persons',
        payload: res.data
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteContactPersons = (section, site_id, persons_id) => async (dispatch) => {
  try {
    if (window.confirm(Texts[window.LANGUAGE_SW].weddingSuite.rlyDelete)) {
      const res = await axios.delete('/api/site/persons', {
        params: {
          section,
          site_id,
          persons_id
        }
      });
      if (res.status === 200) {
        dispatch({
          type: 'remove_persons',
          payload: res.data
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const addVenues = (site_id, after_section) => async (dispatch) => {
  try {
    const res = await axios.post('/api/site/venues/add/', {
      site_id,
      after_section
    });
    if (res.status === 200) {
      dispatch({
        type: 'add_venues',
        payload: res.data
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteVenues = (section, site_id, venues_id) => async (dispatch) => {
  try {
    if (window.confirm(Texts[window.LANGUAGE_SW].weddingSuite.rlyDelete)) {
      const res = await axios.delete('/api/site/venues', {
        params: {
          section,
          site_id,
          venues_id
        }
      });
      if (res.status === 200) dispatch({ type: 'remove_venues', payload: res.data });
    }
  } catch (e) {
    console.log(e);
  }
};
