import { createAction } from 'redux-act';
import { IDictionary } from '../../modules/types';

export const errorsGlobalError = createAction<ErrorEvent>();

export const clearLoaders = createAction();

export const showNotifyMessage = createAction<string>();
export const showNotifySuccess = createAction<{ message?: string }>();
export const showNotifyFail = createAction<{ message?: string } | void>();

export const logUserActivity = createAction<IDictionary<any>>();

export const setAppVersion = createAction<number>();
export const setAppVersionDelay = createAction<number>();

export const setRequiredTaxSavedAction = createAction<boolean>();
