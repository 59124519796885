import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import Texts from '../../../json/texts.json';

const SurveyModalNPS = ({ isSurveyModalNPSOpen, setSurveyModalNPSVisibility, surveys }) =>
  isSurveyModalNPSOpen ? (
    <Modal
      centered
      isOpen={isSurveyModalNPSOpen}
      toggle={() => setSurveyModalNPSVisibility(!isSurveyModalNPSOpen)}
      wrapClassName="modal-dark"
      size="lg"
      backdrop="static"
    >
      <ModalHeader toggle={() => setSurveyModalNPSVisibility(!isSurveyModalNPSOpen)}>
        {Texts[window.LANGUAGE_SW].surveys.ces.modalTitle}
      </ModalHeader>
      <ModalBody className="min-h-500">
        <iframe
          className="min-h-500"
          title="survey-frame"
          id="typeform-full"
          width="100%"
          height="100%"
          frameBorder="0"
          allow="camera; microphone; autoplay; encrypted-media;"
          src={`https://scrappbook.typeform.com/to/vWlY10${
            surveys && surveys.nps && surveys.nps.score >= 0
              ? `?prefilled_answer=${surveys.nps.score}`
              : ''
          }`}
        />
      </ModalBody>
    </Modal>
  ) : null;

function mapStateToProps({ message: { isSurveyModalNPSOpen }, auth: { surveys } }) {
  return {
    isSurveyModalNPSOpen,
    surveys
  };
}

export default connect(mapStateToProps, actions)(SurveyModalNPS);
