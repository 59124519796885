import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveTabSelector,
  getCollectionId,
  getEmailTemplatePreviewSelector,
  getSelectedEmailTemplateSelector
} from '../../modules/selectors';
import { getEmailTemplatePreviewAction } from '../../modules/actions';
import styled from 'styled-components';
import { getTranslationKey } from '../../modules/utils';
import useDeliveryForm from './useDeliveryForm';

const Wrapper = styled.div`
  img {
    max-width: 100% !important;
  }

  [contentEditable='true'] {
    white-space: pre-line;
    &:focus,
    &:hover {
      box-shadow: 0 0 0 0 transparent !important;
    }
  }

  #email-preview-edit-text {
    display: block;
  }
`;

interface IProps {}

export const EmailTemplatePreview: React.FC<IProps> = () => {
  const dispatch = useDispatch();
  const [deliveryForm, setDeliveryForm] = useDeliveryForm();
  const collectionID = useSelector(getCollectionId);
  const emailPreviewStore = useSelector(getEmailTemplatePreviewSelector);
  const ref = useRef(null);
  const selectedTemplate = useSelector(getSelectedEmailTemplateSelector);
  const [emailPreview, setEmailPreview] = useState('No Template');
  const { html } = selectedTemplate;
  const selectedCollectionTab = useSelector(getActiveTabSelector);
  const lastStorageActiveTab = localStorage.getItem('collection:settings:lastTab');

  useEffect(() => {
    if (ref.current) {
      // @ts-ignore
      ref.current.addEventListener('keyup', (e: any) => {
        e.stopPropagation();
        const emailText = e.target.innerHTML
          ? e.target.innerHTML
              .replace(/(?:\r\n|\r|\n)/g, '')
              .replace(/<div><br><\/div>/g, '<br>')
              .replace(/<div>/g, '<br>')
              .replace(/<\/div>/g, '')
          : html;

        setDeliveryForm({ html: emailText, selectedTemplateId: selectedTemplate.id });
      });
    }
  }, [ref.current, selectedTemplate.id]);

  useEffect(() => {
    const defaulTemplate = `<span id="email-preview-edit-text" contenteditable="true">
			${getTranslationKey('collectionSettings.sendEmail.defaultEmailPreviewText')}
		</span>`;
    const previewTemplate = `<span id="email-preview-edit-text" contenteditable="true">${html}</span>`;

    const textReplacement = html.length ? previewTemplate : defaulTemplate;

    const replacedString = emailPreviewStore.split('{{html}}').join(textReplacement);
    setEmailPreview(replacedString);
  }, [emailPreviewStore, html]);

  useEffect(() => {
    const isDeliveryTab = [selectedCollectionTab, lastStorageActiveTab].includes('delivery');

    if (collectionID && isDeliveryTab) {
      dispatch(getEmailTemplatePreviewAction());
    }
  }, [collectionID, deliveryForm.showPasswordInMail, selectedCollectionTab, lastStorageActiveTab]);

  return <Wrapper ref={ref} dangerouslySetInnerHTML={{ __html: emailPreview }} />;
};
