import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Row } from 'reactstrap';
import Texts from '../../../json/texts.json';
import { connect } from 'react-redux';
import InputGroup from '../../../elements/Forms/InputGroup';
import * as actions from '../../../actions';
import { Field, reduxForm } from 'redux-form';
import approve from 'approvejs';
import history from '../../../helpers/history';
import { sample } from 'lodash';

const fields = {
  email: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].emailPlaceholder,
    title: Texts[window.LANGUAGE_SW].email,
    rules: {
      email: {
        message: Texts[window.LANGUAGE_SW].emailErrorMessage
      }
    }
  }
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);

    this.state = {
      backgroundImage: this.getRandomBackgroundUrl()
    };
  }

  componentDidMount() {
    if (this.props.auth.authenticated) history.push('/');
  }

  getRandomBackgroundUrl() {
    const backgroundUrls = [
      `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/builder/login/flying-camera.jpg'`,
      `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/builder/login/camera-smartphone-laptop.jpg'`,
      `${process.env.REACT_APP_PUBLIC_BUCKET_URL}/builder/login/workspace.jpg`
    ];

    return sample(backgroundUrls);
  }

  submit(values) {
    this.props.forgotPassword(values.email, this.props.notify);
  }

  render() {
    return (
      <div
        className="app flex-row align-items-center"
        style={{
          backgroundImage:
            window.USER_TYPE !== 'VST'
              ? `url(${this.state.backgroundImage})`
              : `url(${window.CDN_URL}/public/gallerypix-loginscreen.jpg)`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: window.USER_TYPE !== 'VST' ? 'gray' : 'unset'
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md="7">
              <CardGroup>
                <Card>
                  <CardBody>
                    <Form onSubmit={this.props.handleSubmit(this.submit)}>
                      <h1> {Texts[window.LANGUAGE_SW].forgotPasswordTitle}</h1>
                      <p className="text-muted">
                        {Texts[window.LANGUAGE_SW].forgotPasswordTitleDesc}
                      </p>
                      {Object.keys(fields).map((fieldKey, index) => (
                        <Field
                          component={InputGroup}
                          key={index}
                          name={fieldKey}
                          field={fields[fieldKey]}
                        />
                      ))}
                      <Row>
                        <Col xs="12">
                          <Button
                            color="primary"
                            className="w-100-p"
                            disabled={
                              this.props.pristine || this.props.invalid || this.props.submitting
                            }
                            type="submit"
                          >
                            {Texts[window.LANGUAGE_SW].requestNewPassword}
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="m-t-10 text-center">
                          <a href="/#/login/">{Texts[window.LANGUAGE_SW].login}</a>
                        </Col>
                      </Row>
                      {window.USER_TYPE === 'VST' && (
                        <Row className="m-t-20">
                          <Col xs="12">
                            <hr />
                            <a
                              target="_blank"
                              href="https://www.vst-pro.de/impressum/"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].imprint}
                            </a>
                            {' - '}
                            <a
                              target="_blank"
                              href="https://www.vst-pro.de/datenschutz/"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].privacy}
                            </a>
                            {' - '}
                            <a
                              target="_blank"
                              href="https://www.vst-pro.de/kontakt/"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].contact}
                            </a>
                            {' - '}
                            <a
                              target="_blank"
                              href="https://www.vst-pro.de/produktwelten/produkte/produkt/vst-gallerypix/"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].productInfos}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {window.USER_TYPE === 'SB' && (
                        <Row className="m-t-20">
                          <Col xs="12">
                            <hr />
                            <a
                              target="_blank"
                              href="https://scrappbook.de/impressum-2"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].imprint}
                            </a>
                            {' - '}
                            <a
                              target="_blank"
                              href="https://scrappbook.de/datenschutz-2"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].privacy}
                            </a>
                            {' - '}
                            <a
                              target="_blank"
                              href="https://scrappbook.de/kontakt-support/"
                              rel="noopener noreferrer"
                            >
                              {Texts[window.LANGUAGE_SW].contact}
                            </a>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function validate(values) {
  const errors = {};

  Object.keys(fields).forEach((fieldKey) => {
    const result = approve.value(values[fieldKey], fields[fieldKey].rules);
    if (result.errors.length > 0) {
      errors[fieldKey] = result.errors;
    }
  });

  return errors;
}

ForgotPassword = reduxForm({
  form: 'forgotPasswordForm',
  validate
})(ForgotPassword);

ForgotPassword = connect(
  (state) => ({
    config: state.config,
    auth: state.auth
  }),
  actions
)(ForgotPassword);

export default ForgotPassword;
