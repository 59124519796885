import React, { PureComponent } from 'react';
import { AppNavbarBrand } from '@coreui/react';
import logo from '../../assets/img/brand/logo_blue.png';
import { DefaultAppHeaderDropdown } from '../../components';

class EditSiteHeader extends PureComponent {
  render() {
    return (
      <>
        <AppNavbarBrand
          href="/#/account/setup"
          className="p-2"
          full={{
            src: logo,
            width: '100%',
            alt: 'LeadSuite Logo'
          }}
          minimized={{
            src: logo,
            width: 30,
            height: 30,
            alt: 'LeadSuite Logo'
          }}
        />
        <DefaultAppHeaderDropdown onlyLogout />
      </>
    );
  }
}

export default EditSiteHeader;
