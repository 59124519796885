import React from 'react';
import Texts from '../../../json/texts.json';
import moment from 'moment';
import currencySymbols from '../../../helpers/currency';

const ConfirmData = ({
  lead: { first_name, last_name, email, phone_no, zipcode },
  product,
  appointment
}) => (
  <div>
    <h2>{Texts[window.LANGUAGE_SW].overview}</h2>
    <p>
      <strong>{Texts[window.LANGUAGE_SW].contactInformation}</strong>
      <br />
      {first_name} {last_name} <br />
      {email}
      {phone_no && <div>{phone_no}</div>}
      {zipcode && <div>{zipcode}</div>}
    </p>
    {product._id && (
      <div>
        <h3 className="inline">{product.title}</h3>
        <h3 className="float-right inline">{product.price + currencySymbols(product.currency)}</h3>
        <p>{product.description}</p>
      </div>
    )}
    {appointment && (
      <p>
        <strong>{Texts[window.LANGUAGE_SW].appointment}</strong>
        <br />
        {`${moment(appointment.start).format('dddd D. MMMM YYYY')} - ${moment(
          appointment.start
        ).format('HH:mm')} - ${moment(appointment.end).format('HH:mm')} Uhr`}
      </p>
    )}
  </div>
);

export default ConfirmData;
