import { getTranslationKey } from '../../../modules/utils/helpers';
import { IAccessRules } from '../../../modules/types/accessRules';
import { get } from 'lodash';

interface IFeeData {
  [key: string]: number | string;
}

const isFinite = (item: number | null) => item !== null;
const isAvailable = (item: number | null) => item !== 0;
const getTitle = (item: number, text: string, unlimText: string) =>
  isFinite(item)
    ? `${item} ${getTranslationKey(`usageTab.${text}`)}`
    : getTranslationKey(`usageTab.${unlimText}`);

export const getDataList = (
  accessRules: IAccessRules,
  feeData: IFeeData,
  isPaymentProblem: boolean
) => {
  const {
    brands: brandsLeft = 0,
    gallerypresets: collectionPresetsLeft = 0,
    collections: collectionsLeft = 0,
    storageSpaceConsumptionMb: imageStorageLeftMb = 0,
    slideshows: slideshowsLeft = 0,
    domains: domainsLeft = 0,
    videoStorageSpaceConsumptionMb: videoStorageLeftMb = 0
  } = accessRules.planUsage || {};

  const { canCreateCustomWatermark = false, canCustomimzeDesign = false } =
    accessRules.availableRules || {};

  const {
    brands = 0,
    collectionPresets = 0,
    collections = 0,
    domains = 0,
    slideshows = 0,
    imageStorageMb = 0,
    videoStorageMb = 0
  } = accessRules.planConfig || {};

  const getProgress = (spaceLeft: number, entireSpace: number) =>
    ((spaceLeft / entireSpace) * 100).toFixed(2);

  return [
    {
      id: 'imageStorageMb',
      title: getTitle(
        isFinite(imageStorageMb) ? imageStorageMb / 1000 : imageStorageMb,
        'imageStorage',
        'unlimImageStorage'
      ),
      content: isFinite(imageStorageMb)
        ? `${(imageStorageLeftMb / 1000).toFixed(2)} / ${imageStorageMb / 1000} GB`
        : '∞',
      linkTitle: !isPaymentProblem && isFinite(imageStorageMb),
      isAvailable: isAvailable(imageStorageMb),
      progress: getProgress(imageStorageLeftMb, imageStorageMb),
      isAboveLimit: isFinite(imageStorageMb) && imageStorageLeftMb > imageStorageMb,
      amountAboveLimit: isFinite(imageStorageMb) && imageStorageLeftMb - imageStorageMb
    },
    {
      id: 'videoStorageMb',
      title: videoStorageMb / 1000 + getTranslationKey('usageTab.videoStorage'),
      content: `${(videoStorageLeftMb / 1000).toFixed(2)} / ${videoStorageMb / 1000} GB`,
      linkTitle:
        !isPaymentProblem && isFinite(videoStorageMb)
          ? getTranslationKey('usageTab.upgradeForMore')
          : '',
      isAvailable: isAvailable(videoStorageMb),
      progress: getProgress(videoStorageLeftMb, videoStorageMb),
      isAboveLimit: isFinite(videoStorageMb) && videoStorageLeftMb > videoStorageMb,
      amountAboveLimit: isFinite(videoStorageMb) && videoStorageLeftMb - videoStorageMb,
      upgradeAddonModalKey: 'video-storage'
    },
    {
      id: 'collections',
      title: getTitle(collections, 'createdGalleries', 'unlimGalleries'),
      content: isFinite(collections) ? `${collectionsLeft} / ${collections}` : '∞',
      linkTitle:
        !isPaymentProblem && isFinite(collections)
          ? getTranslationKey('usageTab.upgradeForMore')
          : '',
      isAvailable: isAvailable(collections),
      progress: getProgress(collectionsLeft, collections),
      isAboveLimit: isFinite(collections) && collectionsLeft > collections,
      amountAboveLimit: isFinite(collections) && collectionsLeft - collections
    },
    {
      id: 'slideshows',
      title: getTitle(slideshows, 'createdSlideshows', 'unlimSlideshows'),
      content: isFinite(slideshows) ? `${slideshowsLeft} / ${slideshows}` : '∞',
      linkTitle:
        !isPaymentProblem && isFinite(slideshows)
          ? getTranslationKey('usageTab.upgradeForMore')
          : '',
      isAvailable: isAvailable(slideshows),
      progress: getProgress(slideshowsLeft, slideshows),
      isAboveLimit: isFinite(slideshows) && slideshowsLeft > slideshows,
      amountAboveLimit: isFinite(slideshows) && slideshowsLeft - slideshows,
      upgradeAddonModalKey: 'slideshows'
    },
    {
      id: 'brands',
      title: getTitle(brands, 'brands', 'unlimBrands'),
      content: isFinite(brands) ? `${brandsLeft} / ${brands}` : '∞',
      linkTitle:
        !isPaymentProblem && isFinite(brands) ? getTranslationKey('usageTab.upgradeForMore') : '',
      isAvailable: isAvailable(brands),
      progress: getProgress(brandsLeft, brands),
      isAboveLimit: isFinite(brands) && brandsLeft > brands,
      amountAboveLimit: isFinite(brands) && brandsLeft - brands,
      upgradeAddonModalKey: 'brands'
    },
    {
      id: 'collectionPresets',
      title: getTitle(collectionPresets, 'galleryPresets', 'unlimGalleryPresets'),
      content: isFinite(collectionPresets)
        ? `${collectionPresetsLeft} / ${collectionPresets}`
        : '∞',
      linkTitle:
        !isPaymentProblem && isFinite(collectionPresets)
          ? getTranslationKey('usageTab.upgradeForMore')
          : '',
      isAvailable: isAvailable(collectionPresets),
      progress: getProgress(collectionPresetsLeft, collectionPresets),
      isAboveLimit: isFinite(collectionPresets) && collectionPresetsLeft > collectionPresets,
      amountAboveLimit: isFinite(collectionPresets) && collectionPresetsLeft - collectionPresets
    },
    {
      id: 'domains',
      title: getTitle(domains, 'customDomains', 'unlimCustomDomains'),
      content: isFinite(domains) ? `${domainsLeft} / ${domains}` : '∞',
      linkTitle:
        !isPaymentProblem && isFinite(domains) ? getTranslationKey('usageTab.upgradeForMore') : '',
      isAvailable: isAvailable(domains),
      progress: getProgress(domainsLeft, domains),
      isAboveLimit: isFinite(domains) && domainsLeft > domains,
      amountAboveLimit: isFinite(domains) && domainsLeft - domains,
      upgradeAddonModalKey: 'domains'
    },
    {
      id: 'serviceFee',
      title: getTranslationKey('usageTab.serviceFeeText', feeData),
      isAvailable: true
    },
    {
      id: 'customWatermarks',
      title: getTranslationKey('usageTab.customWatermarks'),
      linkTitle:
        !isPaymentProblem && !canCreateCustomWatermark ? getTranslationKey('usageTab.upgrade') : '',
      isAvailable: canCreateCustomWatermark,
      isAboveLimit: !canCreateCustomWatermark && get(accessRules, 'planUsage.watermarks', 0) > 1,
      amountAboveLimit: !canCreateCustomWatermark && get(accessRules, 'planUsage.watermarks', 1) - 1
    },
    {
      id: 'customDesign',
      title: getTranslationKey('usageTab.customDesign'),
      linkTitle:
        !isPaymentProblem && !canCustomimzeDesign ? getTranslationKey('usageTab.upgrade') : '',
      isAvailable: canCustomimzeDesign,
      isAboveLimit:
        !canCustomimzeDesign && get(accessRules, 'planUsage.presetsWithCustomDesign', 0) > 0,
      amountAboveLimit:
        !canCustomimzeDesign && get(accessRules, 'planUsage.presetsWithCustomDesign', 0)
    }
  ];
};
