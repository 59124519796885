import { hotjar } from 'react-hotjar';

export const initializeHotjar = () => {
  try {
    if (
      process.env.REACT_APP_ENABLE_HOTJAR &&
      process.env.REACT_APP_HOTJAR_ID &&
      process.env.REACT_APP_HOTJAR_VERSION &&
      window.USER_TYPE === 'SB' &&
      !hotjar.initialized()
    ) {
      hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, process.env.REACT_APP_HOTJAR_VERSION);
    }
  } catch (e) {
    console.log(e);
  }
};
