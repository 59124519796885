import React from 'react';
import { getTranslationKey } from '../../../modules/utils/helpers';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getModalData } from '../../../modules/selectors';
import useModalHelpers from '../useModalHelpers';

const StyledTextArea = styled.textarea`
  && {
    height: 85px;
    resize: none;
  }
`;

const ThirdStep = () => {
  const { handleValue } = useModalHelpers();
  const { additionalAnswer = '' } = useSelector(getModalData);

  return (
    <div>
      <span>{getTranslationKey('cancelSubscriptionModal.answers.otherFeedback')}</span>
      <div className="form-group m-b-5 mt-1">
        <StyledTextArea
          className="form-control"
          name="additionalAnswer"
          placeholder={getTranslationKey(
            'cancelSubscriptionModal.answers.otherFeedbackPlaceholder'
          )}
          onChange={({ target: { name, value } }) => handleValue(name, value)}
          value={additionalAnswer}
        />
      </div>
    </div>
  );
};

export default ThirdStep;
