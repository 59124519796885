import React from 'react';
import classNames from 'classnames';

const PricingItemListItem = ({ children, notAvailable, noBulletPoint }) => (
  <li
    className={classNames({
      notAvailable,
      noBulletPoint
    })}
  >
    {children}
  </li>
);

export default PricingItemListItem;
