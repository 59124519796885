import React from 'react';
import { ImageWrap } from './index';
import { getTranslationKey } from '../../modules/utils';
import { Button } from 'reactstrap';
import monitorsImg from '../../assets/img/monitors-img.png';
import { useDispatch } from 'react-redux';
import { sendLoginLinkEmailAction } from '../../modules/actions';

interface IFirstRestrictionStep {
  closeModal: (e: React.SyntheticEvent) => void;
  goToNextStep: () => void;
}

export const FirstRestrictionStep: React.FC<IFirstRestrictionStep> = ({
  closeModal,
  goToNextStep
}) => {
  const dispatch = useDispatch();

  const sendLoginLink = () => dispatch(sendLoginLinkEmailAction({ callback: goToNextStep }));

  return (
    <>
      <h1>{getTranslationKey('mobileRestrictionModal.title')}</h1>
      <p>{getTranslationKey('mobileRestrictionModal.description')}</p>
      <ImageWrap>
        <img src={monitorsImg} alt="" />
      </ImageWrap>
      <p className="mt-3">{getTranslationKey('mobileRestrictionModal.callToAction')}</p>

      <Button color="success" className="btn btn-primary" size="lg" onClick={sendLoginLink}>
        {getTranslationKey('mobileRestrictionModal.button')}
      </Button>

      <a className="mt-5" href="/" onClick={closeModal}>
        {getTranslationKey('mobileRestrictionModal.continue')}
      </a>
    </>
  );
};
