import axios from '../helpers/axios';
import * as errors from '../errors';
import Texts from '../json/texts.json';

export const getImprints = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/imprints');
    dispatch({ type: 'SET_ALL_IMPRINTS', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const createImprint = (imprint, notify) => async (dispatch) => {
  try {
    const res = await axios.post('/api/imprint/', imprint);
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyCreated,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'ADD_IMPRINT', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const editImprint = (imprint, notify) => async (dispatch) => {
  try {
    await axios.put('/api/imprint/', imprint);
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyEdited,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'EDIT_IMPRINT', payload: imprint });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const deleteImprint = (imprint_id, notify) => async (dispatch) => {
  try {
    await axios.delete('/api/imprint/', { params: { imprint_id } });
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyDeleted,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'REMOVE_IMPRINT', payload: imprint_id });
  } catch (e) {
    if (e.response.status === 428) {
      notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].customDomains.deleteImprintErrorInUse,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 10000
      });
    } else errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};
