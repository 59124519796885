import axios from '../helpers/axios';
import { handleErrorWithRedirectIfNotAuth } from '../errors/generalError';

export const getAdminDashboardStatistics = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/statistics/adminDashboard');
    if (res.status === 200) {
      dispatch({ type: 'fetch_admin_dashboard_statistics', payload: res.data });
    }
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);
  }
};

export const getUserDashboardStatistics = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/statistics/userDashboard');
    if (res.status === 200) {
      dispatch({ type: 'fetch_user_dashboard_statistics', payload: res.data });
    }
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);
  }
};
