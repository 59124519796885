import { createAction } from 'redux-act';
import { FetchSlideshowMusic, ILoadMoreAction, ITrack } from '../../modules/types';

export const fetchMusic = createAction();
export const fetchMusicSuccess = createAction<{ data: ITrack[]; totalAmount: number }>();
export const fetchSlideshowMusic = createAction<FetchSlideshowMusic>();
export const fetchSlideshowMusicSuccess = createAction<{ data: ITrack[]; slideshowId: string }>();
export const filterMusic = createAction<any>('FILTER_MUSIC_DATA');
export const fetchFavoriteMusic = createAction();
export const updateFavoritesSuccess = createAction<{ favorites: ITrack[] }>(
  'UPDATE_FAVORITES_SUCCESS'
);
export const addMusicToFavorites = createAction<{ song: ITrack }>();
export const removeFavorites = createAction<string>();
export const loadMore = createAction<ILoadMoreAction>('LOAD_MORE');
export const changeIsLoadingMoreStatus = createAction<boolean>('LOADING_MORE');
export const clearMusic = createAction('CLEAR_MUSIC');
export const changeisFetchingSlideshowSoundtracks = createAction<boolean>();
