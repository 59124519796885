export default function (state = { all: [], products: [] }, action) {
  switch (action.type) {
    case 'SET_ALL_BOOKINGSITES':
      return { ...state, all: action.payload };
    case 'ADD_BOOKINGSITE':
      return { ...state, all: [action.payload, ...state.all] };
    case 'SET_ALL_BOOKING_PRODUCTS':
      return { ...state, products: action.payload };
    case 'ADD_BOOKING_PRODUCT':
      return { ...state, products: [...state.products, action.payload] };
    case 'EDIT_BOOKING_PRODUCT':
      const editedProduct = action.payload;
      const products = state.products.map((product) => {
        if (product._id === editedProduct._id) return editedProduct;

        return product;
      });

      return { ...state, products };
    case 'REMOVE_BOOKING_PRODUCT':
      return {
        ...state,
        products: state.products.filter((product) => product._id !== action.payload)
      };
    default:
      return state;
  }
}
