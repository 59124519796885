import { createReducer } from 'redux-act';
import { IEmailTemplate, IEmailTemplatesReducer } from '../../../modules/types/emails';
import {
  fetchEmailTemplatesSuccessAction,
  fetchRecipientsSuccessAction,
  getEmailTemplatePreviewSuccessAction,
  setDeliveryFormAction,
  setSelectedEmailTemplateAction
} from '../../../modules/actions';

const initialState: IEmailTemplatesReducer = {
  items: [],
  isFetched: false,
  selectedTemplate: {
    id: '',
    name: '',
    subject: '',
    html: '',
    buttonLabel: '',
    type: 'collection-delivery'
  },
  recipientsEmails: [],
  recipientsEmailsOptions: [],
  emailPreviewHTML: '',
  subject: '',
  html: '',
  selectedTemplateId: '',
  emailsCollectedWithPurchase: true,
  sendCopyToUser: false,
  mainCustomerData: { firstName: '', lastName: '' },
  showPasswordInMail: true
};

export const emailTemplates = createReducer<IEmailTemplatesReducer>({}, initialState);
emailTemplates.on(
  fetchEmailTemplatesSuccessAction,
  (state: IEmailTemplatesReducer, payload: IEmailTemplate[]) => ({
    ...state,
    items: payload,
    isFetched: true
  })
);
emailTemplates.on(
  setSelectedEmailTemplateAction,
  (state: IEmailTemplatesReducer, payload: IEmailTemplate) => ({
    ...state,
    selectedTemplate: payload
  })
);
emailTemplates.on(
  fetchRecipientsSuccessAction,
  (state: IEmailTemplatesReducer, payload: string[]) => ({
    ...state,
    recipientsEmailsOptions: payload
  })
);
emailTemplates.on(
  getEmailTemplatePreviewSuccessAction,
  (state: IEmailTemplatesReducer, payload: string) => ({
    ...state,
    emailPreviewHTML: payload
  })
);

emailTemplates.on(
  setDeliveryFormAction,
  (state: IEmailTemplatesReducer, payload: Partial<IEmailTemplatesReducer>) => ({
    ...state,
    ...payload
  })
);

export { initialState as emailTemplatesInitialState };
