export default function (state = { all: [] }, action) {
  switch (action.type) {
    case 'SET_ALL_IMPRINTS':
      return { ...state, all: action.payload };
    case 'ADD_IMPRINT':
      return { ...state, all: [action.payload, ...state.all] };
    case 'EDIT_IMPRINT':
      const newImprint = action.payload;
      const newImprints = state.all.map((imprint) => {
        if (imprint._id !== newImprint._id) return imprint;

        return newImprint;
      });

      return {
        ...state,
        all: newImprints
      };
    case 'REMOVE_IMPRINT':
      return {
        ...state,
        all: state.all.filter((imprint) => imprint._id !== action.payload)
      };
    default:
      return state;
  }
}
