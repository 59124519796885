export const setCookie = (cname, cvalue, exdays) => {
  try {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();

    let domain = '';

    if (window.location.hostname !== 'localhost') {
      domain = `;domain=.${window.location.hostname}`;
    }

    document.cookie = cname + '=' + cvalue + ';' + expires + domain + ';path=/';
  } catch (e) {
    console.log(e);
  }
};

export const getCookie = (cname) => {
  try {
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return null;
  } catch (e) {
    console.log(e);

    return null;
  }
};
