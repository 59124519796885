import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Texts from '../../../json/texts.json';
import SetDefaultValues from '../../SetupAccount/SetDefaultValues';

const EditBrand = ({ isOpen, userBrandId, toggle }) =>
  userBrandId ? (
    <Modal centered isOpen={isOpen} toggle={toggle} wrapClassName="modal-dark" size="lg">
      <ModalHeader toggle={toggle}>{Texts[window.LANGUAGE_SW].brand.edit}</ModalHeader>
      <ModalBody>
        <SetDefaultValues accountView userBrandId={userBrandId} />
      </ModalBody>
    </Modal>
  ) : null;

export default EditBrand;
