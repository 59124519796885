import { IPreset } from '../reducers/presets';
import { ActionButton } from '../../pages/collectionPresets/ActionButton';
import React from 'react';
import { getTranslationKey } from '../utils/helpers';
import { IDictionary } from '../types';
import { useSelector } from 'react-redux';
import { getBrandsList, getDomainsList, getWatermarksList } from '../selectors';
import { getDevelopedTemplates } from '../selectors/templates';
import { get, uniqueId } from 'lodash';
import { SORT_IMAGES_OPTIONS } from '../constants/images';

interface ITableList {
  name: string;
  isDefault: string;
  action: React.ReactElement;
}

interface IUsePresetTableData {
  tableList: ITableList[];
  tableCols: string[];
  tableColsNames: string[];
  sortable: IDictionary<string>[];
}

export const usePresetTableData = (presetsList: IPreset[]): IUsePresetTableData => {
  const items = presetsList.map((preset) => ({
    name: preset.name,
    isDefault: preset.default
      ? getTranslationKey('galleryPresets.yes')
      : getTranslationKey('galleryPresets.no'),
    action: <ActionButton presetId={preset._id} />
  }));

  return {
    tableList: items,
    tableCols: ['name', 'isDefault', 'action'],
    tableColsNames: [
      getTranslationKey('galleryPresets.nameCol'),
      getTranslationKey('galleryPresets.defaultCol'),
      getTranslationKey('galleryPresets.actionCol')
    ],
    sortable: [
      {
        column: 'name',
        sortFunction: 'CaseInsensitive'
      },
      {
        column: 'isDefault',
        sortFunction: 'NumericInteger'
      }
    ]
  };
};

export const usePresetsSettingsSelectsData = () => {
  const brands = useSelector(getBrandsList);
  const domains = useSelector(getDomainsList);
  const watermarks = useSelector(getWatermarksList);
  const templates = useSelector(getDevelopedTemplates);
  const brandsOptions = Object.keys(brands)
    .map((key) => ({
      value: key,
      text: get(brands, `${key}.name`, '')
    }))
    .filter((option) => option.text);
  // @ts-ignore
  const domainsOptions = domains.map((domainItem) => ({
    value: domainItem._id,
    text: domainItem.hostname
  }));
  const watermarksOptions = Object.keys(watermarks)
    .map((key) => ({
      value: key,
      text: get(watermarks, `${key}.description`, '')
    }))
    .filter((option) => option.text);
  const templatesOptions = templates.map((templateItem) => ({
    value: templateItem._id,
    text: templateItem.displayName
  }));
  const sortingOptions = SORT_IMAGES_OPTIONS.map((opt) => ({
    ...opt,
    text: getTranslationKey(opt.key)
  }));
  const galleryStyleOptions = [
    {
      value: 'justified',
      text: getTranslationKey('galleryStyleOptions.option1')
    },
    {
      value: 'masonry',
      text: getTranslationKey('galleryStyleOptions.option2')
    }
  ];
  const langTranslations = getTranslationKey('languages');
  const languageOptions = Object.keys(langTranslations).map((key) => ({
    value: key,
    text: get(langTranslations, key, '')
  }));

  return {
    brandsOptions,
    domainsOptions,
    watermarksOptions,
    templatesOptions,
    galleryStyleOptions,
    languageOptions,
    sortingOptions
  };
};

export const usePresetGalleries = (preset: IPreset) => {
  const galleriesWithId = preset.galleries.map((gallery) => ({
    ...gallery,
    id: uniqueId('preset-gallery')
  }));

  return {
    galleries: galleriesWithId
  };
};
