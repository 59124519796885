import React, { useEffect, useState } from 'react';
import { getTranslationKey } from '../../modules/utils';
import { partial } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getAvailableCurrenciesAction, updateUserTaxAction } from '../../modules/actions';
import { getCountriesList } from '../../modules/selectors/countries';
import { fetchCountriesAction } from '../../modules/actions/countries';
import { CheckBox } from '../Forms/CheckBox';
import { Exist } from '../Exist';
import { InputItem } from '../Forms/InputItem';
import { SelectItem } from '../Forms/SelectItem';
import { ICountry } from '../../modules/types';
import { TAX_QUICK_LIST } from '../../modules/constants';
import { getAvailableCurrenciesSelector, getUser } from '../../modules/selectors';
import media from '../../assets/css/media';
import styled from 'styled-components';
import { FormTooltip } from '../Forms/InputTooltip';
import { Alert } from 'reactstrap';
import history from '../../helpers/history';

interface IProps {
  isFullForm?: boolean;
  isOnboardingForm?: boolean;
}

const Form = styled.form``;
const TaxWrapper = styled.div`
  .input-item-group {
    width: 40%;
    @media (max-width: ${media.desktop}) {
      width: 60%;
    }
  }
`;

const validatePercent = (value: unknown) => {
  const num = Number(value);

  if (Number.isNaN(num)) return '';
  if (num < 0) return 0;
  if (num > 100) return 100;

  return num;
};

const getCountriesOptions = (list: ICountry[]) =>
  list.map((country) => ({ value: country.id, text: country.name }));

const fieldMargin = '10px 0 10px 0';

export const ShopTaxSetupForm: React.FC<IProps> = ({
  isFullForm = false,
  isOnboardingForm = false
}) => {
  const dispatch = useDispatch();
  const { taxSettings, _id, currency, _country } = useSelector(getUser);
  const availableCurrencies = useSelector(getAvailableCurrenciesSelector);
  const countriesList = useSelector(getCountriesList);
  const [formState, setFormState] = useState({
    smallBusinessOwner: false,
    tax: 1,
    country: _country,
    currency,
    vatId: '',
    taxId: '',
    registrationNumber: '',
    commercialRegister: ''
  });
  const countriesOptions = getCountriesOptions(countriesList);
  const currenciesOptions = availableCurrencies.map((currencyCode) => ({
    value: currencyCode,
    text: getTranslationKey(`currencies.${currencyCode}`)
  }));

  useEffect(() => {
    dispatch(getAvailableCurrenciesAction());
  }, []);

  useEffect(() => {
    if (!isFullForm) {
      dispatch(getAvailableCurrenciesAction(formState.country));
    }
  }, [formState.country]);

  useEffect(() => {
    if (_id) {
      setFormState({
        ...formState,
        ...taxSettings,
        tax: taxSettings.endCustomerPercent,
        currency
      });
    }
  }, [_id, taxSettings, currency]);

  useEffect(() => {
    if (!countriesList.length) {
      dispatch(fetchCountriesAction());
    }
  }, [dispatch, countriesList]);

  const changeStateHandler = (field: string, e: string | number | React.SyntheticEvent) => {
    const value: any = field === 'smallBusinessOwner' ? !formState[field] : e;
    setFormState({ ...formState, [field]: value });
  };

  useEffect(() => {
    const validated = validatePercent(formState.tax);
    if (formState.smallBusinessOwner) {
      changeStateHandler('tax', 0);

      return;
    }
    if (validated !== formState.tax) {
      changeStateHandler('tax', validated);
    }
  }, [formState.tax, formState.smallBusinessOwner]); // eslint-disable-line

  const submitForm = (e: React.SyntheticEvent) => {
    e.preventDefault();

    dispatch(updateUserTaxAction({ isEdit: isFullForm, form: formState }));
    if (isOnboardingForm) history.push('/dashboard');
  };

  const taxOptions = TAX_QUICK_LIST.map(({ value, textKey }) => ({
    value,
    text: `${value.replace('.', ',')}% ${getTranslationKey(textKey)}`
  }));

  return (
    <Form onSubmit={submitForm}>
      {formState.country !== 'usa' && (
        <CheckBox
          checked={formState.smallBusinessOwner}
          text={getTranslationKey('shop.taxConfig.businessCheckboxLabel')}
          onChange={partial(changeStateHandler, 'smallBusinessOwner')}
          disabled={taxSettings.isConfirmed}
        />
      )}
      <Exist when={!formState.smallBusinessOwner}>
        <TaxWrapper>
          <InputItem
            id="tax"
            withTooltip
            tooltipText={getTranslationKey('settingsSection.tax.taxTooltip')}
            label={getTranslationKey('shop.taxConfig.taxRateLabel')}
            type="number"
            changeCallback={partial(changeStateHandler, 'tax')}
            value={String(formState.tax)}
            placeholder="-"
            step=".01"
            margin={fieldMargin}
            dropdownConfig={
              formState.country !== 'usa'
                ? {
                    buttonLabel: '%',
                    options: taxOptions,
                    callback: partial(changeStateHandler, 'tax')
                  }
                : null
            }
            disabled={taxSettings.isConfirmed}
          />
        </TaxWrapper>
      </Exist>
      {taxSettings.isConfirmed && (
        <Alert color="warning">{getTranslationKey('shop.taxConfig.warning-text')}</Alert>
      )}
      <SelectItem
        label={getTranslationKey('settingsSection.accountCurrency')}
        options={currenciesOptions}
        value={formState.currency}
        changeCallback={partial(changeStateHandler, 'currency')}
        margin="10px 0 20px 0"
        labelActions={
          <FormTooltip
            isShow
            tooltipID="tt-account-currency"
            tooltipPlacement="bottom"
            tooltipText={getTranslationKey('settingsSection.currencyTooltip', {
              link: 'https://support.scrappbook.de/de/articles/69651-account-wahrung-andern-eur-chf-usd'
            })}
          />
        }
      />

      <Exist when={!isFullForm}>
        <SelectItem
          label={getTranslationKey('settingsSection.locationLabel')}
          options={countriesOptions}
          value={formState.country}
          changeCallback={partial(changeStateHandler, 'country')}
          margin="10px 0 20px 0"
        />
      </Exist>
      <Exist when={isFullForm}>
        <InputItem
          id="vat-id"
          withTooltip
          tooltipText={getTranslationKey('settingsSection.tax.vatIDTooltip')}
          label={getTranslationKey('settingsSection.tax.vatIDLabel')}
          type="text"
          changeCallback={partial(changeStateHandler, 'vatId')}
          value={String(formState.vatId)}
          placeholder="-"
          margin={fieldMargin}
        />
        <InputItem
          id="tax-id"
          withTooltip
          tooltipText={getTranslationKey('settingsSection.tax.taxIDTooltip')}
          label={getTranslationKey('settingsSection.tax.taxIDLabel')}
          type="text"
          changeCallback={partial(changeStateHandler, 'taxId')}
          value={String(formState.taxId)}
          placeholder="-"
          margin={fieldMargin}
        />
        <InputItem
          id="registration-number"
          withTooltip
          tooltipText={getTranslationKey('settingsSection.tax.registrationNumberTooltip')}
          label={getTranslationKey('settingsSection.tax.registrationNumberLabel')}
          type="text"
          changeCallback={partial(changeStateHandler, 'registrationNumber')}
          value={String(formState.registrationNumber)}
          placeholder="-"
          margin={fieldMargin}
        />
        <InputItem
          id="commercial-reg"
          withTooltip
          tooltipText={getTranslationKey('settingsSection.tax.commercialRegisterTooltip')}
          label={getTranslationKey('settingsSection.tax.commercialRegister')}
          type="text"
          changeCallback={partial(changeStateHandler, 'commercialRegister')}
          value={String(formState.commercialRegister)}
          placeholder="-"
          margin={fieldMargin}
        />
      </Exist>
      <button type="submit" className="btn btn-success px-4 w-100-p">
        {!isFullForm
          ? getTranslationKey('shop.taxConfig.confirmButton')
          : getTranslationKey('settingsSection.tax.save')}
      </button>
    </Form>
  );
};
