import React, { useEffect, useState } from 'react';
import { IStoreState } from '../../modules/types';
import { FaPen, FaPlus, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../Table';
import { getTranslationKey } from '../../modules/utils';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import approve from 'approvejs';
import InputGroup from '../Forms/InputGroup';
import { Imprint } from '../../modules/reducers/imprints';
import { createImprint, deleteImprint, notify as notifyAction, editImprint } from '../../actions';
import theme from '../../assets/css/theme';

const fields = {
  description: {
    type: 'text',
    placeholder: getTranslationKey('description'),
    tooltip_id: 'imprintDescription',
    tooltip_text: getTranslationKey('customDomains.descriptionTooltip'),
    title: getTranslationKey('description'),
    rules: {
      required: { message: getTranslationKey('requiredInput') }
    }
  },
  url: {
    type: 'text',
    placeholder: getTranslationKey('customDomains.url'),
    tooltip_id: 'urlToolTip',
    tooltip_text: getTranslationKey('customDomains.urlToolTip'),
    title: getTranslationKey('customDomains.url'),
    rules: {}
  },
  imprint: {
    type: 'textarea',
    placeholder: getTranslationKey('customDomains.imprint'),
    tooltip_id: 'imprintTooltip',
    tooltip_text: getTranslationKey('customDomains.imprintTooltip'),
    title: getTranslationKey('customDomains.imprint'),
    rules: {}
  }
};

// interface IProps extends DecoratedFormProps, PropsWithChildren<InjectedFormProps> {}

const ImprintTable = (props: any) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState('edit');
  const [currentImprintId, setCurrentImprintId] = useState('');
  const imprints = useSelector(({ imprints }: IStoreState) => imprints.all);
  const toggle = () => setIsOpen(!isOpen);
  const notify = (...args: any[]) => dispatch(notifyAction.apply(null, args)); // eslint-disable-line

  useEffect(() => {
    if (!isOpen) {
      props.reset!();
      setCurrentImprintId('');
    }
  }, [isOpen]); // eslint-disable-line

  const openEdit = (imprint: Imprint) => {
    props.change!('description', imprint.description);
    props.change!('url', imprint.url);
    props.change!('imprint', imprint.imprint);
    setCurrentImprintId(imprint._id);
    setMode('edit');
    toggle();
  };

  const onDeleteImprint = (imprintId: string) => {
    dispatch(deleteImprint(imprintId, notify));
    if (isOpen) toggle();
  };

  const submit = (values: any) => {
    if (mode === 'edit') {
      dispatch(editImprint({ ...values, _id: currentImprintId }, notify));
    } else dispatch(createImprint(values, notify));
    toggle();
  };

  const items: any[] = imprints.map((imprint) => ({
    ...imprint,
    action: (
      <div className="d-flex justify-content-end">
        <FaPen
          style={{ cursor: 'pointer' }}
          className="mr-3"
          color={theme.commonColors.second}
          onClick={() => openEdit(imprint)}
        />
        <FaTrash
          style={{ cursor: 'pointer' }}
          color={theme.commonColors.second}
          onClick={() => onDeleteImprint(imprint._id)}
        />
      </div>
    )
  }));

  items.push({
    description: (
      <button
        className="btn btn-light w-100-p create-new-button"
        onClick={() => {
          toggle();
          setMode('create');
        }}
      >
        <FaPlus size={15} color={theme.commonColors.second} className="plus-icon mr-2" />
        {getTranslationKey('customDomains.newImprint')}
      </button>
    )
  });

  const label =
    mode === 'edit'
      ? getTranslationKey('customDomains.editImprint')
      : getTranslationKey('customDomains.createImprint');

  return (
    <>
      <Table
        items={items}
        columns={['description', 'action']}
        columnNames={['', '']}
        defaultSorting={{ column: '' }}
        noMatchingRecords={getTranslationKey('customDomains.noMatchingRecords')}
      />
      <Modal centered isOpen={isOpen} toggle={toggle} wrapClassName="modal-primary" size="default">
        <ModalHeader toggle={toggle}>{label}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={props.handleSubmit!(submit)}>
                {Object.keys(fields).map((fieldKey, index) => (
                  <Field
                    component={InputGroup}
                    key={index}
                    name={fieldKey}
                    field={fields[fieldKey as keyof typeof fields]}
                  />
                ))}

                <button
                  className="btn btn-primary w-100-p"
                  type="submit"
                  disabled={props.pristine || props.invalid || props.submitting}
                >
                  {label}
                </button>
              </form>
              <a
                href="http://www.impressum-generator.de/impressum-generator/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {getTranslationKey('customDomains.imprintGenerator')}
              </a>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {mode === 'edit' && (
            <small className="cursor-pointer " onClick={() => onDeleteImprint(currentImprintId)}>
              <i className="sli-trash m-r-5" />
              {`${getTranslationKey('imprint')} ${getTranslationKey('delete')}`}
            </small>
          )}
          <button className="btn btn-danger pull-left" onClick={toggle}>
            {getTranslationKey('abort')}
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

function validate(values: any) {
  const errors: any = {};

  if (!values.url && !values.imprint) {
    const error = [getTranslationKey('minOneRequired')];
    errors.imprint = error;
    errors.url = error;
  }

  Object.keys(fields).forEach((fieldKey: string) => {
    const result = approve.value(values[fieldKey], fields[fieldKey as keyof typeof fields].rules);
    if (result.errors.length > 0) {
      errors[fieldKey] = result.errors;
    }
  });

  return errors;
}

export default reduxForm({
  form: 'imprintCreationForm',
  validate
})(ImprintTable);
