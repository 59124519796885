export interface IColor {
  color: string;
}

export interface IAsset {
  background: IColor;
  type: string;
  src?: string;
  text?: string;
  imageId?: string;
  textColor?: string;
  font?: string;
  fontSize?: string;
  logoSize?: number;
  brandId?: string;
}

interface ITransition {
  type: string;
  duration: number;
}

export interface ITransitions {
  in: ITransition;
  out: ITransition;
}

export interface ISlide {
  slideId: string;
  startTime: number;
  durationSeconds: number;
  asset: IAsset;
  transition: ITransitions;
  id?: string;
}

export interface SlideshowOnboarding {
  done: boolean;
  titleIsSet: boolean;
  imagesAdded: boolean;
  musicAdded: boolean;
}

export interface ISlideshowOnboarding {
  done: boolean;
  slideshowId: string;
  titleIsSet: boolean;
  imagesAdded: boolean;
  musicAdded: boolean;
}

export interface ISong {
  src: string;
  durationSeconds: number;
  startTime: number;
  soundtrackVendorId?: string;
  id: string;
  bpm: number;
}

export enum Crossfade {
  Slow = 'slow',
  Normal = 'normal',
  Fast = 'fast',
  FadeToBackground = 'fade-to-background'
}

export type SlideshowRenderStatus = {
  status: 'none' | 'rendering' | 'canceled' | 'failed' | 'success';
  videoId?: string;
};

export type AspectRatio = [16, 9] | [9, 16];

export enum ImageFitMode {
  Contain = 'contain',
  Cover = 'cover'
}

export interface ISlideshowStatePresent {
  name: string;
  goToSlideshowPage?: boolean;
  background: IColor;
  slides: ISlide[];
  durationSeconds: number;
  selectedSlide?: ISlide | null;
  collectionId: string;
  isSlidesFetched: boolean;
  selectedImagesForSlideshow: ISlide[];
  id: string;
  soundtracks: ISong[];
  tracksDuration: number | null;
  crossfade: Crossfade | string;
  speed: string;
  itemKey?: string;
  isLoading?: boolean;
  isShowBeatMatch: boolean;
  isFitAll: boolean;
  isSlideshowPlaying: boolean;
  playSlideshowPlayer: boolean | null;
  albumId: string;
  seekedTime: number | null;
  render: SlideshowRenderStatus;
  slideshowOnboarding?: SlideshowOnboarding;
  editableSlide?: ISlide | null;
  aspectRatio?: AspectRatio;
  imagesFitMode?: ImageFitMode;
  status: string;
  logoModalAdd: boolean;
  isLoadingSlideshowSoundtracks: boolean;
}

export interface ILogoSlidePayload {
  size: number;
  backgroundColor: string;
  isAddingSlideToTheEnd: boolean;
  brandId: string;
  imageId: string;
  src: string;
}

export interface ISlideshowState {
  past: ISlideshowStatePresent[];
  present: ISlideshowStatePresent;
  future: ISlideshowStatePresent[];
}

export interface ICreateSlideshowAction {
  collectionId: string;
  galleryId: string;
  history: any;
  name: string;
}
export interface IFetchSlideshowAction {
  slideshowId: string;
}

export interface ISountrackAction {
  src: string;
  durationSeconds: number;
}

export interface ISlideshow {
  text?: string;
  src?: string;
  id: string;
  color?: string;
  name: string;
  background: { color: string };
  albumId: string;
  slides: ISlide[];
  status: string;
}

export interface ILoadMoreAction {
  skip?: number;
  limit: number;
}
