import React from 'react';
import styled from 'styled-components';
import { getSearchImageString } from '../../modules/selectors';
import { InputItem } from '../Forms/InputItem';
import { useDispatch, useSelector } from 'react-redux';
import { searchImage } from '../../modules/actions';
import { getTranslationKey } from '../../modules/utils/helpers';
import { components } from 'react-select';
import { FaTimes } from 'react-icons/fa';

const OptionsWrapper = styled.div`
  background: #ffffff;
  position: absolute;
  top: 105%;
  z-index: 2;
  width: 100%;
`;

const preventPropagation = (e: React.SyntheticEvent) => e.stopPropagation();

export const MultiValueRemove = (props: any) => (
  <div onClick={preventPropagation} role="button">
    <components.MultiValueRemove {...props}>
      <FaTimes />
    </components.MultiValueRemove>
  </div>
);
/* tslint:disable:react-a11y-input-elements */
export const Option = (props: any) => {
  const onChangePlaceholder = (): null => null;

  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={onChangePlaceholder}
          aria-checked={props.isSelected}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export const Menu = (props: any) => {
  const dispatch = useDispatch();
  const searchImageValue = useSelector(getSearchImageString);
  const searchImageHandler = (value: string) => {
    dispatch(searchImage(value));
  };

  return (
    <OptionsWrapper onClick={preventPropagation} onTouchStart={preventPropagation}>
      <InputItem
        value={searchImageValue}
        changeCallback={searchImageHandler}
        placeholder={getTranslationKey(
          'gallerySettings.filterSettings.originalImageNameFilterPlaceholder'
        )}
        margin="10px 5px"
        id="search-image"
        isSearch
        formTheme="main"
        type="search"
        onKeyDown={preventPropagation}
      />
      {props.children}
    </OptionsWrapper>
  );
};
