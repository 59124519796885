import React, { ReactNode } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils/helpers';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import styled from 'styled-components';
import useModalHelpers from './useModalHelpers';

const StyledModalHeader = styled(ModalHeader)`
  color: #fff;
`;

interface IStepMap {
  [key: number]: ReactNode;
}

const stepMap: IStepMap = {
  1: <FirstStep />,
  2: <SecondStep />,
  3: <ThirdStep />
};

const CancelSubscriptionModal = () => {
  const { step, isOpen, goNextStep, closeModal, activeRadio } = useModalHelpers();

  return (
    <Modal centered isOpen={isOpen} size="md">
      <StyledModalHeader toggle={closeModal}>
        {getTranslationKey('cancelSubscriptionModal.title')}
      </StyledModalHeader>

      <ModalBody>{stepMap[step]}</ModalBody>

      <ModalFooter>
        <Button
          size="md"
          color="success"
          onClick={goNextStep}
          className="btn btn-primary"
          disabled={!activeRadio.length}
        >
          {getTranslationKey('cancelSubscriptionModal.nextButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelSubscriptionModal;
