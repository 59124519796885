import { createReducer } from 'redux-act';
import { getRenderServicStatusSuccessAction, setAppVersion, setAppVersionDelay, setRequiredTaxSavedAction } from '../../../modules/actions';
import { CHECKSUMS_INTERVAL } from '../../../modules/constants';

export interface IGlobalReducerState {
	appVersion: number;
	appVersionDelay: number;
	requiredTaxSaved: boolean;
	renderServiceStatus: string;
}

const initialState: IGlobalReducerState = {
	appVersion: 0,
	appVersionDelay: CHECKSUMS_INTERVAL,
	requiredTaxSaved: false,
	renderServiceStatus: 'running'
};

export const global = createReducer<IGlobalReducerState>( {}, initialState );
global.on( setAppVersion, ( state: IGlobalReducerState, payload: number ) => ({
	...state,
	appVersion: payload
}) );
global.on( setAppVersionDelay, ( state: IGlobalReducerState, payload: number ) => ({
	...state,
	appVersionDelay: payload
}) );
global.on( setRequiredTaxSavedAction, ( state: IGlobalReducerState, payload: boolean ) => ({
	...state,
	requiredTaxSaved: payload
}) );
global.on( getRenderServicStatusSuccessAction, ( state: IGlobalReducerState, payload: string ) => ({
	...state,
	renderServiceStatus: payload
}) );
