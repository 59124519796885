import React, { useState } from 'react';
import { Alert } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { changeModalState } from '../../modules/actions';
import { getUser } from '../../modules/selectors';
import { getAccessRules } from '../../modules/selectors/accessRules';
import { get } from 'lodash';
import { getDataList } from '../../pages/OverviewTab/Usage/getDataList';
import { CURRENCIES_SYMBOLS, SHOP_CURRENCIES } from '../../modules/types';
import { useIsPaymentProblem } from '../../common/use-is-payment-problem';

const FeaturesDowngradeAlert = () => {
  const [isUserClosedAlert, setIsUserClosedAlert] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const { isPaymentProblem } = useIsPaymentProblem();
  let downgradeDate = user?.subscription?.featuresDowngrade?.date;
  downgradeDate = !!downgradeDate && new Date(downgradeDate);
  const accessRules = useSelector(getAccessRules);
  const percent = get(user, 'serviceFee.percent', '');
  const fixed = get(user, 'serviceFee.fixed', '');
  const feeData = {
    percent,
    currencySymbol: CURRENCIES_SYMBOLS[user.currency as SHOP_CURRENCIES],
    fixed: (fixed / 100).toFixed(2)
  };
  const featuresAboveLimit = getDataList(accessRules, feeData, isPaymentProblem).filter(
    (feature) => feature.isAboveLimit
  );
  const isOpen =
    !isUserClosedAlert &&
    !user?.subscription?.featuresDowngrade?.ignoreDowngrade &&
    featuresAboveLimit.length > 0 &&
    downgradeDate &&
    downgradeDate > new Date();

  const daysUntilDownGrade = Math.floor(
    moment.duration(moment(downgradeDate, moment.ISO_8601).diff(new Date())).asDays()
  );

  return (
    <Alert
      id="features-downgrade-alert"
      className="cursor-pointer mb-0"
      isOpen={isOpen}
      dismissible
      toggle={(e) => {
        e.stopPropagation();
        setIsUserClosedAlert(true);
      }}
      onClick={() => {
        dispatch(
          changeModalState({
            key: 'featuresDowngradeModal',
            state: true,
            modalData: { daysUntilDownGrade }
          })
        );
      }}
      color="warning"
    >
      <div>
        {daysUntilDownGrade === 0 ? (
          getTranslationKey('subscription.featuresDowngrade.alert-banner-text-zero-days')
        ) : (
          <>
            {getTranslationKey('subscription.featuresDowngrade.alert-banner-text')}
            <b> {daysUntilDownGrade} </b>
            {getTranslationKey('subscription.featuresDowngrade.days')}.
          </>
        )}
      </div>
    </Alert>
  );
};

export default FeaturesDowngradeAlert;
