export default function (state = { active: false, groups: [], forms: [] }, action) {
  switch (action.type) {
    case 'set_ms_active':
      return { ...state, active: action.payload };
    case 'set_ms_groups':
      return { ...state, groups: action.payload };
    case 'set_ms_forms':
      return { ...state, forms: action.payload };
    default:
      return state;
  }
}
