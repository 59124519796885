import React from 'react';
import styled, { css } from 'styled-components';
import { partial } from 'lodash';
import { Nav } from 'reactstrap';
import TabItem from './TabItem';

interface IWrapper {
  isSortable: boolean;
}

const sortableStyles = css`
  .sortable.nav {
    flex-wrap: nowrap;
  }
`;
const Wrapper = styled.div<IWrapper>`
  ${(props) => props.isSortable && sortableStyles};
`;

const NavTabsStyled = styled(Nav)`
  border: none;
  display: flex;
  @media all and (max-width: 550px) {
    flex-wrap: nowrap;
    max-width: 420px;
    overflow-x: auto;
    overflow-y: hidden;
  }
  @media all and (max-width: 480px) {
    max-width: 350px;
  }
  @media all and (max-width: 400px) {
    max-width: 270px;
  }
`;

interface TabListItem {
  id: string;
  label: string;
  isHidden?: boolean;
}

interface IProps {
  tabsList: TabListItem[];
  activeTab: string;
  onTabClick: (tabName: string) => void;
  isSortable?: boolean;
}

export const Tabs = ({ tabsList, onTabClick, activeTab, isSortable = false }: IProps) => {
  const isTabActive = (tabId: string) => activeTab === tabId;

  return (
    <Wrapper isSortable={isSortable}>
      <NavTabsStyled tabs>
        {tabsList.map((name) =>
          !name.isHidden ? (
            <TabItem
              name={name.label}
              key={name.id}
              onClick={partial(onTabClick, name.id)}
              isActive={isTabActive(name.id)}
              className={`pt-${name.id}`}
            />
          ) : null
        )}
      </NavTabsStyled>
    </Wrapper>
  );
};

export default Tabs;
