import { createAction } from 'redux-act';
import { IEmailPackage, ISelectionOption } from '../types/emails';
import { EditorState } from 'draft-js';

export const setEmailPackage = createAction<string>();
export const setEmailStrategy = createAction<string>();
export const setEmailSelectionOptions = createAction<ISelectionOption[]>();
export const setEmailEditorContent = createAction<EditorState>();
export const setEditorWasChanged = createAction<boolean>();

export const fetchEmailPackages = createAction();
export const fetchEmailPackagesSuccess = createAction<IEmailPackage[]>();

export const createEmailPackage = createAction<string>();

export const deleteEmailPackage = createAction<string>();

export const updateEmailPackage = createAction<string>();
