import axios from '../helpers/axios';
import * as errors from '../errors';
import Texts from '../json/texts.json';

export const getPrivacys = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/privacys');
    dispatch({ type: 'SET_ALL_PRIVACYS', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const createPrivacy = (privacy, notify) => async (dispatch) => {
  try {
    const res = await axios.post('/api/privacy/', privacy);
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyCreated,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'ADD_PRIVACY', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const editPrivacy = (privacy, notify) => async (dispatch) => {
  try {
    await axios.put('/api/privacy/', privacy);
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyEdited,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'EDIT_PRIVACY', payload: privacy });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const deletePrivacy = (privacy_id, notify) => async (dispatch) => {
  try {
    await axios.delete('/api/privacy/', { params: { privacy_id } });
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyDeleted,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'REMOVE_PRIVACY', payload: privacy_id });
  } catch (e) {
    if (e.response.status === 428) {
      notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].customDomains.deletePrivacyErrorInUse,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 10000
      });
    } else errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};
