import Dinero, { Currency } from 'dinero.js';

type PostFormatFunction = (formattedAmount: string) => string;

const localeCurrencyMap: Record<
  string,
  { locale: string; format: string; postFormat: PostFormatFunction }
> = {
  EUR: {
    locale: 'de-DE',
    format: '0.00',
    postFormat: (formattedAmount) =>
      formattedAmount.replace('.', ',').replace('€', '').trim() + '\u00A0€'
  },
  CHF: {
    locale: 'de-CH',
    format: '0.00',
    postFormat: (formattedAmount) => formattedAmount.replace('CHF', 'Fr.')
  },
  USD: {
    locale: 'en-US',
    format: '0.00',
    postFormat: (formattedAmount) => formattedAmount
  }
};

export function displayPrice(amountInCents: number, currency?: Currency): string {
  if (currency && !localeCurrencyMap[currency]) {
    return Dinero({ amount: amountInCents, currency }).toFormat();
  }

  if (!currency) return Dinero({ amount: amountInCents }).setLocale('en').toFormat();

  const amount = amountInCents / 100;
  const { locale, format, postFormat } = localeCurrencyMap[currency];

  if (!locale || !format || !postFormat) {
    throw new Error(`Unsupported currency: ${currency}`);
  }

  const formattedAmount = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(amount);

  const finalNumber = postFormat(formattedAmount);
  return finalNumber;
}

export function displayAmount(amount: number) {
  return Dinero({ amount }).setLocale('en');
}
