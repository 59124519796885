import {
  clearSelection,
  updateSelection, 
  setInsertIndex, 
  clearAllDraggableState 
} from './../../actions/draggableSlideState';
import { createReducer } from 'redux-act';

interface IDraggableSlideState {
  selectedSlides: any,
  lastSelectedIndex: number,
  dragIndex: number,
  hoverIndex: number,
  insertIndex: number,
  isDragging: boolean
}

const initState: IDraggableSlideState = {
  selectedSlides: [],
  lastSelectedIndex: -1,
  dragIndex: -1,
  hoverIndex: -1,
  insertIndex: -1,
  isDragging: false
};

export const draggableSlideState = createReducer({}, initState);

draggableSlideState.on( clearSelection, () => initState );
draggableSlideState.on( updateSelection, ( state, payload ) => ({
  ...state,
  selectedSlides: payload.newSelectedCards,
  lastSelectedIndex: payload.newLastSelectedIndex 
  || payload.newLastSelectedIndex === 0 
  ? payload.newLastSelectedIndex : state.lastSelectedIndex
}));

draggableSlideState.on( setInsertIndex, ( state, payload) => ({
    ...state,
    dragIndex: payload.dragIndex,
    hoverIndex: payload.hoverIndex,
    insertIndex: payload.insertIndex
  }));

  draggableSlideState.on( clearAllDraggableState, () => initState);
