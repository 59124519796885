import { IStoreState } from '../types';

export const getEmailTemplatesSelector = ({ emailTemplates }: IStoreState) => emailTemplates.items;
export const getSelectedEmailTemplateSelector = ({ emailTemplates }: IStoreState) =>
  emailTemplates.selectedTemplate;
export const getRecipientsListSelector = ({ emailTemplates }: IStoreState) =>
  emailTemplates.recipientsEmailsOptions;
export const getEmailTemplatePreviewSelector = ({ emailTemplates }: IStoreState) =>
  emailTemplates.emailPreviewHTML;
export const getDeliveryFormSelector = ({ emailTemplates }: IStoreState) => ({
  subject: emailTemplates.subject,
  recipientsEmails: emailTemplates.recipientsEmails,
  emailsCollectedWithPurchase: emailTemplates.emailsCollectedWithPurchase,
  sendCopyToUser: emailTemplates.sendCopyToUser,
  emailPreviewHTML: emailTemplates.emailPreviewHTML,
  html: emailTemplates.html,
  selectedTemplateId: emailTemplates.selectedTemplateId,
  showPasswordInMail: emailTemplates.showPasswordInMail
});
