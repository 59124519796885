import React from 'react';
import { Container, Progress } from 'reactstrap';
import { AppFooter, AppHeader } from '@coreui/react';
import { connect } from 'react-redux';
import WhiteLabelFooter from '../WhiteLabelLayout/DefaultFooter';
import SetupAccountHeader from './SetupAccountHeader';
import WhiteLabelHeader from '../WhiteLabelLayout/DefaultHeader';

import SetupAccount from '../../elements/SetupAccount';

const SetAccount = ({ whiteLabel, isMobileDevice }) => (
  <div className="app">
    <AppHeader fixed>
      {whiteLabel && whiteLabel.active ? <WhiteLabelHeader onlyLogout /> : <SetupAccountHeader />}
    </AppHeader>
    <div className="app-body">
      <Container fluid>
        {1 === 2 && isMobileDevice && (
          <Progress
            className="h-30 top-fixed-progess"
            animated
            color="success"
            value={window.value || 30}
            max={100}
          />
        )}
        <SetupAccount />
      </Container>
    </div>
    <AppFooter>
      <WhiteLabelFooter />
    </AppFooter>
  </div>
);

const mapStateToProps = ({ auth: { whiteLabel }, environment: { isMobileDevice } }) => ({
  whiteLabel,
  isMobileDevice
});

export default connect(mapStateToProps, null)(SetAccount);
