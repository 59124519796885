import React from 'react';
import { ImageWrap } from './index';
import { getTranslationKey } from '../../modules/utils';
import emailImg from '../../assets/img/email-success-img.png';

interface ISecondRestrictionStep {
  closeModal: (e: React.SyntheticEvent) => void;
}

export const SecondRestrictionStep: React.FC<ISecondRestrictionStep> = ({ closeModal }) => (
  <>
    <h1>{getTranslationKey('mobileRestrictionModal.successTitle')}</h1>
    <p>{getTranslationKey('mobileRestrictionModal.checkEmailText')}</p>
    <ImageWrap>
      <img src={emailImg} alt="" />
    </ImageWrap>

    <a className="mt-5" href="/" onClick={closeModal}>
      {getTranslationKey('mobileRestrictionModal.continue')}
    </a>
  </>
);
