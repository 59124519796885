import { IStoreState } from '../types';
import { createSelector } from 'reselect';
import { IModalsReducerState } from '../reducers';

export const getModalsStates = (state: IStoreState) => state.modals;
export const getModalData = createSelector(getModalsStates, (modals) => modals.modalData);
export const getModalState = createSelector(
  getModalsStates,
  (modals) => (modalId: keyof Omit<IModalsReducerState, 'modalData'>) => modals[modalId]
);
