import React from 'react';
import ScrAppBookPricing from './ScrAppBookPricing';

const PublicPricing = () => (
  <div className="container-fluid bg-white scrappbook-pricing-page">
    <ScrAppBookPricing
      authentication={{
        digistore: {
          discountKey: {
            first_percent: 20,
            percent: 0
          }
        },
        authenticated: false
      }}
    />
  </div>
);

export default PublicPricing;

/*

<nav className="navbar navbar-primary navbar-static-top navbar-fixed-top">
  <div className="container-fluid">
    <div className="navbar-header">
      <button
        type="button"
        className="navbar-toggle collapsed"
        data-toggle="collapse"
        data-target="#app-navbar-collapse"
      >
        <span className="sr-only">Toggle Navigation</span>
        <span className="icon-bar" />
        <span className="icon-bar" />
        <span className="icon-bar" />
      </button>

      <a className="navbar-brand" href="http://scrappbook.de">
        <img
          src="http://scrappbook.de/images/scrappbook_negativ_blau.png"
          style={{ height: "100%", float: "left", paddingRight: "10px" }}
        />
      </a>
    </div>
    <div className="collapse navbar-collapse" id="app-navbar-collapse">
      <ul className="nav navbar-nav">
        <li>
          <a id="funktionen" href="http://scrappbook.de/funktionen">
            Funktionen
          </a>
        </li>

        <li>
          <a
            id="ueber_uns"
            href="http://scrappbook.de/ueber-uns"
            target="_self"
          >
            Über uns
          </a>
        </li>
        <li>
          <a
            id="kundenstimmen"
            href="http://scrappbook.de/kundenstimmen"
            target="_self"
          >
            Kundenstimmen
          </a>
        </li>
        <li>
          <a
            id="kontakt_hilfe"
            href="http://scrappbook.de/kontakt"
            target="_self"
          >
            Kontakt / Hilfe
          </a>
        </li>
      </ul>

      <ul className="nav navbar-nav navbar-right">
        <li>
          <a href="https://app.scrappbook.de/login">Login</a>
        </li>
        <li>
          <a
            className="button-nav-reg"
            href="https://app.scrappbook.de/register/?"
          >
            Registrieren
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>


*/
