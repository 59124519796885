import { createAction } from 'redux-act';
import { IDomainUpdateAction } from '../types/collection';
import { IPortfolio } from '../reducers/portfolios';
import {
  IPortfolioDeleteTitlteImageAction,
  IPortfolioParamsAction,
  IPortfolioUploadAction,
  IPortfoliosUpdateAction,
  ISinglePortfolioParamsAction
} from '../types/portfolios';

export const clearPortfolios = createAction();

export const createNewPortfolioAction = createAction<Partial<IPortfolio>>();

export const fetchPortfoliosAction = createAction<Partial<IPortfolioParamsAction>>();
export const fetchPortfoliosSuccess =
  createAction<{
    portfolios: IPortfolio[];
    portfoliosTotalCount: number | null;
    searchQuery?: string;
  }>();
export const fetchPortfoliosFailed = createAction();

export const fetchSinglePortfolioAction = createAction<ISinglePortfolioParamsAction>();

export const changePortfoliosStateAction = createAction<Partial<IPortfolio>>();

export const updatePortfoliosByPathAction = createAction<IPortfoliosUpdateAction>();
export const updatePortfoliosDomainAction = createAction<IDomainUpdateAction>();

export const uploadPortfolioImageAction = createAction<IPortfolioUploadAction>();
export const uploadPortfolioImageActionProgressStart = createAction();
export const uploadPortfolioImageActionProgressStop = createAction();

export const deletePortfolioAction = createAction<string>();
export const deleteTitleImageAction = createAction<IPortfolioDeleteTitlteImageAction>();
