import { useSelector } from 'react-redux';
import { getUser } from '../selectors';
import { CURRENCIES_SYMBOLS, SHOP_CURRENCIES } from '../types';

export const useSubscriptionCurrency = () => {
  const user = useSelector(getUser);
  const subscriptionCurrency =
    user.currency === SHOP_CURRENCIES.USD ? SHOP_CURRENCIES.USD : SHOP_CURRENCIES.EUR;
  const currencySymbol =
    subscriptionCurrency === SHOP_CURRENCIES.USD
      ? CURRENCIES_SYMBOLS[SHOP_CURRENCIES.USD]
      : CURRENCIES_SYMBOLS[SHOP_CURRENCIES.EUR];

  return {
    subscriptionCurrency,
    currencySymbol
  };
};
