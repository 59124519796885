import React from 'react';
import { Col, Row } from 'reactstrap';
import Texts from '../../../json/texts.json';
import strategySteps from '../../../data/mailjetTemplateKeysForStrategys';

const CommonStrategy = ({ userStrategySteps, doStrategyStep, currentStrategy: { name } }) => {
  if (!userStrategySteps) return null;
  const steps = strategySteps[name];
  const checked = {};
  steps.forEach((step) => {
    checked[step] = userStrategySteps.includes(step);
  });

  return (
    <Row>
      {Object.keys(checked).map((key, index) => (
        <Col
          key={key}
          xs="12"
          className={!checked[`${name}_${index}`] && index !== 0 ? 'text-muted' : ''}
        >
          <strong>
            {Texts[window.LANGUAGE_SW].step} {(index + 1).toString()}
          </strong>
          <div className="p-10">
            <div className="checkbox position-relative form-check">
              <label className="form-check-label">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={checked[key]}
                  disabled={
                    (index !== 0 && (!checked[`${name}_${index}`] || checked[key])) ||
                    (index === 0 && checked[key])
                  }
                  onChange={() => doStrategyStep(key)}
                />
                {Texts[window.LANGUAGE_SW].strategies[name][key]}
              </label>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default CommonStrategy;
