export const DEFAULT_VIDEO_OBJECT = {
	extension: '',
	fileName: '',
	fileSizeMb: 0,
	gallery_id: '',
	id: '',
	identifier: '',
	mimeType: '',
	status: '',
	uploadedAt: '',
	url: '',
	urlExpireDate: '',
	_collection: '',
	_id: '',
	_user: '',
}