import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { Field, reduxForm } from 'redux-form';
import InputGroup from '../Forms/InputGroup';
import Texts from '../../json/texts.json';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class RedeemDiscountKey extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
  }

  handleClose = () => this.setState({ isOpen: false });

  toggle() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const { redeemDiscountKey, handleSubmit, notify } = this.props;

    return (
      <div>
        <p className="mb-0">
          <a
            href="/"
            className="mb-0"
            onClick={(e) => {
              e.preventDefault();
              this.toggle();
            }}
          >
            {Texts[window.LANGUAGE_SW].pricingTables.haveDiscountKey}
          </a>
        </p>
        <Modal
          centered
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          wrapClassName="modal-primary"
          size="md"
        >
          <ModalHeader toggle={this.toggle}>
            {Texts[window.LANGUAGE_SW].pricingTables.redeemDiscountKey}
          </ModalHeader>
          <ModalBody>
            <form
              onSubmit={handleSubmit((values) =>
                redeemDiscountKey(values, notify, this.handleClose)
              )}
            >
              <Field
                className="m-b-0"
                component={InputGroup}
                name="discountKey"
                field={{
                  type: 'text',
                  placeholder: Texts[window.LANGUAGE_SW].pricingTables.discountKey,
                  title: Texts[window.LANGUAGE_SW].pricingTables.discountKey
                }}
              />
              <Button type="submit" color="primary" className="w-100-p">
                {Texts[window.LANGUAGE_SW].pricingTables.redeemDiscountKey}
              </Button>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-danger pull-left"
              onClick={() => this.setState((prevState) => ({ isOpen: !prevState.isOpen }))}
            >
              {Texts[window.LANGUAGE_SW].abort}
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

RedeemDiscountKey = reduxForm({
  form: 'redeemDiscountKeyForm'
})(RedeemDiscountKey);

function mapStateToProps({ notify }) {
  return { notify };
}

export default connect(mapStateToProps, actions)(RedeemDiscountKey);
