import { SagaIterator } from 'redux-saga';
import { ISagaAction } from '../../../modules/types';
import { put, call, select } from 'redux-saga/effects';
import Api from '../../../modules/utils/API';
import { errorsGlobalError, showNotifyFail, showNotifySuccess } from '../../../modules/actions';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import {
  fetchPortfoliosSuccess,
  fetchPortfoliosFailed,
  changePortfoliosStateAction,
  uploadPortfolioImageActionProgressStart,
  uploadPortfolioImageActionProgressStop,
  fetchPortfoliosAction
} from '../../actions/portfolios';
import {
  IPortfolioDeleteTitlteImageAction,
  IPortfolioParamsAction,
  IPortfolioUploadAction,
  IPortfoliosUpdateAction,
  ISinglePortfolioParamsAction
} from '../../types/portfolios';
import { getPaginationState, getSelectedPortfolio } from '../../selectors/portfolios';
import history from '../../../helpers/history';
import axiosWithoutCredentials from 'axios';
import { IPortfolio } from '../../reducers';
import { getTranslationKey } from '../../utils';

// export const initialCollectionFetchSaga = function* (action: ISagaAction<string>): SagaIterator {
//   yield call(clearCollectionSaga);
//   yield call(fetchCollectionSaga, action);
// };

export const fetchPortfoliosSaga = function* ({
  payload: { skip = 0, limit = 10, sortBy = '', sortOrder = 'desc', searchQuery = '' }
}: ISagaAction<Partial<IPortfolioParamsAction>>): SagaIterator {
  try {
    const response = yield call(Api.Portfolios.get, {
      skip,
      limit,
      sortBy,
      sortOrder,
      searchQuery,
      ownerRequest: true
    });

    ApiErrors.checkOnApiError(response);
    yield put(fetchPortfoliosSuccess({ ...response.result, searchQuery }));

    return response.result;
  } catch (e) {
    yield put(fetchPortfoliosFailed());
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const fetchSinglePortfolioSaga = function* ({
  payload: { portfolioId = '' }
}: ISagaAction<ISinglePortfolioParamsAction>): SagaIterator {
  try {
    const response = yield call(Api.Portfolios.getSinglePortfolio, { portfolioId });

    const { result } = response;

    ApiErrors.checkOnApiError(response);
    yield put(changePortfoliosStateAction({ ...result }));

    return response.result;
  } catch (e) {
    yield put(fetchPortfoliosFailed());
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const createNewPortfolio = function* ({
  payload
}: ISagaAction<Partial<IPortfolio>>): SagaIterator {
  const response = yield call(Api.Portfolios.create, { data: payload });
  ApiErrors.checkOnApiError(response);

  const { result } = response;

  yield call(history.push, `/portfolios/edit/${result._id}`);
  yield put(changePortfoliosStateAction({ ...result }));
  yield put(showNotifySuccess({}));
};

export const updatePortfolioByPathSaga = function* ({
  payload: { path, value }
}: ISagaAction<IPortfoliosUpdateAction>): SagaIterator {
  try {
    const { _id } = yield select(getSelectedPortfolio);

    const updatedRes = yield call(Api.Portfolios.update, {
      portfolioId: _id,
      data: { [path]: value }
    });

    if (updatedRes.code === 403) {
      yield put(showNotifyFail({ message: getTranslationKey('portfolio.pathIsAlreadyInUse') }));
      return;
    }

    yield put(changePortfoliosStateAction(updatedRes.result));
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const deletePortfolioSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const { skip, limit, sortBy, sortOrder, searchQuery } = yield select(getPaginationState);
    yield call(Api.Portfolios.delete, { portfolioId: payload });
    yield put(fetchPortfoliosAction({ skip, limit, sortBy, sortOrder, searchQuery }));

    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const uploadPortfolioImageSaga = function* ({
  payload
}: ISagaAction<IPortfolioUploadAction>): SagaIterator {
  try {
    const { skip, limit, sortBy, sortOrder, searchQuery } = yield select(getPaginationState);
    yield put(uploadPortfolioImageActionProgressStart());

    const { result } = yield call(Api.Portfolios.uploadTitleImage, {
      portfolioId: payload.portfolioId,
      imageName: payload.imageName
    });

    yield call(axiosWithoutCredentials.put, result.signedUrl, payload.file);
    yield call(Api.Images.compress, { image_id: result.imageId });
    yield put(fetchPortfoliosAction({ skip, limit, sortBy, sortOrder, searchQuery }));

    yield put(showNotifySuccess({}));
    yield put(uploadPortfolioImageActionProgressStop());
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const deleteTitleImageSaga = function* ({
  payload
}: ISagaAction<IPortfolioDeleteTitlteImageAction>): SagaIterator {
  try {
    const { skip, limit, sortBy, sortOrder, searchQuery } = yield select(getPaginationState);
    yield call(Api.Portfolios.deleteTitleImage, {
      portfolioId: payload.portfolioId,
      imageId: payload.imageId
    });
    yield put(fetchPortfoliosAction({ skip, limit, sortBy, sortOrder, searchQuery }));

    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};
