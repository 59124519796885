import { Currency } from 'dinero.js';
import { IDigitalPricingList, IPackage } from './digital-pricing';
import { IGalleryTag, IDictionary } from './';

export interface IPricingListItem {
  createdAt: string;
  name: string;
  taxRate: number;
  updatedAt: string;
  __v: number;
  _id: string;
  _laboratory: string;
  _user: string;
}

export interface IFetchPricingListAction {
  id?: string;
}

export interface IProductDimensions {
  height: number;
  width: number;
  depth: number;
  unit: 'cm';
}

export interface IProductImage {
  type: string;
  src: string;
  order: number;
}

export enum ShopOptionsEnum {
  Border = 'border',
  Finish = 'finish',
  BorderColor = 'border-color',
  Color = 'color',
  Surface = 'surface',
  ColorCombination = 'color-combination',
  Material = 'material'
}

export type TOptionType = typeof ShopOptionsEnum;

export interface IOption {
  _id: string;
  name: string;
  type: TOptionType;
  default: boolean;
}

export interface IPerfectFor {
  icon: string;
  text: string;
}

export interface ICropping {
  aspectRatioX: number;
  aspectRatioY: number;
  autoRotateAspectRatio: boolean;
  canIncreaseImageQuantity: boolean;
}

export interface IProduct {
  id: string;
  laboratory: string;
  title: {
    en: string;
    de: string;
  };
  dimensions: IProductDimensions;
  _productGroup: {
    slug: string;
  };
  tags: IDictionary<string>;
  bulletPoints: string[];
  description: string;
  images: IProductImage[];
  options: IOption[];
  perfectFor: IPerfectFor[];
  price: number;
  priceTotal: number;
  tax: number;
  currency: string;
  slug: string;
  minImageSelection: number;
  maxImageSelection: number;
  maxPages?: number;
  cropping: ICropping;
  productGroupId: string;
}

export interface IPriceItem {
  active: boolean;
  additionalImagePriceTotal: number;
  additionalPageResellerPriceTotal: number;
  additionalImageResellerPriceTotal: number;
  allowEditAdditionalImage: boolean;
  allowEditAdditionalPage: boolean;
  currency: Currency;
  priceTotal: number;
  productName: string;
  resellerPriceTotal: number;
  _id: string;
  imagesIncluded?: number;
  additionalPagePriceTotal?: number;
  pagesIncluded?: number;
  pagesIncludedReseller?: number;
  _product: IProduct;
}

export enum SHOP_ORDER_STATUS {
  PENDING = 'pending',
  DRAFT = 'draft',
  PAYMENT_PENDING = 'payment_pending',
  PAYMENT_COMPLETE = 'payment_complete',
  CROP_AND_STORE_IMAGES = 'crop_and_store_images',
  ERROR_CROP_AND_STORE_IMAGES = 'error_crop_and_store_images',
  SENDING_TO_LAB = 'sending_to_lab',
  ERROR_SENDING_TO_LAB = 'error_sending_to_lab',
  MANUFACTURING_PENDING = 'manufacturing_pending',
  DELIVERY_PENDING = 'delivery_pending',
  HOLD = 'hold',
  COMPLETE = 'complete',
  CANCELED = 'canceled'
}

export type OrderShippingDetails = {
  countryCode: string;
  countryName: string;
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  zipCode: string;
  city: string;
};

export enum SHOP_CURRENCIES {
  EUR = 'EUR',
  USD = 'USD',
  CHF = 'CHF',
  GBP = 'GBP'
}

export interface ProfitBreakdownItem {
  reason: string;
  amount: number;
}

export interface IUserEarnings {
  items: ProfitBreakdownItem[];
  earnings: number;
  taxEarnings: number;
  grossEarnings: number;
}

export interface IShopOrder {
  id: string;
  collection: string;
  orderNumber: number;
  grandTotal: number;
  payments: string[];
  status: SHOP_ORDER_STATUS;
  payinProcessed?: boolean;
  paid: boolean;
  shipping: OrderShippingDetails;
  createdAt: Date;
  currency: SHOP_CURRENCIES;
  collectionTitle: string;
  invoiceNumbers: string[];
  invoiceDownloadLink: string;
  userEarnings?: IUserEarnings;
  endCustomerInfo: {
    email?: string;
    phone?: string;
  };
  products: Array<{
    images: Array<unknown>;
  }>;
}

export interface IFetchOrderListForUserResponse {
  list: IShopOrder[];
  meta: {
    totalAmount: number;
  };
}

export const CURRENCIES_SYMBOLS: { [key in SHOP_CURRENCIES]: string } = {
  [SHOP_CURRENCIES.USD]: '$',
  [SHOP_CURRENCIES.EUR]: '€',
  [SHOP_CURRENCIES.GBP]: '£',
  [SHOP_CURRENCIES.CHF]: 'Fr.'
};

export enum ShopCouponTypesEnum {
  Total = 'total',
  Product = 'product',
  ProductGroup = 'productGroup',
  Shipping = 'shipment'
}

export interface IShopCouponDiscount {
  type: ShopCouponTypesEnum;
  valueType: string;
  productId?: string | null;
  productGroupId?: string | null;
  quantityMax?: number | null;
  valueFixed: number;
  valuePercent: number;
}

export interface IShopCoupon {
  id?: string;
  user: string;
  name: string;
  description?: string;
  active: boolean;
  code: string;
  codeLowerCase?: string;
  validUntilDate: string;
  isUsedInSalesAutomation: boolean;
  minimumOrderValue?: number | null;
  discounts: IShopCouponDiscount[];
}

export interface IBalanceItem {
  amount: number;
  pendingPayout: boolean;
  minBalanceAmountForPayout: number;
}

export interface IUserAccountBalance {
  balance: IBalanceItem;
  pendingPayout: IBalanceItem;
}

export interface IPayDocument {
  date: string;
  url: string;
  orderNumbers: number[];
  reason: string;
}

export interface IShopReducerState {
  pricingList: IPricingListItem[];
  digitalPricing: {
    lists: IDigitalPricingList[];
    isCreatePackageModalOpen: boolean;
    currentlyOpenedDigitalList: Omit<IDigitalPricingList, 'packages'> & {
      packages: {
        tier: number;
        packageId: IPackage;
      }[];
    };
  };
  payDocuments: IPayDocument[];
  userHasEnoughInvoicingData: boolean | null;
  selectedPriceList: string;
  productUserPricing: IPriceItem[];
  userBalance: IUserAccountBalance;
  coupons: IShopCoupon[];
  orders: IFetchOrderListForUserResponse;
  orderImages: OrderImageForUser[];
  isCouponsFetched: boolean;
  productGroups: ProductGroup[];
  products: IProduct[];
  laboratories: Laboratory[];
  globalSettings: null | {
    id: string;
    userId: string;
    printShopActive: boolean;
  };
  collectionsSearchResult: ISearchCollection[];
  salesAutomation: {
    bulkUpdateInProgress: boolean;
  };
}

export type OrderImageForUser = {
  imageUrl: string;
  quantity: number;
  productTitle: string;
  productDimensions?: string;
  selectedOptions: string[];
  grandGrossPrice: number;
};

export interface IUpdatePriceValue {
  id: string;
  local?: boolean;
  data: {
    key: string;
    value: number;
  };
}

export type OrderCustomerInfo = OrderShippingDetails & {
  email?: string;
  phone?: string;
};

export interface IGlobalShopSettings {
  id: string;
  printShopActive: boolean;
  userId: string;
}

export enum ShopCurrenciesEnum {
  EUR = 'EUR' /*,
	USD = "USD",
	CHF = "CHF",
	GBP = "GBP"*/
}

export type TShopCurrencies = typeof ShopCurrenciesEnum;

export enum ShopCouponValueTypeEnum {
  Fixed = 'fixed',
  Percent = 'percent'
}

export interface IShopSalesAutomation {
  couponCodeAdvertising: {
    enabled: boolean;
    startInDays: number | null;
    endsAfterDays: number | null;
    couponId: string | null;
    amountOfImagesBetweenBanners?: string | number | null;
  };
  abandonedShoppingCartReminder: {
    enabled: boolean;
  };
  emptyCartProductSuggestion: {
    enabled: boolean;
  };
  freeShipping: {
    enabled: boolean;
    startsFrom: number | null;
  };
  couponCodeAfterPurchase: {
    enabled: boolean;
    couponId: string | null;
  };
  galleryExpires: {
    enabled: boolean;
    expiresInDays: number | null;
    expirationDate: Date | null;
  };
  emailPackage: string;
}

export interface ProductGroupImage {
  src: string;
  type: string;
}

export interface ProductGroupTitle {
  en: string;
  de: string;
}

export interface ProductGroup {
  id: string;
  images: ProductGroupImage[];
  order: number;
  slug: string;
  title: ProductGroupTitle;
  couponTitle: ProductGroupTitle;
}

export interface IFetchOrderListForUserParams {
  limit?: number;
  skip?: number;
  filters: {
    dateRange?: {
      start?: string;
      end?: string;
    };
  };
}

export interface ICancelOrderParams {
  orderId: string;
}

export interface IFetchOrderListForUserResponse {
  list: IShopOrder[];
  meta: {
    totalAmount: number;
  };
}

export type Laboratory = {
  id: string;
  name: string;
  default: boolean;
};

export interface IRequestPayoutAction {
  amount: number;
  currency: string;
  account: string;
}

export interface ISearchCollection {
  _id: string;
  title: string;
}

export interface ISalesConfigBulkUpdateAction {
  collectionsToExclude: string[];
  printShopPricingId?: string;
  salesConfig: Partial<IShopSalesAutomation>;
  galleryTags?: IGalleryTag[];
}

export interface IShopPayDocumentsAction {
  skip: number;
  limit: number;
}
