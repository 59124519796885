import React from 'react';
import { connect } from 'react-redux';
import Texts from '../../../json/texts.json';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import InputGroup from '../../Forms/InputGroup';
import Checkbox from '../../Forms/Checkbox';
import approve from 'approvejs';

const fields = {
  first_name: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].firstname + ' *',
    rules: {
      required: { message: Texts[window.LANGUAGE_SW].requiredFirstnameInput }
    }
  },
  last_name: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].lastname + ' *',
    rules: {
      required: { message: Texts[window.LANGUAGE_SW].requiredLastnameInput }
    }
  },
  email: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].emailPlaceholder + ' *',
    rules: {
      email: {
        message: Texts[window.LANGUAGE_SW].requiredEMailInput
      }
    }
  },
  phone_no: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].phone_no,
    rules: {}
  },
  zipcode: {
    type: 'text',
    placeholder: Texts[window.LANGUAGE_SW].zipcode,
    rules: {}
  },
  message: {
    type: 'textarea',
    rules: {
      ignoreNull: true,
      min: 0,
      max: {
        max: 500,
        message: Texts[window.LANGUAGE_SW].maxXCharacters.replace('$X$', '500')
      }
    },
    placeholder: Texts[window.LANGUAGE_SW].message
  }
};

let LeadForm = ({
  invalid,
  submitting,
  pristine,
  handleSubmit,
  optin,
  saveLead,
  phone_no_required
}) => (
  <form onSubmit={handleSubmit(saveLead)}>
    <div className="row">
      <div className="col">
        {Object.keys(fields).map((fieldKey, index) => {
          const field = fields[fieldKey];

          if (fieldKey === 'phone_no' && phone_no_required) {
            field.rules = {
              required: {
                message: Texts[window.LANGUAGE_SW].requiredPhoneNumberInput
              }
            };
            if (field.placeholder && !field.placeholder.includes('*')) field.placeholder += ' *';
          }

          return <Field component={InputGroup} key={index} name={fieldKey} field={field} />;
        })}
        <div className="booking-optin-wrapper">
          <Field
            component={Checkbox}
            name="optin"
            field={{
              text: Texts[window.LANGUAGE_SW].emailContactAllowed,
              checked: optin
            }}
          />
        </div>
        <button
          className="btn btn-success w-100-p btn-booking"
          disabled={invalid || submitting || pristine}
          type="submit"
        >
          {Texts[window.LANGUAGE_SW].nextStep}
        </button>
      </div>
    </div>
  </form>
);

function validate(values) {
  const errors = {};

  Object.keys(fields).forEach((fieldKey) => {
    const result = approve.value(values[fieldKey], fields[fieldKey].rules);
    if (result.errors.length > 0) {
      errors[fieldKey] = result.errors;
    }
  });

  return errors;
}

LeadForm = reduxForm({
  form: 'bookingLeadForm',
  validate,
  destroyOnUnmount: false
})(LeadForm);

const selector = formValueSelector('bookingLeadForm'); // Create the selector

const mapStateToProps = (state) => ({
  optin: selector(state, 'optin')
});

export default connect(mapStateToProps)(LeadForm);
