import { getSlideshowsList } from './../../selectors/slideshowsList';
import { all, call, put, select } from 'redux-saga/effects';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import { Api } from '../../../modules/utils';
import { SagaIterator } from 'redux-saga';
import {
  changeSlideshowPublishStatusSuccess,
  errorsGlobalError,
  fetchSlideshowsListSuccess,
  showNotifySuccess,
  accessRulesAction
} from '../../../modules/actions';
import { IRemoveSlideshow, ISlideshow } from '../../../modules/types';
import { getGalleryById } from '../../../modules/selectors';
import { setGalleryValues } from '../galleries';

export const fetchSlideshowsListSaga = function* ({
  payload
}: {
  payload: { slideshowIds: string[]; galleryID: string };
}): SagaIterator {
  try {
    const { slideshowIds } = payload;

    const responses = yield all(
      slideshowIds.map((id: string) => {
        return call(Api.Slideshows.get, { slideshowId: id });
      })
    );
    ApiErrors.checkOnApiError(responses);

    yield put(fetchSlideshowsListSuccess(responses));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const removeSlideshowSaga = function* ({
  payload
}: {
  payload: IRemoveSlideshow;
}): SagaIterator {
  try {
    const { slideshowId, collectionId, galleryID } = payload;

    const getGallery = yield select(getGalleryById);
    const gallery = getGallery(galleryID);

    const updatedGallerySlideshows = gallery.slideshows.filter((id: string) => id !== slideshowId);

    yield call(setGalleryValues, { slideshows: updatedGallerySlideshows }, galleryID);

    const res = yield call(Api.Slideshows.delete, { collectionId }, slideshowId);
    ApiErrors.checkOnApiError(res);
    yield put(accessRulesAction());
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const changeSlideshowPublishStatusSaga = function* ({
  payload
}: {
  payload: { status: string; id: string };
}): SagaIterator {
  try {
    const { status, id } = payload;
    const slideshowsList = yield select(getSlideshowsList);

    const res = yield call(Api.Slideshows.update, { status: status }, id);
    ApiErrors.checkOnApiError(res);

    const updatedSlideshowsList = slideshowsList.map((slideshow: ISlideshow) => {
      if (slideshow.id === id) {
        slideshow.status = res.result.status;
      }

      return slideshow;
    });

    yield put(changeSlideshowPublishStatusSuccess({ slideshowsList: updatedSlideshowsList }));
    yield put(accessRulesAction());
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};
