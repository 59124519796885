// import Texts from "../json/texts.json";

export const setDiscountModalVisibility = (payload) => (dispatch) => {
  try {
    dispatch({ type: 'SET_DISCOUNT_MODAL_VISIBILITY', payload });
  } catch (e) {
    // errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const setMessageModalVisibility = (payload) => (dispatch) => {
  try {
    dispatch({ type: 'SET_MESSAGE_MODAL_VISIBILITY', payload });
  } catch (e) {
    // errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const setSurveyModalVisibility = (payload) => (dispatch) => {
  try {
    dispatch({ type: 'SET_SURVEY_MODAL_VISIBILITY', payload });
  } catch (e) {
    // errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const setSurveyModalNPSVisibility = (payload) => (dispatch) => {
  try {
    dispatch({ type: 'SET_SURVEY_MODAL_NPS_VISIBILITY', payload });
  } catch (e) {
    // errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const setUseDesktopModalVisibility = (payload) => (dispatch) => {
  try {
    dispatch({ type: 'SET_USE_DESKTOP_MODAL_VISIBILITY', payload });
  } catch (e) {
    // errors.handleErrorWithRedirectIfNotAuth(e);
  }
};
