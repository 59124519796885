import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils/helpers';
import { InputItem } from '../../components';
import { useDispatch } from 'react-redux';
import { createPresetAction } from '../../modules/actions';

interface IProps {
  onClose: () => void;
  isOpen: boolean;
}

export const CreatePresetModal = ({ onClose, isOpen }: IProps) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');

  const closeModal = () => {
    setName('');
    onClose();
  };

  const createNewPresetHandler = () => {
    dispatch(createPresetAction(name));
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} toggle={closeModal} wrapClassName="modal-dark" size="default" centered>
      <ModalHeader toggle={closeModal}>
        {getTranslationKey('galleryPresets.createModalTitle')}
      </ModalHeader>
      <ModalBody>
        <InputItem
          value={name}
          placeholder={getTranslationKey('galleryPresets.createInputPlaceholder')}
          withTooltip
          label={getTranslationKey('galleryPresets.createInputLabel')}
          changeCallback={setName}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          block
          color="success"
          disabled={!name.length}
          onClick={createNewPresetHandler}
          className="btn btn-primary"
        >
          {getTranslationKey('galleryPresets.createButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
