import axios from '../helpers/axios';
import axiosWithoutCredentials from 'axios';
import Texts from '../json/texts.json';
import history from '../helpers/history';
import ImageCompressor from '@xkeshi/image-compressor';
import queryString from 'query-string';
import { changeModalState } from '../modules/actions/modals';
import { get } from 'lodash';
import { fixedUserIds } from './fixedUserIds';
import { isLocalhost } from '../registerServiceWorker';
import { setCookie } from '../helpers/cookies';

export const logUserActivity = (data) => () => {
  try {
    axios.post('/api/useractivitylog/logaction', data);
  } catch (e) {
    console.log(e);
  }
};

export const updateDefaultUserLanguages = (data) => async () => {
  try {
    await axios.put('/api/me/updateDefaultUserLanguages', data);

    if (!data.noReload) window.location.reload();
  } catch (e) {
    console.log(e);
  }
};

export const setUpgradeAccountOpenState = (payload) => (dispatch) =>
  dispatch({
    type: 'SET_MODAL_STATE',
    payload
  });

export const setCollectionsModalOpenState = (payload) => (dispatch) =>
  dispatch({
    type: 'SET_MODAL_STATE',
    payload
  });

export const activateMobileRestrictionModal = () => (dispatch) =>
  dispatch(changeModalState({ key: 'mobileRestrictionModal', state: true }));

export const activateNewUserModalState = () => (dispatch) =>
  dispatch(changeModalState({ key: 'newCustomerModal', state: true }));

export const setUpgradeSlideshowAccountOpenState = (payload) => (dispatch) =>
  dispatch({
    type: 'SET_UPGRADE_SLIDESHOW_ACCOUNT_OPEN_STATE',
    payload
  });

export const redeemDiscountKey = (values, notify, handleClose) => async (dispatch) => {
  try {
    const result = await axios.put('/api/payment/discountKey/redeem', {
      discountKey: values.discountKey
    });

    if (result.data.redeemResultStatus === 200) {
      dispatch({ type: 'SET_USER_DIGISTORE', payload: result.data.digistore });
      notify &&
        notify({
          title: Texts[window.LANGUAGE_SW].success,
          message: Texts[window.LANGUAGE_SW].pricingTables.discountKeyRedeemed,
          status: 'success',
          position: 'tc',
          dismissible: true,
          dismissAfter: 5000
        });
      handleClose && handleClose();
    } else if (result.data.redeemResultStatus === 404) {
      notify &&
        notify({
          title: Texts[window.LANGUAGE_SW].error,
          message: Texts[window.LANGUAGE_SW].pricingTables.discountKeyNotFound,
          status: 'error',
          position: 'tc',
          dismissible: true,
          dismissAfter: 5000
        });
    }
  } catch (e) {
    notify &&
      notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].generalError,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 5000
      });
  }
};

export const registerUser = (user, notify) => async () => {
  try {
    const signupResult = await axios.post('/api/signup', user);

    if (signupResult.data.temporaryLoginUrl) {
      window.location = signupResult.data.temporaryLoginUrl;

      return true;
    }
    if (user.type === 'VST') {
      return true;
    }
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 15000
    });

    return false;
  } catch (e) {
    const message =
      e.response.status === 422
        ? Texts[window.LANGUAGE_SW].userAlreadyExists
        : Texts[window.LANGUAGE_SW].generalError;
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 15000
    });

    return false;
  }
};

export const restartSubscription = (notify) => async () => {
  try {
    const res = await axios.post('/api/subscription/restart');

    if (res.data.result === 'success') {
      if (res.data.rebilling_start_url) {
        window.location = res.data.rebilling_start_url;
      } else {
        notify({
          title: Texts[window.LANGUAGE_SW].success,
          message: Texts[window.LANGUAGE_SW].restartSubscriptionSuccessfull,
          status: 'info',
          position: 'tc',
          dismissible: true,
          dismissAfter: 15000
        });

        window.setTimeout(() => window.location.reload(), 5000);
      }
    } else {
      notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].generalError,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 15000
      });
    }
  } catch (e) {
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 15000
    });
  }
};

export const cancelAbo = (notify) => async () => {
  try {
    if (window.confirm(Texts[window.LANGUAGE_SW].rlyCancelAbo)) {
      const res = await axios.post('/api/subscription/cancel-subscription');

      if (res.data.result === 'success') {
        notify({
          title: Texts[window.LANGUAGE_SW].success,
          message: Texts[window.LANGUAGE_SW].cancelAboSuccess,
          status: 'info',
          position: 'tc',
          dismissible: true,
          dismissAfter: 0
        });

        window.setTimeout(() => window.location.reload(), 5000);
      } else {
        notify({
          title: Texts[window.LANGUAGE_SW].error,
          message: Texts[window.LANGUAGE_SW].generalError,
          status: 'error',
          position: 'tc',
          dismissible: true,
          dismissAfter: 15000
        });
      }
    }
  } catch (e) {
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 15000
    });
  }
};

export const dispatchAction = (type, payload) => (dispatch) => dispatch({ type, payload });

export const loginUser = (user, notify) => async () => {
  try {
    const currentOrigin = `${window.location.protocol}//${window.location.host}`;
    const loginOrigin = encodeURIComponent(currentOrigin);
    const query = window.location.href.split('?')[1];
    const loginResult = await axios.post(`/api/signin?${query}`, {
      ...user,
      loginOrigin,
      isAjax: true
    });

    if (loginResult?.data?.redirectUrl) window.location = loginResult.data.redirectUrl;
    if (isLocalhost) window.location.reload();
  } catch (e) {
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].loginError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 5000
    });
    window.location.reload();
  }
};

export const logoutUser = () => async () => {
  localStorage.removeItem('auth');
  window.localStorage.removeItem('subscriptionConfig');
  setCookie('rescrictionModalClosed', '');

  if (get(window, 'Intercom.booted')) {
    // @ts-ignore
    window.Intercom('shutdown');
  }

  window.location = `${window.API_URL}/api/logout`;
};

export const deleteMe = () => async () => {
  try {
    if (window.confirm(Texts[window.LANGUAGE_SW].rlyDeleteUserAccount)) {
      await axios.delete('/api/me');

      return true;
    }

    return false;
  } catch (e) {
    console.log(e);

    return false;
  }
};

export const checkMaintenance = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/maintenance');

    dispatch({
      type: 'SET_MAINTENANCE_MODE',
      payload: res.data.maintenance_mode
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCurrentUser = () => async (dispatch) => {
  try {
    const query = queryString.parse(history.location.search);

    const res = await axios.get('/api/me', {
      params: query
    });

    if (res.data === false) {
      dispatch({ type: 'UNAUTH_USER' });
      localStorage.removeItem('auth');
      window.localStorage.removeItem('subscriptionConfig');
      window.CURRENT_USER = null;
    } else {
      const { _country, email, subscription, type, first_name, _id, intercomUserHash } = res.data;

      window.CURRENT_USER_ID = _id;
      // Used from tag manager
      window.CURRENT_USER = res.data;

      if (window.analytics && !localStorage.getItem('auth') && type === 'SB') {
        const userId = fixedUserIds.includes(_id) ? `fix_${_id}` : _id;
        window.analytics.identify(
          userId,
          {
            email,
            first_name,
            _country,
            subscription
          },
          {
            integrations: {
              Intercom: {
                user_hash: intercomUserHash
              }
            }
          }
        );
      }

      localStorage.setItem('auth', true);
      dispatch({ type: 'AUTH_USER' });
      dispatch({ type: 'SET_USER', payload: res.data });
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateEmailSubscription = (state, notify) => async () => {
  try {
    await axios.post('/api/hubspot/updateEmailSubscription/', { state });

    window.location.reload();
  } catch (e) {
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 15000
    });
    console.log(e);
  }
};

export const changeCurrentUserPassword =
  (password, notify, redirect = true) =>
  async (dispatch) => {
    try {
      await axios.put('/api/me/change_password', {
        password
      });
      notify &&
        notify({
          title: Texts[window.LANGUAGE_SW].success,
          message: Texts[window.LANGUAGE_SW].changedPassword,
          status: 'success',
          position: 'tc',
          dismissible: true,
          dismissAfter: 5000
        });

      dispatch({
        type: 'set_registration_process',
        payload: { key: 'setPassword', value: true }
      });
      redirect && history.push('/dashboard');
    } catch (e) {
      notify &&
        notify({
          title: Texts[window.LANGUAGE_SW].error,
          message: Texts[window.LANGUAGE_SW].generalError,
          status: 'error',
          position: 'tc',
          dismissible: true,
          dismissAfter: 15000
        });
    }
  };

export const forgotPassword = (email, notify) => async () => {
  try {
    await axios.post('/api/me/forgot_password', {
      email: email.toLowerCase(),
      type: window.USER_TYPE
    });
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].forgotPasswordSuccess,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 15000
    });
  } catch (e) {
    const message =
      e.response.status === 404
        ? Texts[window.LANGUAGE_SW].emailNotExist
        : Texts[window.LANGUAGE_SW].generalError;
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 15000
    });
  }
};

export const setCurrentUserValue = (data) => async (dispatch) => {
  try {
    await axios.put('/api/me/setValue', data);
    !data.notSetInUI && dispatch({ type: 'SET_USER_VALUE', payload: data });
  } catch (e) {
    console.log(e);
  }
};

export const updateCurrentUser =
  (user, notify, redirect = '/dashboard', changedEmail = false) =>
  async (dispatch) => {
    try {
      await axios.put('/api/me/', user);
      dispatch({ type: 'SET_USER', payload: user });

      if (notify) {
        notify({
          title: Texts[window.LANGUAGE_SW].success,
          message: Texts[window.LANGUAGE_SW].changedAccountData.replace('$', user.first_name),
          status: 'success',
          position: 'tc',
          dismissible: true,
          dismissAfter: 5000
        });

        if (changedEmail) {
          notify({
            title: Texts[window.LANGUAGE_SW].actionRequired,
            message: Texts[window.LANGUAGE_SW].changedAccountEmail,
            status: 'warning',
            position: 'tc',
            dismissible: true,
            dismissAfter: 30000
          });
        }
      }

      redirect && history.push(redirect);
    } catch (e) {
      notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].generalError,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 15000
      });
    }
  };

export const uploadDefaultUserImage =
  ({ type, userBrandId, file, notify }) =>
  async () => {
    try {
      const res = await axios.get('/api/me/upload/defaultUserImage', {
        params: {
          objectName: file.name,
          type,
          userBrandId
        }
      });

      const imageUploadRes = res.data;

      let imageFile;
      if (!imageUploadRes.compress) imageFile = file;
      else {
        const imageCompressor = new ImageCompressor();
        imageFile = await imageCompressor.compress(file, {
          quality: 0.7,
          maxWidth: 1920,
          maxHeight: 1080
        });
      }
      await axiosWithoutCredentials.put(imageUploadRes.signedUrl, imageFile);

      const img = {
        cryptImageName: imageUploadRes.cryptImageName,
        originalImageName: imageUploadRes.originalImageName,
        url: imageUploadRes.url
      };

      /*
    führt dazu, dass die Form neu initialisiert wird und eingegebene Daten verschwinden
    dispatch({
      type: "SET_USER_BRAND_IMAGE",
      payload: { type, img, userBrandId }
    }); */

      return img;
    } catch (e) {
      notify &&
        notify({
          title: Texts[window.LANGUAGE_SW].error,
          message: Texts[window.LANGUAGE_SW].generalError,
          status: 'error',
          position: 'tc',
          dismissible: true,
          dismissAfter: 15000
        });
    }
  };
