import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import Texts from '../../../json/texts.json';
import { getPricingPageUrl } from '../../DiscountKey';

const UpgradeSlideshowAccountModal = ({
  isOpen,
  setUpgradeSlideshowAccountOpenState,
  subscription,
  digistore
}) => (
  <Modal
    centered
    isOpen={isOpen}
    toggle={() => setUpgradeSlideshowAccountOpenState(!isOpen)}
    wrapClassName="modal-dark"
    size="sm"
  >
    <ModalHeader toggle={() => setUpgradeSlideshowAccountOpenState(!isOpen)}>
      {Texts[window.LANGUAGE_SW].upgradeAccount}
    </ModalHeader>
    <ModalBody>
      <p>{Texts[window.LANGUAGE_SW].slideshowUpgradeAccountHint}</p>

      <Button
        className="w-100-p m-b-30"
        color="success"
        href={
          subscription.plan === 'free'
            ? getPricingPageUrl(digistore, {
                utm_medium: 'upgrade-popup-button',
                utm_campaign: 'builder-upgrades',
                utm_source: 'builder'
              })
            : 'https://scrappbook.de/slideshows-fuer-fotografen/'
        }
        target={subscription.plan === 'free' ? '_self' : '_blank'}
        onClick={() => {
          setUpgradeSlideshowAccountOpenState(false);
        }}
      >
        {subscription.plan === 'free'
          ? Texts[window.LANGUAGE_SW].upgradeAccount
          : Texts[window.LANGUAGE_SW].showSlideshowPricing}
      </Button>
    </ModalBody>
  </Modal>
);

function mapStateToProps({ auth: { upgradeSlideshowAccountIsOpen, digistore, subscription } }) {
  return { isOpen: upgradeSlideshowAccountIsOpen, digistore, subscription };
}

export default connect(mapStateToProps, actions)(UpgradeSlideshowAccountModal);
