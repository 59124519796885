/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import theme from 'assets/css/theme';
import { get, isNaN, partial, sortBy } from 'lodash';
// import { FaRegTrashAlt } from 'react-icons/fa';
import {
  IProduct,
  IShopCouponDiscount,
  Laboratory,
  ProductGroup,
  ShopCouponTypesEnum,
  ShopCouponValueTypeEnum
} from '../../modules/types';
import { getTranslationKey } from '../../modules/utils';
import { InputItem, SelectItem } from '../Forms';
import { useSelector } from 'react-redux';
import {
  getLaboratoriesSelector,
  getProductGroupsList,
  getProductList,
  getUser
} from '../../modules/selectors';
import media from '../../assets/css/media';
import { displayAmount } from '../../modules/utils/helpers/display-price';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 85px;
  margin-bottom: 10px;
  padding: 0 10px;
  @media (max-width: ${media.desktop}) {
    flex-direction: column;
    height: auto;
    & > div {
      width: 100%;
    }
  }
`;
// const DeleteButton = styled.div`
// 	margin-left: auto;
// 	cursor: pointer;
// 	margin-top: 21px;
// `;

const typeOptions = () => {
  const options = [];
  // eslint-
  for (const [propertyKey, propertyValue] of Object.entries(ShopCouponTypesEnum)) {
    // eslint-disable-line
    if (!Number.isNaN(Number(propertyKey))) {
      continue; // eslint-disable-line
    }
    const isDisabled = ['Shipping'].includes(propertyKey);
    if (!isDisabled) {
      options.push({
        value: propertyValue,
        text: getTranslationKey(`shop.coupons.${propertyValue}`),
        isDisabled
      });
    }
  }

  return options;
};

const productGroupsOption = (list: ProductGroup[]) =>
  list.map((item) => {
    const title = get(item, 'title', { en: '', de: '' });
    const couponTitle = get(item, 'couponTitle', title);

    return {
      value: item.id,
      text: window.LANGUAGE_SW === 1 ? couponTitle.en : couponTitle.de
    };
  });

const productsOption = (list: IProduct[], laboratories: Laboratory[]) =>
  list.map((item) => {
    const titleTranslations = get(item, 'title', { en: '', de: '' });
    const title = window.LANGUAGE_SW === 1 ? titleTranslations.en : titleTranslations.de;
    const laboratory = laboratories.find((lab) => lab.id === item.laboratory);
    const dimensions = item.dimensions ? ` ${item.dimensions.width}x${item.dimensions.height}` : '';

    return {
      value: item.id,
      text: `${laboratory?.name} | ${title}${dimensions}`
    };
  });

const fieldsMargin = '10px 10px 10px 0';

const validateMaxQ = (value: unknown) => {
  const num = parseInt(value as string, 10);
  if (isNaN(num)) {
    return '';
  }
  if (num <= 0) {
    return 1;
  }

  return num;
};
const validatePercent = (value: unknown) => {
  const num = parseInt(value as string, 10);
  if (isNaN(num)) {
    return '';
  }
  if (num <= 0) {
    return 1;
  }
  if (num > 100) {
    return 100;
  }

  return num;
};
const showValue = (value?: number) =>
  value ? String(displayAmount(Number(value)).toFormat('0.00')) : '';

interface IProp {
  index: number;
  updateDiscountCallback: (discount: IShopCouponDiscount, index: number) => void;
  discount: IShopCouponDiscount;
}

// tslint:disable-next-line:cyclomatic-complexity max-func-body-length
export const DiscountItem = ({ discount, index, updateDiscountCallback }: IProp) => {
  const productGroups = useSelector(getProductGroupsList);
  const user = useSelector(getUser);
  const products = useSelector(getProductList);
  const laboratories = useSelector(getLaboratoriesSelector);
  const { type, valueType, quantityMax, valueFixed, valuePercent } = discount;
  const showFixedValue = showValue(valueFixed);
  const [fixedValue, setFixedValue] = useState(showFixedValue);
  const changeDiscountHandler = (field: string, value: unknown) => {
    if (field === 'valueFixed') {
      setFixedValue(value as string);
    }
    updateDiscountCallback(
      {
        ...discount,
        [field]:
          field === 'valueFixed' ? parseFloat(String(((value as number) * 100).toFixed())) : value
      },
      index
    );
  };
  const isValueTypeDisabled = ['total', 'shipment'].includes(type);
  const productLabel =
    isValueTypeDisabled || type === ShopCouponTypesEnum.Product
      ? getTranslationKey('shop.coupons.productLabel')
      : getTranslationKey('shop.coupons.productGroupLabel');
  const productSelectField = type === ShopCouponTypesEnum.Product ? 'productId' : 'productGroupId';
  const productSelectValue = get(discount, productSelectField, '');
  const valueTypeValue = valueType === ShopCouponValueTypeEnum.Fixed ? fixedValue : valuePercent;
  const valueTypeFiled =
    valueType === ShopCouponValueTypeEnum.Fixed ? 'valueFixed' : 'valuePercent';
  const valueTypeButtonValue =
    valueType === ShopCouponValueTypeEnum.Fixed
      ? getTranslationKey(`currencies.${user.currency}`)
      : '%';
  const valueTypeOptions =
    valueType === ShopCouponValueTypeEnum.Fixed
      ? [
          {
            value: ShopCouponValueTypeEnum.Percent,
            text: '%'
          }
        ]
      : [
          {
            value: ShopCouponValueTypeEnum.Fixed,
            text: getTranslationKey(`currencies.${user.currency}`)
          }
        ];
  const step = valueType === ShopCouponValueTypeEnum.Fixed ? '0.1' : '1';
  const productGroupOptions = productGroupsOption(productGroups);
  const productsOptions = productsOption(sortBy(products, 'laboratory'), laboratories);
  useEffect(() => {
    const validated = validateMaxQ(quantityMax);
    if (!isValueTypeDisabled && validated !== quantityMax) {
      changeDiscountHandler('quantityMax', validated);
    }
  }, [quantityMax, isValueTypeDisabled]); // eslint-disable-line
  useEffect(() => {
    if ([ShopCouponTypesEnum.ProductGroup, ShopCouponTypesEnum.Product].indexOf(type) === -1) {
      updateDiscountCallback(
        {
          ...discount,
          productId: products[0].id,
          productGroupId: null,
          quantityMax: null
        },
        index
      );
    }
  }, [type]); // eslint-disable-line

  useEffect(() => {
    const validated = validatePercent(valuePercent);
    if (valueType === ShopCouponValueTypeEnum.Percent && validated !== valuePercent) {
      changeDiscountHandler('valuePercent', validated);
    }
  }, [valueType, valuePercent]); // eslint-disable-line

  return (
    <Wrapper>
      <SelectItem
        label={getTranslationKey('shop.coupons.typeLabel')}
        options={typeOptions()}
        value={type}
        changeCallback={partial(changeDiscountHandler, 'type')}
        margin={fieldsMargin}
      />
      <SelectItem
        defaultOptionsText={type === ShopCouponTypesEnum.Product ? null : '-'}
        label={productLabel}
        options={type === ShopCouponTypesEnum.Product ? productsOptions : productGroupOptions}
        value={String(productSelectValue)}
        disabled={isValueTypeDisabled}
        changeCallback={partial(changeDiscountHandler, productSelectField)}
        margin={fieldsMargin}
        required={type === ShopCouponTypesEnum.ProductGroup}
      />
      <InputItem
        label={getTranslationKey('shop.coupons.maxLabel')}
        type="number"
        changeCallback={partial(changeDiscountHandler, 'quantityMax')}
        value={String(quantityMax)}
        placeholder="-"
        disabled={isValueTypeDisabled}
        min={1}
        margin={fieldsMargin}
        withTooltip
        tooltipText={getTranslationKey('shop.coupons.maxQuantityTooltip')}
        id="quantityMax"
      />
      <InputItem
        label={getTranslationKey('shop.coupons.valueLabel')}
        type="number"
        changeCallback={partial(changeDiscountHandler, valueTypeFiled)}
        value={String(valueTypeValue)}
        placeholder="-"
        min={1}
        step={step}
        margin={fieldsMargin}
        dropdownConfig={{
          buttonLabel: valueTypeButtonValue,
          options: valueTypeOptions,
          callback: partial(changeDiscountHandler, 'valueType')
        }}
        required
      />
      {/* <DeleteButton onClick={ partial( deleteDiscountCallback, index ) }> */}
      {/*	<FaRegTrashAlt color={ theme.commonColors.second } size={ 18 }/> */}
      {/* </DeleteButton> */}
    </Wrapper>
  );
};
