/* eslint-disable vars-on-top */
import moment from 'moment';
import { hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import Texts from '../json/texts.json';

const brandPrimary = '#066cd2';
const brandSuccess = '#4dbd74';
const brandInfo = '#63c2de';
const brandWarning = '#ffc107';
const brandDanger = '#f86c6b';

export default function (state = { loadingDashboardStatistics: true }, action) {
  try {
    switch (action.type) {
      case 'fetch_admin_dashboard_statistics':
        const { payload } = action;
        if (payload && payload.length > 0) {
          const adminDashboardMainChartData = {
            // eslint-disable-line
            maxValue: 0,
            labels: [],
            datasets: [
              {
                label: Texts[window.LANGUAGE_SW].adminDashboard.views,
                backgroundColor: hexToRgba(brandInfo, 10),
                borderColor: brandInfo,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: []
              },
              {
                label: Texts[window.LANGUAGE_SW].adminDashboard.shares,
                backgroundColor: 'transparent',
                borderColor: brandSuccess,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: []
              },
              {
                label: Texts[window.LANGUAGE_SW].adminDashboard.createdSites,
                backgroundColor: 'transparent',
                borderColor: brandDanger,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: []
              },
              {
                label: Texts[window.LANGUAGE_SW].adminDashboard.imagesCount,
                backgroundColor: 'transparent',
                borderColor: brandPrimary,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: []
              },
              {
                label: Texts[window.LANGUAGE_SW].adminDashboard.appInstalls,
                backgroundColor: 'transparent',
                borderColor: brandWarning,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: []
              }
            ]
          };
          var adminDashboardMainChartOverAllStats; // eslint-disable-line

          payload.forEach((data, index) => {
            const _data = { ...data };

            if (index === 0) {
              adminDashboardMainChartOverAllStats = _data; // eslint-disable-line
            } else {
              adminDashboardMainChartData.datasets[0].data.push(_data.views);
              if (_data.views > adminDashboardMainChartData.maxValue) {
                adminDashboardMainChartData.maxValue = _data.views;
              }

              adminDashboardMainChartData.datasets[1].data.push(_data.shares);
              if (_data.shares > adminDashboardMainChartData.maxValue) {
                adminDashboardMainChartData.maxValue = _data.shares;
              }

              adminDashboardMainChartData.datasets[2].data.push(_data.createdSites);
              if (_data.createdSites > adminDashboardMainChartData.maxValue) {
                adminDashboardMainChartData.maxValue = _data.createdSites;
              }

              adminDashboardMainChartData.datasets[3].data.push(_data.uploadedImages);
              if (_data.uploadedImages > adminDashboardMainChartData.maxValue) {
                adminDashboardMainChartData.maxValue = _data.uploadedImages;
              }

              adminDashboardMainChartData.datasets[4].data.push(_data.appInstalls);
              if (_data.appInstalls > adminDashboardMainChartData.maxValue) {
                adminDashboardMainChartData.maxValue = _data.appInstalls;
              }

              const date = moment(_data.date).format('MM-DD');
              adminDashboardMainChartData.labels.push(date);
            }
          });
        }

        return {
          ...state,
          adminDashboardMainChartData, // eslint-disable-line
          adminDashboardMainChartOverAllStats // eslint-disable-line
        };
      case 'fetch_user_dashboard_statistics':
        if (action.payload && action.payload.length > 0) {
          const userDashboardMainChartData = {
            maxValue: 0,
            labels: [],
            datasets: [
              {
                label: Texts[window.LANGUAGE_SW].userDashboard.views,
                backgroundColor: hexToRgba(brandInfo, 10),
                borderColor: brandInfo,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: []
              },
              {
                label: Texts[window.LANGUAGE_SW].userDashboard.shares,
                backgroundColor: 'transparent',
                borderColor: brandSuccess,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: []
              },
              {
                label: Texts[window.LANGUAGE_SW].userDashboard.appInstalls,
                backgroundColor: 'transparent',
                borderColor: brandDanger,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: []
              },
              {
                label: Texts[window.LANGUAGE_SW].userDashboard.downloads,
                backgroundColor: 'transparent',
                borderColor: brandWarning,
                pointHoverBackgroundColor: '#fff',
                borderWidth: 2,
                data: []
              }
            ]
          };
          var userDashboardMainChartOverAllStats; // eslint-disable-line

          action.payload.forEach((data, index) => {
            const _data = { ...data };

            if (moment(_data.date).isSame('2020-08-05', 'day')) return;

            if (index === 0) {
              userDashboardMainChartOverAllStats = _data; // eslint-disable-line
            } else {
              userDashboardMainChartData.datasets[0].data.push(_data.views);
              if (_data.views > userDashboardMainChartData.maxValue) {
                userDashboardMainChartData.maxValue = _data.views;
              }

              userDashboardMainChartData.datasets[1].data.push(_data.shares);
              if (_data.shares > userDashboardMainChartData.maxValue) {
                userDashboardMainChartData.maxValue = _data.shares;
              }

              userDashboardMainChartData.datasets[2].data.push(_data.appInstalls);
              if (_data.appInstalls > userDashboardMainChartData.maxValue) {
                userDashboardMainChartData.maxValue = _data.appInstalls;
              }

              userDashboardMainChartData.datasets[3].data.push(_data.downloads);
              if (_data.downloads > userDashboardMainChartData.maxValue) {
                userDashboardMainChartData.maxValue = _data.downloads;
              }

              const date = moment(_data.date).format('MM-DD');
              userDashboardMainChartData.labels.push(date);
            }
          });

          return {
            ...state,
            loadingDashboardStatistics: false,
            userDashboardMainChartData, // eslint-disable-line
            userDashboardMainChartOverAllStats // eslint-disable-line
          };
        }

        return {
          ...state,
          loadingDashboardStatistics: false
        };
      default:
        return state;
    }
  } catch (e) {
    console.log(e);
  }
}
