import { createReducer } from 'redux-act';
import { IUserReducer } from '../../../modules/types/user';
import {
  fetchPayoutAccountSuccessAction,
  getAvailableCurrenciesSuccessAction
} from '../../../modules/actions';

const initialState: IUserReducer = {
  payoutAccount: {
    id: '',
    userId: '',
    iban: '',
    payPalEmail: ''
  },
  availableCurrencies: []
};

export const user = createReducer<IUserReducer>({}, initialState);

user.on(fetchPayoutAccountSuccessAction, (state, payload) => ({
  ...state,
  payoutAccount: payload
}));

user.on(getAvailableCurrenciesSuccessAction, (state, payload) => ({
  ...state,
  availableCurrencies: payload
}));
