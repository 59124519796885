import React from 'react';
import styled from 'styled-components';
import { getTranslationKey } from '../../../modules/utils';
import { UncontrolledTooltip } from 'reactstrap';
import { useSelector } from 'react-redux';
import { getUser } from '../../../modules/selectors';
import { get } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  i {
    margin-left: 5px;
  }
`;

export const MarginCol = () => {
  const user = useSelector(getUser);
  const percent = get(user, 'serviceFee.percent', '');
  const fixed = get(user, 'serviceFee.fixed', '');
  const fixedCurrency = ` ${user.currency || ''}`;

  return (
    <Wrapper>
      {getTranslationKey('shop.pricingTable.marginLabel')}{' '}
      <i className="fa fa-icon fa-question-circle" id="margin-tooltip" />
      <UncontrolledTooltip autohide={false} target="margin-tooltip">
        {getTranslationKey('shop.pricingTable.marginTooltip', {
          percent,
          fixed: (fixed / 100).toFixed(2),
          fixedCurrency
        })}
      </UncontrolledTooltip>
    </Wrapper>
  );
};
