import { Tabs } from '../components';
import TabContentComponent from '../components/Tabs/TabContentComponent';
import { SETTINGS_TABS } from '../modules/constants';
import { getTranslationKey } from '../modules/utils';
import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import Language from '../views/Language';
import CustomDomain from '../views/User/CustomDomain';
import WatermarkAndBrand from '../views/watermark-and-brand';
import TaxSettings from './TaxSettings';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getUser } from '../modules/selectors';
import CollectionPresets from './CollectionPresets';
import useTrackTabView from '../hooks/tracking/use-track-tab-view';
import Message from '../views/Message';

const PageTitle = styled.p`
  font-size: 2rem;
  font-weight: semi-bold;
`;

const PageLayout = ({ children }: { children: any }) => (
  <div className="animated fadeIn">
    <Row>
      <Col sm="12" md="12" lg="12">
        {children}
      </Col>
    </Row>
  </div>
);

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const TabsComponents = [
  {
    id: 'watermark-and-brand',
    component: WatermarkAndBrand
  },
  {
    id: 'gallery-presets',
    component: CollectionPresets
  },
  {
    id: 'tax',
    component: TaxSettings
  },
  {
    id: 'messages',
    component: Message
  },
  {
    id: 'domains',
    component: CustomDomain
  },
  {
    id: 'language',
    component: Language
  }
];

export const Settings = () => {
  const trackTab = useTrackTabView();
  const user = useSelector(getUser);
  const [activeTab, setActiveTab] = useState('watermark-and-brand');
  let tabsList = SETTINGS_TABS.map((item) => ({
    ...item,
    label: getTranslationKey(item.label)
  }));

  if (
    !(
      !user.whiteLabel.active ||
      (user.whiteLabel.hideElements && !user.whiteLabel.hideElements.includes('notifications'))
    )
  ) {
    tabsList = tabsList.filter((tab) => tab.id !== 'messages');
  }

  const changeTabHandler = (tabName: string) => {
    setActiveTab(tabName);
    trackTab(tabName, 'settings');
  };

  return (
    <PageLayout>
      <PageTitle>{getTranslationKey('settings')}</PageTitle>
      <TabsWrapper>
        <Tabs tabsList={tabsList} activeTab={activeTab} onTabClick={changeTabHandler} />
      </TabsWrapper>
      <TabContentComponent activeTab={activeTab} contentList={TabsComponents} />
    </PageLayout>
  );
};

export default Settings;
