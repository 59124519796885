export default function (state = {}, action) {
  switch (action.type) {
    case 'SET_DISCOUNT_MODAL_VISIBILITY':
      return { ...state, isDiscountModalOpen: action.payload };
    case 'SET_MESSAGE_MODAL_VISIBILITY':
      return {
        ...state,
        isMessageModalOpen: action.payload.isMessageModalOpen,
        messages: action.payload.messages || []
      };
    case 'SET_SURVEY_MODAL_VISIBILITY':
      return { ...state, isSurveyModalOpen: action.payload };
    case 'SET_SURVEY_MODAL_NPS_VISIBILITY':
      return { ...state, isSurveyModalNPSOpen: action.payload };
    case 'SET_USE_DESKTOP_MODAL_VISIBILITY':
      return { ...state, isUseDesktopModalIsOpen: action.payload };
    default:
      return state;
  }
}
