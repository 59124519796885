import Texts from '../json/texts.json';
import { orderBy as _orderBy } from 'lodash';

export default function (state = {}, action) {
  try {
    switch (action.type) {
      case 'fetch_all_users':
        return { ...state, allUsers: action.payload };
      case 'fetch_all_user_sites':
        return { ...state, allUserSites: action.payload };
      case 'fetch_sites_group_by_type':
        const { groupByType, overallCount } = action.payload;

        let sitesGroupByType = [];
        const others = {
          title: Texts[window.LANGUAGE_SW].adminDashboard.siteTypeStats.others,
          count: 0,
          percent: 0
        };

        groupByType.forEach((type) => {
          if (type.count < 10 || !type._id) {
            others.count += type.count;
            others.percent = Math.floor((100 / overallCount) * others.count);
          } else {
            sitesGroupByType.push({
              title: type._id,
              count: type.count,
              percent: Math.floor((100 / overallCount) * type.count)
            });
          }
        });

        if (others.count > 0) sitesGroupByType.push(others);

        sitesGroupByType = _orderBy(sitesGroupByType, 'count', 'desc');

        return { ...state, sitesGroupByType };
      default:
        return state;
    }
  } catch (e) {
    console.log(e);
  }
}
