import React from 'react';
import styled from 'styled-components';
import { IPreset } from '../../modules/reducers';
import TemplateConfigurationForm from '../../elements/Collections/TemplateConfigurationForm';

const Wrapper = styled.div``;

interface IProps {
  preset: IPreset;
  onChangeCallback: (data: any) => void;
}

export const PresetDesign: React.FC<IProps> = ({ preset, onChangeCallback }) => {
  const presetDesign = preset.design || {};

  return (
    <Wrapper>
      <TemplateConfigurationForm
        formValue={{
          // @ts-ignore
          design: presetDesign,
          selectedTemplateId: preset._scrappbookGalleryTemplate,
          galleryStyle: preset.galleryStyle
        }}
        setValue={(form) => {
          onChangeCallback({
            design: {
              ...presetDesign,
              ...form.design,
              variables: {
                // @ts-ignore
                ...presetDesign.variables,
                ...(form.design || {}).variables
              }
            },
            galleryStyle: form.galleryStyle || preset.galleryStyle,
            _scrappbookGalleryTemplate: form.selectedTemplateId || preset._scrappbookGalleryTemplate
          });
        }}
      />
    </Wrapper>
  );
};
