export default function (state = { all: [], gateways: [] }, action) {
  switch (action.type) {
    case 'SET_ALL_PAYMENTS':
      return { ...state, all: action.payload };
    case 'SET_PAYMENT_GATEWAY':
      return { ...state, [action.payload.type]: action.payload.data };
    default:
      return state;
  }
}
