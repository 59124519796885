import { SagaIterator } from 'redux-saga';
import axiosWithoutCredentials from 'axios';
import ImageCompressor from '@xkeshi/image-compressor';
import { get, set } from 'lodash';
import { call, put, select, take } from 'redux-saga/effects';
import Api from '../../../modules/utils/API';
import {
  ICollectionUpdateAction,
  IDomainUpdateAction,
  IMainCustomersUpdateAction,
  ISagaAction,
  ISearchCollectionsAction,
  IUploadAppIconAction,
  IUploadCollectionHeaderAction
} from '../../types';
import { IEndCustomerProps } from '../../types/endCustomer';
import {
  changeCollectionState,
  changeCollectionStateSuccess,
  clearCollection,
  clearGalleries,
  clearImages,
  clearSelected,
  errorsGlobalError,
  fetchCollectionFailed,
  fetchCollectionSuccess,
  fetchImagesSuccess,
  searchCollectionsSuccessAction,
  setIsCropped,
  showNotifyMessage,
  showNotifySuccess
} from '../../actions';
import {
  getCollectionHeaderImage,
  getCollectionId,
  getCollectionsNew,
  getImageByID,
  getImagesList
} from '../../selectors';

import { multipleAttempts } from '../global';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import { getTranslationKey } from '../../utils';
import { IEndCustomer, IImage } from '../../reducers';

export const initialCollectionFetchSaga = function* (action: ISagaAction<string>): SagaIterator {
  yield call(clearCollectionSaga);
  yield call(fetchCollectionSaga, action);
};

export const clearCollectionSaga = function* (): SagaIterator {
  yield put(clearCollection());
  yield put(clearGalleries());
  yield put(clearImages());
  yield put(clearSelected());
};

export const fetchCollectionSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const response = yield call(Api.Collections.get, {
      id: payload,
      ownerRequest: true
    });

    if (response.code === 401 || response.code === 403) {
      window.location.href = '/#/collections';
    }

    ApiErrors.checkOnApiError(response);
    yield put(fetchCollectionSuccess(response));
  } catch (e) {
    yield put(fetchCollectionFailed());
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const searchCollectionsSaga = function* ({
  payload
}: ISagaAction<ISearchCollectionsAction>): SagaIterator {
  try {
    const response = yield call(Api.Collections.search, payload);
    ApiErrors.checkOnApiError(response);
    yield put(searchCollectionsSuccessAction(response.result));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const deleteCollectionCustomDomainSaga = function* (): SagaIterator {
  try {
    const collectionID = yield select(getCollectionId);
    yield call(Api.Collections.deleteDomain, {
      site_id: collectionID
    });

    const response = yield put(
      changeCollectionState({
        path: 'customDomain',
        value: {
          domain: '',
          path: '',
          url: ''
        }
      })
    );
    ApiErrors.checkOnApiError(response);
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const updateCollectionByPathSaga = function* ({
  payload
}: ISagaAction<ICollectionUpdateAction>): SagaIterator {
  try {
    const collectionID = yield select(getCollectionId);
    const response = yield call(Api.Collections.update, {
      collection_id: collectionID,
      path: payload.path,
      value: payload.value
    });
    ApiErrors.checkOnApiError(response);

    const { path, payload: resultPayload } = response;

    yield put(changeCollectionState({ path, value: resultPayload }));

    if (payload.path === 'customDomain.path' && response.isPathAlreadyTaken) {
      yield put(showNotifyMessage(getTranslationKey('customDomains.path-is-already-in-use')));
    } else if (!payload.isSilent) {
      yield put(showNotifySuccess({}));
    }
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const updateCollectionDomainSaga = function* ({
  payload
}: ISagaAction<IDomainUpdateAction>): SagaIterator {
  try {
    const collectionID = yield select(getCollectionId);
    const { customDomain } = yield select(getCollectionsNew);

    const resultDomain = yield call(Api.Collections.update, {
      collection_id: collectionID,
      path: 'customDomain.domain',
      value: payload.domain
    });
    ApiErrors.checkOnApiError(resultDomain);
    const resultPath = yield call(Api.Collections.update, {
      collection_id: collectionID,
      path: 'customDomain.path',
      value: payload.path
    });
    ApiErrors.checkOnApiError(resultPath);
    yield put(
      changeCollectionState({
        path: 'customDomain',
        value: {
          ...customDomain,
          url: get(resultPath, 'payload.url', get(resultDomain, 'payload.url', customDomain.url)),
          domain: get(resultDomain, 'payload.domain', customDomain.domain),
          path: get(resultPath, 'payload.path', customDomain.path)
        }
      })
    );

    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const updateMainCustomersSaga = function* ({
  payload
}: ISagaAction<IMainCustomersUpdateAction>): SagaIterator {
  try {
    const collection = yield select(getCollectionsNew);
    const collectionID = yield select(getCollectionId);

    const response = yield call(Api.Collections.update, {
      collection_id: collectionID,
      path: 'mainCustomers',
      value: payload.value
    });
    ApiErrors.checkOnApiError(response);

    yield put(changeCollectionStateSuccess(set(collection, 'mainCustomers', response.value)));
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const deleteMainCustomerSaga = function* ({ payload }: ISagaAction<string>): SagaIterator {
  try {
    const collection = yield select(getCollectionsNew);
    const collectionID = yield select(getCollectionId);
    const response = yield call(Api.Collections.deleteMainCustomer, {
      collection_id: collectionID,
      value: payload
    });
    ApiErrors.checkOnApiError(response);
    const { mainCustomers } = response.result.props;

    const updatedMainCustomers = collection.mainCustomers.filter((customer: IEndCustomer) =>
      mainCustomers.includes(customer.id)
    );

    yield put(changeCollectionStateSuccess(set(collection, 'mainCustomers', updatedMainCustomers)));
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const setEndCustomerEmailSubscriptionSaga = function* ({
  payload
}: ISagaAction<IEndCustomerProps>): SagaIterator {
  try {
    const collection = yield select(getCollectionsNew);
    const response = yield call(Api.EndCustomer.setEndCustomerSubscription, {
      endCustomerId: payload.endCustomerId,
      value: payload.value
    });
    ApiErrors.checkOnApiError(response);
    const { value } = response.result;

    const currentEndCustomer = collection.mainCustomers.find(
      (customer: IEndCustomer) => customer.id === payload.endCustomerId
    );
    currentEndCustomer.marketingEmailsEnabled = value;

    yield put(
      changeCollectionStateSuccess(set(collection, 'mainCustomers', collection.mainCustomers))
    );
    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const changeCollectionStateSaga = function* ({
  payload
}: ISagaAction<ICollectionUpdateAction>): SagaIterator {
  const collection = yield select(getCollectionsNew);
  yield put(changeCollectionStateSuccess(set(collection, payload.path, payload.value)));
};

export const getPinSaga = function* (): SagaIterator {
  try {
    let collectionID = yield select(getCollectionId);

    if (!Boolean(collectionID.length)) {
      yield take(fetchCollectionSuccess);
    }
    collectionID = yield select(getCollectionId);
    const response = yield call(Api.Collections.getPin, {
      collection_id: collectionID
    });

    ApiErrors.checkOnApiError(response);

    yield put(changeCollectionStateSuccess(response));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const setAppIconSaga = function* ({
  payload
}: ISagaAction<IUploadAppIconAction>): SagaIterator {
  const { imageID, blob } = payload;
  const collectionID = yield select(getCollectionId);

  try {
    const { originalImageName, gallery_id } = yield select((state) => getImageByID(state)(imageID));

    const signedUrlResponse = yield call(Api.Collections.uploadAppIcon, {
      collection_id: collectionID,
      originalImageName,
      albumId: gallery_id
    });

    ApiErrors.checkOnApiError(signedUrlResponse);
    const { signedUrl, newImage } = signedUrlResponse;

    yield call(multipleAttempts(uploadImageToS3), signedUrl, blob, {});
    yield call(checkIsAppImageOnS3Saga, newImage, 0, signedUrl, blob);
    yield put(fetchImagesSuccess({ appIcon: newImage }));
    yield put(setIsCropped(true));
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(setIsCropped(true));
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

const checkIsAppImageOnS3Saga = function* (
  newImage: IImage,
  attempt: number,
  signedUrl: string,
  blob: Blob
): SagaIterator {
  const response = yield call(multipleAttempts(Api.Images.checkS3Exist), {
    image_id: newImage._id,
    size: 'L'
  });

  if (response.error) {
    if (attempt < 4) {
      console.warn('==========================');
      console.warn(`Attempt ${attempt}`);
      console.warn('==========================');
      yield call(multipleAttempts(uploadImageToS3), signedUrl, blob, {});
      yield call(multipleAttempts(uploadImageToS3), signedUrl, blob, {});
      return yield call(checkIsAppImageOnS3Saga, newImage, attempt + 1, signedUrl, blob);
    } else {
      const errorText = getTranslationKey('errors.s3Request')
        // tslint:disable-next-line:no-invalid-template-strings
        .replace('${ image.originalImageName }', newImage.originalImageName); // eslint-disable-line
      throw Error(errorText);
    }
  } else {
    return true;
  }
};

export const setCollectionHeaderSaga = function* ({
  payload
}: ISagaAction<IUploadCollectionHeaderAction>): SagaIterator {
  const { file } = payload;
  const collectionID = yield select(getCollectionId);

  try {
    const currentCollectionHeader = yield select(getCollectionHeaderImage);

    const currentId = currentCollectionHeader ? currentCollectionHeader._id : null;

    const signedUrlResponse = yield call(multipleAttempts(Api.Images.preUploadHeaderImage), {
      originalImageName: file.name,
      collectionId: collectionID
    });

    ApiErrors.checkOnApiError(signedUrlResponse);
    const { signedUrl, image } = signedUrlResponse.result;

    const imageCompressor = new ImageCompressor(file);
    // @ts-ignore
    const imageFile = yield imageCompressor.compress(file, {
      quality: 0.9,
      maxWidth: 2000
    });

    yield call(multipleAttempts(() => axiosWithoutCredentials.put(signedUrl, imageFile)));

    const images = yield select(getImagesList);
    const updated = [...images.filter((image: IImage) => image._id !== currentId), image];

    yield put(fetchImagesSuccess({ items: updated }));

    yield put(showNotifySuccess({}));
  } catch (e) {
    // @ts-ignore
    yield put(errorsGlobalError(e));
  }
};

export const uploadImageToS3 = function* (signedUrl: string, blob: Blob, options: any) {
  // @ts-ignore
  return yield axiosWithoutCredentials.put(signedUrl, blob, options);
};
