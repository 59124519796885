import { createAction } from 'redux-act';
import { ICreateDigitalPricingList, ICreatePackage, IDeleteDigitalPricingList, IDeletePackage, IGetDigitalPricingListPackages, IGetDigitalPricingListPackagesSuccess, IGetDigitalPricingListSuccess, ISetCurrentDigitalPricingList, IUpdateDigitalPricingList, IUpdatePackage } from '../../modules/types/digital-pricing';
import { IShopReducerState } from '../../modules/types';

export const fetchDigitalPricingListsAction = createAction();
export const fetchDigitalPricingListsSuccessAction = createAction<IGetDigitalPricingListSuccess>();
export const fetchDigitalPricingListPackagesAction = createAction<IGetDigitalPricingListPackages>();
export const fetchDigitalPricingListPackagesSuccessAction = createAction<IGetDigitalPricingListPackagesSuccess>();
export const setCurrentDigitalPricingListAction = createAction<ISetCurrentDigitalPricingList>();
export const setCurrentDigitalPricingListSuccessAction = createAction<IShopReducerState['digitalPricing']['currentlyOpenedDigitalList']>();
export const createPackageAction = createAction<ICreatePackage>();
export const deletePackageAction = createAction<IDeletePackage>();
export const updatePackageAction = createAction<IUpdatePackage>();
export const createDigitalPricingListAction = createAction<ICreateDigitalPricingList>();
export const deleteDigitalPricingListAction = createAction<IDeleteDigitalPricingList>();
export const updateDigitalPricingListAction = createAction<IUpdateDigitalPricingList>();
export const setIsCreatePackageModalOpen = createAction<boolean>();
export const clearDigitalPricingDetails = createAction();
