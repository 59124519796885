import axios from '../helpers/axios';
import { handleErrorWithRedirectIfNotAuth } from '../errors/generalError';

export const getAllUsers = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/users');
    if (res.status === 200) dispatch({ type: 'fetch_all_users', payload: res.data });
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);
  }
};

export const getUserById = (id) => async () => {
  try {
    const res = await axios.get('/api/admin/user', { params: { id } });
    if (res.status === 200) return res.data;
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);

    return {};
  }
};

export const getUserActivities = (user_id) => async () => {
  try {
    const res = await axios.get('/api/admin/user/activities', {
      params: { user_id }
    });
    if (res.status === 200) return res.data;
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);

    return [];
  }
};

export const getUserSites = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/sites');
    if (res.status === 200) dispatch({ type: 'fetch_all_user_sites', payload: res.data });
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);
  }
};

export const getSitesGroupByType = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/admin/sites/groupByType');
    if (res.status === 200) dispatch({ type: 'fetch_sites_group_by_type', payload: res.data });
  } catch (e) {
    handleErrorWithRedirectIfNotAuth(e);
  }
};

export const createPPUser = (email) => async () => {
  try {
    const res = await axios.post('/api/signup', { email });
    if (res.status === 200) return res.data;

    return null;
  } catch (e) {
    return null;
  }
};

export const toggleLockedVST = (user_id) => async () => {
  try {
    await axios.post('/api/vst/user/toggleLocked', { user_id });
    setTimeout(() => window.location.reload(), 2000);

    return true;
  } catch (e) {
    console.log(e);

    return false;
  }
};

export const setPlanVST =
  ({ user_id, plan }) =>
  async () => {
    try {
      await axios.post('/api/vst/user/setPlan', { user_id, plan });
      setTimeout(() => window.location.reload(), 2000);

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

export const setIntervallVST =
  ({ user_id, intervall }) =>
  async () => {
    try {
      await axios.post('/api/vst/user/setIntervall', { user_id, intervall });
      setTimeout(() => window.location.reload(), 2000);

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

export const updateUserAsAdmin =
  ({ user_id, data, reload }) =>
  async () => {
    try {
      await axios.post('/api/admin/user', { user_id, data });
      reload && setTimeout(() => window.location.reload(), 2000);

      // alert("user updated");
      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

export const activateShopForAllCollectionsByUserId =
  ({ user_id }) =>
  async () => {
    try {
      const res = await axios.post('/api/shop/activateShopForAllCollectionsByUserId', {
        user_id
      });
      window.alert(`update successfully => ${res.data.nModified}`);

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

export const adminCreateProductPricingListForUserId =
  ({ user_id }) =>
  async () => {
    try {
      const res = await axios.put('/api/productPricingList/admin', {
        user_id
      });
      window.alert(
        `update successfully new list name => ${res.data.name} / default => ${res.data.default}`
      );

      return true;
    } catch (e) {
      console.log(e);

      return false;
    }
  };

export const sendConfirmationEmail = () => async () => {
  try {
    const res = await axios.post('/api/emails/send-confirmation-email');
    if (res.status === 200) return res.data;

    return null;
  } catch (e) {
    return null;
  }
};
