import { createAction } from 'redux-act';
import { IGalleryTagState } from '../../modules/reducers/galleryTags';
import {
  IGalleryTag,
  RemoveGalleryTagCollectionAction,
  AddGalleryTagCollectionAction
} from '../../modules/types';

export const fetchGalleriesTags = createAction();
export const fetchGalleriesTagsSuccess = createAction<IGalleryTag[]>();
export const changeGalleryTagState = createAction<Partial<IGalleryTagState>>();
export const addGalleryTagCollectionAction = createAction<AddGalleryTagCollectionAction>();
export const removeGalleryTagCollectionAction = createAction<RemoveGalleryTagCollectionAction>();
