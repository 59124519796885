import React from 'react';
import styled, { css } from 'styled-components';
import LoadingSpinner from './LoadingSpinner';

interface ILoadingComponent {
  readonly withCoverBg?: true;
  readonly position?: 'flex-end' | 'center';
}

const getCoverStyles = (position?: string) => css`
  position: absolute;
  display: flex;
  align-items: ${position || 'center'};
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 5;
  user-select: none;
  background-color: rgba(255, 255, 255, 0.65);
`;

const LoadingSpinnerWrapper = styled.div<ILoadingComponent>`
  pointer-events: none;
  display: flex;
  ${(props) => props.withCoverBg && getCoverStyles(props.position)}
`;

export const AppLoading = ({ withCoverBg, position }: ILoadingComponent) => (
  <LoadingSpinnerWrapper withCoverBg={withCoverBg} position={position} className="loading">
    <LoadingSpinner />
  </LoadingSpinnerWrapper>
);

export default AppLoading;
