import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FaPen } from 'react-icons/fa';
import { InputItem } from '../Forms/InputItem';
import theme from '../../assets/css/theme';

const StaticTitle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  span {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  svg {
    height: 15px;
    color: ${theme.commonColors.second};
    margin-right: 5px;
    flex: 0 0 auto;
  }
`;
const whiteTheme = css`
  svg {
    color: #ffffff;
  }
`;
const Wrapper = styled.form<{ themeName?: string }>`
  &:focus {
    outline: none;
  }
  ${(props) => props.themeName === 'white' && whiteTheme};
`;

interface IProps {
  text: string;
  submitChangedCallBack: (text: string) => void;
  themeName?: 'white';
}

export const EditableTitleField = ({ text, submitChangedCallBack, themeName }: IProps) => {
  const [editState, changeEditState] = useState(false);
  const [textState, changeTitleState] = useState(text);
  useEffect(() => {
    if (textState !== text) {
      changeTitleState(text);
    }
  }, [text]); // eslint-disable-line
  const toggleEditState = () => changeEditState(!editState);
  const changeTitleHandler = (value: string) => changeTitleState(value);
  const onSubmitHandler = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (textState) {
      submitChangedCallBack(textState);
    }
    changeEditState(false);
  };
  const removeFormEditMode = (e: React.SyntheticEvent) => {
    const { currentTarget } = e;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        toggleEditState();
      }
    }, 0);
  };

  return (
    <Wrapper
      onSubmit={onSubmitHandler}
      tabIndex={0}
      onBlur={removeFormEditMode}
      themeName={themeName}
    >
      {!editState ? (
        <StaticTitle onClick={toggleEditState}>
          <FaPen />
          <span>{text}</span>
        </StaticTitle>
      ) : null}
      {editState ? (
        <InputItem
          value={textState}
          changeCallback={changeTitleHandler}
          onBlurCallback={onSubmitHandler}
          margin="0"
        />
      ) : null}
    </Wrapper>
  );
};
