import axios from '../helpers/axios';
import { sleep } from '../helpers/globalFunctions';
import * as errors from '../errors';
import axiosWithoutCredentials from 'axios';
import { addNotification as notify } from 'reapop';
import Texts from '../json/texts.json';

export const updateGalleryFilter = (payload) => (dispatch) =>
  dispatch({ type: 'UPDATE_GALLERY_FILTER', payload });

export const setImageForAppIconCrop = (payload) => (dispatch) => {
  try {
    dispatch({ type: 'SET_IMG_APP_ICON_CROP', payload });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const saveImageOrder = (data) => async (dispatch) => {
  try {
    await axios.post('/api/images/order', data);

    dispatch(
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].saved,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 1500
      })
    );
  } catch (e) {
    console.log(e);
  }
};

export const orderGalleryImages = (payload) => (dispatch) =>
  dispatch({
    type: 'UPDATE_GALLERY_IMAGES',
    payload
  });

export const setGalleryTitleImage = (data) => async (dispatch) => {
  try {
    const res = await axios.post('/api/image/setAsTitleImage', data);

    dispatch({
      type: 'UPDATE_GALLERY_TITLE_IMAGE',
      payload: {
        newTitleImage: data,
        oldTitleImage_id: res.data.oldTitleImage_id
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const removeImageFromCollection = (payload) => (dispatch) => {
  try {
    dispatch({ type: 'REMOVE_IMAGE_FROM_COLLECTION', payload });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const loadGalleryImages = (gallery_id) => async (dispatch) => {
  try {
    const res = await axios.get('api/images/gallery', {
      params: { gallery_id }
    });

    dispatch({
      type: 'UPDATE_GALLERY_IMAGES',
      payload: {
        images: res.data,
        gallery_id
      }
    });
  } catch (e) {
    console.log(e);
  }
};

export const addImagesToGallery = (payload) => (dispatch) => {
  try {
    dispatch({ type: 'ADD_IMG_TO_GALLERY', payload });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const deleteImagesFromGallery = (data) => async (dispatch) => {
  try {
    const params = { image_id: data.image_id };

    if (!params.image_id) {
      if (data.image_ids) params.image_ids = data.image_ids;
      else if (data.gallery_id) params.gallery_id = data.gallery_id;

      params.collection_id = data.collection_id;

      await axios.delete('/api/images', {
        params
      });
    } else {
      await axios.delete('/api/image', {
        params
      });
    }

    dispatch({
      type: 'DELETE_IMAGES_FROM_COLLECTION',
      payload: data
    });

    dispatch({
      type: 'REMOVE_IMAGES_FROM_SELECTIONS',
      payload: data
    });

    return true;
  } catch (e) {
    console.log(e);

    return false;
  }
};

const axiosRetry = async function (url, data, attempt = 0) {
  const advancedLogging = false;
  while (attempt < 5) {
    try {
      advancedLogging && window.logToServer(`[axiosRetry] attempt:${attempt}`);
      const res = await axios.get(url, data); // eslint-disable-line

      advancedLogging && window.logToServer(`[axiosRetry] attempt:${attempt} - success`);

      return res;
    } catch (e) {
      if (attempt < 4) {
        window.logToServer(
          `[axiosRetry] attempt:${attempt} failed wait ${
            (5000 * (attempt + 1)) / 1000
          } seconds with data:${JSON.stringify(data)} err:${JSON.stringify(e)}`
        );
        await sleep(5000 * (attempt + 1)); // eslint-disable-line
      } else {
        window.logToServer(`[axiosRetry] failed 5 times with data:${JSON.stringify(data)}`);
        throw e;
      }
    } finally {
      attempt++;
    }
  }
};

export const uploadAppIcon =
  ({ image, collection }, cb) =>
  async (dispatch) => {
    try {
      const signedUrlResponse = await axiosRetry('/api/upload/appIcon', {
        params: {
          collection_id: collection._id,
          originalImageName: image.originalImageName
        }
      });

      const { newImage } = signedUrlResponse.data;

      const delayedRevoke = async (tryNo = 1) => {
        try {
          if (tryNo <= 5) {
            tryNo++;
            await axios.get('/api/image/checkIfExistsOnS3', {
              params: {
                size: 'L',
                image_id: newImage._id
              }
            });

            dispatch({
              type: 'ADD_IMAGE_TO_COLLECTION',
              payload: {
                image: newImage,
                collection_id: collection._id
              }
            });
            cb && cb();
          }
        } catch (e) {
          return setTimeout(() => delayedRevoke(tryNo), 1500);
        }
      };
      setTimeout(delayedRevoke, 2500);
    } catch (e) {
      console.log(e);
    }
  };

const uploadImageToS3 = async function (signedUrl, image, settings, attempt = 0) {
  const advancedLogging = false;
  let response = { status: 500 };

  while (attempt < 5 && response.status !== 200) {
    try {
      advancedLogging &&
        window.logToServer(`[uploadImage-${image._id}] uploadImageToS3 attempt:${attempt} - start`);
      response = await axiosWithoutCredentials.put(signedUrl, image.file, settings); // eslint-disable-line
      advancedLogging &&
        window.logToServer(
          `[uploadImage-${image._id}] uploadImageToS3 attempt:${attempt} - success`
        );
    } catch (e) {
      window.logToServer(
        `[uploadImage-${image._id}] uploadImageToS3 attempt:${attempt} - fail wait ${
          (5000 * (attempt + 1)) / 1000
        } seconds - error:${JSON.stringify(e)}`
      );
      await sleep(5000 * (attempt + 1)); // eslint-disable-line

      if (attempt === 4) {
        window.logToServer(
          `[uploadImage-${
            image._id
          }] uploadImageToS3 attempt:${attempt} - failed 5 times - error:${JSON.stringify(e)}`
        );
      }
    } finally {
      attempt++;
    }
  }

  return response;
};

export const uploadImage =
  ({ image, collection, gallery_id, replaceDuplicates }) =>
  async (dispatch) => {
    const advancedLogging = false;
    try {
      const signedUrlResponse = await axiosRetry('/api/upload/image', {
        params: {
          collection_id: collection._id,
          type: gallery_id,
          originalImageName: image.originalImageName,
          imageSize: image.file.size,
          builderRequest: true,
          replace: replaceDuplicates || undefined,
          existing_image_id: replaceDuplicates && image.duplicate ? image.duplicate._id : undefined
        }
      });

      const { newImage, signedUrl, storageLimitReached, maintenance_mode, asyncCompression } =
        signedUrlResponse.data;

      if (maintenance_mode) {
        dispatch({
          type: 'UPDATE_TEMP_IMAGE_ID',
          payload: {
            newImage: {
              ...newImage,
              isUploading: false,
              maintenance_mode: true
            },
            temp_id: image._id,
            gallery_id
          }
        });
      } else if (storageLimitReached) {
        dispatch({
          type: 'UPDATE_TEMP_IMAGE_ID',
          payload: {
            newImage: {
              ...newImage,
              isUploading: false,
              storageLimitReached: true
            },
            temp_id: image._id,
            gallery_id
          }
        });
      } else {
        advancedLogging &&
          window.logToServer(`[uploadImage-${image._id}-${newImage._id}] upload start`);

        const uploadResult = await uploadImageToS3(signedUrl, image);

        if (uploadResult && uploadResult.status === 200) {
          await sleep(1000);

          advancedLogging &&
            window.logToServer(
              `[uploadImage-${image._id}-${newImage._id}] checkIfExistsOnS3 start`
            );

          const checkResult = await axiosRetry('/api/image/checkIfExistsOnS3', {
            params: {
              original: true,
              image_id: newImage._id
            }
          });

          if (!checkResult) {
            advancedLogging &&
              window.logToServer(
                `[uploadImage-${image._id}-${newImage._id}] checkIfExistsOnS3 failed -> second upload try`
              );

            const uploadResult2 = await uploadImageToS3(signedUrl, image);

            if (!uploadResult2) {
              advancedLogging &&
                window.logToServer(
                  `[uploadImage-${image._id}-${
                    newImage._id
                  }] upload2 failed result:${JSON.stringify(uploadResult2)}`
                );

              return dispatch({
                type: 'UPDATE_TEMP_IMAGE_ID',
                payload: {
                  newImage: {
                    isUploading: false,
                    imageProcessing: false,
                    uploadError: true
                  },
                  temp_id: image._id,
                  gallery_id
                }
              });
            }
          }

          advancedLogging &&
            window.logToServer(
              `[uploadImage-${image._id}-${newImage._id}] checkIfExistsOnS3 success`
            );

          dispatch({
            type: 'UPDATE_TEMP_IMAGE_ID',
            payload: {
              newImage: {
                ...newImage,
                isUploading: false,
                imageProcessing: true
              },
              temp_id: image._id,
              gallery_id
            }
          });

          const afterProcessingFunc = function () {
            advancedLogging &&
              window.logToServer(
                `[uploadImage-${image._id}-${newImage._id}] imagecompression success`
              );

            dispatch({
              type: 'UPDATE_IMAGE',
              payload: {
                newImage: {
                  _id: newImage._id,
                  imageProcessing: false,
                  isUploading: false
                },
                gallery_id
              }
            });
          };

          if (!asyncCompression) {
            advancedLogging &&
              window.logToServer(
                `[uploadImage-${image._id}-${newImage._id}] imagecompression start`
              );

            axios
              .post('/api/image/compress', {
                image_id: newImage._id
              })
              .then((compressionResult) => {
                const { processing } = compressionResult.data;

                if (processing === 'error') {
                  window.logToServer(
                    `[uploadImage-${image._id}-${newImage._id}] imagecompression failed`
                  );

                  dispatch({
                    type: 'UPDATE_IMAGE',
                    payload: {
                      newImage: {
                        _id: newImage._id,
                        imageProcessing: false,
                        uploadError: true,
                        isUploading: false
                      },
                      gallery_id
                    }
                  });
                } else if (processing === 'running') {
                  setTimeout(afterProcessingFunc, 300000);
                } else {
                  afterProcessingFunc();
                }
              })
              .catch((e) => {
                window.logToServer(
                  `[uploadImage-${image._id}-${
                    newImage._id
                  }] imagecompression failed ${JSON.stringify(e)}`
                );

                setTimeout(afterProcessingFunc, 300000);
              });
          } else {
            setTimeout(afterProcessingFunc, 30000);
          }
        } else {
          window.logToServer(
            `[uploadImage-${image._id}] upload failed result:${JSON.stringify(uploadResult)}`
          );

          dispatch({
            type: 'UPDATE_TEMP_IMAGE_ID',
            payload: {
              newImage: {
                isUploading: false,
                imageProcessing: false,
                uploadError: true
              },
              temp_id: image._id,
              gallery_id
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
      window.logToServer(
        `[uploadImage-${image._id}] signedUrlResponse failed err: ${JSON.stringify(e)}`
      );
      dispatch({
        type: 'UPDATE_TEMP_IMAGE_ID',
        payload: {
          newImage: {
            isUploading: false,
            imageProcessing: false,
            uploadError: true
          },
          temp_id: image._id,
          gallery_id
        }
      });
    } finally {
      advancedLogging && window.logToServer(`[uploadImage-${image._id}] upload end`);
    }
  };
