import { IImageDimensions } from '../../../modules/types';

export const processImageDimensions = (file: File): Promise<IImageDimensions | null> => {
  return new Promise<IImageDimensions>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      (event) => {
        const dataURL: string = event.target.result as string;
        const imageObj = new Image();

        imageObj.src = dataURL;
        imageObj.onerror = () => {
          // @@LOG
          resolve(null);
        };
        imageObj.onload = () => {
          resolve({ width: imageObj.width, height: imageObj.height });
        };
      },
      false
    );
    reader.readAsDataURL(file);
  });
};
