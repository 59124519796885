import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Button, Input, Row, Col } from 'reactstrap';
import * as actions from '../../../actions';
import Texts from '../../../json/texts.json';

class NewWatermark extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      description: ''
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    const { availableRules = {}, setUpgradeAccountOpenState } = this.props;
    const isLimitExcceeded = !availableRules.canCreateCustomWatermark;

    const toggleOpen = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

    if (isLimitExcceeded) {
      return setUpgradeAccountOpenState({
        key: 'upgradeModal',
        state: true,
        callback: toggleOpen,
        desiredAction: 'customWatermark'
      });
    }

    toggleOpen();
  }

  async create(type) {
    const { createWatermark, notify, onWatermarkCreated } = this.props;
    const watermark = await createWatermark(
      {
        description:
          this.state.description || Texts[window.LANGUAGE_SW].watermark.defaultDescription,
        type,
        text: type === 'text' ? Texts[window.LANGUAGE_SW].watermark.defaultText : undefined
      },
      notify
    );

    if (watermark && onWatermarkCreated) {
      this.setState({ isOpen: false, description: '' }, () => onWatermarkCreated(watermark));
    }
  }

  render() {
    const { availableRules = {} } = this.props;
    const { isOpen, description } = this.state;

    return (
      <div className="inline">
        <div onClick={this.toggle}>{this.props.children}</div>
        <Modal
          centered
          isOpen={isOpen && availableRules.canCreateCustomWatermark}
          toggle={this.toggle}
          wrapClassName="modal-dark"
          size="lg"
          backdrop
        >
          <ModalHeader toggle={this.toggle}>{Texts[window.LANGUAGE_SW].watermark.add}</ModalHeader>
          <ModalBody>
            <Row className="justify-content-center hidden">
              <Col xs="6">
                <small>
                  <b>{Texts[window.LANGUAGE_SW].watermark.description}</b>
                </small>
                <Input
                  className="mb-2"
                  value={description}
                  type="text"
                  onChange={({ target: { value } }) => this.setState({ description: value })}
                  placeholder={Texts[window.LANGUAGE_SW].watermark.descriptionPlaceholder}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6" sm="12">
                <Button
                  onClick={() => this.create('image')}
                  color="primary"
                  className="mb-2 w-100-p"
                >
                  {Texts[window.LANGUAGE_SW].watermark.imageWatermark}
                </Button>
                <h5>{Texts[window.LANGUAGE_SW].example}:</h5>
                <img
                  className="img-fluid mb-2"
                  src={`${window.CDN_URL}/public/previewWatermarkImage.jpg`}
                  alt=""
                />
              </Col>

              <Col md="6" sm="12">
                <Button
                  onClick={() => this.create('text')}
                  color="primary"
                  className="w-100-p mb-2"
                >
                  {Texts[window.LANGUAGE_SW].watermark.textWatermark}
                </Button>
                <h5>{Texts[window.LANGUAGE_SW].example}:</h5>
                <img
                  className="img-fluid mb-2"
                  src={`${window.CDN_URL}/public/previewWatermarkText.jpg`}
                  alt=""
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ notify, accessRules: { availableRules } }) {
  return { notify, availableRules };
}
export default connect(mapStateToProps, actions)(NewWatermark);
