import { createAction } from 'redux-act';
import { CollectionsViewMode, IGallery } from '../../modules/reducers/galleries';
import {
	IChangeGalleryOrderAction,
	ICreateGalleryAction,
	IDeleteGalleryAction, IDeleteVideoAction,
	IGalleriesWatermarksUpdateAction,
	IGalleryAction,
	IGalleryUploadingAction,
	IGalleryUploadingClearAction,
	ISetGalleryUploadProgressAction,
	IStartUploadGalleryVideoAction,
	IToggleGalleryWatermarkAction,
	IUpdateGalleryAction
} from '../../modules/types';

export const clearGalleries = createAction();

export const fetchGalleries = createAction();
export const fetchGalleriesSuccess = createAction<IGallery[]>();
export const fetchGalleriesFailed = createAction();

export const fetchGallery = createAction<IGalleryAction>();

export const createGallery = createAction<ICreateGalleryAction>();
export const deleteGallery = createAction<IDeleteGalleryAction>();
export const updateGallery = createAction<IUpdateGalleryAction>();
export const setGalleryUploadProgressAction = createAction<ISetGalleryUploadProgressAction>();

export const multipleGalleryUpdate = createAction<Partial<IGallery>>();
export const multipleWatermarkGalleryUpdate = createAction<IGalleriesWatermarksUpdateAction>();

export const changeGalleryOrder = createAction<IChangeGalleryOrderAction>();

export const toggleGalleryWatermark = createAction<IToggleGalleryWatermarkAction>();

export const galleryUploadingAction = createAction<IGalleryUploadingAction>();
export const galleryUploadingClearAction = createAction<IGalleryUploadingClearAction>();
export const galleryUploadingClearSuccess = createAction<IGallery[]>();
export const galleriesUploadingClearAction = createAction();


export const startUploadGalleryVideo = createAction<IStartUploadGalleryVideoAction>();
export const cancelVideoUploading = createAction();
export const deleteGalleryVideo = createAction<IDeleteVideoAction>();

export const setImagesToMoveOnCreate = createAction<string[]>();
export const setCollectionsViewMode = createAction<CollectionsViewMode>();
export const setCurrentGalleryId = createAction<string>('SET_CURRENT_GALLERY_ID');
