import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getTranslationKey } from '../../modules/utils';
import styled from 'styled-components';
import { ShopTaxSetupForm } from './ShopTaxSetupForm';
import { useSelector } from 'react-redux';
import { getRequiredTaxSaved, getUser } from '../../modules/selectors';

const Description = styled.p``;

export const ShopTaxModal = () => {
  const { registrationProcess, type, taxSettings } = useSelector(getUser);
  const taxIsSaved = useSelector(getRequiredTaxSaved);
  const { setTaxDetails, isOnboardingFinished } = registrationProcess;
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (!taxSettings.isConfirmed || (isOnboardingFinished && !setTaxDetails && type === 'SB')) {
      setIsOpen(true);
    }
  }, [setTaxDetails, isOnboardingFinished, taxSettings]); // eslint-disable-line
  useEffect(() => {
    if (taxIsSaved) {
      setIsOpen(false);
    }
  }, [taxIsSaved]); // eslint-disable-line

  return (
    <Modal isOpen={isOpen} wrapClassName="modal-dark" size="default" centered>
      <ModalHeader>{getTranslationKey('shop.taxConfig.shopModalTitle')}</ModalHeader>
      <ModalBody>
        <Description className="text-muted">
          {getTranslationKey('shop.taxConfig.shopModalText')}
        </Description>
        <ShopTaxSetupForm />
      </ModalBody>
    </Modal>
  );
};
