import { SagaIterator } from 'redux-saga';
import { put, call, select } from 'redux-saga/effects';
import { set } from 'lodash';
import Api from '../../../modules/utils/API';
import {
  errorsGlobalError,
  fetchGalleriesTagsSuccess,
  showNotifySuccess,
  changeGalleryTagState,
  showNotifyFail
} from '../../../modules/actions';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import {
  ISagaAction,
  IGalleryTag,
  RemoveGalleryTagCollectionAction,
  AddGalleryTagCollectionAction
} from '../../../modules/types';
import { getCollectionsNew, getGalleriesTags } from '../../../modules/selectors';
import { changeCollectionStateSuccess } from '../../../modules/actions/collections';

export const fetchGalleriesTagsSaga = function* (): SagaIterator {
  try {
    const response = yield call(Api.GalleriesTags.get);
    ApiErrors.checkOnApiError(response);

    yield put(fetchGalleriesTagsSuccess(response.result));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const addGalleryTagCollectionSaga = function* ({
  payload
}: ISagaAction<AddGalleryTagCollectionAction>): SagaIterator {
  const collection = yield select(getCollectionsNew);

  try {
    const { galleryTag } = payload;

    const response = yield call(Api.GalleriesTags.add, {
      tag: galleryTag.value,
      collectionId: galleryTag.collectionId || undefined,
      type: galleryTag.type
    });
    ApiErrors.checkOnApiError(response);

    if (collection) {
      yield put(
        changeCollectionStateSuccess(set(collection, 'tags', [...collection.tags, response.result]))
      );
    }

    const { all } = yield select(getGalleriesTags);
    yield put(changeGalleryTagState({ all: [...all, response.result] }));

    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
    yield put(changeCollectionStateSuccess(set(collection, 'tags', [...collection.tags])));
    yield put(showNotifyFail());
  }
};

export const removeGalleryTagCollectionSaga = function* ({
  payload
}: ISagaAction<RemoveGalleryTagCollectionAction>): SagaIterator {
  const collection = yield select(getCollectionsNew);

  try {
    const { galleryTag } = payload;
    let specificTag: IGalleryTag = null;

    if (!galleryTag._id) {
      // Fail safe, unlikely to occur
      specificTag = collection.tags.find((tg: any) => tg.value === galleryTag.value);
    }

    const response = yield call(
      Api.GalleriesTags.remove,
      {
        collectionId: galleryTag.collectionId || specificTag?.collectionId
      },
      galleryTag._id || specificTag?._id
    );
    ApiErrors.checkOnApiError(response);

    if (collection) {
      let currentCollectionTags: IGalleryTag[] = collection.tags;

      yield put(
        changeCollectionStateSuccess(
          set(
            collection,
            'tags',
            currentCollectionTags.filter(
              (currentTag) => currentTag._id !== (galleryTag._id || specificTag?._id)
            )
          )
        )
      );
    }

    const { all } = yield select(getGalleriesTags);
    let currentUserTags: IGalleryTag[] = all;
    yield put(
      changeGalleryTagState({
        all: currentUserTags.filter(
          (currentTag) => currentTag._id !== (galleryTag._id || specificTag?._id)
        )
      })
    );
    yield put(showNotifySuccess({}));
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
    yield put(changeCollectionStateSuccess(set(collection, 'tags', [...collection.tags])));
    yield put(showNotifyFail());
  }
};
