import { IStoreState } from '../types';
import { createSelector } from 'reselect';
import { find } from 'lodash';

export const getShop = (state: IStoreState) => state.shop;
export const getProductPrisingListSelector = createSelector(
  getShop,
  ({ pricingList }) => pricingList
);
export const getSelectedPriceListSelector = createSelector(
  getShop,
  ({ selectedPriceList }) => selectedPriceList
);
export const getProductUserPricing = createSelector(
  getShop,
  ({ productUserPricing }) => productUserPricing
);
export const getUserAccountBalanceSelector = createSelector(
  getShop,
  ({ userBalance }) => userBalance
);
export const getProductPricingItemByIDSelector = createSelector(
  getProductPrisingListSelector,
  (list) => (_id: string) => find(list, { _id })
);
export const getShopCouponsSelector = createSelector(getShop, ({ coupons }) => coupons);
export const getShopCouponsFetchedSelector = createSelector(
  getShop,
  ({ isCouponsFetched }) => isCouponsFetched
);
export const getShopCouponByIDSelector = createSelector(
  getShopCouponsSelector,
  (coupons) => (id: string) => find(coupons, { id })
);
export const getProductGroupsList = createSelector(getShop, ({ productGroups }) => productGroups);
export const getProductList = createSelector(getShop, ({ products }) => products);
export const getGlobalShopSettingsSelector = createSelector(
  getShop,
  ({ globalSettings }) => globalSettings
);
export const getShopOrdersSelector = createSelector(getShop, ({ orders }) => orders);
export const getOrderImagesSelector = createSelector(getShop, ({ orderImages }) => orderImages);
export const getLaboratoriesSelector = createSelector(getShop, ({ laboratories }) => laboratories);
export const getOrderListById = createSelector(
  getShopOrdersSelector,
  ({ list }) =>
    (id: string) =>
      find(list, { id })
);
export const getPayDocuments = createSelector(getShop, ({ payDocuments }) => payDocuments);
export const getUserHasEnoughInvoicingData = createSelector(
  getShop,
  ({ userHasEnoughInvoicingData }) => userHasEnoughInvoicingData
);
