import { orderBy as _orderBy } from 'lodash';

export default function (state = { all: [], imageForAppIconCrop: {}, filter: {} }, action) {
  switch (action.type) {
    case 'UPDATE_GALLERY_FILTER': {
      const { gallery_id, key, value } = action.payload;
      let galleryImages = state[gallery_id];
      const imageFilter = { ...state.filter[gallery_id], [key]: value };

      if (galleryImages) {
        galleryImages = galleryImages.map((img) => {
          let matchFilter;

          if (
            imageFilter &&
            (!!imageFilter.originalImageName ||
              (imageFilter.likeCommentFilter && imageFilter.likeCommentFilter !== 'all'))
          ) {
            imageFilter.active = true;
            matchFilter = false;
            if (
              imageFilter.originalImageName !== '' &&
              img.originalImageName.includes(imageFilter.originalImageName)
            ) {
              matchFilter = true;
            }

            if (
              imageFilter.likeCommentFilter &&
              imageFilter.likeCommentFilter !== 'all' &&
              (!imageFilter.originalImageName ||
                (imageFilter.originalImageName !== '' && matchFilter))
            ) {
              switch (imageFilter.likeCommentFilter) {
                case 'like':
                  matchFilter = !!img.like;
                  break;
                case 'noLike':
                  matchFilter = !img.like;
                  break;
                case 'comment':
                  matchFilter = !!img.comment;
                  break;
                case 'noComment':
                  matchFilter = !img.comment;
                  break;
                case 'likeOrComment':
                  matchFilter = !!img.comment || !!img.like;
                  break;
                default:
                  matchFilter = !!img.comment && !!img.like;
              }
            }
          } else {
            imageFilter.active = false;
          }

          return { ...img, matchFilter };
        });
      }

      return {
        ...state,
        filter: {
          ...state.filter,
          [gallery_id]: imageFilter
        },
        [gallery_id]: galleryImages
      };
    }
    case 'SET_IMG_APP_ICON_CROP': {
      return { ...state, imageForAppIconCrop: action.payload };
    }
    case 'UPDATE_GALLERY_IMAGES': {
      const { images, gallery_id } = action.payload;

      return { ...state, [gallery_id]: images };
    }
    case 'ADD_IMG_TO_GALLERY': {
      const { galleryId, images, replaceDuplicates } = action.payload;
      let existingGallery = state[galleryId];

      let modifiedGallery = [];
      if (existingGallery) {
        if (replaceDuplicates) {
          let newImages = [...images];

          existingGallery = existingGallery.map((img) => {
            newImages = newImages.filter((newImg) => {
              if (newImg.originalImageName === img.originalImageName) {
                img.isUploading = true;

                return false;
              }

              return true;
            });

            return { ...img };
          });

          modifiedGallery = [...existingGallery, ...newImages];
        } else {
          modifiedGallery = [...existingGallery, ...images];
        }
      } else modifiedGallery = [...images];

      let uploading = 0;
      let uploaded = 0;

      modifiedGallery.forEach((image) => {
        if (image.isUploading === true) uploading++;
        else if (image.isUploading === false) uploaded++;
      });

      return {
        ...state,
        [galleryId]: modifiedGallery,
        [`${galleryId}_isUploading`]:
          uploaded !== uploading + uploaded
            ? {
                uploading,
                uploaded
              }
            : undefined
      };
    }
    case 'UPDATE_GALLERY_TITLE_IMAGE': {
      const { newTitleImage, oldTitleImage_id } = action.payload;
      const gallery = state[newTitleImage.gallery_id];

      if (gallery) {
        const updatedGallery = gallery.map((img) => {
          if (img._id === newTitleImage.image_id) {
            return { ...img, isTitleImage: true };
          }
          if (img._id === oldTitleImage_id) {
            return { ...img, isTitleImage: false };
          }

          return img;
        });

        return { ...state, [newTitleImage.gallery_id]: updatedGallery };
      }

      return state;
    }
    case 'DELETE_IMAGES_FROM_COLLECTION': {
      const { gallery_id, image_id, image_ids } = action.payload;
      const existingGallery = state[gallery_id];

      if (existingGallery) {
        if (image_id) {
          return {
            ...state,
            [gallery_id]: existingGallery.filter((i) => i._id !== image_id)
          };
        }
        if (image_ids) {
          return {
            ...state,
            [gallery_id]: existingGallery.filter((i) => !image_ids.includes(i._id))
          };
        }
      }

      return {
        ...state,
        [gallery_id]: []
      };
    }
    case 'UPDATE_TEMP_IMAGE_ID': {
      const { temp_id, newImage, gallery_id } = action.payload;
      const galleryImages = state[gallery_id];

      if (galleryImages) {
        let uploading = 0; // eslint-disable-line
        let uploaded = 0; // eslint-disable-line

        const gal = galleryImages.map((image) => {
          const updatedImage = image._id === temp_id ? { ...image, ...newImage } : image;

          if (updatedImage.isUploading === true) uploading++;
          else if (updatedImage.isUploading === false) uploaded++;

          return updatedImage;
        });

        return {
          ...state,
          [gallery_id]: gal,
          [`${gallery_id}_isUploading`]:
            uploaded !== uploading + uploaded
              ? {
                  uploading,
                  uploaded
                }
              : undefined
        };
      }

      return {
        ...state,
        [gallery_id]: undefined,
        [`${gallery_id}_isUploading`]: undefined
      };
    }
    case 'UPDATE_IMAGE': {
      const { newImage, gallery_id } = action.payload;
      const galleryImages = state[gallery_id];
      const gal = galleryImages.map((image) =>
        image._id === newImage._id ? { ...image, ...newImage } : image
      );

      return {
        ...state,
        [gallery_id]: gal
      };
    }
    case 'FETCH_COLLECTION_GALLERYS': {
      const collection = action.payload;
      const collectionGallerys = collection.galleries;

      const gallerysObject = {};
      collectionGallerys &&
        collectionGallerys.forEach((gal) => {
          const filteredImages = collection.images.filter((i) => i.gallery_id === gal._id);

          gallerysObject[gal._id] = _orderBy(filteredImages, [gal.sortBy], [gal.sortOrder]);
        });

      return { ...state, ...gallerysObject };
    }
    default:
      return state;
  }
}
