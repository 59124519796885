import React from 'react';
import styled from 'styled-components';
import { Col, Progress } from 'reactstrap';
import theme from '../../assets/css/theme';

const ProgressBarStyled = styled(Progress)`
  width: 100%;
  height: 20px;
  .bg-success {
    background-color: ${theme.commonColors.second} !important;
  }
  .bg-warning {
    background-color: ${theme.commonColors.second} !important;
    &.progress-bar-animated {
      animation: none;
    }
  }
`;

interface IProps {
  animated?: boolean;
  color?: string;
  value?: number;
  colWidth?: number;
}

export const ProgressBar = ({
  animated = true,
  color = 'success',
  value = 100,
  colWidth = 12
}: IProps) => (
  <Col xs={colWidth}>
    <ProgressBarStyled animated={animated} color={color} value={value} />
  </Col>
);
