import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getModalsStates } from '../../modules/selectors/modals';
import { getTranslationKey } from '../../modules/utils/helpers';
import { getUser } from '../../modules/selectors/user';
import { getHTMLFromText } from '../../helpers/html';
import { startTrialAction } from '../../modules/actions/trial';
import { getDataList } from './data';
import styled from 'styled-components';

const BenefitsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const BenefitsListItem = styled.li`
  margin-top: 5px;
`;

const BenefitsText = styled.span`
  margin-left: 10px;
`;

const NewCustomerModal = () => {
  const dispatch = useDispatch();
  const { first_name } = useSelector(getUser);
  const { newCustomerModal } = useSelector(getModalsStates);
  const dataList = getDataList();

  const startTrial = () => dispatch(startTrialAction());

  return (
    <Modal
      isOpen={newCustomerModal}
      toggle={() => {}}
      centered
      wrapClassName="modal-dark"
      size="default"
    >
      <ModalHeader toggle={null}>{getTranslationKey('newCustomerModal.title')}</ModalHeader>
      <ModalBody>
        <h3>{getTranslationKey('newCustomerModal.contentTitle')}</h3>
        <div className="mt-2 mb-2">
          {getTranslationKey('newCustomerModal.text_1', { userName: first_name })}
        </div>
        <BenefitsList>
          {dataList.map(({ icon, text }, idx) => (
            <BenefitsListItem key={idx}>
              {icon}
              <BenefitsText>{text}</BenefitsText>
            </BenefitsListItem>
          ))}
        </BenefitsList>
        {getHTMLFromText(getTranslationKey('newCustomerModal.text_2'))}
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={startTrial} className="btn btn-outline">
          {getTranslationKey('newCustomerModal.startTrial')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewCustomerModal;
