import React, { Component } from 'react';
import { connect } from 'react-redux';
import Texts from '../../../json/texts.json';
import * as actions from '../../../actions';
import { Col, Row } from 'reactstrap';
import NewWatermark from './NewWatermark';
import EditWatermark from './EditWatermark';
import Card, { CardBody, CardFooter } from '../../../components/Card';
import { FaPen, FaPlus, FaTrashAlt } from 'react-icons/fa';

class Watermarks extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.editWatermark = this.editWatermark.bind(this);
    this.toggleEditWatermark = this.toggleEditWatermark.bind(this);
  }

  getSortedWatermarks(watermarks) {
    const watermarksArr = Object.values(watermarks);
    const idx = watermarksArr.findIndex((el) => el.isDefault);
    const splicedItem = watermarksArr.splice(idx, 1) || [];

    return [...splicedItem, ...watermarksArr];
  }

  toggleEditWatermark() {
    this.setState((prevState) => ({
      editWatermarkOpen: !prevState.editWatermarkOpen,
      editwatermark_id: null
    }));
  }

  editWatermark(editwatermark_id) {
    this.setState({ editWatermarkOpen: true, editwatermark_id });
  }

  render() {
    const { watermarks, deleteWatermark, notify } = this.props;
    const { editWatermarkOpen, editwatermark_id } = this.state;

    return (
      <Row>
        <EditWatermark
          isOpen={editWatermarkOpen}
          toggle={this.toggleEditWatermark}
          watermark={watermarks[editwatermark_id]}
        />
        {this.getSortedWatermarks(watermarks).map(
          (value, index) =>
            value &&
            value._id && (
              <Col sm="12" md="12" lg="6" key={index}>
                <Card>
                  <CardBody className="h-200 text-center position-relative border rounded">
                    {value.type === 'image' && value.watermarkPublicKey ? (
                      <img
                        src={window.CDN_URL + `/public/${value.watermarkPublicKey}`}
                        className="img-fluid watermark-preview-card max-h-150"
                        alt=""
                      />
                    ) : (
                      <h2 className="watermark-preview-card">{value.text}</h2>
                    )}
                  </CardBody>
                  <CardFooter className="mt-3 d-flex justify-content-between">
                    {value.description}
                    <div className="d-flex align-items">
                      <FaPen
                        className="m-t-1 m-r-10 font-lg cursor-pointer color-primary"
                        onClick={() => this.editWatermark(value._id)}
                      />
                      {!value.isDefault && (
                        <FaTrashAlt
                          className="font-lg cursor-pointer color-primary"
                          onClick={() => deleteWatermark(value._id, null, notify)}
                        />
                      )}
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            )
        )}
        <Col sm="12" md="12" lg="6">
          <NewWatermark onWatermarkCreated={({ _id }) => this.editWatermark(_id)}>
            <Card className="text-center cursor-pointer">
              <CardBody className="h-200 text-center position-relative border rounded">
                <img
                  className="img-fluid watermark-preview-card max-h-150"
                  src="/assets/images/add-image-placeholder-icon.svg"
                  alt=""
                />
              </CardBody>
              <CardFooter className="mt-3 d-flex justify-content-between">
                {Texts[window.LANGUAGE_SW].watermark.add}
                <FaPlus className="color-primary" />
              </CardFooter>
            </Card>
          </NewWatermark>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps({ watermarks, notify }) {
  return { watermarks, notify };
}

export default connect(mapStateToProps, actions)(Watermarks);
