import React, { useEffect, useState } from 'react';
import { IDictionary, IPriceItem } from '../../modules/types';
import { useDispatch } from 'react-redux';
import { updatePriceValueAction } from '../../modules/actions';
import { InputItem } from '../Forms';
import styled from 'styled-components';
import { displayAmount } from '../../modules/utils/helpers/display-price';

const Wrapper = styled.div`
  input {
    &:required,
    &:invalid {
      box-shadow: none;
    }
  }
`;

const getValidValue = (
  name: string,
  value: number,
  price: IPriceItem,
  min?: number,
  max?: number,
  onlyEven?: boolean
) => {
  if (typeof min === 'number' && value < min) {
    value = min;
  }

  if (typeof max === 'number' && value > max) {
    value = max;
  }

  if (onlyEven && value % 2 !== 0) {
    value = Math.floor(value / 2) * 2;
  }

  if (name === 'priceTotal') {
    const min = displayAmount(price.resellerPriceTotal).toUnit();

    return value < min ? min : parseFloat(value.toFixed(2));
  }

  if (name === 'additionalImagePriceTotal') {
    return parseFloat(value.toFixed(2));
  }

  if (name === 'additionalPagePriceTotal') {
    return parseFloat((value / 2).toFixed(2));
  }

  if (name === 'imagesIncluded') {
    return Math.round(value);
  }

  if (name === 'pagesIncluded') {
    return Math.round(value * 2);
  }

  return value;
};

const getMin = (name: string, price: IPriceItem) => {
  const mins: IDictionary<number> = {
    priceTotal: displayAmount(price.resellerPriceTotal).toUnit(),
    default: 0
  };

  return mins[name] || mins.default;
};

interface IProps<T extends number | string> {
  name: string;
  value?: T;
  onChange?: (value: T) => void;
  disabled?: boolean;
  price: IPriceItem;
  min?: number;
  max?: number;
  onlyEven?: boolean;
}

export const PriceInput = <T extends number | string>({
  name,
  value = '' as T,
  disabled = false,
  price,
  min,
  max,
  onlyEven,
  onChange
}: IProps<T>) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(value as any);
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const updateInputValue = () => {
    const validated = getValidValue(name, parseFloat(inputValue), price, min, max, onlyEven);
    const validatedInputValue = name === 'pagesIncluded' ? validated / 2 : validated;
    const isFormatted = ['imagesIncluded', 'pagesIncluded'].indexOf(name) === -1;
    setInputValue(
      isFormatted
        ? String(displayAmount(validatedInputValue * 100).toFormat('0.00'))
        : validatedInputValue
    );
    dispatch(
      updatePriceValueAction({
        id: price._id,
        data: {
          key: name,
          value: isFormatted
            ? parseFloat(String((validated * 100).toFixed()))
            : parseFloat(String(validated))
        }
      })
    );
  };
  const step = ['imagesIncluded', 'pagesIncluded'].indexOf(name) > -1 ? '1' : '0.1';
  const changeCallback = (changedValue: string) => {
    setInputValue(changedValue);
    if (onChange) {
      onChange((typeof value === 'string' ? changedValue : parseFloat(changedValue)) as T);
    }
  };

  return (
    <Wrapper>
      <InputItem
        type="number"
        step={step}
        changeCallback={changeCallback}
        onBlurCallback={updateInputValue}
        value={inputValue}
        placeholder="-"
        disabled={disabled}
        min={typeof min === 'number' ? min : getMin(name, price)}
        max={typeof max === 'number' ? max : undefined}
      />
    </Wrapper>
  );
};
