import axios from '../helpers/axios';
import * as errors from '../errors';
import Texts from '../json/texts.json';
import { accessRulesAction, fetchPresetsAction } from '../modules/actions';
import { changeModalState } from '../modules/actions/modals';

export const setUserBrandValue = (data) => (dispatch) =>
  dispatch({ type: 'SET_USER_BRAND_VALUE', payload: data });

export const getUserBrands = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/userBrands?ownerRequest=true');

    dispatch({ type: 'SET_USER_BRANDS', payload: res.data });
  } catch (e) {
    console.log(e);
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const createUserBrand = (data, notify) => async (dispatch) => {
  try {
    const res = await axios.post('/api/userBrand', data);
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyCreated,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'SET_USER_BRAND', payload: res.data });
    dispatch(accessRulesAction());

    return res.data._id;
  } catch (e) {
    dispatch(
      changeModalState({
        key: 'upgradeModal',
        state: true,
        desiredAction: 'brands',
        modalData: { addon: 'brands' }
      })
    );
  }
};

export const updateUserBrand = (data, notify) => async (dispatch) => {
  try {
    const res = await axios.put('/api/userBrand', data);
    notify &&
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].successfullyEdited,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 3000
      });
    dispatch({ type: 'SET_USER_BRAND', payload: res.data });
  } catch (e) {
    notify &&
      notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].generalError,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 3000
      });
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const deleteUserBrand = (userBrand_id, notify) => async (dispatch) => {
  try {
    if (window.confirm(Texts[window.LANGUAGE_SW].userBrand.rlyDelete)) {
      await axios.delete('/api/userBrand', { params: { userBrand_id } });
      notify({
        title: Texts[window.LANGUAGE_SW].success,
        message: Texts[window.LANGUAGE_SW].successfullyDeleted,
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 3000
      });
      dispatch({ type: 'REMOVE_USER_BRAND', payload: userBrand_id });
      dispatch(accessRulesAction());
      dispatch(fetchPresetsAction());

      return true;
    }

    return false;
  } catch (e) {
    notify({
      title: Texts[window.LANGUAGE_SW].error,
      message: Texts[window.LANGUAGE_SW].generalError,
      status: 'error',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};
