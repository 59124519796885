import { SagaIterator } from 'redux-saga';
import { put, select, call, all, take } from 'redux-saga/effects';
import { errorsGlobalError, changeModalState, showNotifyFail } from '../../../modules/actions';
import { getSubscription } from '../../../modules/selectors/subscription';
import { get } from 'lodash';
import queryString from 'query-string';
import { SUBSCRIPTION_INTERVALS } from '../../../modules/constants';
import { Api } from '../../../modules/utils';
import ApiErrors from '../../../modules/utils/API/APIErrors';
import { AnyAction } from 'redux';
import { fetchPaymentInfoAction, putPaymentInfoAction } from '../../actions/paymentInfo';
import { notify } from 'reapop';
import { getTranslationKey } from '../../utils/helpers/index';
import { updateSubscriptionStore } from '../../../modules/actions/subscription';
import {
  IRestartSubscriptionActionSource,
  ISubscriptionItem
} from '../../../modules/types/subscriptions';
import { getPaymentInfo } from '../../../modules/selectors/paymentInfo';
import { dispatchAction, getCurrentUser } from '../../../actions';
import { ISagaAction } from '../../types';
import { ISetupIntentFailed } from '../../types/subscription';
import { getUser } from '../../selectors';

export const buySubscriptionSaga = function* (): SagaIterator {
  try {
    yield put(updateSubscriptionStore({ isRedirecting: false }));
    const subscription = yield select(getSubscription);
    const { plan, billingCycle, brandsAmount, domainsAmount, templates, videoStorage, slideshows } =
      subscription;
    const interval = get(SUBSCRIPTION_INTERVALS, billingCycle, '');
    const linkPath = `${plan}/${interval}`;
    const querySymbol = templates || videoStorage || slideshows ? '?' : '';
    const data: any = {};
    if (templates) {
      data.templates = templates;
    }
    if (videoStorage) {
      data.videoStorage = videoStorage;
    }
    if (brandsAmount) {
      data.brandsAmount = brandsAmount;
    }
    if (domainsAmount) {
      data.domainsAmount = domainsAmount;
    }
    if (slideshows) {
      data.slideshowsPlan = 'unlimited';
    }

    const query = queryString.stringify(data, { arrayFormat: 'bracket' });
    localStorage.setItem('subscriptionConfig', JSON.stringify(subscription));

    const res = yield call(Api.User.getBuyUrl, { linkPath, querySymbol, query });
    ApiErrors.checkOnApiError(res);

    // just until dgigistore fixed the pop up bug
    yield put(updateSubscriptionStore({ isRedirecting: true }));
    window.location.href = res.url;
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const cancelSubscriptionSaga = function* (actions: AnyAction): SagaIterator {
  try {
    const res = yield call(Api.User.cancelSubscription, actions.payload);

    console.log('res', res);

    yield put(fetchPaymentInfoAction());
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const restartSubscriptionSaga = function* (actions: AnyAction): SagaIterator {
  try {
    const { subscriptions, paymentProvider } = yield select(getPaymentInfo);
    const {
      payload: { subscriptionType, actionSource }
    } = actions;

    const { billing_status, rebilling_start_url, result } = yield call(
      Api.User.restartSubscription,
      {
        subscriptionType
      }
    );

    if (paymentProvider !== 'stripe' && billing_status !== 'paying') {
      if (rebilling_start_url) {
        window.open(rebilling_start_url, '_blank');
        return;
      }

      const subSelected = subscriptions.find(
        (subscription: ISubscriptionItem) => subscription.subscriptionType === subscriptionType
      );

      if (subSelected.renewUrl) {
        yield put(
          changeModalState({
            key: 'activeCancelledSubModal',
            state: false
          })
        );
        yield put(
          changeModalState({
            key: 'restartSubErrorModal',
            state: true,
            modalData: {
              renewUrl: subSelected.renewUrl
            }
          })
        );

        return;
      }

      throw result;
    }

    yield put(
      changeModalState({
        key: 'activeCancelledSubModal',
        state: false
      })
    );
    yield put(
      notify({
        title: getTranslationKey('success'),
        status: 'success',
        position: 'tc',
        dismissible: true,
        dismissAfter: 7500
      })
    );
    yield put(fetchPaymentInfoAction());

    if (
      paymentProvider !== 'stripe' &&
      actionSource === IRestartSubscriptionActionSource.ActiveCancelledSubModal
    ) {
      yield call(buySubscriptionSaga);
    }
  } catch (e) {
    yield put(showNotifyFail());
    yield put(errorsGlobalError(e as ErrorEvent));
    yield put(
      changeModalState({
        key: 'activeCancelledSubModal',
        state: false
      })
    );
  }
};

export const updateBillingAddressSaga = function* (actions: AnyAction): SagaIterator {
  try {
    let billingAddpayload = actions.payload;
    const location = billingAddpayload.routerPath;
    const justUpdate = billingAddpayload.justUpdate;

    if (billingAddpayload.waitForTaxSettingsUpdate) {
      yield take('SET_USER_TAX_SETTINGS');
    }

    delete billingAddpayload.justUpdate;
    delete billingAddpayload.routerPath;
    delete billingAddpayload.waitForTaxSettingsUpdate;

    if (location === '/subscription' || location === '/subscription/pricing/payment') {
      actions.payload.setApi = true;
    }

    let paymentInfo = yield select(getPaymentInfo);
    yield put(
      putPaymentInfoAction({
        ...paymentInfo,
        updatingAddress: true
      })
    );

    const res = yield call(Api.User.updateBillingAddress, actions.payload);

    paymentInfo = yield select(getPaymentInfo);
    yield put(
      putPaymentInfoAction({
        ...paymentInfo,
        updatingAddress: false
      })
    );

    if (!res.error) {
      yield all([
        put(fetchPaymentInfoAction()),
        put(
          notify({
            title: getTranslationKey('success'),
            status: 'success',
            position: 'tc',
            dismissible: true,
            dismissAfter: 7500
          })
        ),
        // @ts-expect-error
        yield put(getCurrentUser())
      ]);

      if (justUpdate || paymentInfo.paymentProvider === 'stripe') return;

      if (location !== '/subscription') {
        yield call(buySubscriptionSaga);
      } else {
        yield put(
          changeModalState({
            key: 'billingAddressChangeModal',
            state: true
          })
        );
      }
    } else {
      throw res.error || res.response.data.error;
    }
  } catch (e) {
    yield put(errorsGlobalError(e as ErrorEvent));
  }
};

export const setupIntentFailed = function* ({
  payload
}: ISagaAction<ISetupIntentFailed>): SagaIterator {
  try {
    const response = yield call(Api.Subscription.setupIntentFailed, payload);
    ApiErrors.checkOnApiError(response);

    const user = yield select(getUser);
    yield put({
      type: 'SET_USER',
      payload: {
        subscription: {
          ...user.subscription,
          state: 'payment_setup_problem',
          stripe: {
            ...user.subscription.stripe,
            paymentSetup: {
              clientSecret: payload.clientSecret,
              returnUrl: payload.returnUrl
            }
          }
        }
      }
    });
  } catch (e) {
    console.error('Failed to track event', e);
  }
};
