import React, { useEffect } from 'react';
import { getDevelopedTemplates } from '../../modules/selectors/templates';
import { useDispatch, useSelector } from 'react-redux';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import styled, { css } from 'styled-components';
import { fetchTemplates } from '../../modules/actions';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { setModalOpenState } from '../../actions';

const Wrapper = styled.div``;

const TemplateImage = styled.img`
  width: 100%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
`;

interface ITemplateItem {
  isSelected: boolean;
  isBlocked: boolean;
}

const blockedCss = css`
  ${TemplateImage} {
    &:hover {
      cursor: not-allowed;
    }
  }
`;
const TemplateItem = styled.div<ITemplateItem>`
  width: 150px;
  flex: 0 0 auto;
  margin: 5px 5px 5px 5px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: ${(props) => (props.isSelected ? '0px 0px 15px -2px rgb(92, 135, 98)' : 'none')};
  ${(props) => props.isBlocked && blockedCss};
`;

type Props = {
  values: {
    selectedTemplateId: string;
  };
  setValue: (values: Partial<Props['values']>) => void;
};

export const TemplatesList = ({ values, setValue }: Props) => {
  const dispatch = useDispatch();
  const templates = useSelector(getDevelopedTemplates);

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  const onSelect = (templateId: any) => {
    const template = templates.find((template) => template._id === templateId);
    if (template.userIsAllowedToUseTemplate) {
      setValue({ selectedTemplateId: templateId });
    } else {
      dispatch(
        setModalOpenState({
          key: 'upgradeModal',
          state: true,
          modalData: {
            addon: 'template',
            templateId
          }
        })
      );
    }
  };

  const isTemplateSelected = (templateID: string) => values.selectedTemplateId === templateID;

  return (
    <Wrapper>
      <ScrollMenu
        data={templates.map((template) => (
          <TemplateItem
            key={template._id}
            isSelected={isTemplateSelected(template._id)}
            isBlocked={false}
          >
            <TemplateImage id={template._id} src={template.cardUrl} alt="TemplateImage" />
          </TemplateItem>
        ))}
        arrowLeft={
          <FaChevronLeft size={15} color="gray" style={{ marginRight: '5px', cursor: 'pointer' }} />
        }
        arrowRight={
          <FaChevronRight size={15} color="gray" style={{ marginLeft: '5px', cursor: 'pointer' }} />
        }
        innerWrapperStyle={{ padding: '10px' }}
        scrollToSelected
        selected={values.selectedTemplateId}
        onSelect={onSelect}
      />
    </Wrapper>
  );
};
export default TemplatesList;
