import React from 'react';
import { InputItem } from './InputItem';
import styled from 'styled-components';

const ErrorsLine = styled.div`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f86c6b;
`;

interface IField {
  placeholder: string;
  type: string;
  title: string;
  tooltip_id: string;
  tooltip_text: string;
}

interface IMeta {
  touched: boolean;
  error: any[];
}

interface IProps {
  input: any;
  field: IField;
  meta: IMeta;
}

export const InputGroup = ({
  input,
  field: { placeholder, type, title, tooltip_id, tooltip_text },
  meta: { touched, error = [] }
}: IProps) => {
  const isError = touched && error.length;
  const getErrorFormClass = isError ? 'form-control is-invalid' : 'form-control';

  return (
    <div className="form-group">
      <InputItem
        {...input}
        type={type}
        value={input.value}
        changeCallback={undefined}
        placeholder={placeholder}
        label={title}
        withTooltip={!!tooltip_id}
        tooltipText={tooltip_text}
        id={tooltip_id}
        className={getErrorFormClass}
      />
      {isError ? error.map((e: string, index) => <ErrorsLine key={index}>{e}</ErrorsLine>) : null}
    </div>
  );
};
