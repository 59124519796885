import querystring from 'query-string';

export default function (digistore, utm = {}) {
  try {
    if (digistore && digistore.discountKey && digistore.discountKey._id) {
      return (
        '/#/subscription/pricing?' +
        querystring.stringify({
          a: 1, // to avaoid parse error when getting parameters from hash url
          ...digistore.discountKey,
          discountKey: digistore.discountKey._id,
          _id: undefined,
          replaceDiscountKey: 1,
          ...utm
        })
      );
    }

    return (
      '/#/subscription/pricing?' +
      querystring.stringify({
        a: 1, // to avaoid parse error when getting parameters from hash url
        ...utm
      })
    );
  } catch (e) {
    console.log(e);

    return '/#/subscription/pricing';
  }
}
