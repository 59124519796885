import { createReducer } from 'redux-act';
import { IModalStateChangeAction } from '../../types/modals';

export interface IModalsReducerState {
  createGalleryOpenState: boolean;
  moveToGalleryOpenState: boolean;
  exportImagesModal: boolean;
  changeGalleryTextModal: boolean;
  selectWaterMarkModal: boolean;
  cropImageModal: boolean;
  setFocalPointModal: boolean;
  duplicatesImagesModal: boolean;
  addGalleryVideoModal: boolean;
  addGalleryVideoEmbedModal: boolean;
  appVersionModal: boolean;
  watermarkProcessingModal: boolean;
  presetsSettingModal: boolean;
  addGallerySlideshowModal: boolean;
  arrangeSlidesModal: boolean;
  chooseCreationModal: boolean;
  chooseCollectionPhotosModal: boolean;
  addMusicModal: boolean;
  addTitleModal: boolean;
  createSlideshowModal: boolean;
  editTitleSlideModal: boolean;
  logoModal: boolean;
  newCustomerModal: boolean;
  subscriptionCancelModal: boolean;
  upgradeModal: boolean;
  collectionModal: boolean;
  featuresDowngradeModal: boolean;
  billingAddressChangeModal: boolean;
  emailTextModal: boolean;
  addNewMailPackageModal: boolean;
  deleteMailPackageModal: boolean;
  templateChangesConfirmationModal: boolean;
  gallerySettingsModal: boolean;
  firstGalleryImagesModal: boolean;
  modalData: { [key: string]: any };
  activeCancelledSubModal: boolean;
  restartSubErrorModal: boolean;
  mobileRestrictionModal: boolean;
  progressUploadModal: boolean;
  progressUploadCloseModal: boolean;
  spaceLimitReachedModal: boolean;
  updateAddonsModal: boolean;
}

const initialState: IModalsReducerState = {
  createGalleryOpenState: false,
  moveToGalleryOpenState: false,
  exportImagesModal: false,
  changeGalleryTextModal: false,
  selectWaterMarkModal: false,
  cropImageModal: false,
  setFocalPointModal: false,
  duplicatesImagesModal: false,
  addGalleryVideoModal: false,
  addGalleryVideoEmbedModal: false,
  appVersionModal: false,
  watermarkProcessingModal: false,
  presetsSettingModal: false,
  addGallerySlideshowModal: false,
  arrangeSlidesModal: false,
  chooseCreationModal: false,
  chooseCollectionPhotosModal: false,
  addMusicModal: false,
  addTitleModal: false,
  createSlideshowModal: false,
  editTitleSlideModal: false,
  logoModal: false,
  newCustomerModal: false,
  subscriptionCancelModal: false,
  collectionModal: false,
  upgradeModal: false,
  featuresDowngradeModal: false,
  billingAddressChangeModal: false,
  emailTextModal: false,
  addNewMailPackageModal: false,
  deleteMailPackageModal: false,
  templateChangesConfirmationModal: false,
  gallerySettingsModal: false,
  firstGalleryImagesModal: false,
  modalData: {},
  activeCancelledSubModal: false,
  restartSubErrorModal: false,
  mobileRestrictionModal: false,
  progressUploadModal: false,
  progressUploadCloseModal: false,
  spaceLimitReachedModal: false,
  updateAddonsModal: false
};

export const modals = createReducer<IModalsReducerState>({}, initialState);
modals.on('SET_MODAL_STATE', (state: IModalsReducerState, payload: IModalStateChangeAction) => {
  return {
    ...state,
    [payload.key]: payload.state,
    modalData: { ...state.modalData, ...payload?.modalData } || {}
  };
});
