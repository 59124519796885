import axios from '../helpers/axios';
import * as errors from '../errors';
import Texts from '../json/texts.json';
import { accessRulesAction, fetchPresetsAction, fetchDomainsSuccess } from '../modules/actions';
import { changeModalState } from '../modules/actions/modals';

export const getDomains = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/domains');
    dispatch({ type: 'SET_ALL_DOMAINS', payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};

export const createDomain = (domain, notify) => async (dispatch, getState) => {
  try {
    const { domainsNew } = getState();

    const res = await axios.post('/api/domain/', domain);
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyCreated,
      status: 'success',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'ADD_DOMAIN', payload: res.data });
    dispatch(accessRulesAction());
    dispatch(fetchDomainsSuccess([...domainsNew.items, res.data]));
  } catch (e) {
    const { status } = e?.response ?? {};

    switch (status) {
      case 402:
        dispatch(
          changeModalState({
            key: 'upgradeModal',
            state: true,
            desiredAction: 'domains',
            modalData: { addon: 'domains' }
          })
        );
        break;
      case 422:
        notify({
          title: Texts[window.LANGUAGE_SW].error,
          message: Texts[window.LANGUAGE_SW].generalError,
          status: 'error',
          position: 'tc',
          dismissible: true,
          dismissAfter: 15000
        });
        break;
      default:
        errors.handleErrorWithRedirectIfNotAuth(e);
    }
  }
};

export const deleteDomain = (domain_id, notify) => async (dispatch, getState) => {
  try {
    const { domainsNew } = getState();

    await axios.delete('/api/domain/', { params: { domain_id } });
    notify({
      title: Texts[window.LANGUAGE_SW].success,
      message: Texts[window.LANGUAGE_SW].successfullyDeleted,
      status: 'info',
      position: 'tc',
      dismissible: true,
      dismissAfter: 3000
    });
    dispatch({ type: 'REMOVE_DOMAIN', payload: domain_id });
    dispatch(accessRulesAction());

    const newList = domainsNew.items.filter((item) => item._id !== domain_id);
    dispatch(fetchDomainsSuccess(newList));

    dispatch(fetchPresetsAction());
  } catch (e) {
    if (e.response.status === 428) {
      notify({
        title: Texts[window.LANGUAGE_SW].error,
        message: Texts[window.LANGUAGE_SW].customDomains.deleteImprintErrorInUse,
        status: 'error',
        position: 'tc',
        dismissible: true,
        dismissAfter: 10000
      });
    } else errors.handleErrorWithRedirectIfNotAuth(e, notify);
  }
};

export const updateDomain = (domain) => async () => {
  try {
    await axios.put('/api/domain/', { params: domain });
    // dispatch({ type: "UPDATE_DOMAIN", payload: res.data });
  } catch (e) {
    errors.handleErrorWithRedirectIfNotAuth(e);
  }
};
