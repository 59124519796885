import React from 'react';
import { getTranslationKey } from '../../modules/utils/helpers';
import { FaCheck } from 'react-icons/fa';
import styled from 'styled-components';

const StyledFaCheck = styled(FaCheck)`
  color: green;
`;

export const getDataList = () => [
  { icon: <StyledFaCheck />, text: getTranslationKey('newCustomerModal.benefit_1') },
  { icon: <StyledFaCheck />, text: getTranslationKey('newCustomerModal.benefit_2') },
  { icon: <StyledFaCheck />, text: getTranslationKey('newCustomerModal.benefit_3') },
  { icon: <StyledFaCheck />, text: getTranslationKey('newCustomerModal.benefit_4') },
  { icon: <StyledFaCheck />, text: getTranslationKey('newCustomerModal.benefit_5') },
  { icon: <StyledFaCheck />, text: getTranslationKey('newCustomerModal.benefit_6') }
];
