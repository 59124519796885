import { useLocation } from 'react-router';
import useTracking from './use-tracking';

export default function useTrackClick() {
  const track = useTracking();
  const location = useLocation();

  return (buttonId: string, extraPayload?: { [key: string]: any }) => {
    track('clicked-action', {
      button_id: buttonId,
      location: location.pathname,
      ...extraPayload
    });
  };
}
