import { setDeliveryFormAction } from '../../modules/actions';
import { getDeliveryFormSelector } from '../../modules/selectors';
import { IEmailDeliveryForm } from '../../modules/types/emails';
import { useDispatch, useSelector } from 'react-redux';

const useDeliveryForm = (): [IEmailDeliveryForm, (form: Partial<IEmailDeliveryForm>) => void] => {
  const dispatch = useDispatch();
  const deliveryForm = useSelector(getDeliveryFormSelector);
  const setDeliveryForm = (form: Partial<IEmailDeliveryForm>) =>
    dispatch(setDeliveryFormAction(form));

  return [deliveryForm, setDeliveryForm];
};

export default useDeliveryForm;
